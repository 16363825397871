export default {
  required: '* This field is required.',
  required2: 'Required',
  email: 'Must be a valid email address.',
  max: {
    string: 'The field may not be greater than {max} characters.'
  },
  maxLength: 'Must be {max} characters or less.',
  unique: 'Must be unique.',
  saveDuplicatedMessage: 'That condition is already saved'
}
