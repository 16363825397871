export default {
  profileSettings: {
    title: 'プロフィール設定',
    basicInformation: {
      title: '基本情報',
      subtitle: '写真 / 連絡先 / 住所 / ビザ'
    },
    job: {
      title: '経験',
      subtitle: '経験職種',
      selectFromList: {
        cantFind: '検索する',
        selected: '選択済み',
        new: '新規',
        add: '追加',
        cancel: 'キャンセル',
        seeMore: '全て表示',
        selectJobRole: '経験職種を選択',
        list: 'リスト'
      },
      required: '必須',
      years: '年数',
      selectFromListText: 'リストから選択',
      jobRole: '職種',
      save: '保存する',
      category: 'カテゴリ',
      selectedJobRole: '現在の経験職種',
      addJobRole: '経験職種を追加する'
    },
    skill: {
      title: 'スキル',
      subtitle: 'ITスキル / 専門領域',
      selectFromList: {
        selectITSkill: '経験職種を選択'
      },
      languageOrTools: '言語またはツール',
      addITSkill: 'ITスキルを追加する',
      selectedITSkill: '選択されたITスキル'
    },
    workExperience: {
      title: '経歴',
      subtitle: '在籍企業 / 職務経験'
    },
    preference: {
      title: '希望条件',
      subtitle: '業界 / 職種 / 年収 / ほか'
    },
    licenseAward: {
      title: 'ライセンス / 表彰',
      subtitle: '資格 / 受賞歴 / ほか',
      licenseOrAwardPlaceholder: 'ライセンスまたは表彰',
      addLicenseOrAward: 'ライセンス/表彰の追加',
      licenseOrCertification: 'ライセンス/認証',
      yearOfAcquisition: '取得年',
      award: '表彰',
      license: 'ライセンス'
    },
    education: {
      title: '学歴',
      subtitle: '学校 / 教育'
    },
    language: {
      title: '言語',
      subtitle: '語学力 / 資格'
    },
    preview: 'プレビュー',
    addFromList: 'Add from List'
  },
  accountSettings: {
    title: 'アカウント設定',
    credentials: {
      title: 'ID / パスワード',
      subtitle: 'ID / パスワード / その他情報',
      loginFields: {
        login: 'ログインID',
        password: 'パスワード'
      },
      phoneFields: {
        label: '電話番号',
        countryCode: '国コード',
        phoneNumber: '電話番号'
      },
      additionalFields: {
        label: 'その他の情報',
        linkedIn: 'LinkedIn'
      }
    },
    email: {
      title: 'メール',
      subtitle: 'メール受信設定'
    },
    exclude: {
      title: '企業ブロック',
      subtitle: '特定の企業をブロック設定'
    },
    inform: {
      title: '入社報告',
      subtitle: '転職お祝い金の受け取り申請'
    },
    private: {
      title: 'プロフィール非公開',
      subtitle: '非公開設定 / 退会手続き'
    },
    delete: {
      title: '削除',
      subtitle: 'アカウントを削除する'
    }
  }
}
