const data = {
  loading: false,
  data: {},
  dirty: false,
  cvData: {},
  account_information: {}
}

export default {
  namespaced: true,
  state() {
    return {
      ...data
    }
  },
  getters: {
    sanitizedFilter: (state, getters) => {
      const languages = {
        ...(state.data.native ? { native: state.data.native } : {}),
        ...(state.data.englishLevel
          ? { english: state.data.englishLevel }
          : {}),
        ...(state.data.englishCertificates
          ? { english_certificates: state.data.englishCertificates }
          : {}),
        ...(state.data.otherLanguages
          ? { other_languages: state.data.otherLanguages }
          : {})
      }

      return {
        ...(state.data.jobPositions.length
          ? { job_positions: state.data.jobPositions }
          : {}),
        ...(state.data?.work_details?.length
          ? { work_details: state.data.work_details }
          : {}),
        ...(state.data.currency ? { currency: state.data.currency } : {}),
        ...(state.data.current_working_status
          ? { current_working_status: state.data.current_working_status }
          : {}),
        ...(state.data.work_summary
          ? { work_summary: state.data.work_summary }
          : {}),
        ...(state.data.annual_income
          ? { latest_annual_income: state.data.annual_income }
          : {}),
        ...(state.data.areas?.length ? { areas: state.data.areas } : {}),
        ...(state.data.skills?.length ? { skills: state.data.skills } : {}),
        ...(state.data.japaneseLevel
          ? { japanese_language_level: state.data.japaneseLevel }
          : {}),
        ...(state.data.jlpt ? { jlpt: state.data.jlpt } : {}),
        ...(languages ? { languages: languages } : {}),
        ...(state.data.preferredTypes
          ? { preferred_type: state.data.preferredTypes }
          : {}),
        ...(state.data.educations?.length
          ? { educations: state.data.educations }
          : {}),
        ...(state.data.registerToGTalent
          ? { subscribed_to_gtalent: state.data.registerToGTalent }
          : {}),
        ...(state.data.firstName ? { first_name: state.data.firstName } : {}),
        ...(state.data.lastName ? { last_name: state.data.lastName } : {}),
        ...(state.data.nationality
          ? { nationality: state.data.nationality }
          : {}),
        ...(state.data.dateOfBirth
          ? { date_of_birth: state.data.dateOfBirth }
          : {}),
        ...(state.data.furiganaFirstName
          ? { furigana_first_name: state.data.furiganaFirstName }
          : {}),
        ...(state.data.furiganaLastName
          ? { furigana_last_name: state.data.furiganaLastName }
          : {}),
        ...(state.data.gender ? { gender: state.data.gender } : {}),
        ...(state.data.visaExpirationDate
          ? { visa_expiration_date: state.data.visaExpirationDate }
          : {}),
        ...(state.data.workPermission
          ? { work_permission_id: state.data.workPermission }
          : {}),
        ...(state.data.currentAddress
          ? { current_address: state.data.currentAddress }
          : {}),
        ...(state.data.currentCountryResidence
          ? { current_country_residence: state.data.currentCountryResidence }
          : {}),
        ...(state.data.currentCityResidence
          ? { current_city_residence: state.data.currentCityResidence }
          : {}),
        ...(state.data.latestAnnualIncome
          ? { latest_annual_income: state.data.latestAnnualIncome }
          : {}),
        ...(state.data.desiredAnnualSalary
          ? { desired_annual_salary: state.data.desiredAnnualSalary }
          : {}),
        ...(state.data.licenses?.length
          ? { licenses: state.data.licenses }
          : {}),
        ...(state.data.awards?.length ? { awards: state.data.awards } : {}),
        ...(state.data.dialingCode ? { dialing_code_id: state.data.dialingCode } : {}),
        ...(state.data.number?.length ? { phone: state.data.number } : {})
      }
    }
  },
  mutations: {
    /**
     * Set loading.
     *
     * @param {*} state
     * @param {*} loading
     */
    SET_LOADING(state, loading) {
      state.loading = loading
    },
    /**
     * Set data.
     *
     * @param {*} state
     * @param {*} items
     */
    SET_DATA(state, items) {
      state.data = items
    },

    SET_CV_DATA(state, payload) {
      state.cvData = payload.data || payload
    },
    /**
     * Set the dirty state of the form.
     * @param {*} state
     * @param {*} dirty
     */
    SET_DIRTY(state, dirty) {
      state.dirty = dirty
    },
    /**
     * Reset all state.
     * @param {*} state
     */
    RESET(state) {
      for (const key in state) {
        state[key] = data[key]
      }
    },

    SET_ACCOUNT_INFORMATION(state, data) {
      state.account_information = data
    }
  },
  actions: {
    /**
     * Set the loading state
     *
     * @param {*} param0
     * @param {*} loading
     */
    setLoading({ commit }, loading) {
      commit('SET_LOADING', loading)
    },
    /**
     * Set the data.
     *
     * @param {*} state
     * @param {*} data
     */
    setData({ commit }, data) {
      commit('SET_DATA', data)
    },

    setCVData({ commit }, data) {
      commit('SET_CV_DATA', data)
    },
    /**
     * Set the dirty state.
     * @param {*} state
     * @param {*} dirty
     */
    setDirty({ commit }, dirty) {
      commit('SET_DIRTY', dirty)
    },
    /**
     * Reset all state.
     * @param {*} state
     */
    reset({ commit }) {
      commit('RESET')
    },

    setAccountInformation({ commit }, data) {
      commit('SET_ACCOUNT_INFORMATION', data)
    }
  }
}
