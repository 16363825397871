export default {
  tags: {
    title: 'Sign Up',
    meta: {
      description: 'This page is the Sign Up page.',
      keywords:
        'Sign Up, Japan, Jobs, Foreigners, Job Change, IT, Web, Engineers, GitTap',
      og: {
        site_name: 'GitTap',
        title: 'Entry Form | Find IT Jobs in Japan - GitTap',
        description:
          'Entry Form of GitTap. GitTap is a matching platform of IT Jobs in Japan for foreigners. We specialize in finding jobs for all IT Engineers at high-profile Japanese companies. '
      }
    }
  },
  banner: 'グローバルな環境で活躍したいあなたへ',
  title: 'アカウントを作成',
  subtitle: 'アカウントを作成すると、',
  and: 'および',
  more: 'に同意したものと見なされます。',
  links: {
    termsPre: '',
    terms: '利用規約',
    and: 'と',
    termsPost: 'に同意する',
    privacy: 'プライバシーポリシー',
    handlingPersonalInformation: '個人情報の取り扱い',
    login: 'こちらから'
  },
  footer: {
    one: 'または以下のアカウントでサインイン',
    two: 'すでにGitTapアカウントをお持ちの場合は、',
    three: 'ログインできます'
  },
  form: {
    email: {
      label: 'Eメール',
      placeholder: 'メールアドレスを入力してください'
    },
    password: {
      label: 'パスワード',
      placeholder: 'パスワードを設定してください'
    }
  },
  create: 'アカウントを作成する',
  validation: {
    password: 'パスワードは英数字で8文字以上にする必要があります',
    required: '必須。'
  },
  success: {
    title: '認証メールが送信されました。',
    checkEmail:
      'GitTapからの受信メールを確認し<br>ログイン～登録を完了させてください。',
    body: 'まだ会員登録は完了していません！',
    or: 'か',
    login: 'ログイン'
  },
  verified: {
    title: 'アカウントの認証が完了しました',
    body: '「まずはプロフィール情報をつくりましょう。」',
    failed: {
      title: '認証に失敗しました',
      body: 'URLが無効または期限切れです。',
      actionLabel: '再登録'
    }
  },
  social: {
    google: 'Googleで新規登録',
    facebook: 'Facebookで新規登録',
    linkedIn: 'LinkedInで新規登録',
    github: 'Githubで新規登録'
  },
  loginLabel: {
    google: 'Googleでログイン',
    linkedIn: 'LinkedInでログイン',
    github: 'Githubでログイン',
    facebook: 'Facebookでログイン'
  },
  tokenError:
    'このURLはすでに使用されています。招待者へ新しいURL の発行を依頼してください。',
  signupToGitTap: '新規登録',
  alreadyMember: '既にアカウントをお持ちの方はこちらから',
  orContinueWithEmail: 'メールアドレスで新規登録'
}
