export default {
  basicInformation: '基本情報',
  skill: 'スキル',
  japaneseLevel: '日本語レベル',
  hintHeader: 'プロフィールが充実しているほど、面接につながりやすくなります！',
  hints: {
    skills: 'ITスキルは最新の情報になっていますか？',
    experienceSummary: '職務経験の詳細は記入しましたか？',
    workDetail: '経験したプロジェクトを記入しましたか？'
  },
  help: [
    {
      header: '応募前にプロフィールを充実させて選考突破率UP',
      content: '多くの企業はあなたの「ITスキル」と「経歴の詳細」をみて判断します。 まずは選考突破に向けて、プロフィールを積極的に記入しましょう！'
    },
    {
      header: 'テンプレート機能を活用してみましょう！',
      content: 'テンプレート機能は、簡単入力だけでなく日本企業のフォーマットに沿って記載できるので読んでもらえる可能性がアップします！'
    }
  ],
  message: 'メッセージ',
  confirm: '確認',
  confirmation: '確認',
  messageHint: '志望動機と自己PRを書けば、企業から返信されやすくなります！',
  messagePlaceholder: 'I saw the posting for the Full stack engineer on GitTap and I’m interested in the role. I’d like to be a part of your team and be involved in its creation and use my skills.',

  // Profile
  top: 'トップ',
  skills: 'ITスキル',
  preference: '希望条件',
  workExperience: '職歴',
  noImage: 'No Image',
  profileVideo: '紹介動画',
  residence: '{residence}在住',
  yearsOld: '{years}歳',
  company: '会社',
  latestEducation: '最終学歴',
  major: '専攻',
  degree: '学位',
  workVisa: '就労ビザ',
  englishLevel: '英語レベル',
  workingInJapan: '日系企業経験',
  jlpt: 'JLPT（日本語能力試験）',
  otherJapaneseQualifications: 'その他日本語資格',
  englishQualifications: '英語の資格',
  otherLanguages: 'その他の言語',
  languageDetailsNote: '語学スキルの詳細はページ下部で確認できます。',
  canBe: 'あり',
  areaOfExpertise: '経験分野',
  star: 'Star',
  contribution: 'Contribution',
  pullRequest: 'Pull Request',
  desiredCondition: '希望条件',
  currentAnnualIncome: '現在年収',
  desiredPosition: '希望ポジション',
  preferredPrefectures: '希望勤務地',
  preferredEmploymentType: '雇用形態',
  priorityItem: '優先項目',
  nationwide: '全国',
  workHistoryAndBiography: '職歴 概要と略歴',
  latestOrderFromTop: '上から最新順',
  companyName: '会社名',
  jobTitleIndustry: '役職',
  years: '年数',
  experiencedJobCategory: '経験職種',
  workHistorySummary: '職歴概要',
  japaneseTranslation: '日本語翻訳',
  workHistoryNumber: '職歴{number}',
  workInJapan: '日本国内勤務',
  industry: '業界',
  companySize: '企業規模',
  employmentStatus: '雇用形態',
  jobTitleRole: '役職・役割',
  lengthOfService: '勤続年数',
  jobDescription: '職務内容',
  hBetweenOriginalAndJapanese: '原文と日本語を切替',
  developmentLanguage: '開発言語',
  roleScale: '役割/規模',
  seeMore: '続きをみる',
  current: '現行',
  fullScreen: 'Full Screen',
  year: '{years}年',
  months: '1ヶ月以内 | 1ヶ月 | {months}ヶ月',
  confirmationDialog: {
    header: 'プロフィール情報を充実させると選考通過確率が2~3倍 高まります。',
    applyProfile: 'このプロフィール情報で応募する',
    editProfile: '編集する',
    addMessage: '企業にメッセージを送れます'
  },
  leavePageMessage: '入力したメッセージは削除されます。このページから離れますか？',
  jobTitleAndIndustry: '役職 / 業界'
}
