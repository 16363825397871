export default {
  title: 'パスワードを変更する',
  current: {
    label: '現在のパスワード',
    placeholder: '（現在のパスワードを入力してください）'
  },
  new: {
    label: '新しいパスワード',
    placeholder: '(alphabetand123)'
  },
  confirm: {
    label: '新しいパスワードを確認',
    placeholder: '（同上）'
  },
  submit: '新しいパスワードを保存',
  validations: {
    oldPassword: {
      required: '現在のパスワードは必須です',
      incorrect: 'このパスワードは間違っています。'
    },
    newPassword: {
      required: '新しいパスワードは必須です',
      minLength: '8文字以上にする必要があります',
      maxLength: '最大20文字です。',
      same: '新しいパスワードは現在のパスワードと異なるものにしてください',
      alphaAndNumeric: 'アルファベットと数字を含める必要があります。'
    },
    passwordConfirmation: {
      required: '新しいパスワードの確認入力は必須です',
      matched: 'パスワードが一致しません'
    }
  },
  success: 'パスワードは正常に変更されました'
}
