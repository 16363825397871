import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VMenu,{attrs:{"offset-y":"","bottom":"","left":"","tile":"","max-width":"450","content-class":"notif-menu"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VBtn,_vm._g({staticClass:"text-none btn-notif",class:{active: _vm.active},staticStyle:{"padding-right":"8px !important"},attrs:{"text":""},on:{"click":_vm.toggle}},on),[(_vm.admin_notification.unread === 0 || _vm.admin_notification.unread === undefined)?_c(VBadge,{staticClass:"nav-list notif-disabled",attrs:{"overlap":"","dot":"","value":_vm.admin_notification.unread}},[_c(VIcon,{attrs:{"color":"#626262"},domProps:{"textContent":_vm._s('mdi-bell')}})],1):_c(VBadge,{staticClass:"nav-list",attrs:{"color":"red","overlap":"","content":_vm.unreadAdminNotifications,"value":_vm.admin_notification.unread}},[_c(VIcon,{attrs:{"color":"#626262"},domProps:{"textContent":_vm._s('mdi-bell')}})],1)],1)]}}])},[_c('NotificationList',{attrs:{"items":_vm.admin_notification.items}}),_c(VDivider),_c('div',{staticClass:"see-all"},[_c('span',{staticClass:"primary--text text-none",on:{"click":function($event){return _vm.$router.push({name: 'NotificationList'})}}},[_vm._v(" "+_vm._s(_vm.$t('notification.see_all'))+" ")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }