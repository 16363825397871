import api from '../index'

const {
  http,
  index
} = api

/**
 * ==================
 * Inform got offer API
 * ==================
 */
export default {
  baseUrl: `${process.env.VUE_APP_COMMON_URL}/api/v1`,
  url: 'faqs',
  http,
  index
}
