import api from '../index'

const {
  http,
  index,
  store,
  update,
  destroy,
  simpleSearch
} = api

/**
 * =======================================================
 * Applicant Education API
 * =======================================================
 */
export default {
  baseUrl: `${process.env.VUE_APP_CUSTOMER_URL}/api/personal/v1`,
  url: 'applicant-educations',
  http,
  index,
  store,
  update,
  destroy,
  simpleSearch,
  /**
   * Sync applicant degrees
   *
   */
  sync (payload) {
    return this.http(this.baseUrl)
      .post(`${this.url}/sync`, payload)
  }
}
