import api from '../../index'

const {
  http,
  index
} = api

/**
 * ====================================================
 * Industry Category API
 * ====================================================
 */
export default {
  baseUrl: `${process.env.VUE_APP_CUSTOMER_URL}/api/v1`,
  url: 'industry-categories',
  http,
  index
}
