import applicantExperience from '@/api/customer/applicant-experience'

const data = {
  items: [],
  loading: false
}

export default {
  namespaced: true,
  state () {
    return {
      ...data
    }
  },
  mutations: {
    /**
     * Set the status.
     *
     * @param {*} state
     * @param {*} items
     */
    SET_LOADING (state, loading) {
      state.loading = loading
    },

    /**
     * Set the items state
     *
     * @param {*} state
     * @param {*} items
     */
    SET_ITEMS (state, items) {
      state.items = items
    }
  },
  actions: {
    /**
     * Get the work_experiences
     *
     */
    setItems ({ commit }, items) {
      commit('SET_ITEMS', items)
    },

    /**
     * Get the work_experiences
     *
     */
    fetch ({ dispatch, commit }, id) {
      const params = {
        where: [
          {
            applicant_id: id
          }
        ],
        orderBy: [
          {
            column: 'current',
            descending: true
          },
          {
            column: 'end_date',
            descending: true
          },
          {
            column: 'start_date',
            descending: true
          }
        ],
        perPage: 10,
        with: [
          { relationship: 'companySize' },
          { relationship: 'employmentType' },
          { relationship: 'applicantExperienceIndustries.industry.industryCategory' },
          { relationship: 'applicantExperienceWorkDetails' }
        ]
      }

      commit('SET_LOADING', true)

      return applicantExperience.advancedSearch(params)
        .then(({ data }) => commit('SET_ITEMS', data.data))
        .catch(({ response }) => dispatch('httpException/handle', response, { root: true }))
        .finally(() => commit('SET_LOADING', false))
    }
  }
}
