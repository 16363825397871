/**
 * =======================================
 * API Request for Authorization
 * =======================================
 */
import axios from 'axios'
import Config from '@/config/app'
import lang from '@/services/lang'

const locale = lang.get() ? lang.get().value : null

export default {
  baseUrl: Config.services.auth.url,

  /**
   * Login Request
   *
   * @param {*} params
   * @return {*} http
   */
  authGoogle (params) {
    const headers = {
      Accept: 'application/json',
      'Accept-Language': locale
    }

    return axios.post(`${this.baseUrl}/api/v1/login/google`, params, { headers, withCredentials: true })
  },

  /**
   * Get Google Auth Url
   *
   * @return {*} http
   */
  googleAuthUrl (params) {
    const headers = {
      Accept: 'application/json',
      'Accept-Language': locale
    }

    return axios.post(`${this.baseUrl}/api/v1/login/google/popup`, params, { headers, withCredentials: true })
  },

  /**
   * Get Facebook Auth Popup url
   *
   * @return {*} http
   */
  facebookPopup (params) {
    const headers = {
      Accept: 'application/json',
      'Accept-Language': locale
    }

    return axios.post(`${this.baseUrl}/api/v1/login/facebook/popup`, params, { headers, withCredentials: true })
  },

  /**
   * Auth User Request for LinkedIn
   *
   * @param {*} params
   * @return {*} http
   */
  authLinkedin (params) {
    const headers = {
      Accept: 'application/json',
      'Accept-Language': locale
    }

    return axios.post(`${this.baseUrl}/api/v1/login/linkedin`, params, { headers, withCredentials: true })
  },

  /**
   * Acess Token Request for LinkedIn
   *
   * @param {*} params
   * @return {*} http
   */
  linkedinToken (params) {
    const headers = {
      Accept: 'application/json',
      'Accept-Language': locale
    }

    return axios.post(`${this.baseUrl}/api/v1/login/linkedin/token`, params, { headers, withCredentials: true })
  },

  /**
   * Auth User Request for Github
   *
   * @param {*} params
   * @return {*} http
   */
  authGithub (params) {
    const headers = {
      Accept: 'application/json',
      'Accept-Language': locale
    }

    return axios.post(`${this.baseUrl}/api/v1/login/github`, params, { headers, withCredentials: true })
  },

  /**
   * Acess Token Request for Github
   *
   * @param {*} params
   * @return {*} http
   */
  githubToken (params) {
    const headers = {
      Accept: 'application/json',
      'Accept-Language': locale
    }

    return axios.post(`${this.baseUrl}/api/v1/login/github/token`, params, { headers, withCredentials: true })
  }
}
