import uniqBy from 'lodash/uniqBy'

export default {
  namespaced: true,
  strict: process.env.NODE_ENV === 'development',
  state () {
    return {
      conditions: [],
      loading: false
    }
  },
  getters: {
    /**
     * Check if has conditions
     * @return bool
     */
    hasConditions: state => {
      return state.conditions.length > 0
    }
  },
  mutations: {
    /**
     * Set the conditions.
     *
     * @param {*} state
     * @param {*} conditions
     */
    SET_CONDITIONS (state, conditions) {
      state.conditions = conditions
    },
    /**
     * Set the conditions.
     *
     * @param {*} state
     * @param {*} value
     */
    SET_LOADING (state, value) {
      state.loading = value
    }
  },
  actions: {
    /**
     * Set the conditions.
     *
     * @param {*} state
     * @param {*} conditions
     */
    setConditions ({ commit }, conditions) {
      commit('SET_CONDITIONS', conditions)
    },
    /**
       * Set the conditions.
       *
       * @param {*} state
       * @param {*} condition
       */
    addCondition ({ commit, state }, condition) {
      let conditions = state.conditions

      conditions = uniqBy(conditions.concat(condition))

      commit('SET_CONDITIONS', conditions)
    },
    /**
     * Remove keyword from conditions.
     *
     * @param {*} state
     * @param {*} condition
     */
    removeCondition ({ commit, state }, keyword) {
      const conditions = state.conditions.filter((item) => item !== keyword)

      commit('SET_CONDITIONS', conditions)
    },
    /**
     * Start loading.
     *
     * @param {commit}
     */
    startLoading ({ commit }) {
      commit('SET_LOADING', true)
    },
    /**
     * Start loading.
     *
     * @param {commit}
     */
    stopLoading ({ commit }) {
      commit('SET_LOADING', false)
    }
  }
}
