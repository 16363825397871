import company from '@/api/client/company'

export default {
  namespaced: true,
  state () {
    return {
      loading: false,
      items: []
    }
  },
  mutations: {
    /**
     * Set the items.
     *
     * @param {*} state
     * @param {*} items
     */
    SET_ITEMS (state, items) {
      state.items = items
    },
    /**
     * Set the status.
     *
     * @param {*} state
     * @param {*} items
     */
    SET_LOADING (state, loading) {
      state.loading = loading
    },
    setRankings (state) {
      state.items = state.items.sort((logo1, logo2) => (logo1.rank > logo2.rank) ? 1 : -1)
    }
  },
  actions: {
    /**
     * Fetch all companyLogoJobs records.
     *
     * @param { commit, dispatch, state}
     */
    fetch ({ commit, dispatch, state }, params) {
      if (state.items.length) return

      commit('SET_LOADING', true)

      return company.logos()
        .then(response => commit('SET_ITEMS', response.data))
        .catch(({ response }) => dispatch('httpException/handle', response, { root: true }))
        .finally(() => commit('SET_LOADING', false))
    }
  }
}
