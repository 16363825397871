import applyApi from '@/api/common/applies'

export default {
  namespaced: true,
  state () {
    return {
      loading: false,
      items: [],
      pagination: {
        per_page: 6,
        page: 1
      }
    }
  },
  mutations: {
    /**
     * Set loading.
     *
     * @param {*} state
     * @param {*} loading
     */
    SET_LOADING (state, loading) {
      state.loading = loading
    },
    /**
     * Set items.
     *
     * @param {*} state
     * @param {*} items
     */
    SET_ITEMS (state, items) {
      state.items = items
    },
    /**
     * Set pagination.
     *
     * @param {*} state
     * @param {*} pagination
     */
    SET_PAGINATION (state, pagination) {
      state.pagination = pagination
    }
  },
  actions: {
    /**
     * Fetch items
     * If items is already populated skip the process
     *
     * @param { getters, dispatch} params
     */
    softFetch ({ getters, dispatch }, params) {
      if (getters.hasItems) return
      dispatch('fetch', params)
    },
    /**
     * Fetch Items
     *
     * @param {commit, dispatch} object
     */
    fetch ({ commit, dispatch }, params) {
      commit('SET_LOADING', true)

      return applyApi.search(params)
        .then(({ data }) => {
          if ('data' in data && typeof (data) === 'object') {
            commit('SET_ITEMS', data.data)

            delete data.data

            commit('SET_PAGINATION', data)
          }
        })
        .catch(({ response }) => {
          dispatch('httpException/handle', response, { root: true })
        })
        .finally(() => {
          commit('SET_LOADING', false)
        })
    }
  }
}
