export default {
  basicInformation: '基本情報',
  experience: '経験',
  skill: 'スキル',
  career: '経歴',
  education: '学歴',
  language: '言語',
  licenseAward: 'ライセンス / 表彰',
  preference: '希望条件',
  preview: 'プレビュー',
  edit: '項目を編集する',
  save: '保存する',
  messageHeader: 'この項目を削除しますか？',
  messageBody: '削除後に変更を保存してください。',
  buttonCancel: 'キャンセル',
  buttonAction: '削除',
  invalidFileSize: '最大ファイルサイズは5MBまでです。',
  invalidFileFormat: 'ファイルは画像（.jpg、.jpeg、.png）である必要があります。',
  currentWork: '現在の仕事',
  periodFrom: '開始年月',
  periodTo: '終了年月',
  workedInJapan: ' 日本での勤務経験',
  jobTitle: '職名',
  companyName: '会社名',
  companySize: '会社（従業員）の規模',
  employmentType: '雇用形態',
  workDetail: 'プロジェクトの詳細/作業の詳細',
  selectTemplate: 'テンプレートを選択',
  experienceSection: {
    area: '経験分野'
  },
  itSkills: '使用ITスキル',
  roleSize: '役割/規模',
  addProject: '項目を追加',
  selectTemplate: 'テンプレートを選択',
  deleteProject: 'プロジェクトを削除する',
  experienceSection: {
    area: '経験分野'
  },
  careerSection: {
    workSummary: '経験のまとめ',
    currentWorkStatus: '雇用状況',
    currency: '年収の通貨',
    annualIncome: '年収',
    workDetail: '経歴の詳細',
    listIsAutomatically: 'リストは経歴の新しいもの順に自動的にソートされます。'
  },
  introductionVideo: '自己紹介動画',
  uploadVideo: {
    text1: 'ファイルをアップロード',
    text2: '（50MBまで）'
  },
  invalidVideoSize: '最大ファイルサイズは50MBまでです。',
  invalidVideoFormat: 'ファイルはビデオ（.mp4、.mov、.webm）である必要があります。',
  shortVideo: '動画',
  shortVideoDescription: '簡単な自己紹介動画をアップすることで、企業に語学力をアピールできます',
  deleteVideo: '動画を削除',
  deleteMessage: '動画を削除しますか？',
  languageSection: {
    englishCertificate: '資格・表彰',
    otherLanguage: 'その他の言語',
    score: 'スコア',
    level: 'レベル'
  },
  profileVideo: '紹介動画',
  fiveSelectMaximum: '最大5個まで選択可能です',
  male: '男性',
  female: '女性',
  other: 'その他',
  uploadHeader: {
    header: 'プロフィール自動入力',
    body: 'レジュメをアップロードして簡単入力',
    button: 'アップロード'
  },
  dialogUpload: {
    header: 'レジュメをアップロードして簡単登録',
    body: 'ドラッグ&ドロップ or ファイルを選択',
    message: {
      message1: '“経歴や経験スキルについて詳細情報が書かれたレジュメをアップロードしてください',
      message2: ''
    },
    supportedFiles: {
      message: 'アップロード可能なファイル：pdf, doc, docx, rtf, txt',
      fileSize: '（容量：5MB）'
    },
    blankItems: '空欄の項目にのみ入力',
    overwriteItems: 'すべての項目を上書き'
  },
  uploadSuccess: {
    header: '自動入力が成功しました',
    body: '取り込んだ情報が正しいか確認、おかしな箇所があれば修正しましょう',
    checkbox: {
      item1: '残りの項目も追加入力することで企業の評価が高まります',
      item2: 'ページを離れる前には保存しましょう'
    }
  },
  emptyField: '未入力の項目があります'
}
