<template>
  <v-app>
    <AppSidenav />
    <AppToolbar />
    <div
      class="error-container d-flex justify-center align-center white--text"
    >
      <div class="display-error my-10 text-center">
        <h1 class="code">
          404
        </h1>
        <p class="mb-10">
          {{ $t('common.The page you are looking for is not found') }}
        </p>
        <v-btn
          color="primary"
          dark
          large
          width="150"
          @click="$router.push('/')"
        >
          {{ $t('common.homeButton') }}
        </v-btn>
      </div>
    </div>
    <AppFooter />
  </v-app>
</template>
<script>
import AppToolbar from '@/components/Toolbar/AppToolbar'
import AppSidenav from '@/components/AppSidenav'
import AppFooter from '@/components/AppFooter'

export default {
  components: {
    AppToolbar,
    AppSidenav,
    AppFooter
  },
  data () {
    return {
      //
    }
  },
  methods: {
    goTo (link) {
      this.$router.push({ name: link })
    }
  }
}
</script>

<style scoped>
.code {
  font-size: 120px;
  font-family: Anton;
  font-weight: 400;
}
.error-container {
  background-image: linear-gradient(to right, #023352 , #298895);
  height: 100%;
}
.error-container p {
  font-size: 16px;
  font-family: Roboto;
  font-weight: 300;
}
.v-btn >>> span {
  text-transform: capitalize;
  font-size: 15px;
}
</style>
