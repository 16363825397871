import omitBy from 'lodash/omitBy'
import isNull from 'lodash/isNull'
import isUndefined from 'lodash/isUndefined'
import { DateTime } from 'luxon'
import logger from '@/services/logger'

const getIDs = (items) => {
  if (!items) return null
  if (Array.isArray(items)) {
    const selections = items.map((item) => item.id)
    return selections.length > 0 ? selections : null
  } else {
    return items ? items.id : null
  }
}

const getPostedDate = (postedTime) => {
  if (!postedTime) return null
  return DateTime.now().minus({ days: postedTime.value }).toFormat('yyyy-LL-dd')
}

export default {
  namespaced: true,
  strict: process.env.NODE_ENV === 'development',
  state () {
    return {
      conditions: {},
      rawConditions: {},
      loading: false,
      open: false,
      filterId: null,
      keyword: null
    }
  },
  getters: {
    /**
     * Has advance conditions
     */
    hasAdvanceConditions: (state, getters) => {
      const propToIgnore = 'popular_keywords'
      const propToIgnore2 = 'japan_prefecture_id'
      const propToIgnore3 = 'job_position_id'

      let hasAdvanceFilters = false

      const filteredKeys = Object.keys(getters.apiFiltersForSearch).filter(key => key !== propToIgnore && key !== propToIgnore2 && key !== propToIgnore3)

      if (filteredKeys.length > 0) {
        hasAdvanceFilters = true
      } else {
        hasAdvanceFilters = false
      }

      return hasAdvanceFilters
    },
    /**
     * Raw Filter
     */
    filters: state => {
      return {
        popular_keywords: state.rawConditions.popular_keywords || [],
        japan_prefecture_id: state.rawConditions.japan_prefecture_id || [],
        job_position_id: state.rawConditions.job_position_id || [],
        appeal_points: state.rawConditions.appeal_points || [],
        employment_type_id: state.rawConditions.employment_type_id || null,
        industries: state.rawConditions.industries || [],
        featured_it_skills: state.rawConditions.featured_it_skills || [],
        it_skills: state.rawConditions.it_skills || [],
        language_id: state.rawConditions.language_id || null,
        language_score_level_id: state.rawConditions.language_score_level_id || null,
        jlpt_score_level_id: state.rawConditions.jlpt_score_level_id || [],
        minimum_preferred_annual_income: state.rawConditions.minimum_preferred_annual_income || null,
        posted_time: state.rawConditions.posted_time || null,
        foreigner_percent_id: state.rawConditions.foreigner_percent_id || null,
        english_usage_percent_id: state.rawConditions.english_usage_percent_id || null,
        required_english: state.rawConditions.required_english || null,
        required_japanese: state.rawConditions.required_japanese || null
      }
    },
    /**
     * Check if has advance condition chips
     */
    checkIfHasAdvanceConditionChips: (state, getters) => {
      // added this condition to check every filters of the search bar if has a value.
      try {
        const filters = Object.entries(getters.filters)
        for (var a = 0; a < filters.length; a++) {
          var value = filters[a]
          if (Array.isArray(value[1])) {
            if (value[1].length !== 0) {
              return true
            }
          } else {
            if (value[1] !== null) {
              return true
            }
          }
        }
        return false
      } catch (error) {
        logger(error)

        return false
      }
    },
    /**
     * Filter for api request
     */
    apiFiltersForSearch: state => {
      // added this condition to combined it skills & featured it skills
      let itSkills = []
      if (getIDs(state.rawConditions.it_skills)) itSkills = itSkills.concat(getIDs(state.rawConditions.it_skills))
      if (getIDs(state.rawConditions.featured_it_skills)) itSkills = itSkills.concat(getIDs(state.rawConditions.featured_it_skills))

      const languages = []

      if (getIDs(state.rawConditions.required_english)) languages.push({ language_id: 12, language_score_level_id: getIDs(state.rawConditions.required_english) })
      if (getIDs(state.rawConditions.required_japanese)) languages.push({ language_id: 23, language_score_level_id: getIDs(state.rawConditions.required_japanese) })

      const popularKeywords = state.rawConditions.popular_keywords ? state.rawConditions.popular_keywords.map(item => item.name) : []
      const minimumPreferredAnnualIncome = !!state.rawConditions.minimum_preferred_annual_income ? state.rawConditions.minimum_preferred_annual_income.value : null

      const filters = {
        popular_keywords: popularKeywords,
        japan_prefecture_id: getIDs(state.rawConditions.japan_prefecture_id),
        job_position_id: getIDs(state.rawConditions.job_position_id),
        appeal_points: getIDs(state.rawConditions.appeal_points),
        employment_type_id: getIDs(state.rawConditions.employment_type_id),
        industries: getIDs(state.rawConditions.industries),
        it_skills: itSkills.length > 0 ? itSkills : null,
        language_id: getIDs(state.rawConditions.language_id),
        language_score_level_id: getIDs(state.rawConditions.language_score_level_id),
        jlpt_score_level_id: getIDs(state.rawConditions.jlpt_score_level_id),
        minimum_preferred_annual_income: minimumPreferredAnnualIncome,
        posted_time: getPostedDate(state.rawConditions.posted_time),
        foreigner_percent_id: getIDs(state.rawConditions.foreigner_percent_id) || null,
        english_usage_percent_id: getIDs(state.rawConditions.english_usage_percent_id) || null,
        languages: languages.length > 0 ? languages : null
      }

      return omitBy(filters, isNull, isUndefined)
    },
    /**
     * Filter for api request for storing
     */
    apiFiltersForStoring: state => {
      const filters = {
        popular_keywords: getIDs(state.rawConditions.popular_keywords),
        japan_prefecture_id: getIDs(state.rawConditions.japan_prefecture_id),
        job_position_id: getIDs(state.rawConditions.job_position_id),
        appeal_points: getIDs(state.rawConditions.appeal_points),
        employment_type_id: getIDs(state.rawConditions.employment_type_id),
        industries: getIDs(state.rawConditions.industries),
        it_skills: getIDs(state.rawConditions.it_skills),
        featured_it_skills: getIDs(state.rawConditions.featured_it_skills),
        language_id: getIDs(state.rawConditions.language_id),
        language_score_level_id: getIDs(state.rawConditions.language_score_level_id),
        jlpt_score_level_id: getIDs(state.rawConditions.jlpt_score_level_id),
        minimum_preferred_annual_income: getIDs(state.rawConditions.minimum_preferred_annual_income),
        posted_time: state.rawConditions.posted_time ? state.rawConditions.posted_time.id : null,
        foreigner_percent_id: getIDs(state.rawConditions.foreigner_percent_id) || null,
        english_usage_percent_id: getIDs(state.rawConditions.english_usage_percent_id) || null,
        required_english: getIDs(state.rawConditions.required_english) || null,
        required_japanese: getIDs(state.rawConditions.required_japanese) || null
      }

      return omitBy(filters, isNull, isUndefined)
    },

    filtersForDisplay: (state, getters) => {
      const japanPrefectureIds = state.rawConditions.japan_prefecture_id
        ? state.rawConditions.japan_prefecture_id.filter((condition) => {
            return !['work_remotely', 'work_remotely_nationwide'].includes(
              condition.id
            )
          })
        : []

      const filters = {
        remote_work: getIDs(state.rawConditions.remote_work) || null,
        japan_prefecture_id: getIDs(japanPrefectureIds)
      }

      const obj = {
        ...getters.apiFiltersForStoring
      }

      return omitBy(Object.assign(obj, filters), isNull, isUndefined)
    },
    /**
     * Getting Cascade Keys
     * Ex: Removing Language => Removing Language Score Level too
     */
    cascadeRelatedKFilterKeys: () => {
      return {
        language_id: [
          'language_score_level_id'
        ]
      }
    },

    /**
     * Has basic filter
     */
    hasBasicFilter: (state, getters) => {
      const keysToInclude = ['japan_prefecture_id', 'job_position_id']
      const basicFilterKey = Object.keys(getters.apiFiltersForSearch).filter(key => keysToInclude.includes(key))
      return basicFilterKey.length > 0
    }
  },
  mutations: {
    /**
     * Set the condition.
     *
     * @param {*} state
     * @param {*} condition
     */
    SET_CONDITION (state, condition) {
      state.conditions = condition
    },
    /**
     * Set the raw condition.
     *
     * @param {*} state
     * @param {*} condition
     */
    SET_RAW_CONDITION (state, condition) {
      state.rawConditions = condition
    },
    /**
     * Set the raw condition.
     *
     * @param {*} state
     * @param {*} condition
     */
    SET_FILTER_ID (state, data) {
      state.filterId = data
    },
    /**
     * Set the filter keyword
     *
     * @param {*} state
     * @param {*} condition
     */
    SET_FILTER_KEYWORD (state, data) {
      state.keyword = data
    },
    /**
     * Set the condition.
     *
     * @param {*} state
     * @param {*} filter
     * @param {*} value
     */
    SET_FIELD_CONDITION (state, { filter, value }) {
      state.conditions[filter] = value
      state.rawConditions[filter] = value
    },
    /**
     * Set the condition.
     *
     * @param {*} state
     * @param {*} value
     */
    SET_LOADING (state, value) {
      state.loading = value
    },
    /**
     * Set the open.
     *
     * @param {*} state
     * @param {*} value
     */
    SET_OPEN (state, value) {
      state.open = value
    }
  },
  actions: {
    /**
     * Set the condition.
     *
     * @param {*} state
     * @param {*} conditions
     */
    setCondition ({ commit }, condition) {
      commit('SET_CONDITION', condition)
    },
    /**
     * Set the raw condition.
     *
     * @param {*} state
     * @param {*} conditions
     */
    setRawCondition ({ commit }, condition) {
      commit('SET_RAW_CONDITION', condition)
    },
    /**
     * Start loading.
     *
     * @param {commit}
     */
    startLoading ({ commit }) {
      commit('SET_LOADING', true)
    },
    /**
     * Start loading.
     *
     * @param {commit}
     */
    stopLoading ({ commit }) {
      commit('SET_LOADING', false)
    },
    /**
     * Open dialog.
     *
     * @param {commit}
     */
    openDialog ({ commit }) {
      commit('SET_OPEN', true)
    },
    /**
     * close dialog.
     *
     * @param {commit}
     */
    closeDialog ({ commit }) {
      commit('SET_OPEN', false)
    },
    /**
     * set Filter Id
     *
     * @param {commit}
     */
    setFilterId ({ commit }, data) {
      commit('SET_FILTER_ID', data)
    },
    /**
     * set Filter Id
     *
     * @param {commit}
     */
    setFilterKeyword ({ commit }, data) {
      commit('SET_FILTER_KEYWORD', data)
    },
    /**
     * Reset Field
     * @param {*} commit
     * @param {*} filter
     */
    resetAdvanceSearchField ({ commit, state }, filter) {
      commit('SET_FIELD_CONDITION', { filter: filter, value: null })
      commit('SET_RAW_CONDITION', state.rawConditions)
      commit('SET_CONDITION', state.conditions)
    }
  }
}
