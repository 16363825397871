import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VRow,{attrs:{"justify":"center"}},[_c(VDialog,{attrs:{"value":_vm.alertDialog,"persistent":"","max-width":_vm.maxWidth,"scrollable":""}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c(VCardText,[_vm._v(_vm._s(_vm.body))]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","text":"","rounded":""},on:{"click":function($event){return _vm.closeAndAgree()}}},[_vm._v(" "+_vm._s(_vm.actionLabel)+" ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }