export default {
  '/gittap-info': 'GitTapとは何ですか？',
  '/jobs': '仕事を探す',
  '/faq-inquiry': 'FAQ /お問い合わせ',
  '/blog': 'ブログ',
  '/contact-us': 'お問い合わせ',
  '/terms-of-use': '利用規約',
  '/privacy-policy': 'プライバシーポリシー',
  '/handling-personal-information': '個人情報の取り扱い',
  '/search': 'もっと求人を探す',
  '/personal-information': 'プロフィール',
  '/sign-up': 'サインアップ',
  '/login': 'ログイン',
  '/personal-information/profile': 'プロフィール',
  '/personal-information/skill-experience': 'スキル/経験',
  '/personal-information/preference': '希望条件',
  '/about-us': '企業情報',
  '/account-settings': 'アカウント'
}
