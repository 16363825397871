import videoInterviewApi from '@/api/common/video-interview'

export default {
  namespaced: true,
  state () {
    return {
      loading: false,
      videoInterview: null
    }
  },
  mutations: {
    /**
     * Set loading.
     *
     * @param {*} state
     * @param {*} loading
     */
    SET_LOADING (state, loading) {
      state.loading = loading
    },
    /**
     * Set the video interview.
     *
     * @param {*} state
     * @param {*} items
     */
    SET_VIDEO_INTERVIEW (state, data) {
      state.videoInterview = data
    }
  },
  actions: {
    /**
     * Fetch Items
     *
     * @param {commit, dispatch} object
     */
    fetch ({ commit, dispatch }, id) {
      commit('SET_LOADING', true)
      commit('SET_VIDEO_INTERVIEW', null)

      return videoInterviewApi.show(id)
        .then(({ data }) => {
          commit('SET_VIDEO_INTERVIEW', data)
        })
        .catch(({ response }) => {
          dispatch('httpException/handle', response, { root: true })
        })
        .finally(() => {
          commit('SET_LOADING', false)
        })
    }
  }
}
