import applicantPositionExperience from '@/api/customer/applicant-position-experience'
import uniqBy from 'lodash/uniqBy'

export default {
  namespaced: true,
  state () {
    return {
      editable: false,
      selected: [],
      softDeletedItems: [],
      form: []
    }
  },
  mutations: {
    /**
     * Set selected items.
     *
     * @param {*} state
     * @param {*} selected
     */
    SET_SELECTED (state, selected) {
      state.selected = selected
    },
    /**
     * Set editable boolean.
     *
     * @param {*} state
     * @param {*} editable
     */
    SET_EDITABLE (state, editable) {
      state.editable = editable
    },
    /**
     * Set default form.
     *
     * @param {*} state
     * @param {*} form
     */
    SET_FORM (state, form) {
      state.form = form
    },
    /**
     * Set soft deleted items.
     *
     * @param {*} state
     * @param {*} item
     */
    SET_SOFT_DELETE (state, item) {
      state.softDeletedItems.push(item)
    }
  },
  actions: {
    /**
     * Discard changes.
     *
     * @param {commit, state}
     */
    restoreItems ({ commit, state }) {
      const newlyAdded = state.selected.filter(el => !state.form.find(a => a.id === el.id))

      let form = state.form.slice()

      form = form.map(el => {
        return {
          id: el.job_position_id,
          yoe: el.years_of_experience_range_id
        }
      })

      const data = form.concat(newlyAdded)

      commit('SET_SELECTED', data.filter(el => el.yoe))
    },
    /**
     * Soft delete selected item.
     *
     * @param {commit, state}
     * @param {*} item
     */
    softDeleteItem ({ commit, state }, item) {
      commit('SET_SELECTED', state.selected.filter(el => el.id !== item.id))
      commit('SET_SOFT_DELETE', item)
    },
    /**
     * Set mode.
     *
     * @param {commit}
     * @param {*} item
     */
    isEditable ({ commit }, item) {
      commit('SET_EDITABLE', item)
    },
    /**
     * Set selected items.
     *
     * @param {commit}
     * @param {*} item
     */
    setSelectedItems ({ commit }, item) {
      commit('SET_SELECTED', item)
    },
    /**
     * Set selected items.
     *
     * @param {commit}
     */
    clearSofDeletedItem ({ commit }) {
      commit('SET_SOFT_DELETE', [])
    },
    /**
     * Get Applicant Position Experience
     *
     * @param {commit, dispatch}
     * @param {*} params
     */
    getApplicantPositionExperience ({ commit, dispatch }, params) {
      return applicantPositionExperience.simpleSearch(params)
        .then(({ data }) => {
          const form = data.slice()

          const records = form.map((item) => {
            item = {
              id: item.job_position_id,
              yoe: item.years_of_experience_range_id
            }

            return item
          })

          commit('SET_FORM', form)
          commit('SET_SELECTED', records)
        })
        .catch(({ response }) => {
          dispatch('httpException/handle', response, { root: true })
        })
    },
    /**
     * Store job positions.
     *
     * @param {commit, dispatch, state}
     */
    storeData ({ commit, dispatch, state }) {
      const payload = {}

      payload.applicant_position_experiences = uniqBy(state.selected.map((item) => {
        item = {
          job_position_id: item.id,
          years_of_experience_range_id: item.yoe
        }

        return item
      }), 'job_position_id')

      return applicantPositionExperience.sync(payload)
        .then(({ data }) => data)
        .catch(({ response }) => {
          dispatch('httpException/handle', response, { root: true })
        })
    }
  }
}
