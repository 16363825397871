import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,[_c(VRow,{staticClass:"py-4"},[_c(VCol,{staticClass:"px-0",attrs:{"cols":"12","md":"7"}},_vm._l((_vm.links),function(item,index){return _c('span',{key:index},[_c(VBtn,{staticClass:"text-none caption",attrs:{"text":"","link":"","x-small":""},on:{"click":function($event){return _vm.goToLink(item.path)}}},[_c('span',{staticClass:"footer-font",staticStyle:{"font-size":"14px"}},[_vm._v(" "+_vm._s(item.title)+" ")])]),(index != _vm.links.length-1)?_c('span',{staticClass:"footer-divider"},[_vm._v("|")]):_vm._e()],1)}),0),_c(VCol,{staticClass:"text-right px-0",attrs:{"cols":"6","md":"5"}},[_c('span',{staticClass:"footer-font",staticStyle:{"font-size":"12px"}},[_vm._v(" GitTap© Bizmates Inc. All Rights Reserved "+_vm._s(_vm.year)+" ")]),_c('span',{staticClass:"ml-7"},_vm._l((_vm.socialPageDetails),function(item,index){return _c(VBtn,{key:index,staticClass:"mr-1",attrs:{"icon":""},on:{"click":function($event){return _vm.goToLink(item.link)}}},[_c(VImg,{attrs:{"src":require(`@/assets/${item.iconFileName}`),"height":"24px","width":"24px","contain":""}})],1)}),1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }