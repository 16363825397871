export default {
  title: 'メールアドレスを変更する',
  email: {
    label: 'Eメール',
    placeholder: '新しいメール'
  },
  password: {
    label: '現在のパスワード（確認）',
    placeholder: 'あなたのパスワード'
  },
  confirmCode: 'コードを確認',
  authCode: '6桁の認証コードを入力してください',
  update: 'メールを更新',
  resend: 'コードを再送信',
  resendSuccess: '再送信しました',
  notSame: '新しいメールを既存のメールアドレスにすることはできません',
  success: 'メールが設定されました。',
  sent: 'メールが{email}に送信されました'
}
