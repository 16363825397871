import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarItems } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VAppBar,{staticClass:"toolbar-shadow padding-x",attrs:{"app":"","color":"secondary","elevation":"0","absolute":""}},[_c(VToolbar,{attrs:{"flat":"","color":"secondary"}},[_c(VToolbarTitle,{staticClass:"mr-2 mt-2"},[(_vm.isRegistered)?_c('a',{attrs:{"href":"/"}},[_c('img',{attrs:{"alt":"GitTap Logo","contain":"","src":require('@/assets/gittap_logo_white.png'),"width":"80"}})]):_c('a',{attrs:{"href":_vm.visitorUrl}},[_c('img',{attrs:{"alt":"GitTap Logo","contain":"","src":require('@/assets/gittap_logo_white.png'),"width":"80"}})])]),_c(VSpacer),(_vm.isRegistered)?_c(VToolbarItems,[_c(VBtn,{attrs:{"text":"","color":"white","x-small":"","to":"/notifications"},on:{"click":function($event){_vm.gtmParams[_vm.$route.name] &&
            _vm.customActionPush({ ..._vm.gtmParams[_vm.$route.name] })}}},[_c(VBadge,{attrs:{"color":"disabled","overlap":"","dot":"","value":_vm.admin_notification.unread}},[_c(VIcon,{attrs:{"color":"disabled"},domProps:{"textContent":_vm._s('mdi-bell')}})],1)],1)],1):_vm._e(),(_vm.show)?_c(VAppBarNavIcon,{on:{"click":function($event){$event.stopPropagation();return _vm.toggleDrawer(true)}}},[_c(VIcon,{attrs:{"color":"disabled"},domProps:{"textContent":_vm._s(_vm.menuIcon)}})],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }