import jobPostingApi from '@/api/client/job-posting'

const LIST_PAGE = 'LIST'
const DETAIL_PAGE = 'DETAIL'
const APPLY_PAGE = 'APPLY'

export default {
  namespaced: true,
  state () {
    return {
      loading: false,
      items: [],
      similarItems: [],
      selected: {},
      details: {},
      navigation: LIST_PAGE,
      applyCompleted: false
    }
  },
  getters: {
    /**
     * Check if list is selected
     * @return bool
     */
    isSelectedListPage: state => {
      return state.navigation === LIST_PAGE
    },
    /**
     * Check if details is selected
     * @return bool
     */
    isSelectedDetailPage: state => {
      return state.navigation === DETAIL_PAGE
    },
    /**
     * Identify if items is empty
     */
    hasItems: state => {
      return !!state.items.length
    },
    /**
     * Identify if details is empty
     */
    hasDetails: state => {
      return !!Object.keys(state.details).length
    },
    /**
     * Identify if details is duplicated
     */
    hasDuplicateDetails: state => {
      return (id) => state.details.id === id
    }
  },
  mutations: {
    /**
     * Set apply completed
     *
     * @param {*} state
     * @param {*} selected
     */
    SET_APPLY_COMPLETED (state, status) {
      state.applyCompleted = status
    },
    /**
     * Set selected item.
     *
     * @param {*} state
     * @param {*} selected
     */
    SET_SELECTED (state, selected) {
      state.selected = selected
    },
    /**
     * Set loading.
     *
     * @param {*} state
     * @param {*} loading
     */
    SET_LOADING (state, loading) {
      state.loading = loading
    },
    /**
     * Set items.
     *
     * @param {*} state
     * @param {*} items
     */
    SET_ITEMS (state, items) {
      state.items = items
    },
    /**
     * Set items similar.
     *
     * @param {*} state
     * @param {*} items
     */
    SET_ITEMS_SIMILAR (state, items) {
      state.similarItems = items
    },
    /**
     * Set details.
     *
     * @param {*} state
     * @param {*} details
     */
    SET_DETAILS (state, details) {
      state.details = details
    },
    /**
     * Set Navigation.
     *
     * @param {*} state
     * @param {*} details
     */
    SET_NAVIGATION (state, value) {
      state.navigation = value
    }
  },
  actions: {
    /**
     * Set the apply status
     *
     * @param {commit}
     */
    setApplyStatus ({ commit }, status) {
      commit('SET_APPLY_COMPLETED', status)
    },
    /**
     * Go to search list
     *
     * @param {commit}
     */
    goToListPage ({ commit }) {
      commit('SET_NAVIGATION', LIST_PAGE)
    },
    /**
     * Go to details page
     *
     * @param {commit}
     */
    goToDetailPage ({ commit }) {
      commit('SET_NAVIGATION', DETAIL_PAGE)
    },
    /**
     * Go to details page
     *
     * @param {commit}
     */
    goToApplyPage ({ commit }) {
      commit('SET_NAVIGATION', APPLY_PAGE)
    },
    /**
     * Set details.
     *
     * @param {commit}
     * @param {*} details
     */
    setDetails ({ commit }, details) {
      commit('SET_DETAILS', details)
    },
    /**
     * Set items.
     *
     * @param {commit}
     * @param {*} items
     */
    setItems ({ commit }, items) {
      commit('SET_ITEMS', items)
    },
    /**
     * Set items similar.
     *
     * @param {commit}
     * @param {*} items
     */
    setItemsSimilar ({ commit }, items) {
      commit('SET_ITEMS_SIMILAR', items)
    },
    /**
     * Set selected items.
     *
     * @param {commit}
     * @param {*} item
     */
    setSelectedItem ({ commit }, item) {
      commit('SET_SELECTED', item)
    },
    /**
     * Fetch items
     * If items is already populated skip the process
     *
     * @param { getters, dispatch} params
     */
    async softFetchItems ({ getters, dispatch }, params) {
      if (getters.hasItems) return
      await dispatch('fetchItems', params)
    },
    /**
     * Fetch Items
     *
     * @param {commit, dispatch} params
     */
    fetchItems ({ commit, dispatch }, params) {
      commit('SET_LOADING', true)

      return jobPostingApi.search(params)
        .then(({ data }) => {
          commit('SET_ITEMS', data)
        })
        .catch(({ response }) => {
          dispatch('httpException/handle', response, { root: true })
        })
        .finally(() => {
          commit('SET_LOADING', false)
        })
    },
    /**
     * Fetch Items Similar
     *
     * @param {commit, dispatch} params
     */
    fetchItemsSimilar ({ commit, dispatch }, params) {
      commit('SET_LOADING', true)

      return jobPostingApi.searchSimilar(params)
        .then(({ data }) => {
          commit('SET_ITEMS_SIMILAR', data)
        })
        .catch(({ response }) => {
          dispatch('httpException/handle', response, { root: true })
        })
        .finally(() => {
          commit('SET_LOADING', false)
        })
    },
    /**
     * Fetch details
     * If details is already populated skip the process
     *
     * @param { getters, dispatch} id
     */
    async softFetchDetails ({ getters, dispatch }, id) {
      if (getters.hasDetails && getters.hasDuplicateDetails(id)) return
      await dispatch('fetchDetails', id)
    },
    /**
     * Fetch Details
     *
     * @param {commit, dispatch} object
     */
    fetchDetails ({ commit, dispatch }, id) {
      commit('SET_LOADING', true)

      return jobPostingApi.show(id)
        .then(({ data }) => {
          commit('SET_DETAILS', data)

          return data
        })
        .catch(({ response }) => {
          dispatch('httpException/handle', response, { root: true })
        })
        .finally(() => {
          commit('SET_LOADING', false)
        })
    }
  }
}
