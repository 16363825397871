import Cookies from 'vue-cookies'
import Config from '@/config/app'

export default {
  /**
   * Get the language from storage.
   *
   */
  get () {
    if (Cookies.isKey(Config.COOKIES.LANGUAGE_KEY)) {
      return Cookies.get(Config.COOKIES.LANGUAGE_KEY)
    }

    return JSON.parse(localStorage.getItem('language'))
  },

  /**
   * Set the language in storage & cookies.
   *
   * @param {*} language
   */
  set (language) {
    language.label = language.value === 'en' ? 'English' : '日本語'

    Cookies.set(Config.COOKIES.LANGUAGE_KEY, language)
    localStorage.setItem('language', JSON.stringify(language))
  },

  setBrowserLanguage (language) {
    Cookies.set('browser-language', language, 3600, '/', Config.COOKIES.DOMAIN)
    localStorage.setItem('language', JSON.stringify(this.labels(language)))
  },

  getLanguage () {
    if (Cookies.isKey(Config.COOKIES.LANGUAGE_KEY)) {
      return Cookies.get(Config.COOKIES.LANGUAGE_KEY)
    } else {
      const browserLanguage = window.navigator.language === 'ja' ? 'ja' : 'en'
      return this.labels(browserLanguage)
    }
  },

  labels (language) {
    switch (language) {
      case 'ja': {
        return { label: '日本語', value: 'ja' }
      }
      default: {
        return { label: 'English', value: 'en' }
      }
    }
  }
}
