import { mapState, mapGetters, mapActions } from 'vuex'
import lang from '@/services/lang'

export default {
  props: {
    links: {
      type: Array,
      required: false,
      default: Array
    }
  },
  computed: {
    ...mapState('core', ['languages']),
    ...mapGetters('core', ['selectedLanguage']),

    /**
     * Selected language
     *
     */
    selected () {
      if (!this.selectedLanguage) return ''

      return this.selectedLanguage
    }
  },
  methods: {
    ...mapActions('core', [
      'setLanguage',
      'toggleDrawer'
    ]),

    onLanguageChange (language) {
      this.setLanguage(language)
    }
  }
}
