export default {
  basicInformation: 'Basic Information',
  skill: 'Skill',
  japaneseLevel: 'Required Japanese Level',
  hintHeader: 'Increase your chance to get interviewed by creating a competitive profile',
  hints: {
    skills: 'Are your technical skills up to date?',
    experienceSummary: 'Are your technical skills latest ?',
    workDetail: 'Have you added work projects?'
  },
  message: 'Message',
  messageHint: 'By telling your strength and reason for applying, you will more likely get a reply.',
  messagePlaceholder: 'I am writing to show my interest to apply for the Full stack engineer job on GitTap. I am applying for this position because...',
  confirm: 'Confirm',
  confirmation: 'Confirmation',
  help: [
    {
      header: 'Improve your profile before you apply to increase your chances of being selected.',
      content: 'Many companies will look at your "IT skills" and "Work Details" to make a decision. Be proactive in filling out your profile so you can make it through the selection process!'
    },
    {
      header: 'Use the profile template feature first!',
      content: 'The template feature not only makes it easy to type, but also allows you to follow the format of a Japanese company, increasing the chances that your message will be read!'
    }
  ],

  // Profile
  top: 'Home',
  skills: 'Skills',
  preference: 'Conditions',
  workExperience: 'Working',
  noImage: 'No Image',
  profileVideo: 'Profile Video',
  residence: 'Lives in {residence}',
  yearsOld: '1 year old | {years} years old',
  company: 'Company',
  latestEducation: 'Education',
  major: 'Major',
  degree: 'Degree',
  workVisa: 'Permission to Work',
  englishLevel: 'English Level',
  workingInJapan: 'Worked in Japan',
  jlpt: 'JLPT',
  otherJapaneseQualifications: 'Other Japanese Certificates',
  englishQualifications: 'English Certificates',
  otherLanguages: 'Other languages',
  languageDetailsNote: 'See the bottom of the page for details on language skills.',
  canBe: 'Available',
  areaOfExpertise: 'Experienced area / job',
  star: 'Star',
  contribution: 'Contribution',
  pullRequest: 'Pull Request',
  desiredCondition: 'Preference',
  currentAnnualIncome: 'Latest Annual Income',
  desiredPosition: 'Preferred Job',
  preferredPrefectures: 'Preferred Work Location',
  preferredEmploymentType: 'Preferred Employment Type',
  priorityItem: 'Priority',
  nationwide: 'Nationwide',
  workHistoryAndBiography: 'Work Experience',
  latestOrderFromTop: 'Latest from top',
  companyName: 'Company Name',
  jobTitleIndustry: 'Job Title',
  years: 'Years of Experience',
  experiencedJobCategory: 'Experienced Job Role',
  workHistorySummary: 'Short Description of Work Experience',
  japaneseTranslation: 'Translation to Japanese',
  workHistoryNumber: 'Work Experience {number}',
  workInJapan: 'Worked in Japan',
  industry: 'Industry',
  companySize: 'Size of Company',
  employmentStatus: 'Employment Type',
  jobTitleRole: 'Job Title',
  lengthOfService: 'Years of Experience',
  jobDescription: 'Project detail / Work detail',
  switchBetweenOriginalAndJapanese: 'Switch to Japanese',
  developmentLanguage: 'Used IT Skills',
  roleScale: 'Role / Size',
  seeMore: 'See More',
  current: 'Current',
  fullScreen: 'Full Screen',
  year: '1 year | {years} years',
  months: 'Less than a month | 1 month | {months} months',
  confirmationDialog: {
    header: 'Enriching your profile information will increase your selection pass rate by 2-3 times.',
    applyProfile: 'Apply with this profile.',
    editProfile: 'Edit your profile',
    addMessage: 'Add message'
  },
  leavePageMessage: 'The message you added will be deleted. Do you want to leave the page?',
  jobTitleAndIndustry: 'Job Title / Industry',
}
