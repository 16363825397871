import axios from 'axios'
import Config from '@/config/app'
import Cookies from 'vue-cookies'
import { DateTime } from 'luxon'

/**
 * ===============================================
 * Auth service
 * ===============================================
 *
 * Provides authentication properties and actions
 *
 * ===============================================
 */

export default {
  /**
   * The intended page of the user
   *
   */
  requestedPage: '/',

  /**
   * Formats and stores the auth access in sessionStorage
   *
   * @param data
   */
  setAuth(data) {
    data.expiration_date = DateTime.now()
      .plus({ seconds: data.expires_in })
      .toISO()

    delete data.expires_in
    delete data.access_token
    delete data.refresh_token
    delete data.token_type

    localStorage.setItem('auth', JSON.stringify(data))
  },

  /**
   * Gets the auth access in sessionStorage.
   *
   * @return * { expiration_date }
   */
  getAuth() {
    return JSON.parse(localStorage.getItem('auth'))
  },

  /**
   * store the user data in sessionStorage
   *
   * @param data
   */
  setUser(data) {
    sessionStorage.setItem('user', JSON.stringify(data))
  },

  /**
   * Gets the user data in sessionStorage.
   *
   * @return {*}
   */
  getUser() {
    return JSON.parse(sessionStorage.getItem('user'))
  },

  /**
   * store the registration data in sessionStorage
   *
   * @param data
   */
  setRegistrationStatus(data) {
    localStorage.setItem('registration', JSON.stringify(data))
  },

  /**
   * Gets the registration data in localStorage.
   *
   * @return {*}
   */
  getRegistrationStatus() {
    return JSON.parse(localStorage.getItem('registration'))
  },

  /**
   * Check if user if from hrbc and need to complete registration.
   *
   * @return boolean
   */
  isHrbcRegistration() {
    return this.getUser().hrbc_resume_id && this.getRegistrationStatus() !== 4
  },

  /**
   * Check if first time login
   *
   * @return boolean
   */
  isFirstTimeLogin() {
    return (
      !this.getUser().updated_initial_password &&
      this.getUser().account_info === true
    )
  },

  /**
   * Verify if authenticated
   *
   * @return bool
   */
  isAuthenticated() {
    return !!this.getAuth()
  },

  /**
   * Gets the expiration_date from auth.
   *
   */
  getTokenExpiration() {
    return this.getAuth() ? this.getAuth().expiration_date : null
  },

  /**
   * Removes auth and user from sessionStorage.
   *
   */
  flush() {
    let userId = null
    if (sessionStorage.getItem('user_id')) {
      userId = sessionStorage.getItem('user_id')
    }
    localStorage.removeItem('auth')
    sessionStorage.removeItem('user')
    localStorage.removeItem('registration')
    sessionStorage.clear()
    sessionStorage.setItem('user_id', userId)
    Cookies.remove('applicant_id')
  },

  /**
   * Checks if token is expired.
   * An allowance of 1 minute is set
   * to prevent tokens expiring between API calls
   *
   * @return bool
   */
  isAccessTokenExpired() {
    const tokenExpiration = DateTime.fromISO(this.getTokenExpiration())

    return (
      DateTime.now().plus({ minutes: 1 }).equals(tokenExpiration) ||
      DateTime.now().plus({ minutes: 1 }) > tokenExpiration
    )
  },

  /**
   * Checks refresh is allowed
   * An allowance of 1 hour
   *
   * @return bool
   */
  allowRefresh() {
    const tokenExpiration = this.getTokenExpiration()
    const refreshTokenAllowance = DateTime.fromISO(tokenExpiration).plus({
      hours: 1
    })

    return DateTime.now() < refreshTokenAllowance
  },

  /**
   * Sends an auth check request to Auth API.
   *
   * @return {*} http
   */
  verifyAccessToken() {
    const headers = {
      Accept: 'application/json'
    }

    return axios.get(`${Config.services.auth.url}/api/v1/user/auth`, {
      headers,
      withCredentials: true
    })
  },

  /**
   * Sends refresh token to Auth API.
   *
   * @return {*} http
   */
  refreshToken() {
    const headers = {
      Accept: 'application/json'
    }

    return axios.post(
      `${Config.services.auth.url}/api/v1/refresh-token`,
      {},
      { headers, withCredentials: true }
    )
  },

  /**
   * Set applicant_id to cookie
   */
  setApplicantIdToCookie(applicantId) {
    Cookies.set('applicant_id', applicantId)
  }
}
