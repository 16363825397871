export default {
  helpMessages: {
    message1: 'ITスキルは最新の情報になっていますか？',
    message2: '職務経験の詳細は記入しましたか？',
    message3: '経験したプロジェクトを記入しましたか？'
  },
  profile: 'プロフィール',
  scout: 'スカウト',
  like: '気になる',
  interested: '興味あり'
}
