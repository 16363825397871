import appealPointApi from '@/api/customer/master/appeal-point'

export default {
  namespaced: true,
  state() {
    return {
      items: sessionStorage.getItem('appealPoints')
        ? JSON.parse(sessionStorage.getItem('appealPoints'))
        : [],
      loading: false,
    }
  },
  mutations: {
    /**
     * Set the items.
     *
     * @param {*} state
     * @param {*} items
     */
    SET_ITEMS(state, items) {
      state.items = items
    },

    SET_LOADING(state, loading) {
      state.loading = loading
    },
  },
  actions: {
    /**
     * Commit the SET_ITEMS mutation
     *
     */
    setItems({ commit }, items) {
      commit('SET_ITEMS', items)

      sessionStorage.setItem('appealPoints', JSON.stringify(items))
    },
    /**
     * Get all records.
     *
     * @param {*}
     */
    all({ commit, dispatch, state }) {
      if (state.items.length) return

      commit('SET_LOADING', true)

      return appealPointApi
        .index()
        .then(({ data }) => {
          commit('SET_ITEMS', data)

          sessionStorage.setItem('appealPoints', JSON.stringify(data))
        })
        .catch(({ response }) =>
          dispatch('httpException/handle', response, { root: true })
        )
        .finally(() => commit('SET_LOADING', false))
    },

    /**
     * Commit the SET_LOADING mutation.
     *
     * @param {*} param0
     * @param {*} loading
     */
    setLoading({ commit }, loading) {
      commit('SET_LOADING', loading)
    },
  },
}
