import api from '../../index'

const {
  http,
  advancedSearch
} = api

/**
 * ===================
 * Job Position Category API
 * ===================
 */
export default {
  baseUrl: `${process.env.VUE_APP_CUSTOMER_URL}/api/v1`,
  url: 'job-position-categories',
  http,
  advancedSearch
}
