import faq from '@/api/common/faq'
// import Fuse from 'fuse.js'

export default {
  namespaced: true,
  state () {
    return {
      loading: false,
      items: []
    }
  },
  getters: {
    hasItems: state => {
      return state.items.length > 0
    },
    items: state => {
      return state.items
    }
  },
  mutations: {
    /**
     * Set the items.
     *
     * @param {*} state
     * @param {*} items
     */
    SET_ITEMS (state, items) {
      state.items = items
    },
    /**
     * Set the loading status.
     *
     * @param {*} state
     * @param {*} items
     */
    SET_LOADING (state, loading) {
      state.loading = loading
    }
  },
  actions: {
    /**
     * Fetch items
     * If items is already populated skip the process
     *
     * @param { getters, dispatch}
     */
    softFetch ({ getters, dispatch }) {
      if (getters.hasItems) return

      dispatch('fetch')
    },

    /**
     * Fetch Items
     *
     * @param {commit, dispatch} object
     */
    fetch ({ commit, dispatch }) {
      commit('SET_LOADING', true)

      return faq.index()
        .then(({ data }) => {
          commit('SET_ITEMS', data)
        })
        .catch(({ response }) => {
          dispatch('httpException/handle', response, { root: true })
        })
        .finally(() => {
          commit('SET_LOADING', false)
        })
    },

    async onSearch ({ commit, getters, dispatch }, searchKey) {
      await dispatch('fetch')
      // const options = { includeScore: true, keys: ['en_question', 'ja_question', 'en_answer', 'ja_answer'] }
      // const fuse = new Fuse(getters.items, options)
      // const results = fuse.search(searchKey)
      // commit('SET_ITEMS', results.map(x => x.item))
      const results = getters.items.filter((x) => {
        if ((x.ja_answer + x.ja_question + x.en_question + x.en_answer).match(new RegExp(searchKey, 'i'))) { return x }
      })
      const newArray = []
      results.forEach((x) => {
        newArray.push(x)
        if (x.parent_id && !newArray.find(m => m.id === x.parent_id)) {
          newArray.push(getters.items.find(m => m.id === x.parent_id))
        }
      })
      commit('SET_ITEMS', newArray)
    }
  }
}
