/**
 * =======================================
 * API Request for Video Interview
 * =======================================
 */
import api from '@/api/index'
import Config from '@/config/app'

const {
  http,
  show
} = api

export default {
  baseUrl: Config.services.common.url,
  url: '/api/v1/video-interviews',
  http,
  show,

  /**
   * Answer video interview
   *
   * @param {*} id
   * @param {*} payload
   * @param progressHandler
   */
  update (id, payload, progressHandler) {
    return this.http(this.baseUrl)
      .post(`${this.url}/${id}/update`, payload, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: (progress) => {
          progressHandler(progress)
        }
      })
  }
}
