/**
 * -------------------------------------------------
 * Mixins For Switch Control
 * -------------------------------------------------
 */
export default {
  methods: {
    /**
     * Switch On/Enable the property
     *
     * @param String property
     * @return void
     */
    enable (property) {
      this.$set(this, property, true)
    },

    /**
     * Switch Off/disable the property
     *
     * @param String property
     * @return void
     */
    disable (property) {
      this.$set(this, property, false)
    },

    /**
     * Switch On/Enable the property
     *
     * @param String property
     * @return void
     */
    start (property) {
      this.$set(this, property, true)
    },

    /**
     * Switch Off/disable the property
     *
     * @param String property
     * @return void
     */
    stop (property) {
      this.$set(this, property, false)
    },

    /**
     * Switch On/Enable the property
     *
     * @param String property
     * @return void
     */
    show (property) {
      this.$set(this, property, true)
    },

    /**
       * Switch Off/disable the property
       *
       * @param String property
       * @return void
       */
    hide (property) {
      this.$set(this, property, false)
    },

    /**
     * Toggle Property
     * From: true -> false /
     * From: false -> true
     *
     * @param String property
     * @return void
     */
    toggle (property) {
      this.$set(this, property, !this[property])
    }
  }
}
