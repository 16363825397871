import Vue from 'vue'
import applicant from '@/api/customer/v2/applicant'

const data = {
  loading: false,
  saving: false,
  applicant: {},
  applicantSavePayload: {},
  cvData: {},
  populateField: false,
  cvPopulateFieldType: null
}

export default {
  namespaced: true,
  state() {
    return {
      ...data,
    }
  },
  getters: {
    completionRate: (state) => {
      return state.applicant?.completion_rate?.overall_completion_rate
    },

    completionRateNumber: (state) => {
      if (!state.applicant?.completion_rate?.overall_completion_rate) return '0%'
      return `${parseInt(state.applicant?.completion_rate?.overall_completion_rate)}%`
    },
  },
  mutations: {
    SET_LOADING(state, loading) {
      state.loading = loading
    },
    SET_SAVING(state, saving) {
      state.saving = saving
    },
    SET_VUEX(state, payload) {
      state[payload.key] = payload.data
    },
    SAVE_PAYLOAD(state, payload) {
      Vue.set(state.applicantSavePayload, payload.key, payload.data)
    },
    CLEAR_SAVE_PAYLOAD(state) {
      state.applicantSavePayload = {}
    },
    SET_APPLICANT(state, items) {
      state.applicant = items
    },
    DELETE_PAYLOAD_KEY(state, key) {
      delete state.applicantSavePayload[key]
    },
    SET_CV_DATA(state, payload) {
      state.cvData = payload.data
    },
    SAVE_CV_DATA(state, payload) {
      state.populateField = payload
    },
    SET_CV_POPULATE_FIELD_TYPE (state, payload) {
      state.cvPopulateFieldType = payload
    },
  },
  actions: {
    /**
     * Set the loading state
     *
     * @param {*} param0
     * @param {*} loading
     */
    setLoading({ commit }, loading) {
      commit('SET_LOADING', loading)
    },
    /**
     * Set the data.
     *
     * @param {*} state
     * @param {*} data
     */
    setData({ commit }, data) {
      commit('SET_DATA', data)
    },
    /**
     * Set the data.
     *
     * @param {*} state
     * @param {*} data
     */
    setApplicant({ commit }, data) {
      commit('SET_APPLICANT', data)
    },
    /**
     * Set the dirty state.
     * @param {*} state
     * @param {*} dirty
     */
    setDirty({ commit }, dirty) {
      commit('SET_DIRTY', dirty)
    },
    /**
     * Set the specific data state.
     * @param {*} state
     * @param {*} dirty
     */
    setVuex({ commit }, payload) {
      commit('SET_VUEX', payload)
    },

    setCvPopulateFieldType({ commit }, payload) {
      commit('SET_CV_POPULATE_FIELD_TYPE', payload)
    },

    /**
     * Set the specific data state.
     * @param {*} state
     * @param {*} dirty
     */
    savePayload({ commit }, payload) {
      commit('SAVE_PAYLOAD', payload)
    },

    deletePayloadKey({ commit }, key) {
      commit('DELETE_PAYLOAD_KEY', key)
    },

    setCVData({ commit }, payload) {
      commit('SET_CV_DATA', payload)
    },

    saveCVData({ commit }, payload) {
      commit('SAVE_CV_DATA', payload)
    },

    /**
     * Reset all state.
     * @param {*} state
     */
    reset({ commit }) {
      commit('RESET')
    },

    /**
     * Get the applicant details
     *
     * @param {*} params
     */
    getApplicant({ commit, dispatch }) {
      commit('SET_LOADING', true)
      return applicant
        .applicantShow()
        .then(({ data }) => commit('SET_APPLICANT', data))
        .catch(({ response }) => dispatch('httpException/handle', response, { root: true }))
        .finally(() => commit('SET_LOADING', false))
    },

    clearSavePayload({ commit }) {
      commit('CLEAR_SAVE_PAYLOAD')
    },

    /**
     * Update the applicant details
     *
     * @param {*} params
     */
    updateApplicant({ commit, dispatch }, params) {
      commit('SET_SAVING', true)
      return applicant
        .applicantSave(params)
        .then(function ({ data }) {
          commit('CLEAR_SAVE_PAYLOAD')
          commit('SET_APPLICANT', data)
        })
        .catch(({ response }) => dispatch('httpException/handle', response, { root: true }))
        .finally(() => commit('SET_SAVING', false))
    },
  },
}
