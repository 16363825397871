export default {
  namespaced: true,
  state () {
    return {
      items: localStorage.getItem('visitorItems')
        ? JSON.parse(localStorage.getItem('visitorItems'))
        : []
    }
  },
  mutations: {
    /**
     * Reset the state to default.
     *
     * @param {*} state
     */
    RESET (state) {
      state.items = []
      if (localStorage.getItem('visitorItems')) localStorage.removeItem('visitorItems')
    },

    /**
     * Set the items.
     *
     * @param {*} state
     * @param {*} items
     */
    SET_ITEMS (state, items) {
      state.items = items
    }
  },
  actions: {
    /**
     * commit SET_ITEMS mutation
     *
     * @param {*} state
     * @param {*} items
     */
    setItems ({ commit }, items) {
      commit('SET_ITEMS', items)
      localStorage.setItem('visitorItems', JSON.stringify(items))
    },
    /**
     * commit RESET mutation
     *
     * @param {*} state
     */
    reset ({ commit }) {
      commit('RESET')
    }
  }
}
