export default {
  onlineInterview: 'Online Interview',
  interviewResult: {
    labelYes: 'Yes',
    labelNo: 'No'
  },
  jobDescription: 'Job Description',
  workingCondition: 'Working Condition',
  requiredSkills: 'Required Skills and Experiences',
  selectionFlow: 'Selection Process',
  appealingPoints: 'Top-Selling Points',
  rateForeigners: 'Rate of Foreigners',
  rateForeignersHelp: 'Rate of foreigners within the organization',
  frequencyEnglish: 'Frequency of English Usage',
  frequencyEnglishHelp: 'Frequency of English usage for this opportunity',
  estimatedCharacteristic: 'CHARACTERISTICS OF IDEAL CANDIDATE',
  jobSalary: 'Job/Salary',
  company: 'Company',
  languageSkill: 'REQUIRED LANGUAGE SKILLS',
  language: {
    english: 'English',
    japanese: 'Japanese'
  },
  noResult: 'No Result',
  interested: 'Know More',
  apply: 'Apply',
  applied: 'Applied',
  applySuccess: 'Job Application Successful!',
  workRemotely: 'Remote Work',
  remoteWorkOverseas: 'From Overseas',
  remoteWorkJapan: 'From Japan',
  yes: 'Yes',
  no: 'No',
  incomeRangeUndecided: 'Negotiable',
  foundedDate: 'Since {date}',
  more: 'More',
  skill: 'Related Skills',
  job: 'job',
  languageRequirements: 'Language Requirements',
  required: 'Required',
  preferred: 'Preferred',
  noData: 'No Data',
  recommendedJobs: 'Recommended Jobs',
  save: 'Save',
  saved: 'Saved'
}
