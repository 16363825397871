import japanPrefecture from '@/api/customer/master/japan-prefecture'

const data = {
  items: sessionStorage.getItem('japanPrefectures')
    ? JSON.parse(sessionStorage.getItem('japanPrefectures'))
    : [],
  loading: false,
}

export default {
  namespaced: true,
  state() {
    return {
      ...data,
    }
  },
  mutations: {
    /**
     * Reset the state to default.
     *
     * @param {*} state
     */
    RESET(state) {
      for (const key in state) {
        state[key] = data[key]
      }
    },

    /**
     * Set the items.
     *
     * @param {*} state
     * @param {*} items
     */
    SET_ITEMS(state, items) {
      state.items = items
    },

    /**
     * Set the loading.
     *
     * @param {*} state
     * @param {*} loading
     */
    SET_LOADING(state, loading) {
      state.loading = loading
    },
  },
  actions: {
    /**
     * Get all EmploymentType.
     *
     * @param {*} param
     */
    all({ commit, dispatch, state }, params) {
      if (state.items.length) return

      commit('SET_LOADING', true)

      return japanPrefecture
        .index(params)
        .then(({ data }) => {
          commit('SET_ITEMS', data)
          sessionStorage.setItem('japanPrefectures', JSON.stringify(data))
        })
        .catch(({ response }) =>
          dispatch('httpException/handle', response, { root: true })
        )
        .finally(() => commit('SET_LOADING', false))
    },

    /**
     * Commit the SET_ITEMS mutation
     *
     */
    setItems({ commit }, items) {
      commit('SET_ITEMS', items)

      sessionStorage.setItem('japanPrefectures', JSON.stringify(items))
    },

    /**
     * Commit the SET_LOADING mutation.
     *
     * @param {*} param0
     * @param {*} loading
     */
    setLoading({ commit }, loading) {
      commit('SET_LOADING', loading)
    },
  },
}
