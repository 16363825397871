export default {
  apply: 'Apply',
  ok: 'Ok',
  add: 'Add',
  cancel: 'Cancel',
  close: 'Close',
  done: 'Done',
  edit: 'Edit',
  gotIt: 'Got It!',
  remove: 'Remove',
  required: 'Required',
  update: 'Update',
  save: 'Save',
  saveAndNext: 'Save & Next',
  logout: 'Logout',
  back: 'Back',
  next: 'Next',
  noDisplay: 'Nothing to display',
  unsaved: 'Unsaved Changes',
  abort: 'Do you want to leave without saving?',
  leave: 'Leave',
  success: 'Saved successfully',
  removeSuccess: 'Removed successfully',
  seeMore: 'See More',
  seeAll: 'See all',
  to: 'to',
  'The page you are looking for is not found': 'The page you are looking for is not found.',
  'Please direct to': 'Please direct to',
  'home page': 'home page',
  declined: 'declined',
  thankYou: 'Thank you',
  send: 'Send',
  parsing: 'Parsing',
  fileUploadedAndParsed: 'File uploaded and parsed',
  unableToParseContactSupport:
    "I can't seem to parse the uploaded file. Use the correct CV file or please contact support.",
  search: 'Search',
  select: 'Select',
  submit: 'Submit',
  plusCountOthers: '+{count} other',
  orMore: ' or more',
  orLess: ' or less',
  cancelDialog: {
    title: 'Will you really cancel?',
    content1: 'If you cancel,',
    content2: 'the Video Interview data will not be saved.',
    proceed: 'proceed',
    no: 'no'
  },
  finished: 'Finished',
  failed: 'Failed',
  uploading: 'Uploading',
  cancelled: 'Cancelled',
  unknown: 'Unknown',
  and: 'and',
  yes: 'Yes',
  no: 'No',
  optional: 'Optional',
  readMore: 'Read More',
  new: 'New',
  jobs: 'Jobs',
  jp_currency_unit: 'JPY',
  years: 'Less than a year  | 1 year | {count} years',
  homeButton: 'Home',
  confirm: 'Confirm',
  delete: 'Delete',
  selected: 'Selected',
  closeDialog: 'Close',
  deleteConfirmation: 'Do you want to delete the item?',
  saveNoticeAfterDelete: 'After deleting, you need to save.',
  unsavedChangesTitle: 'Unsaved changes',
  unsavedChangesBody: 'Are you sure you want to leave?',
  multipleChoiceAllowed: 'Multiple choice allowed',
  searching: 'Searching...',
  noDataAvailable: 'No data available',
  reset: 'Reset',
  showResult: 'Show Result',
  csFilter: 'Filter',
  fileNotSupported: 'File is not supported',
  savedFilterSelect: 'Select "My Filter"',
  myFilter: 'My Filter',
  saveCurrentFilter: 'Save Current Filter',
  saveFilterName: 'Save Filter Name',
  resetAll: 'Reset All'
}
