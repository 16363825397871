import uniq from 'lodash/uniq'
import uniqBy from 'lodash/uniqBy'

import applicantSkill from '@/api/customer/applicant-skill'
import constants from '@/config/constants'

const data = {
  editable: false,
  technicalSkills: [],
  softDeletedItems: [],
  form: [],
  featuredSkills: [],
  mappedParsedTechnicalSkills: [],
  mappedParsedFeaturedSkills: []
}

export default {
  namespaced: true,
  state () {
    return {
      ...data
    }
  },
  mutations: {
    /**
     * Reset all state.
     * @param {*} state
     */
    RESET (state) {
      for (const key in state) {
        state[key] = data[key]
      }
    },
    /**
     * Set featured skills.
     *
     * @param {*} state
     * @param {*} featuredSkills
     */
    SET_FEATURED_SKILLS (state, featuredSkills) {
      state.featuredSkills = featuredSkills
    },
    /**
     * Set technical skills.
     *
     * @param {*} state
     * @param {*} technicalSkills
     */
    SET_TECHNICAL_SKILLS (state, technicalSkills) {
      state.technicalSkills = technicalSkills
    },
    /**
     * Set mapped featured skills.
     *
     * @param {*} state
     * @param {*} featuredSkills
     */
    SET_MAPPED_FEATURED_SKILLS (state, featuredSkills) {
      state.mappedParsedFeaturedSkills = featuredSkills
    },
    /**
     * Set mapped technical skills.
     *
     * @param {*} state
     * @param {*} technicalSkills
     */
    SET_MAPPED_TECHNICAL_SKILLS (state, technicalSkills) {
      state.mappedParsedTechnicalSkills = technicalSkills
    },
    /**
     * Set editable boolean.
     *
     * @param {*} state
     * @param {*} editable
     */
    SET_EDITABLE (state, editable) {
      state.editable = editable
    },
    /**
     * Set default form.
     *
     * @param {*} state
     * @param {*} form
     */
    SET_FORM (state, form) {
      state.form = form
    },
    /**
     * Set soft deleted items.
     *
     * @param {*} state
     * @param {*} item
     */
    SET_SOFT_DELETE (state, item) {
      state.softDeletedItems.push(item)
    }
  },
  actions: {
    /**
     * Discard changes.
     *
     * @param {commit, state}
     */
    restoreItems ({ dispatch, commit, state }) {
      const newlyAdded = state.technicalSkills.filter(el => !state.form.find(a => a.id === el.id))
      const data = state.form.concat(newlyAdded)

      dispatch('storeDefaultRecords', data)
    },
    /**
     * Soft delete selected item.
     *
     * @param {commit, state}
     * @param {*} item
     */
    softDeleteItem ({ commit, state }, item) {
      const technicalSkills = state.technicalSkills.filter((data) => data.id !== item.id)

      commit('SET_TECHNICAL_SKILLS', technicalSkills)

      commit('SET_MAPPED_TECHNICAL_SKILLS', technicalSkills)

      commit('SET_SOFT_DELETE', item)
    },
    /**
     * Set mode.
     *
     * @param {commit}
     * @param {*} item
     */
    isEditable ({ commit }, item) {
      commit('SET_EDITABLE', item)
    },
    /**
     * Set selected items.
     *
     * @param {commit}
     * @param {*} item
     */
    setTechnicalSkills ({ commit }, item) {
      commit('SET_TECHNICAL_SKILLS', item)
    },
    /**
     * Set featured skills.
     *
     * @param {commit}
     * @param {*} skills
     */
    setFeaturedSkills ({ commit }, skills) {
      commit('SET_FEATURED_SKILLS', skills)
    },

    /**
     * Set mapped selected items.
     *
     * @param {commit}
     * @param {*} item
     */
    setMappedTechnicalSkills ({ commit }, item) {
      commit('SET_MAPPED_TECHNICAL_SKILLS', item)
    },
    /**
     * Set mapped featured skills.
     *
     * @param {commit}
     * @param {*} skills
     */
    setMappedFeaturedSkills ({ commit }, skills) {
      commit('SET_MAPPED_FEATURED_SKILLS', skills)
    },
    /**
     * Set selected items.
     *
     * @param {commit}
     */
    clearSofDeletedItem ({ commit }) {
      commit('SET_SOFT_DELETE', [])
    },
    /**
     * Get Applicant Skill
     *
     * @param {commit, dispatch}
     * @param {*} params
     */
    getApplicantSkill ({ commit, state, dispatch }, params) {
      if (state.form.length) return

      return applicantSkill.simpleSearch(params)
        .then(({ data }) => {
          dispatch('mapData', data)

          return data
        })
        .catch(({ response }) => dispatch('httpException/handle', response, { root: true }))
    },
    /**
     * Store skills.
     *
     * @param {commit, dispatch, state}
     */
    storeData ({ commit, dispatch, state }) {
      const payload = {}

      const featured = state.featuredSkills.slice().map((item) => {
        const obj = Object.assign({}, item)
        obj.skill_id = item
        return obj
      })

      const techSkills = state.technicalSkills.slice().map((item) => {
        item = {
          skill_id: item.id,
          years_of_experience_range_id: item.yoe
        }

        return item
      })

      const allSkills = uniqBy(techSkills.concat(featured), 'skill_id')

      payload.applicant_skills = allSkills

      return applicantSkill.sync(payload)
        .then((data) => {
          dispatch('mapData', allSkills)

          return data
        })
        .catch(({ response }) => dispatch('httpException/handle', response, { root: true }))
    },

    /**
     * Map data to store.
     *
     */
    mapData ({ state, rootState, commit, dispatch }, data) {
      data = data.map((item) => {
        const skillCategory = rootState.skillCategory.items.find(
          skillCategory => skillCategory.skills.find(skill => skill.id === item.skill_id)
        )

        item = {
          id: item.skill_id,
          skill_category_id: skillCategory.id,
          yoe: item.years_of_experience_range_id
        }

        return item
      })

      commit('SET_FORM', data)
      dispatch('storeDefaultRecords', state.form)
    },

    /**
     * store default records.
     *
     */
    storeDefaultRecords ({ commit, state }, form) {
      if (!form) form = state.form

      form = JSON.parse(JSON.stringify([...form.slice()]))

      const technicalSkills = [
        ...form.filter((item) => item.skill_category_id !== constants.SKILL_CATEGORIES.FEATURED_SKILLS),
        ...state.mappedParsedTechnicalSkills
      ]
      const featuredSkills = [
        ...form.filter((item) => item.skill_category_id === constants.SKILL_CATEGORIES.FEATURED_SKILLS).map((item) => item.id),
        ...state.mappedParsedFeaturedSkills
      ]

      commit('SET_TECHNICAL_SKILLS', uniqBy(technicalSkills, 'id'))
      commit('SET_FEATURED_SKILLS', uniq(featuredSkills))
    }
  }
}
