export default {
  criticalError: {
    title: 'サーバーエラー',
    body: 'サーバーで問題が発生したため現在対応中です。ご不便をおかけして申し訳ございません。'
  },
  internalServerError: 'おっとっと！問題が発生しました。',
  notFound: '要求されたリソースが見つかりませんでした。',
  unauthenticated: '続行するにはログインしてください。',
  unauthorized: '禁じられた',
  unprocessable: 'リクエストにエラーがありました。',
  tooManyRequestsError: 'リクエストが多すぎます。',
  thereWasErrorOnYourRequest: 'エラーが発生しました'
}
