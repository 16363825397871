import Config from '@/config/app'
import jobPostingApi from '@/api/client/job-posting'

export default {
  namespaced: true,
  state () {
    return {
      archive: [],

      loading: false,
      loadingCompanyJobs: false,
      disableJobPostingListing: false,

      // company information
      information: {},
      industries: [],
      appeals: [],
      documents: [],

      // job posting selected id
      selected: null,
      // job details
      details: {}
    }
  },
  getters: {
    /**
     * Check if it is loading
     */
    isLoading: state => {
      return state.loading
    },
    /**
     * Identify if has a company
     */
    hasCompany: state => {
      return Object.keys(state.information).length > 0
    },
    /**
     * Identify if company has a duplicate
     */
    hasDuplicateCompany: state => {
      return (id) => state.information.id === id
    },

    /**
     * Identify if details exists
     */
    hasDetails: state => {
      return (id) => typeof state.archive[id] === 'object'
    },

    /**
     * Get the logo image
     * @param {*} state
     * @return object
     */
    logoImage: state => {
      return state.documents.find((document) => {
        const type = parseInt(document.payload.type)
        return type === Config.LOGO_IMAGE
      }) || {}
    },
    /**
     * Get the cover image
     * @param {*} state
     * @return object
     */
    coverImage: state => {
      return state.documents.find((document) => {
        const type = parseInt(document.payload.type)
        return type === Config.COVER_IMAGE
      }) || {}
    },
    /**
     * Get the appeal images
     * @param {*} state
     * @return array
     */
    appealTopImages: state => {
      return state.documents.filter((document) => {
        const type = parseInt(document.payload.type)
        return type === Config.APPEAL_TOP_IMAGE
      }) || []
    },
    /**
     * Get the appeal images
     * @param {*} state
     * @return array
     */
    appealMiddleImages: state => {
      return state.documents.filter((document) => {
        const type = parseInt(document.payload.type)
        return type === Config.APPEAL_MIDDLE_IMAGE
      }) || []
    },
    /**
     * Get the appeal images
     * @param {*} state
     * @return array
     */
    appealBottomImages: state => {
      return state.documents.filter((document) => {
        const type = parseInt(document.payload.type)
        return type === Config.APPEAL_BOTTOM_IMAGE
      }) || []
    }
  },
  mutations: {
    /**
     * Set the information.
     *
     * @param {*} state
     * @param {*} information
     */
    setInformation (state, information) {
      state.information = information
    },
    /**
     * Set the industries.
     *
     * @param {*} state
     * @param {*} industries
     */
    setIndustries (state, industries) {
      state.industries = industries || []
    },
    /**
     * Set the appeals.
     *
     * @param {*} state
     * @param {*} appeals
     */
    setAppeals (state, appeals) {
      state.appeals = appeals || []
    },
    /**
     * Set the appeals.
     *
     * @param {*} state
     * @param {*} items
     */
    setDocuments (state, documents) {
      state.documents = documents || []
    },

    /**
     * Set the loading status.
     *
     * @param {*} state
     * @param {*} loading
     */
    setLoading (state, loading) {
      state.loading = loading
    },

    /**
     * Disable Job Posting Listing.
     *
     * @param {*} state
     * @param {*} disabled
     */
    setDisableJobPostingListing (state, disabled) {
      state.disableJobPostingListing = disabled
    },

    /**
     * Set the loading status for avaialable jobs.
     *
     * @param {*} state
     * @param {*} loading
     */
    setLoadingAvailableJobs (state, loading) {
      state.loadingCompanyJobs = loading
    },

    /**
     * Set selected item.
     *
     * @param {*} state
     * @param {*} selected
     */
    setSelected (state, selected) {
      state.selected = selected
    },
    /**
     * Set details.
     *
     * @param {*} state
     * @param {*} details
     */
    setDetails (state, details) {
      state.details = details
      state.archive[details.id] = details
    },

    /**
     * Set all from archive.
     *
     * @param {*} state
     * @param {*} information
     */
    loadFromArchive (state, id) {
      state.details = state.archive[id]
      state.industries = state.archive[id].company.industries || []
      state.appeals = state.archive[id].company.appeals || []
      state.documents = state.archive[id].company.documents || []
      state.information = state.archive[id].company
    }
  },
  actions: {
    /**
     * Set details.
     *
     * @param {commit}
     * @param {*} details
     */
    setJobDetails ({ commit }, details) {
      commit('setDetails', details)
    },

    /**
     * Set selected items.
     *
     * @param {commit}
     * @param {*} item
     */
    setSelectedItem ({ commit }, item) {
      commit('setSelected', item)
    },

    /**
     * Fetch details
     * If details is already populated skip the process
     *
     * @param { getters, commit, dispatch} id
     */
    async softFetchDetails ({ getters, commit, dispatch }, id) {
      if (getters.hasDetails(id)) commit('loadFromArchive', id)
      else return await dispatch('fetchDetails', id)
    },

    /**
     * Fetch Details
     *
     * @param {commit, dispatch} object
     */
    fetchDetails ({ commit, dispatch }, id) {
      commit('setLoading', true)

      return jobPostingApi.details({ id, first: 1 })
        .then(({ data }) => {
          commit('setDetails', data)
          commit('setIndustries', data.company.industries || [])
          commit('setAppeals', data.company.appeals || [])
          commit('setDocuments', data.company.documents || [])
          commit('setInformation', data.company)
          return data
        })
        .catch(({ response }) => {
          dispatch('httpException/handle', response, { root: true })
        })
        .finally(() => {
          commit('setLoading', false)
        })
    }
  }
}
