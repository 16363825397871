export default {
  title: '表彰・受賞歴',
  edit: '表彰・受賞歴を編集',
  success: '表彰・受賞歴は正常に保存されました',
  add: 'さらに追加',
  dialog: {
    exists: '表彰・受賞歴はすでに存在します。',
    title: '表彰・受賞歴を追加する',
    form: {
      name: '表彰・受賞名'
    }
  }
}
