<template>
  <v-footer
    v-if="showFooter"
    padless
    class="font-weight-regular body-2 footer-position"
  >
    <v-container class="pa-0">
      <v-row v-if="footer" justify="center" no-gutters>
        <v-col v-if="footer" cols="12">
          <AppFooterMobile
            class="text-center d-flex d-lg-none d-xl-none"
            :year="year"
            :links="links"
          />
          <AppFooterDesktop
            class="d-none d-lg-flex d-xl-flex"
            :year="year"
            :links="links"
          />
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col
          v-if="
            $vuetify.breakpoint.mdAndDown && isRegistered && bottomNavigation
          "
          cols="12"
          class="pt-15"
        >
          <AppMobileNavigation
            :unread-count="message_unread_count"
            :approach-unread-count="approachUnreadCount"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import AppFooterMobile from '@/components/AppFooterMobile'
import AppFooterDesktop from '@/components/AppFooterDesktop'
import AppMobileNavigation from '@/components/AppMobileNavigation'
import logger from '@/services/logger'

export default {
  components: {
    AppFooterMobile,
    AppFooterDesktop,
    AppMobileNavigation,
  },
  data() {
    return {
      year: '2020',
      privacyPolicyUrl: 'https://www.bizmates.co.jp/privacy/',
    }
  },
  computed: {
    ...mapState('core', ['bottomNavigation', 'footer']),
    ...mapState('approachList', {
      approachUnread: 'unread',
    }),
    ...mapState('messageNotifications', {
      message_unread_count: 'unread_count',
    }),
    ...mapState('applicant', ['auth']),

    showFooter () {
      return !['profile-registration', 'welcome', 'cv-edit'].includes(this.$route.path.replace(/\//, ''))
    },

    isRegistered() {
      return this.auth ? !!this.auth.registered_at : false
    },

    /**
     * Get the approach unread count.
     *
     */
    approachUnreadCount() {
      try {
        return this.approachUnread.total_count
      } catch (error) {
        logger.error(error)

        return 0
      }
    },

    /**
     * Footer links
     *
     */
    links() {
      let faqLinks = '/faq-inquiry'

      if (!this.auth) {
        faqLinks =
          this.$i18n.locale === 'en'
            ? `${process.env.VUE_APP_VISITOR_URL}/faq-inquiry`
            : `${process.env.VUE_APP_VISITOR_URL}/ja/faq-inquiry`
      }

      return [
        {
          title: this.$t('navigation./faq-inquiry'),
          path: faqLinks,
        },
        {
          title: this.$t('navigation./about-us'),
          path: `${process.env.VUE_APP_VISITOR_URL}/about-us`,
        },
        {
          title: this.$t('navigation./terms-of-use'),
          path: '/terms-of-use',
        },
        {
          title: this.$t('navigation./privacy-policy'),
          path: this.privacyPolicyUrl,
        },
      ]
    },
  },
  created() {
    this.setYearFooter()

    if (this.auth) this.getApplicant()
  },
  methods: {
    ...mapActions('applicant', { getApplicant: 'softFetch' }),
    /**
     * Set year for trademark footer
     */
    setYearFooter() {
      const currentYear = new Date().getFullYear().toString()

      this.year =
        currentYear === this.year ? this.year : `${this.year} - ${currentYear}`
    },
  },
}
</script>

<style scoped>
.footer-position {
  position: relative;
  border-top: 2px solid #eceff1 !important;
  background: white !important;
}
</style>
