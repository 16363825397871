import api from '../index'

const {
  http,
  index
} = api

/**
 * ===================
 * Scout decline reasons API
 * ===================
 */
export default {
  baseUrl: `${process.env.VUE_APP_COMMON_URL}/api/v1`,
  url: 'scout-decline-reasons',
  http,
  index
}
