import api from '../index'

const {
  http,
  index
} = api

/**
 * ===================
 * IT Certificate Categories API
 * ===================
 */
export default {
  baseUrl: `${process.env.VUE_APP_COMMON_URL}/api/v1`,
  url: 'it-certificate-categories',
  http,
  index
}
