import GuardService from '@/services/guard'

const Home = () => import('../pages/Home')

const GitTapInfo = () => import('../pages/GitTapInfo')
const MySearch = () => import('../pages/MySearchPage')
const FAQInquiryPage = () => import('../pages/FAQInquiryPage')
const BlogPage = () => import('../pages/BlogPage')
const SignUp = () => import('../pages/SignUp')
const ForgotPassword = () => import('../pages/ForgotPassword')
const ResetPassword = () => import('../pages/ResetPassword')
const Login = () => import('../pages/Login')
const TermsOfUse = () => import('../pages/TermsOfUse')
const HandlingPersonalInformation = () => import('../pages/HandlingPersonalInformation')
const SignUpSuccessPage = () => import('../pages/SignUpCompletePage')
const RegistrationSuccessPage = () => import('../pages/RegistrationCompletePage')
const ChangePassword = () => import('../pages/ChangePassword')
const InitialPasswordChange = () => import('../pages/InitialPasswordChange')
const ChangeEmail = () => import('../pages/ChangeEmail')
const Messages = () => import('../pages/MessagesV2')
const Notification = () => import('../pages/Notifications')
const SocialLinkedin = () => import('../pages/SocialLinkedin')
const SocialGithub = () => import('../pages/SocialGithub')
const LpJp = () => import('../pages/LpJp')
const LpEn = () => import('../pages/LpEn')

const SimpleProfileRegistration = () => import('../pages/SimpleProfileRegistration')

const ProfileRegistration = () => import('../pages/ProfileRegistration')
const Channel = () => import('../pages/ProfileRegistration/Channel')
const Language = () => import('../pages/ProfileRegistration/Language')
const PreferenceRegistration = () => import('../pages/ProfileRegistration/PreferenceRegistration')
const PreferenceRegistrationStepOne = () => import('../pages/ProfileRegistration/PreferenceRegistration/StepOne')
const PreferenceRegistrationStepTwo = () => import('../pages/ProfileRegistration/PreferenceRegistration/StepTwo')
const PreferenceRegistrationStepFour = () => import('../pages/ProfileRegistration/PreferenceRegistration/StepFour')
const FinishRegistration = () => import('../pages/ProfileRegistration/FinishRegistration')

const ExperienceRegistration = () => import('../pages/ProfileRegistration/ExperienceRegistration')
const ExperienceRegistrationStepOne = () => import('../pages/ProfileRegistration/ExperienceRegistration/StepOne')
const ExperienceRegistrationStepTwo = () => import('../pages/ProfileRegistration/ExperienceRegistration/StepTwo')
const ExperienceRegistrationStepThree = () => import('../pages/ProfileRegistration/ExperienceRegistration/StepThree')
const ExperienceRegistrationStepFour = () => import('../pages/ProfileRegistration/ExperienceRegistration/StepFour')
const ExperienceRegistrationStepFive = () => import('../pages/ProfileRegistration/ExperienceRegistration/StepFive')

const MyPage = () => import('../pages/MyPage')

const JobsPage = () => import('../pages/JobsPage')
const JobSearch = () => import('../pages/JobsPage/JobSearch')
const JobDetail = () => import('../pages/JobsPage/JobDetail')
const JobApplyComplete = () => import('../pages/JobsPage/JobApplyComplete')

const Approach = () => import('../pages/Approach')
const Scouted = () => import('../pages/Approach/Scouted')
const Applied = () => import('../pages/Approach/Applied')
const Interested = () => import('../pages/Approach/Interested')
const Hidden = () => import('../pages/Approach/Hidden')
const Saved = () => import('../pages/Approach/Saved')
const Recommend = () => import('../pages/Approach/Recommend')

const DeleteAccount = () => import('../pages/DeleteAccount')

const ReferralPage = () => import('../pages/ReferralPage')
const CampaignPage = () => import('../pages/CampaignPage')

const ProfileSettings = () => import('../pages/ProfileSettings')
const ProfilePreview = () => import('../pages/ProfilePreview')

const AccountSettings = () => import('../pages/AccountSettings')
const CredentialSettings = () => import('../pages/AccountSettings/CredentialSettings')
const EmailSettings = () => import('../pages/AccountSettings/EmailSettings')
const ExcludedSettings = () => import('../pages/AccountSettings/ExcludedSettings')
const InformSettings = () => import('../pages/AccountSettings/InformSettings')
const PrivateSettings = () => import('../pages/AccountSettings/PrivateSettings')

/**
 * =======================================================================
 * App routes
 * =======================================================================
 *
 * Lazy loads component to initial page load improve performance.
 * Lazy load components are only loaded when a user is entering the route.
 * Access to this routes require user to be authenticated.
 *
 * =======================================================================
 */
export default {
  routes: [
    {
      path: '',
      name: 'Home',
      meta: { gtm: 'Home' },
      component: Home,
      redirect: '/',
      children: [
        {
          name: 'DeleteAccount',
          path: '/delete-account',
          meta: { gtm: 'DeleteAccount' },
          component: DeleteAccount,
          beforeEnter: (to, from, next) => GuardService.authorized(to, next)
        },
        {
          name: 'MyPage',
          path: '/',
          meta: { gtm: 'MyPage' },
          component: MyPage,
          beforeEnter: (to, from, next) => GuardService.authorized(to, next)
        },
        {
          name: 'GitTapInfo',
          path: '/gittap-info',
          meta: { gtm: 'GitTapInfo' },
          component: GitTapInfo
        },
        {
          name: 'NotificationList',
          path: '/notifications',
          meta: { gtm: 'NotificationList' },
          component: Notification,
          beforeEnter: (to, from, next) => GuardService.authorized(to, next),
          children: [
            {
              name: 'Notification',
              path: ':id',
              meta: { gtm: 'Notification' },
              component: Notification,
              beforeEnter: (to, from, next) => GuardService.authorized(to, next)
            }
          ]
        },
        {
          name: 'ReferralPage',
          path: '/referral-program',
          meta: { gtm: 'ReferralPage' },
          component: ReferralPage
        },
        {
          name: 'CampaignPage',
          path: '/interview-campaign',
          meta: { gtm: 'CampaignPage' },
          component: CampaignPage
        },
        {
          name: 'JobsPage',
          path: '/jobs',
          meta: { gtm: 'JobsPage' },
          component: JobsPage,
          beforeEnter: (to, from, next) => GuardService.authorized(to, next),
          children: [
            {
              name: 'JobSearch',
              path: '/',
              meta: { gtm: 'JobSearch' },
              component: JobSearch
            },
            {
              name: 'JobDetail',
              path: ':id',
              meta: { gtm: 'JobDetail' },
              component: JobDetail
            },
            {
              name: 'JobApplyComplete',
              path: ':id/applycomplete',
              meta: { gtm: 'JobApplyComplete' },
              component: JobApplyComplete
            }
          ]
        },
        {
          name: 'MySearch',
          path: '/my-search',
          meta: { gtm: 'MySearch' },
          component: MySearch,
          beforeEnter: (to, from, next) => GuardService.authorized(to, next)
        },
        {
          name: 'InquiryPage',
          path: '/faq-inquiry',
          meta: { gtm: 'InquiryPage' },
          component: FAQInquiryPage,
          beforeEnter: (to, from, next) => GuardService.authorized(to, next)
        },
        {
          name: 'BlogPage',
          path: '/blog',
          meta: { gtm: 'BlogPage' },
          component: BlogPage
        },
        {
          name: 'Approach',
          path: '/approach',
          meta: { gtm: 'Approach' },
          component: Approach,
          beforeEnter: (to, from, next) => GuardService.authorized(to, next),
          children: [
            {
              name: 'Scouted',
              path: 'scouted',
              meta: { gtm: 'Scouted' },
              alias: '/',
              component: Scouted
            },
            {
              name: 'Interested',
              path: 'interested',
              meta: { gtm: 'Interested' },
              component: Interested
            },
            {
              name: 'Saved',
              path: 'saved',
              meta: { gtm: 'Saved' },
              component: Saved
            },
            {
              name: 'Applied',
              path: 'applied',
              meta: { gtm: 'Applied' },
              component: Applied
            },
            {
              name: 'Hidden',
              path: 'hidden',
              meta: { gtm: 'Hidden' },
              component: Hidden
            },
            {
              name: 'Recommend',
              path: 'Recommend',
              meta: { gtm: 'Recommend' },
              component: Recommend
            }
          ]
        },
        {
          name: 'Messages',
          path: '/messages',
          meta: { gtm: 'Messages' },
          component: Messages,
          beforeEnter: (to, from, next) => GuardService.authorized(to, next),
          children: [
            {
              name: 'Threads',
              path: '/',
              meta: { gtm: 'Threads' }
            },
            {
              name: 'Conversation',
              path: ':code',
              meta: { gtm: 'Conversation' }
            }
          ]
        },
        {
          name: 'TermsOfUse',
          path: '/terms-of-use',
          meta: { gtm: 'TermsOfUse' },
          component: TermsOfUse
        },
        {
          name: 'HandlingPersonalInformation',
          path: '/personal-information',
          meta: { gtm: 'HandlingPersonalInformation' },
          component: HandlingPersonalInformation
        },
        {
          name: 'SocialLinkedin',
          path: '/social-linkedin',
          meta: { gtm: 'SocialLinkedin' },
          component: SocialLinkedin
        },
        {
          name: 'SocialGithub',
          path: '/social-github',
          meta: { gtm: 'SocialGithub' },
          component: SocialGithub
        },
        {
          name: 'SignUp',
          path: '/sign-up',
          meta: { gtm: 'SignUp' },
          component: SignUp,
          beforeEnter: (to, from, next) => GuardService.registration(to, from, next)
        },
        {
          name: 'Welcome',
          path: '/welcome',
          meta: { gtm: 'Welcome' },
          redirect: '/profile-registration',
          component: () => import('../pages/Welcome.vue'),
          beforeEnter: (to, from, next) => GuardService.authorizedNotRegistered(to, next)
        },
        {
          name: 'ProfileEdit',
          path: '/cv-edit',
          meta: { gtm: 'ProfileEdit' },
          component: () => import('../pages/CVEdit.vue'),
          beforeEnter: (to, from, next) => GuardService.authorizedNotRegistered(to, next)
        },
        {
          name: 'SimpleProfileRegistration',
          path: '/profile-registration',
          meta: { gtm: 'ProfileRegistration' },
          component: SimpleProfileRegistration,
          beforeEnter: (to, from, next) => GuardService.authorizedNotRegistered(to, next)
        },
        {
          name: 'ProfileRegistration_old',
          path: '/profile-registration_old',
          meta: { gtm: 'ProfileRegistration' },
          component: ProfileRegistration,
          beforeEnter: (to, from, next) => GuardService.authorizedNotRegistered(to, next),
          children: [
            {
              name: 'FinishRegistration',
              path: 'finish',
              meta: { gtm: 'FinishRegistration' },
              redirect: '/profile-registration',
              component: FinishRegistration
            },
            {
              name: 'Channel',
              path: 'channel',
              meta: { gtm: 'Channel' },
              redirect: '/profile-registration',
              component: Channel
            },
            {
              name: 'Language',
              path: 'language',
              alias: '/',
              meta: { gtm: 'Language' },
              redirect: '/profile-registration',
              component: Language
            },
            {
              name: 'PreferenceRegistration',
              path: 'preference',
              meta: { gtm: 'PreferenceRegistration' },
              component: PreferenceRegistration,
              children: [
                {
                  name: 'PreferenceRegistrationStepOne',
                  path: 'step-1',
                  meta: { gtm: 'PreferenceRegistrationStepOne' },
                  // alias: '/',
                  redirect: '/profile-registration',
                  component: PreferenceRegistrationStepOne
                },
                {
                  name: 'PreferenceRegistrationStepTwo',
                  path: 'step-2',
                  meta: { gtm: 'PreferenceRegistrationStepTwo' },
                  redirect: '/profile-registration',
                  component: PreferenceRegistrationStepTwo
                },
                {
                  name: 'PreferenceRegistrationStepFour',
                  path: 'step-3',
                  meta: { gtm: 'PreferenceRegistrationStepFour' },
                  redirect: '/profile-registration',
                  component: PreferenceRegistrationStepFour
                }
              ]
            },
            {
              name: 'ExperienceRegistration',
              path: 'experience',
              meta: { gtm: 'ExperienceRegistration' },
              component: ExperienceRegistration,
              children: [
                {
                  name: 'ExperienceRegistrationStepOne',
                  path: 'step-1',
                  meta: { gtm: 'ExperienceRegistrationStepOne' },
                  alias: '/',
                  redirect: '/profile-registration',
                  component: ExperienceRegistrationStepOne
                },
                {
                  name: 'ExperienceRegistrationStepTwo',
                  path: 'step-2',
                  meta: { gtm: 'ExperienceRegistrationStepTwo' },
                  redirect: '/profile-registration',
                  component: ExperienceRegistrationStepTwo
                },
                {
                  name: 'ExperienceRegistrationStepThree',
                  path: 'step-3',
                  meta: { gtm: 'ExperienceRegistrationStepThree' },
                  redirect: '/profile-registration',
                  component: ExperienceRegistrationStepThree
                },
                {
                  name: 'ExperienceRegistrationStepFour',
                  path: 'step-4',
                  meta: { gtm: 'ExperienceRegistrationStepFour' },
                  redirect: '/profile-registration',
                  component: ExperienceRegistrationStepFour
                },
                {
                  name: 'ExperienceRegistrationStepFive',
                  path: 'step-5',
                  meta: { gtm: 'ExperienceRegistrationStepFive' },
                  redirect: '/profile-registration',
                  component: ExperienceRegistrationStepFive
                }
              ]
            }
          ]
        },
        {
          name: 'ProfilePreview',
          path: '/profile-preview',
          meta: { gtm: 'ProfilePreview' },
          component: ProfilePreview,
          beforeEnter: (to, from, next) => GuardService.authorized(to, next)
        },
        {
          name: 'ProfileSettings',
          path: '/profile-settings/:section?',
          meta: { gtm: 'ProfileSettings' },
          component: ProfileSettings,
          beforeEnter: (to, from, next) => GuardService.authorized(to, next)
        },
        {
          name: 'AccountSettings',
          path: '/account-settings',
          meta: { gtm: 'AccountSettings' },
          component: AccountSettings,
          beforeEnter: (to, from, next) => GuardService.authorized(to, next),
          children: [
            {
              name: 'CredentialSettings',
              path: 'credential',
              meta: { gtm: 'CredentialSettings' },
              component: CredentialSettings
            },
            {
              name: 'EmailSettings',
              path: 'email',
              meta: { gtm: 'EmailSettings' },
              component: EmailSettings
            },
            {
              name: 'ExcludedSettings',
              path: 'excluded-company',
              meta: { gtm: 'ExcludedSettings' },
              component: ExcludedSettings
            },
            {
              name: 'InformSettings',
              path: 'inform',
              meta: { gtm: 'InformSettings' },
              component: InformSettings
            },
            {
              name: 'PrivateSettings',
              path: 'private',
              meta: { gtm: 'PrivateSettings' },
              component: PrivateSettings
            }
          ]
        },
        {
          name: 'ChangePassword',
          path: '/change-password',
          meta: { gtm: 'ChangePassword' },
          component: ChangePassword,
          beforeEnter: (to, from, next) => GuardService.authorized(to, next)
        },
        {
          name: 'InitialPasswordChange',
          path: '/initial-password-change',
          meta: { gtm: 'ChangePassword' },
          component: InitialPasswordChange,
          beforeEnter: (to, from, next) => GuardService.authorized(to, next)
        },
        {
          name: 'ChangeEmail',
          path: '/change-email',
          meta: { gtm: 'ChangeEmail' },
          component: ChangeEmail,
          beforeEnter: (to, from, next) => GuardService.authorized(to, next)
        }
      ]
    },
    {
      name: 'Login',
      path: '/login',
      meta: { gtm: 'Login' },
      component: Login,
      beforeEnter: (to, from, next) => GuardService.registration(to, from, next)
    },
    {
      name: 'ForgotPassword',
      path: '/forgot-password',
      meta: { gtm: 'ForgotPassword' },
      component: ForgotPassword,
      beforeEnter: (to, from, next) => GuardService.registration(to, from, next)
    },
    {
      name: 'ResetPassword',
      path: '/reset-password',
      meta: { gtm: 'ResetPassword' },
      component: ResetPassword,
      beforeEnter: (to, from, next) => GuardService.registration(to, from, next)
    },
    {
      name: 'SignUpSuccessPage',
      path: '/successsignup',
      meta: { gtm: 'SignUpSuccessPage' },
      component: SignUpSuccessPage,
      beforeEnter: (to, from, next) => GuardService.registration(to, from, next)
    },
    {
      name: 'RegistrationSuccessPage',
      path: '/successregistration',
      meta: { gtm: 'RegistrationSuccessPage' },
      component: RegistrationSuccessPage
    },
    {
      name: 'LpJp',
      path: '/lp-jp',
      meta: { gtm: 'LpJp' },
      component: LpJp
    },
    {
      name: 'LpEn',
      path: '/lp-en',
      meta: { gtm: 'LpEn' },
      component: LpEn
    }
  ]
}
