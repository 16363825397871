import Vue from 'vue'
import Vuelidate from 'vuelidate'
import VueProgressBar from 'vue-progressbar'
import gtm from '@gtm-support/vue2-gtm'
import VueGtag from 'vue-gtag'
import { VueReCaptcha } from 'vue-recaptcha-v3'

import App from './App.vue'
import store from './store'
import router from './router'
import i18n from './plugins/i18n'
import vuetify from './plugins/vuetify'
import vueProgressBar from './plugins/vue-progress-bar'

import './plugins/vue-head'
import './plugins/vue-cookie'
import './plugins/vue-country-flag'
import './plugins/luxon'

Vue.config.devtools = process.env.VUE_APP_ENABLE_DEV_TOOLS === 'true'

Vue.use(VueProgressBar, vueProgressBar)

Vue.use(Vuelidate)

Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_GOOGLE_ANALYTICS_ID }
}, router)

if (process.env.VUE_APP_BUILD_ENV === 'production' || process.env.VUE_APP_BUILD_ENV === 'staging') {
  Vue.use(gtm, {
    id: process.env.VUE_APP_GTM,
    enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
    debug: false, // Whether or not display console logs debugs (optional),
    vueRouter: router // Pass the router instance to automatically sync with router
  })
}

Vue.config.productionTip = false

Vue.use(VueReCaptcha, { siteKey: process.env.VUE_APP_GOOGLE_RECAPTCHA_SITE_KEY_V3 })

new Vue({
  vuetify,
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

if (process.env.VUE_APP_ENABLE_DEV_TOOLS === 'true') {
  window.__VUE_DEVTOOLS_GLOBAL_HOOK__.Vue = App.constructor
}
