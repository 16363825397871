export default {
  title: 'Change Email',
  email: {
    label: 'Email',
    placeholder: 'New Email'
  },
  password: {
    label: 'Current password(confirmation)',
    placeholder: 'Your password'
  },
  confirmCode: 'Confirm Code',
  authCode: 'Please input the six-digit authorization code',
  update: 'Update email',
  resend: 'Resend Code',
  resendSuccess: 'Resend Successful',
  notSame: 'New email cannot be your existing email address',
  success: 'Your email was set successfully.',
  sent: 'An email has been sent to {email}.'
}
