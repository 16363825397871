export default {
  title: 'Education',
  educationalBackground: 'Educational Background',
  list: 'list is automatically sorted by the recent school history.',
  school: 'School',
  schoolName: 'School Name',
  schoolUrl: 'School URL',
  degree: 'Degree / Certificate',
  major: 'Major',
  location: 'Location',
  start: 'Start',
  end: 'End',
  from: 'From',
  to: 'To',
  periodFrom: 'Period From',
  current: 'Currently Studying',
  currentStudent: 'Current Student',
  edit: 'Edit Education Information',
  success: 'Education Saved Successfully',
  validation: {
    url: 'Please input full URL including http or https'
  },
  addNewEducation: 'Add new education',
  editEducation: 'Edit Education',
  addSchool: 'Add School',
  period: 'Period',
  optionalInformation: 'Optional Information',
  pleaseInputUrl: 'Please input URL',
  removeEducation: 'Remove Education',
  removeEducationBody: 'Are you sure you want to delete this education history?'
}
