export default {
  '/gittap-info': "What's GitTap?",
  '/jobs': 'Jobs',
  '/faq-inquiry': 'FAQ/Inquiry',
  '/blog': 'Blog',
  '/contact-us': 'Contact Us',
  '/terms-of-use': 'Terms of Use',
  '/privacy-policy': 'Privacy Policy',
  '/handling-personal-information': 'Handling Personal Information',
  '/search': 'Job Search',
  '/personal-information': 'My Profile',
  '/sign-up': 'Sign Up',
  '/login': 'Login',
  '/personal-information/profile': 'Profile',
  '/personal-information/skill-experience': 'Skill/Experience',
  '/personal-information/preference': 'Preference',
  '/about-us': 'About Us',
  '/for-employer': 'For Employer',
  '/account-settings': 'Account'
}
