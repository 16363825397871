<template>
  <v-app-bar
    v-show="settingHeader && !toolbar"
    :fixed="isProfileSettingPage"
    height="48"
    color="white"
    flat
    class="toolbar"
    style="border:1px solid #EEEEEE !important;"
  >
    <v-row no-gutters>
      <v-col
        cols="2"
        align="center"
        @click="backAction(inPreviewMode)"
      >
        <v-app-bar-nav-icon>
          <v-icon
            color="#626262"
            size="50"
            v-text="'mdi-chevron-left'"
          />
        </v-app-bar-nav-icon>
      </v-col>
      <v-col align-self="center">
        <v-toolbar-title>
          <span class="font-15-list">{{ title }}</span>
        </v-toolbar-title>
      </v-col>
    </v-row>
  </v-app-bar>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  computed: {
    ...mapState('core', ['settingHeader', 'inPreviewMode', 'toolbar']),

    title () {
      if (this.$route.path.includes('profile-settings')) {
        return this.$t('settings.profileSettings.title')
      } else return this.$t('settings.accountSettings.title')
    },

    isProfileSettingPage () {
      return this.$route.path.includes('profile-settings')
    },

    // detect ios device
    isIos () {
      return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
      ].includes(navigator.platform) ||
  // iPad on iOS 13 detection
  (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    }
  },
  methods: {
    ...mapActions('core', ['setBottomNavigation', 'setToolbar', 'setInPreviewMode', 'setSettingHeader']),

    backFromPreview () {
      this.setInPreviewMode(false)
      this.setSettingHeader(false)

      this.setBottomNavigation(true)
      this.setToolbar(true)

      window.scrollTo({ top: 0, behavior: 'smooth' })

      if (this.$route.path.includes('profile-settings')) {
        this.$router.push('/profile-settings')
      } else if (this.$route.path.includes('account-settings')) {
        this.$router.push('/account-settings')
      }
    },
    backFromSettings () {
      if (this.$route.path.includes('profile-settings')) {
        this.$router.push('/profile-settings')
      } else if (this.$route.path.includes('account-settings')) {
        this.$router.push('/account-settings')
      }

      if (!this.dirty) {
        window.scrollTo({ top: 0, behavior: 'smooth' })
      }
    },

    backAction (inPreviewMode) {
      if (!inPreviewMode) {
        this.backFromSettings()
      } else this.backFromPreview()
    }
  }
}
</script>

<style scoped>
.font-15-list {
  font: normal normal normal 15px Roboto;
  letter-spacing: 0px;
  color: var(--v-text-base);
  font-weight: bold;
}
.toolbar >>> .v-toolbar__content, .v-toolbar__extension {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.toolbar >>> .v-toolbar__content >>> .v-toolbar__extension {
  padding-left: 0px !important;
}
</style>
