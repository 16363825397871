export default {
  title: 'FAQ',
  search: 'Search',
  instruction: 'If you can not solve your problem with FAQ, please fill in an inquiry form below.',
  instructionAsterisk1: ' * Please fill in your inquiry as detail information as possible',
  instructionAsterisk2: ' * Our response will be delayed when our office will be closed for weekends, Holidays, and New Year\'s holiday.',
  items: [
    {
      title: 'About "initial registration"',
      topics: [
        {
          title: '"Profile registration" related questions',
          questions: [
            {
              question: 'Can I register without filling all the items?',
              answer: 'Yes, you can register by filling in only the required items displayed in red letters.',
              show: 'both'
            },
            {
              question: 'Do I have to register my profile?',
              answer: `Yes, GitTap does not provide any personal information or resumes to companies until interviews are confirmed.
              Therefore, companies need your profile to examine your skills and experiences before inviting you to interviews. The more detailed your profile is, the more likely you are to be invited to interviews.`,
              show: 'both'
            },
            {
              question: 'Is it possible to skip the initial registration?',
              answer: 'No. Please register your profile first to use the service. This allows companies to find the right candidates, and you to be found by the companies that suit you.',
              show: 'both'
            },
            {
              question: 'What are the advantages of enriching your profile？',
              answer: 'The companies will get a more detailed picture about you and your skills, therefore the matching rate wil be higher.',
              show: 'after login'
            },
            {
              question: 'How much of my registered information will be disclosed?',
              answer: `Once you register on the platform, all information except for your "Name," "Email Address," "Phone Number," and "Current Address" will be disclosed.
              When you apply for a job, your name will be sent to the company you are applying to.
              Your email address, phone number, and current address will be disclosed to the company only when you move on to the first interview.`,
              show: 'after login'
            },
            {
              question: 'Can I register even if I am not living in Japan?',
              answer: "Yes, it doesn't matter if you are not in Japan.",
              show: 'both'
            },
            {
              question: "Can I register even if I don't speak Japanese?",
              answer: 'Yes, you can. You can change the language settings on GitTap at any time.',
              show: 'both'
            },
            {
              question: 'How many resumes can I register on my profile?',
              answer: 'You can only register one resume. Please understand in advance.',
              show: 'after login'
            }
          ]
        }
      ]
    },
    {
      title: 'About "Job Search"',
      topics: [
        {
          title: '"Job Search" related questions',
          questions: [
            {
              question: 'What is a search filter?',
              answer: 'It is an advanced search function where you can narrow your search results to find jobs that suit you using various filters, including keywords, locations, job titles, employment types, industries, IT skills, language levels, JLPT, your ideal annual income, and date posted.',
              show: 'after login'
            },
            {
              question: 'What is a saved search?',
              answer: 'It is a function that allows you to get the search results you want quickly by saving your favorite filters and keywords.',
              show: 'after login'
            },
            {
              question: 'How many search lists can I make?',
              answer: 'You can make as many search lists as you want.',
              show: 'after login'
            }
          ]
        },
        {
          title: '"Know More" related questions',
          questions: [
            {
              question: 'What is "Know More" ?',
              answer: `By sending "Know more", you can show companies that you're interested in learning more about the positions.
              If companies send back "pinned" to you, you can have a casual interview as well.`,
              show: 'both'
            },
            {
              question: 'Can I use "Know More" unlimitedly?',
              answer: 'You can make as many search lists as you want.',
              show: 'both'
            }
          ]
        }
      ]
    },
    {
      title: 'About "Apply"',
      topics: [
        {
          title: '"Apply" related questions',
          questions: [
            {
              question: 'Can I apply for the same job again?',
              answer: 'You cannot apply for the same job more than once.',
              show: 'after login'
            },
            {
              question: 'Can I apply for more than one position at a company at one time?',
              answer: 'Yes, we do not set an upper limit on the number of applications, so to apply to as many as you like.',
              show: 'after login'
            },
            {
              question: "It has been more than a week since I applied, but I haven't received any results or feedback.",
              answer: `Make sure to check your "message" on GitTap first. We do not use emails to notify you of the result. 
              If you haven't received any message yet, please send a message to the recruiter or contact us at info@gittap.jp. When you contact us, please mention your name and ID in the message.`,
              show: 'after login'
            }
          ]
        }
      ]
    },
    {
      title: 'About "interview"',
      topics: [
        {
          title: '"Interview" related questions',
          questions: [
            {
              question: 'Is it possible to have an English interview?',
              answer: 'Yes, but it depends on the company. Make sure to check the job posts for details.',
              show: 'both'
            },
            {
              question: 'Is it possible to have an online interview?',
              answer: 'Yes, some companies may have an online interview. Please check the job post for details.',
              show: 'both'
            }
          ]
        }
      ]
    },
    {
      title: 'About "approach list"',
      topics: [
        {
          title: '"Pinned" related questions',
          questions: [
            {
              question: 'What is "Pinned"?',
              answer: `"Pinned" is like a Favorite Button sent by companies to show their interest in you.
              If you are interested in the jobs they are offering, it is a good idea to "apply" immediately or ask for a casual interview. To apply for a casual interview, simply send back the "I'd like to know more" to the company.`,
              show: 'both'
            }
          ]
        },
        {
          title: '"Matches" related questions',
          questions: [
            {
              question: 'What is a "match"?',
              answer: `When you send the "Know More" on a job post, and the company "Pinned" your profile back (or vice versa), then both of you are "Match". 
              Once you have a job post with "Matched" status, it is a good idea to apply as quickly as possible. You can even ask for a casual interview as well.`,
              show: 'both'
            },
            {
              question: "I can't see the company I'm interacting with in the match. why?",
              answer: 'Companies that you are in contact with do not appear on the "Matched" page. Please use the "Message" page instead. To see a job post on the "Matched" page, you need to send the "Know More" on the job post, and the company has to "Pin" you on it.',
              show: 'after login'
            }
          ]
        },
        {
          title: '"Applied" related questions',
          questions: [
            {
              question: 'Is it possible to edit the list of "applied" companies?',
              answer: 'It cannot be edited.',
              show: 'after login'
            },
            {
              question: 'How long will the application history remain?',
              answer: 'Until the recruitment is completed.',
              show: 'after login'
            },
            {
              question: 'I want to apply for a company that I already declined.',
              answer: 'Unfortunately, you can\'t reapply to the company that you already declined.',
              show: 'after login'
            },
            {
              question: 'I would like to talk to the hiring company before applying.',
              answer: `You can talk to the company before officially applying to the job in the following cases:
              ・When you send "Know More" on the job post, and the company "Pin" your profile
              ・When you send  "I'd like to know more" to the company`,
              show: 'after login'
            }
          ]
        },
        {
          title: '"Know More" related questions',
          questions: [
            {
              question: 'What is the difference between "Pinned" and "Know More"?',
              answer: `"Know More" is a function that allows you to show your interest to the job position. You can send the "Know more" to companies from each job post. 
              "Pinned", on the other hand, is a similar action by the companies when they are interested in your profile for their job posting.`,
              show: 'both'
            },
            {
              question: 'Can I cancel my "Know More"?',
              answer: 'You cannot cancel it.',
              show: 'after login'
            }
          ]
        },
        {
          title: '"Hidden" related questions',
          questions: [
            {
              question: 'Is it possible to change the "Hidden" list?',
              answer: 'Yes. Please click "Show" from the "Hidden" list, and select "Display/Show?" to display the companies.',
              show: 'after login'
            },
            {
              question: 'Can I add notes to the "Hidden" list?',
              answer: 'You can\'t make a note in the list.',
              show: 'after login'
            }
          ]
        }
      ]
    },
    {
      title: 'About "message"',
      topics: [
        {
          title: '"Messages" related questions',
          questions: [
            {
              question: "I haven't received any response from the company about my application. What should I do?",
              answer: `Notifications will be sent to the company within a week from your application.
              If you still don't get any response, please contact the GitTap Team.`,
              show: 'after login'
            },
            {
              question: 'Unreplied messages disappeared automatically. Why is this happening?',
              answer: `The unreplied scout messages will be deleted after a certain period of time.
              Please be aware.`,
              show: 'after login'
            },
            {
              question: 'Can I attach files(images, documents, videos etc) to messages?',
              answer: `You can attach only documents and images in the message.
              (Image file types should be either JPEG or PNG, and the file size should be less than 5MB).`,
              show: 'after login'
            },
            {
              question: 'Can I send a direct message to the company?',
              answer: `You can send a direct message when: 
              ・You reply to the company that "Pinned" you,
              ・You reply to scout messages from companies, or
              ・You move on to interviews.`,
              show: 'after login'
            },
            {
              question: 'Do I get any penalty for replying late?',
              answer: `There is no penalty rule. In the case of scouts, however, there is a certain period of validity on the message. 
              You won't be able to reply after a certain period of time, so please reply it as soon as you can.`,
              show: 'after login'
            },
            {
              question: 'Can I edit or delete the sent messages?',
              answer: 'No. Please be sure to review your message before you send it.',
              show: 'after login'
            },
            {
              question: 'How can I know if my messages have been read?',
              answer: 'You cannot check if the messages have been read or not.',
              show: 'after login'
            },
            {
              question: 'Can I contact the companies directly?',
              answer: `Companies and job seekers will be able to contact directly when,
              ・Job seekers reply to scout messages
              ・Your application are accepted by the company
              ・Companies "pin" you, and you send back the "Know More" to the company
              Please note that we do not accept any inquiries about job offers.`,
              show: 'after login'
            }
          ]
        }
      ]
    },
    {
      title: 'About "Scout"',
      topics: [
        {
          title: '"Scout" related questions',
          questions: [
            {
              question: 'The companies closed job openings, what should I do?',
              answer: 'Please note that the active period of job postings is at the discretion of the hiring companies.',
              show: 'after login'
            },
            {
              question: 'I was scouted for a job that I already applied for. Why?',
              answer: 'Your applications are sent to companies without including any personal information you registered. Therefore, sometimes it may overlap.',
              show: 'after login'
            },
            {
              question: 'Will scout requests be cancelled if I don\'t reply for a long time?',
              answer: 'No, but you are more likely to be invited to the interview if you reply within a week.',
              show: 'after login'
            },
            {
              question: 'Can I attach my resume to the scout message?',
              answer: 'You cannot attach your resume to the scout message (it will not be displayed to the recruiters). Please use the Apply button instead, then attach your resume.',
              show: 'after login'
            }
          ]
        }
      ]
    },
    {
      title: 'About "usage fee"',
      topics: [
        {
          title: '"usage fee" related questions',
          questions: [
            {
              question: 'Is there any fees for using GitTap?',
              answer: 'There are no fees for using GitTap. It is completely free!',
              show: 'both'
            }
          ]
        }
      ]
    },
    {
      title: 'Other',
      topics: [
        {
          title: '"Other" related questions',
          questions: [
            {
              question: "I don't want my current company knows that I'm looking for a new job. Is there any way to keep my account private to them?",
              answer: 'Yes, you can use the block feature to prevent your current company from seeing your account.',
              show: 'after login'
            },
            {
              question: "I can't find the company name I want to block.",
              answer: `If the companies are not on the list, they are not registered on GitTap. 
              They might register at any time in the future, so please check the list regularly.`,
              show: 'after login'
            },
            {
              question: 'How can I write my resume in Japanese?',
              answer: `Feel free to use our guides on how to write a resume. To read the guides, please click the links below.
              How to Write a Resume and download template 
              https://www.gtalent.jp/blog/japanwork-en/job-hunting-en/rirekisho-en
              
              How to Write a Job Resume and download template 
              https://www.gtalent.jp/blog/japanwork-en/job-hunting-en/how-to-write-japanese-cv`,
              show: 'both'
            },
            {
              question: "I'm getting a system error, what should I do?",
              answer: `We apologize for the inconvenience. Please send the following information to the GitTap Management Office:
              - Terminal/model name you are using (smartphone, tablet, PC)
              - Your browser and its version used *
              - Email address of your account
              - Page URL where the error occurred
              - What were you trying to do when the error occurred
              - What have you already tried to solve it (such as clearing the cache)

              * Verified browsers: the latest version of Google Chrome, Safari, Firefox and Microsoft Edge.`,
              show: 'both'
            }
          ]
        }
      ]
    }
  ]
}
