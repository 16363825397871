import goTo from 'vuetify/es5/services/goto'
import lang from '@/services/lang'
import applicantApi from '@/api/customer/applicant'
import auth from '@/services/auth'

/**
 * =====================================================
 * Core store
 * =====================================================
 *
 * Handles core states and behavior of the application.
 *
 * =====================================================
 */
const data = {
  drawer: false,
  loading: false,
  selector: null,
  language: lang.getLanguage(),
  footer: true,
  bottomNavigation: true,
  toolbar: true,
  languages: [
    {
      label: lang.getLanguage() && lang.getLanguage().value === 'en' ? 'English' : '英語',
      value: 'en'
    },
    {
      label: lang.getLanguage() && lang.getLanguage().value === 'en' ? 'Japanese' : '日本語',
      value: 'ja'
    }
  ],
  settingHeader: false,
  inPreviewMode: false
}

export default {
  namespaced: true,
  state () {
    return {
      ...data
    }
  },
  getters: {
    /**
     * Get the language state else from the local storage
     *
     * @param {*} state
     */
    selectedLanguage (state) {
      return state.language || lang.get()
    }
  },
  mutations: {
    /**
     * Set the language state.
     *
     * @param {*} state
     * @param {*} language
     */
    SET_LANGUAGE (state, language) {
      state.language = language
    },

    /**
     * Set the loading.
     *
     * @param state
     * @param loading
     */
    SET_LOADING (state, loading) {
      state.loading = loading
    },

    /**
     * Toggles the navigation drawer.
     *
     * @param state
     * @param data
     */
    TOGGLE_DRAWER (state, data) {
      state.drawer = data
    },

    /**
     * Set the selector
     *
     * @param {*} state
     * @param {*} selector
     */
    SET_SELECTOR (state, selector) {
      state.selector = selector
    },

    /**
     * Set the footer state
     *
     * @param {*} state
     * @param {*} footer
     */
    SET_FOOTER (state, footer) {
      state.footer = footer
    },

    /**
     * Set the toolbar state
     *
     * @param {*} state
     * @param {*} toolbar
     */
    SET_TOOLBAR (state, toolbar) {
      state.toolbar = toolbar
    },

    /**
     * Set the bottomNavigation state
     *
     * @param {*} state
     * @param {*} bottomNavigation
     */
    SET_BOTTOM_NAVIGATION (state, bottomNavigation) {
      state.bottomNavigation = bottomNavigation
    },
    /**
     * Set the settingHeader state
     *
     * @param {*} state
     * @param {*} settingHeader
     */
    SET_SETTING_HEADER (state, settingHeader) {
      state.settingHeader = settingHeader
    },
    /**
     * Set the inPreviewMode state
     *
     * @param {*} state
     * @param {*} inPreviewMode
     */
    SET_PREVIEW_MODE (state, inPreviewMode) {
      state.inPreviewMode = inPreviewMode
    }
  },
  actions: {
    /**
     * Set the loading state
     *
     * @param {*} param0
     * @param {*} loading
     */
    setLoading ({ commit }, loading) {
      commit('SET_LOADING', loading)
    },

    /**
     * Set the selector state
     *
     * @param {*} param0
     * @param {*} selector
     */
    setSelector ({ commit }, selector) {
      commit('SET_SELECTOR', selector)
    },

    /**
     * Trigger a programmatic scroll.
     *
     * @param {*} param0
     * @param {*} delay
     */
    scroll ({ commit, state }, { options, delay }) {
      const fallback = { duration: 300, easing: 'easeInOutCubic', offset: 80 }

      for (const key in fallback) {
        if (options[key]) {
          fallback[key] = options.key
        }
      }

      setTimeout(() => {
        goTo(state.selector, options)

        commit('SET_SELECTOR', null)
      }, delay || 100)
    },

    /**
     * Toggles the navigation drawer.
     *
     * @param state
     * @param data
     */
    toggleDrawer ({ commit }, data) {
      commit('TOGGLE_DRAWER', data)
    },

    /**
     * Set the language and translate.
     *
     * @param {*} config
     * @param {*} item
     */
    async setLanguage ({ commit, dispatch }, value) {
      const language = data.languages.find(l => l.value === value)
      if (!language) return
      if (auth.isAuthenticated() && language.value !== lang.get().value) {
        await applicantApi.setLocale({
          locale: language.value
        })
          .catch(({ response }) => dispatch('httpException/handle', response, { root: true }))
      }
      commit('SET_LANGUAGE', 'null') // this is to trigger the watch property for slectedLanguage
      commit('SET_LANGUAGE', language)
      lang.set(language)
    },

    async onlySetLanguage ({ commit, dispatch }, value) {
      const language = data.languages.find(l => l.value === value)
      if (!language) return
      if (auth.isAuthenticated() && language.value !== lang.get()) {
        await applicantApi.setLocale({
          locale: language.value
        })
          .catch(({ response }) => dispatch('httpException/handle', response, { root: true }))
      }

      lang.set(language)
    },

    /**
     * Commit the SET_FOOTER mutation
     *
     * @param {*} param
     * @param {*} footer
     */
    setFooter ({ commit }, footer) {
      commit('SET_FOOTER', footer)
    },

    /**
     * Commit the SET_TOOLBAR mutation
     *
     * @param {*} param
     * @param {*} toolbar
     */
    setToolbar ({ commit }, toolbar) {
      commit('SET_TOOLBAR', toolbar)
    },

    /**
     * Commit the SET_BOTTOM_NAVIGATION mutation
     *
     * @param {*} param
     * @param {*} bottomNavigation
     */
    setBottomNavigation ({ commit }, bottomNavigation) {
      commit('SET_BOTTOM_NAVIGATION', bottomNavigation)
    },
    /**
     * Commit the SET_SETTING_HEADER mutation
     *
     * @param {*} param
     * @param {*} bottomNavigation
     */
    setSettingHeader ({ commit }, settingHeader) {
      commit('SET_SETTING_HEADER', settingHeader)
    },
    /**
     * Commit the SET_SETTING_HEADER mutation
     *
     * @param {*} param
     * @param {*} bottomNavigation
     */
    setInPreviewMode ({ commit }, inPreviewMode) {
      commit('SET_PREVIEW_MODE', inPreviewMode)
    }
  }
}
