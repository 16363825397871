<template>
  <v-snackbar
    class="custom-letter-spacing"
    :timeout="timeout"
    :top="top"
    :bottom="bottom"
    :right="right"
    :left="left"
    :multi-line="multiLine"
    :vertical="vertical"
    :value="snackbar"
    @input="closeSnackbar"
  >
    <v-icon
      v-if="icon"
      :color="iconColor"
      v-text="icon"
    />
    <span> {{ message }} </span>
    <v-spacer />
    <template v-slot:action="{ attrs }">
      <v-btn
        text
        color="primary"
        class="custom-letter-spacing text-capitalize"
        v-bind="attrs"
        @click.native="closeSnackbar"
      >
        {{ closeLabel }}
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  computed: {
    ...mapState('snackbar', [
      'timeout',
      'top',
      'bottom',
      'right',
      'left',
      'multiLine',
      'vertical',
      'snackbar',
      'message',
      'closeLabel',
      'icon',
      'iconColor'
    ])
  },
  methods: {
    ...mapActions('snackbar', ['closeSnackbar'])
  }
}
</script>

<style scoped>
.custom-letter-spacing {
  letter-spacing: 0rem !important;
}
</style>
