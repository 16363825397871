import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarItems } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VAppBar,{class:_vm.$vuetify.breakpoint.smAndUp ? 'white' : 'secondary',staticStyle:{"border-bottom":"1px solid #edf0f7 !important"},attrs:{"app":"","color":"background","height":_vm.$vuetify.breakpoint.smAndUp ? '56' : '',"flat":""}},[_c(VContainer,{staticClass:"px-0 padding-x",class:{
      'pa-0': !_vm.$vuetify.breakpoint.smAndUp,
      'expanded-width': _vm.onRegistrationProcess,
      'signup': _vm.isSignUpPage
    }},[_c(VToolbar,{attrs:{"flat":"","dense":""}},[_c(VToolbarTitle,{staticClass:"mr-4 mt-2"},[_c('img',{staticStyle:{"cursor":"pointer"},attrs:{"alt":"GitTap Logo","contain":"","src":require(`@/assets/${_vm.logo}`),"width":"85"},on:{"click":_vm.navigateToHome}})]),_c(VToolbarItems,{staticStyle:{"margin-left":"42px"}},[_vm._l((_vm.navList),function(item,index){return [(_vm.isRegistered)?_c(VBtn,{key:'nav-' + index,staticClass:"text-none nav-list",staticStyle:{"margin-right":"25px"},attrs:{"text":"","rounded":"","to":item.to,"small":""},on:{"click":function($event){return _vm.pushGtm(item.code)}}},[(!item.hasBadge)?_c('span',[_vm._v(" "+_vm._s(item.title)+" ")]):_c(VBadge,{attrs:{"color":"red","overlap":"","content":index === 1 ? _vm.unreadApproachFormat : _vm.unreadMessageCountFormat,"value":index === 1 ? !!_vm.unreadApproachFormat : _vm.unreadCount}},[_c('span',[_vm._v(_vm._s(item.title))])])],1):_vm._e()]})],2),_c(VSpacer),(_vm.isRegistered)?_c(VToolbarItems,{staticClass:"notif-toolbar"},[_c('NotificationToolbarMenu',{on:{"customActionPush":function($event){return _vm.pushGtm('notification')}}})],1):_vm._e(),_c('AccountToolbarMenu'),_c('AuthorizationToolbarItem')],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }