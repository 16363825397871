import industriesApi from '@/api/customer/master/industry'

export default {
  namespaced: true,
  state () {
    return {
      items: sessionStorage.getItem('industries')
        ? JSON.parse(sessionStorage.getItem('industries'))
        : [],
      loading: false
    }
  },
  mutations: {
    /**
     * Set the items.
     *
     * @param {*} state
     * @param {*} items
     */
    SET_ITEMS (state, items) {
      state.items = items
    },

    /**
     * Set the loading.
     *
     * @param {*} state
     * @param {*} loading
     */
    SET_LOADING (state, loading) {
      state.loading = loading
    }
  },
  actions: {
    /**
     * Get all industry
     *
     * @param {*} param
     */
    all ({ commit, dispatch, state }, params) {
      if (state.items.length) return

      return industriesApi.index(params)
        .then(({ data }) => {
          commit('SET_ITEMS', data)

          sessionStorage.setItem('industries', JSON.stringify(data))
        })
        .catch(({ response }) => dispatch('httpException/handle', response, { root: true }))
    },

    /**
     * Commit the SET_ITEMS mutation
     *
     */
    setItems ({ commit }, items) {
      commit('SET_ITEMS', items)

      sessionStorage.setItem('industries', JSON.stringify(items))
    },

    /**
     * Commit the SET_LOADING mutation.
     *
     * @param {*} param0
     * @param {*} loading
     */
    setLoading ({ commit }, loading) {
      commit('SET_LOADING', loading)
    }
  }
}
