<template>
  <div v-if="authenticated" class="text-center pl-2" tabindex="0" style="outline-style: none">
    <v-menu v-model="menu" ref="parentMenuRef" offset-y left content-class="mt-5" :close-on-content-click="false"
      z-index="5">
      <template v-slot:activator="{ on, attrs, value }">
        <div class="d-flex justify-center align-center btn-account" v-bind="attrs" v-on="on">
          <v-avatar size="40" class="mr-2">
            <v-img :src="profileImage" />
          </v-avatar>
          <div class="name" :class="{ active: value }">
            {{ fullName }}
          </div>
          <v-icon class="mr-2" right color="#626262" v-text="'mdi-chevron-down'" />
        </div>
      </template>

      <v-fade-transition>
        <div class="dropdown">
          <div class="menu-list profile-image" @click="$refs.parentMenuRef.isActive = false">
            <router-link to="/profile-settings">
              <v-avatar size="72" class="mx-auto">
                <v-img :src="profileImage" />
              </v-avatar>
              <div class="name text-center mt-3">
                {{ fullName }}
              </div>
            </router-link>
          </div>
          <div class="menu-list" @click="$refs.parentMenuRef.isActive = false">
            <ul>
              <li v-if="isRegistered" @click="redirect('/account-settings')">
                <a>
                  {{ $t('navigation./account-settings') }}
                </a>
              </li>
              <li @click="redirect('/faq-inquiry')">
                <a>
                  {{ $t('navigation./faq-inquiry') }}
                </a>
              </li>
            </ul>
          </div>
          <div class="menu-list" @click="$refs.parentMenuRef.isActive = false">
            <ul>
              <li @click="logout">
                <a>
                  {{ $t('common.logout') }}
                  <svg xmlns="http://www.w3.org/2000/svg" width="15.029" height="15.029" viewBox="0 0 15.029 15.029">
                    <g id="Icon_feather-log-out" data-name="Icon feather-log-out" transform="translate(1 1)">
                      <path id="パス_305" data-name="パス 305"
                        d="M8.843,17.529h-2.9A1.448,1.448,0,0,1,4.5,16.082V5.948A1.448,1.448,0,0,1,5.948,4.5h2.9"
                        transform="translate(-4.5 -4.5)" fill="none" stroke="#626262" stroke-linecap="round"
                        stroke-linejoin="round" stroke-width="2" />
                      <path id="パス_306" data-name="パス 306" d="M24,17.738l3.619-3.619L24,10.5"
                        transform="translate(-14.59 -7.605)" fill="none" stroke="#626262" stroke-linecap="round"
                        stroke-linejoin="round" stroke-width="2" />
                      <path id="パス_307" data-name="パス 307" d="M22.186,18H13.5" transform="translate(-9.157 -11.485)"
                        fill="none" stroke="#626262" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                    </g>
                  </svg>
                </a>
              </li>
            </ul>
          </div>
          <div class="menu-list language">
            <v-row no-gutters>
              <v-col cols="5" class="d-flex align-center">
                <span>
                  {{ $t('language.language') }}
                </span>
              </v-col>
              <v-col cols="7">
                <v-select id="selectLanguage" v-model="language" outlined item-value="value" item-text="label"
                  append-icon="mdi-chevron-down" height="32" :items="languages"
                  :menu-props="{ bottom: true, offsetY: true }" @change="onChange" />
              </v-col>
            </v-row>
          </div>
        </div>
      </v-fade-transition>
    </v-menu>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'

import Auth from '@/mixins/auth'
import AuthService from '@/services/auth'
import placeholder from '@/assets/profile_picture_placeholder.png'
import toolbar from '@/mixins/toolbar'
import Cookies from 'vue-cookies'
import Config from '@/config/app'

export default {
  mixins: [Auth, toolbar],
  data() {
    return {
      menu: false,
      language: this.$i18n.locale === 'en' ? 'en' : 'ja',
    }
  },
  computed: {
    ...mapState('applicant', ['auth']),
    ...mapState('core', { ln: 'language' }),
    ...mapGetters('applicant', ['hasApplicant']),

    isRegistered() {
      return this.auth ? !!this.auth.registered_at : false
    },
    profileImage() {
      if (this.auth && this.auth.profile_photo.url) {
        return this.auth.profile_photo.url
      } else {
        return placeholder
      }
    },
    fullName() {
      try {
        if (!this.hasApplicant) return ''

        return this.auth.basic_personal_information?.full_name
          ? this.auth.basic_personal_information.full_name
          : 'ID: ' + this.auth.id
      } catch (error) {
        return ''
      }
    },
    faqLink() {
      if (this.selectedLanguage.value === 'en') {
        return `${process.env.VUE_APP_VISITOR_URL}/faq-inquiry`
      } else return `${process.env.VUE_APP_VISITOR_URL}/ja/faq-inquiry`
    },
  },
  async created() {
    if (AuthService.isAuthenticated()) await this.getApplicant()

    this.language = this.selectedLanguage.value
  },
  methods: {
    ...mapActions('applicant', { getApplicant: 'softFetch' }),
    revertLanguage() {
      this.language = this.$i18n.locale
      if (this.language == 'en') {
        localStorage.setItem(
          'language',
          JSON.stringify({ label: 'English', value: 'en' })
        )
        Cookies.set(Config.COOKIES.LANGUAGE_KEY, {
          label: 'English',
          value: 'en',
        })
        this.languages[1].label = 'Japanese'
      } else {
        localStorage.setItem(
          'language',
          JSON.stringify({ label: '日本語', value: 'ja' })
        )
        Cookies.set(Config.COOKIES.LANGUAGE_KEY, {
          label: '日本語',
          value: 'ja',
        })
        this.languages[0].label = '英語'
      }
    },
    onChange() {
      this.menu = false
      this.setLanguage(this.language)

      setTimeout(() => {
        if (window.alertModal) {
          this.$i18n.locale = this.$i18n.locale === 'en' ? 'ja' : 'en'
        }
      }, '1500')
      setTimeout(() => {
        this.revertLanguage()
        window.alertModal = false
      }, '1800')
    },
    redirect(path) {
      if (path !== this.$route.path) {
        if (!this.authenticated) {
          path = this.faqLink
          window.open(path, '_blank')
        } else {
          if (!this.isRegistered) {
            path = this.faqLink
            window.open(path, '_blank')
          } else this.$router.push({ path })
        }
      }
    },
  },
}
</script>

<style scoped>
.btn-account {
  height: 40px;
  background: #f4f4f4;
  border-radius: 56px;
  cursor: pointer;
  position: relative;
}

.btn-account .name {
  font: normal normal bold 14px/19px Roboto;
  cursor: pointer;
  color: var(--v-text-base);
}

.btn-account .name a {
  font: normal normal bold 14px/19px Roboto;
  cursor: pointer;
  color: var(--v-text-base);
}

.btn-account .name.active {
  color: var(--v-primary-base);
}

.dropdown {
  cursor: pointer;
  width: 328px;
  top: 57px;
  right: 0;
  border: 1px solid var(--background-edf0f7);
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: -4px 0px 16px #00000015;
  border: 1px solid #edf0f7;
  border-radius: 8px;
}

.dropdown .menu-list {
  width: 100%;
  border-bottom: 1px solid #dee0e8;
}

.dropdown .menu-list li:hover {
  background: #f4f4f4 0% 0% no-repeat padding-box;
}

.dropdown .menu-list.profile-image a {
  padding: 24px;
  display: grid;
  font: normal normal bold 14px/19px Roboto;
  color: var(--v-text-base);
  text-decoration: none;
}

.dropdown .menu-list.profile-image a .v-avatar {
  cursor: pointer;
}

.dropdown .menu-list.language {
  padding: 32px 19px;
}

.dropdown ul {
  list-style: none;
  padding: 5px 0px;
}

.dropdown ul li {
  font: normal normal normal 16px/21px Roboto;
  padding: 8px 0px;
  color: var(--v-text-base);
  height: 40px;
}

.dropdown ul li a {
  margin-left: 16px;
}

.dropdown ul li svg {
  position: relative;
  right: -5px;
  top: 2px;
}

.dropdown ul li a {
  text-decoration: none;
  color: var(--v-text-base);
  cursor: pointer;
}

.v-select>>>.v-text-field__details {
  display: none;
}

.v-select>>>.v-input__slot {
  margin-bottom: 0;
  min-height: auto !important;
}

.v-select>>>.v-input__control .v-select__slot .v-input__append-inner {
  margin-top: 5px !important;
}

.v-select>>>.v-input__control .v-select__slot .v-select__selections {
  display: block;
  text-align: center;
}

.v-select>>>.v-input__control .v-select__slot .v-select__selection {
  margin-right: 0 !important;
  max-width: 100%;
  margin-left: 10px;
}

.v-select>>>.v-input__control .v-select__slot .v-select__selections input {
  display: none;
}

/** Dropdowns */
.v-select-list>>>.v-list-item__content {
  height: 32px !important;
  padding: 0px !important;
}

.v-list-item {
  height: 32px !important;
  min-height: 32px !important;
  padding: 0px 8px !important;
}

.v-select-list>>>.v-list-item {
  min-height: 32px !important;
  padding-left: 8px !important;
  padding-right: 8px !important;
}
</style>
