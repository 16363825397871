import api from '../index'

const {
  http,
  show,
  store,
  simpleSearch
} = api

/**
 * ==================
 * Applicant Skill API
 * ==================
 */
export default {
  baseUrl: `${process.env.VUE_APP_CUSTOMER_URL}/api/personal/v1`,
  url: 'applicant-skills',
  http,
  simpleSearch,
  store,
  show,

  /**
   * Sync the ApplicantSkill of an applicant.
   *
   */
  sync (payload) {
    return this.http(this.baseUrl)
      .post(`${this.url}/sync`, payload)
  }
}
