export default {
  videoQuestion: 'VIDEO QUESTION',
  stepper: {
    step1: 'START',
    step2: 'QUESTIONS',
    step3: 'END'
  },
  continue: 'Continue to the Interview',
  question: 'Question',
  back: 'Back',
  next: 'Next',
  uploadVideo: 'Upload a video',
  recordingTips: 'mp4, mov files of 50MB in maximum are available. A movie of less than 1280x720 and shorter than 30 seconds is recommended.',
  send: 'Send',
  close: 'Close',
  submit: 'Are you sure to submit?',
  validation: 'Please fill up necessary items',
  confirmation: {
    fix: 'Change',
    title: 'Your answer confirmation'
  },
  freeTextError: 'Answer must not exceed 400 characters',
  videoError1: 'Invalid File Format!',
  videoError2: 'Video file can be 50MB in maximum.',
  videoError3: 'File must be a video file',
  uploadVideoInterviewTitle: 'Video interview uploads',
  uploadCompleteMessage: 'You\'ve successfully uploaded your video interview.',
  uploadInfoMessage: 'Please wait for uploading files until completed.',
  noUploadMessage: 'You don\'t have any upload yet.',
  beforeLeaveMessage: 'If you refresh the current page, you will lose your progress of ongoing upload. Do you want to continue?',
  videoInterviewAnswerSent: 'You\'ve sent your answer of Video Interview',
  uploadFinalizeMessage: 'Completed',
  videoDeleted: 'The video file has been deleted',
  videoConfirmationDeleted: 'The submitted video files will be automatically deleted in 30 days.',
  videoMessageDeleted: 'The video files will be deleted in 30 days.',
  profileVideo: 'Self-introduction Video'
}
