import incomeRange from '@/api/customer/master/income-range'

const data = {
  items: sessionStorage.getItem('incomeRanges')
    ? JSON.parse(sessionStorage.getItem('incomeRanges'))
    : [],
  selected: {},
  loading: false,
}

export default {
  namespaced: true,

  state() {
    return {
      ...data,
    }
  },
  mutations: {
    /**
     * Set the items.
     *
     * @param {*} state
     * @param {*} items
     */
    SET_ITEMS(state, items) {
      state.items = items
    },

    /**
     * Set the items.
     *
     * @param {*} state
     * @param {*} selected
     */
    SET_SELECTED(state, selected) {
      state.selected = selected
    },

    SET_LOADING(state, loading) {
      state.loading = loading
    },

    /**
     * Reset the state to default.
     *
     * @param {*} state
     */
    RESET(state) {
      for (const key in state) {
        state[key] = data[key]
      }
    },
  },
  actions: {
    /**
     * Get all income range records
     */
    all({ commit, dispatch, state }) {
      if (state.items.length) return

      commit('SET_LOADING', true)

      return incomeRange
        .index()
        .then(({ data }) => {
          commit('SET_ITEMS', data)

          sessionStorage.setItem('incomeRanges', JSON.stringify(data))
        })
        .catch(({ response }) =>
          dispatch('httpException/handle', response, { root: true })
        )
        .finally(() => commit('SET_LOADING', false))
    },

    /**
     * Commit the SET_ITEMS mutation
     *
     */
    setItems({ commit }, items) {
      commit('SET_ITEMS', items)

      sessionStorage.setItem('incomeRanges', JSON.stringify(items))
    },

    /**
     * Set item in selected state
     *
     * @param {*} param0
     * @param {*} item
     */
    setSelectedItem({ commit, state }, item) {
      commit('SET_SELECTED', item)
    },

    /**
     * Commit the SET_LOADING mutation.
     *
     * @param {*} param0
     * @param {*} loading
     */
    setLoading({ commit }, loading) {
      commit('SET_LOADING', loading)
    },
  },
}
