export default {
  title: '学歴',
  educationalBackground: '学歴',
  list: 'リストは新しい項目順に自動的にソートされます。',
  school: '学校名',
  schoolName: '学校名',
  schoolUrl: '学校のURL',
  degree: '学位・修了証',
  major: '専攻',
  location: '所在地',
  start: '入学',
  end: '卒業',
  periodFrom: '開始年月',
  current: '在学中',
  edit: '学歴を編集',
  success: '学歴が保存されました',
  validation: {
    url: 'httpまはたhttpsを含むURLを入力してください。'
  },
  from: '開始年月',
  to: '終了年月',
  currentStudent: '在学中',
  addNewEducation: '学歴を追加する',
  editEducation: '学歴を編集する',
  addSchool: '学歴を追加する',
  period: '期間',
  optionalInformation: '追加情報',
  pleaseInputUrl: 'URLを入力してください',
  removeEducation: '教育を削除',
  removeEducationBody: 'この学歴情報を削除しますか'
}
