import dialingCode from '@/api/customer/master/dialing-code'
import constants from '@/config/constants'

export default {
  namespaced: true,
  state () {
    return {
      loading: false,
      items: sessionStorage.getItem('dialingCodes')
        ? JSON.parse(sessionStorage.getItem('dialingCodes'))
        : []
    }
  },
  getters: {
    hasItems: state => {
      return state.items.length > 0
    }
  },
  mutations: {
    /**
     * Set the items.
     *
     * @param {*} state
     * @param {*} items
     */
    setItems (state, items) {
      state.items = items
    },
    /**
     * Set the status.
     *
     * @param {*} state
     * @param {*} items
     */
    setLoading (state, loading) {
      state.loading = loading
    }
  },
  actions: {
    /**
     * Soft Fetch Dialing Code list
     * If items is already populated skip the process
     *
     * @param { getters, dispatch}
     */
    softFetch ({ getters, dispatch }) {
      if (getters.hasItems) return

      dispatch('fetch')
    },

    /**
     * Fetch Items
     *
     * @param {commit, dispatch} object
     */
    fetch ({ commit, dispatch }) {
      commit('setLoading', true)

      const params = {
        with: [
          'country'
        ]
      }

      dialingCode.simpleSearch(params)
        .then(async ({ data }) => {
          data = await dispatch('arrangeDialingCodes', data)
          commit('setItems', data)
          sessionStorage.setItem('dialingCodes', JSON.stringify(data))
        })
        .catch(({ response }) => dispatch('httpException/handle', response, { root: true }))
        .finally(() => commit('setLoading', false))
    },

    /**
     * Put the japanese dialing code on the first list
     *
     * @return array
     */
    arrangeDialingCodes (props, data) {
      let japanDialingCode = null
      const dialingCodes = data.filter((item) => {
        if (!japanDialingCode) japanDialingCode = item.id === constants.DIALING_CODES.JAPAN ? item : null
        return item.id !== constants.DIALING_CODES.JAPAN
      })
      dialingCodes.unshift(japanDialingCode)
      return dialingCodes
    }
  }
}
