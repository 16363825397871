export default {
  title: '資料',
  placeholder: 'CVのアップロード（最大5MB）',
  downloading: 'ファイルをダウンロードしています...',
  downloaded: 'ファイルが正常にダウンロードされました',
  uploading: 'ファイルをアップロードしています...',
  uploaded: 'ファイルが正常にアップロードされました',
  cvUploaded: 'あなたのCVは正常にアップロードされました。',
  remove: {
    title: 'アップロードしたファイルを削除',
    body: 'このファイルを削除してもよろしいですか？'
  },
  removing: 'ファイルを削除しています...',
  removed: 'ファイルは正常に削除されました',
  file_too_big_title: 'ファイルサイズが大きすぎます。',
  file_too_big_message: 'アップロード可能なファイルサイズは5MBまでです'
}
