export default {
  email: 'Email',
  password: 'Password',
  confirmPassword: 'Confirm Password',
  enterEmail: 'Enter Your Email',
  enterPassword: 'Enter your new password',
  enterConfirmPassword: 'Re-Enter your new password',
  forgotPassword: {
    caption1: 'Please enter your email',
    caption2: 'We will send an email including a reset password link to you',
    success: {
      title: 'Success!',
      body: 'Check your email and click reset link to change your password.'
    }
  },
  resetPassword: {
    success: {
      title: 'Success!',
      body: 'Password changed successfully.'
    }
  },
  initialPasswordChange: {
    title: 'Create new password',
    action: 'Save and Login'
  },
  validation: {
    required: 'Required.',
    password: 'Password must be at least 8 characters of a combination of numbers and letters',
    confirm: 'Password and confirm password must be the same'
  }
}
