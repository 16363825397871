import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VAppBar,{directives:[{name:"show",rawName:"v-show",value:(_vm.settingHeader && !_vm.toolbar),expression:"settingHeader && !toolbar"}],staticClass:"toolbar",staticStyle:{"border":"1px solid #EEEEEE !important"},attrs:{"fixed":_vm.isProfileSettingPage,"height":"48","color":"white","flat":""}},[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"2","align":"center"},on:{"click":function($event){return _vm.backAction(_vm.inPreviewMode)}}},[_c(VAppBarNavIcon,[_c(VIcon,{attrs:{"color":"#626262","size":"50"},domProps:{"textContent":_vm._s('mdi-chevron-left')}})],1)],1),_c(VCol,{attrs:{"align-self":"center"}},[_c(VToolbarTitle,[_c('span',{staticClass:"font-15-list"},[_vm._v(_vm._s(_vm.title))])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }