const data = {
  items: []
}

export default {
  namespaced: true,
  state () {
    return {
      ...data
    }
  },
  mutations: {
    /**
     * Set the items state
     *
     * @param {*} state
     * @param {*} items
     */
    SET_ITEMS (state, items) {
      state.items = items
    }
  },
  actions: {
    /**
     * Commit SET_ITEMS
     *
     * @param {*} param0
     * @param {*} items
     */
    setItems ({ commit }, items) {
      commit('SET_ITEMS', items)
    }
  }
}
