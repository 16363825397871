import applicantSkill from '@/api/customer/applicant-skill'

export default {
  namespaced: true,
  state () {
    return {
      selectedTechnicalSkills: [],
      newTechnicalSkills: []
    }
  },
  mutations: {
    /**
     * Set the selected technical skills.
     *
     * @param {*} state
     * @param value
     */
    SET_SELECTED_TECHNICAL_SKILLS (state, value) {
      state.selectedTechnicalSkills = value
    },
    /**
     * Set the new technical skills.
     *
     * @param {*} state
     * @param value
     */
    SET_NEW_TECHNICAL_SKILLS (state, value) {
      state.newTechnicalSkills = value
    }
  },
  actions: {
    /**
     * Commit the SET_SELECTED_TECHNICAL_SKILLS mutation
     *
     */
    setSelectedTechnicalSkills ({ commit }, value) {
      commit('SET_SELECTED_TECHNICAL_SKILLS', value)
    },
    /**
     * Commit the SET_SELECTED_TECHNICAL_SKILLS mutation
     *
     */
    setNewTechnicalSkills ({ commit }, value) {
      commit('SET_NEW_TECHNICAL_SKILLS', value)
    },
    /**
     * Store skills.
     *
     * @param {commit, dispatch, state}
     */
    storeData ({ commit, dispatch, state }, items) {
      const payload = { applicant_skills: items }
      return applicantSkill.sync(payload)
        .then((data) => {
          return data
        })
        .catch(({ response }) => dispatch('httpException/handle', response, { root: true }))
    }
  }
}
