import api from '../index'

const {
  http,
  index
} = api

/**
 * ====================================================
 * Appeal point API
 * ====================================================
 */
export default {
  baseUrl: `${process.env.VUE_APP_CLIENT_URL}/api/v1`,
  url: 'japan-regions',
  http,
  index
}
