export default {
  information: {
    ceoName: 'CEOの名前',
    location: '所在地',
    founded: '設立',
    capital: '資本',
    capitalAmount: '資本金',
    size: '企業規模',
    type: '上場／非上場',
    industry: '業界',
    website: 'Webサイト'
  },
  business: '事業内容',
  businessDescription: '',
  availableJobs: {
    availableJobs: '{company_name}が募集中の求人一覧',
    retrievingAvailableJobs: '利用可能なジョブを取得しています...',
    seeAll: 'すべてを見る'
  },
  private: '非上場',
  public: '上場'
}
