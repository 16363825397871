<template>
  <v-row justify="center">
    <v-dialog
      :value="confirmationDialog"
      persistent
      max-width="512"
      scrollable
    >
      <v-card height="279">
        <v-card-text class="text-center pa-3">
          <v-row
            no-gutters
            class="fill-height"
            justify="center"
            align="center"
          >
            <v-col align-self="center">
              <p class="headline ma-0">
                {{ title }}
              </p>

              <template v-for="(line, index) in content">
                <p
                  :key="index"
                  class="ma-0"
                  :class="{'body-pc': $vuetify.breakpoint.lgAndUp, 'body-sp': $vuetify.breakpoint.mdAndDown}"
                >
                  {{ line }}
                </p>
              </template>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions
          class="justify-center text-center"
          style="padding-bottom: 16px"
        >
          <v-row
            no-gutters
            align="center"
            justify="center"
          >
            <v-col
              cols="6"
              align-self="center"
            >
              <v-btn
                color="primary"
                class="custom-letter-spacing text-capitalize"
                height="48"
                :width="$vuetify.breakpoint.lgAndUp ? 232 : 148"
                depressed
                style="border-radius: 2px"
                @click="closeAndAgree()"
              >
                {{ actionLabel }}
              </v-btn>
            </v-col>

            <v-col
              cols="6"
              align-self="center"
            >
              <v-btn
                color="primary"
                class="custom-letter-spacing text-capitalize"
                outlined
                height="48"
                :width="$vuetify.breakpoint.lgAndUp ? 232 : 148"
                depressed
                style="border-radius: 2px"
                @click="closeAndDisagree()"
              >
                {{ cancelLabel }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'AppConfirmationDialog',
  computed: {
    ...mapState('dialog', [
      'confirmationDialog',
      'title',
      'body',
      'action',
      'actionLabel',
      'cancel',
      'cancelLabel',
      'color'
    ]),
    /**
     * Body content splitted by new line (\n)
     *
     * @return string
     */
    content () {
      return this.body ? this.body.split('\n') : []
    }
  },
  methods: {
    ...mapActions('dialog', ['closeDialog']),

    /**
     * Hides the dialog and executes the action callback function.
     *
     */
    closeAndAgree () {
      this.closeDialog('confirmationDialog')
      if (this.action) this.action()
    },

    /**
     * Hides the dialog and executes the cancel callback function.
     *
     */
    closeAndDisagree () {
      this.closeDialog('confirmationDialog')
      if (this.cancel) this.cancel()
    }
  }
}
</script>

<style scoped>
.custom-letter-spacing {
  letter-spacing: 0rem !important;
}
.headline {
  font: normal normal bold 18px/21px Roboto !important;
  letter-spacing: 0px !important;
  color: #1A1A1A !important;
  margin-bottom: 8px !important;
}
.body-pc {
  font: normal normal normal 17px/21px Roboto !important;
  letter-spacing: 0px !important;
  color: #626262 !important;
}
.body-sp {
  font: normal normal normal 15px/21px Roboto !important;
  letter-spacing: 0px !important;
  color: #626262 !important;
}
.fill-height {
  height: 180px !important;
  min-height: 180px !important;
  max-height: 180px !important;
}
</style>
