import Vue from 'vue'
import Vuetify from 'vuetify/lib'

import colors from 'vuetify/lib/util/colors'
import en from 'vuetify/es5/locale/en'
import ja from 'vuetify/es5/locale/ja'

Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    locales: { en, ja },
    current: 'en'
  },
  theme: {
    options: {
      customProperties: true
    },
    dark: false,
    themes: {
      light: {
        primary: '#298895',
        primaryLight: '#E2F2F2',
        primaryLight2: '#F8FEFF',
        primary2: '#298896',
        secondary: '#023352',
        secondary2: '#193346',
        accent: '#57C0CE',
        accent2: '#ECEFF1',
        accent3: '#4EA8B4',
        accent4: '#3ABEC7',
        background: colors.shades.white,
        background2: '#F8F9FC',
        background3: '#F0FDFF',
        background4: '#EFF2F8',
        foreground: colors.shades.black,
        error: colors.red,
        error2: '#FEF0F3',
        success: colors.green,
        disabled: '#B7BCC9',
        disabled2: '#9397A0',
        blank: '#EDF0F7',
        blank2: '#EAECF2',
        blank3: '#DEE0E8',
        blank4: '#f9fafb',
        dash: '#EFF0F4',
        chip: '#FFECC9',
        list: '#F7F8FB',
        toolbarSP: '#4D7086',
        remove: '#C60A3D',
        highlighted: '#FCFBEA',
        highlighted2: '#FF9900',
        highlighted3: '#FFED56',
        highlighted4: '#FFE508',
        highlighted5: '#C74E6A',
        highlighted6: '#C74E4E',
        dim: '#023352',
        dim2: '#666666',
        article: '#404E54',
        border: '#C5CFDB',
        border2: '#F2F3F9',
        border3: '#F4F4F4',
        border4: '#5B7181',
        border5: '#EEF0F6',
        border6: '#D5D5D5',
        dark: '#333745',
        dark2: '#032D48',
        dark3: '#0E171D',
        dark4: '#191919',
        warning2: '#FFA62E',
        lighten: '#F7F7F7',
        text: '#1A1A1A',
        action: '#434343',
        darkGrey: '#4A4A4A',
        removeIcon: '#434343',
        black: '#000000',
        black2: '#393E46',
        black3: '#6F6F6F',
        red1: '#BC4C59',
        red2: '#FD5050',
        yellow1: '#D1B411',
        greybg: '#F6F6F6',
        greybg2: '#626262',
        borderLine: '#DBE2EA',
        gold2: '#A0A000',
        link: '#4192DF',
        greyText: '#767676',
        activeNavBottom: '#EEF6F7',
        unread: '#D62F2F',
        greybg3: '#E3E3E3',
        chipDisabledColor: '#E3E3E3',
        chipScoutedColor: '#C74E6B',
        pink: '#FBE9E9',
        white: '#FFFFFF',
        cloudBurst: '#1F3344',
        errorRed: '#D62F2F'
      },
      dark: {
        error: '#E59B08'
      }
    }
  }
})
