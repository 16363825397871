export default {
  email: 'Eメール',
  password: 'パスワード',
  confirmPassword: 'パスワードを再入力',
  enterEmail: 'メールアドレスを入力してください',
  enterPassword: '新しいパスワードを入力',
  enterConfirmPassword: '再度新しいパスワードを入力',
  forgotPassword: {
    caption1: 'メールアドレスを入力してください',
    caption2: 'パスワードのリセットリンクを含むメールを送信します',
    success: {
      title: '成功！',
      body: 'メールを確認し、[リセット]リンクをクリックしてパスワードを変更してください。'
    }
  },
  resetPassword: {
    success: {
      title: '成功！',
      body: 'パスワードは正常に変更されました'
    }
  },
  initialPasswordChange: {
    title: 'パスワードを作成',
    action: '保存してログイン'
  },
  validation: {
    required: '必須。',
    password: '英数字で8文字以上のパスワードを設定してください。',
    confirm: 'パスワードが一致しません'
  }
}
