export default {
  helpMessages: {
    message1: 'Have you added your new skills?',
    message2: 'Have you added your work experience summary?',
    message3: 'Have you added work projects? '
  },
  profile: 'Profile',
  scout: 'Scouted',
  like: 'Pinned',
  interested: 'Interested'
}
