/**
 * =======================================
 * Upload Manager Store
 * =======================================
 *
 * Handles the uploads of the application.
 *
 * Supported: Video Interview
 *
 * =======================================
 */

const UPLOAD_STATUSES = {
  UPLOADING: 'UPLOADING',
  FINISHED: 'FINISHED',
  FAILED: 'FAILED'
}

export default {
  namespaced: true,
  strict: process.env.APP_ENV === 'development',
  state() {
    return {
      open: false,
      queues: [],
      profile_video: false
    }
  },
  getters: {
    /**
     * Check if queues has on going upload
     * @param {*} state
     * @returns bool
     */
    hasOngoingUpload: state => {
      return state.queues.filter((job) => {
        return job.status === UPLOAD_STATUSES.UPLOADING
      }).length > 0
    }
  },
  mutations: {
    /**
     * Shows the upload manager dialog.
     *
     * @param state
     * @param param1
     */
    SET_OPEN(state, status) {
      state.open = status
    },
    /**
     * Add job to queue
     *
     * @param state
     * @param job
     */
    ADD_TO_QUEUE(state, job) {
      state.queues.push(job)
    },
    /**
     * Set Job status
     *
     * @param state
     * @param {index, job}
     */
    UPDATE_JOB(state, { index, job }) {
      state.queues[index] = job
      state.queues = JSON.parse(JSON.stringify(state.queues))
    },
    RESET_QUEUES(state) {
      state.queues = []
    },
    SET_PROFILE_VIDEO(state, status) {
      state.profile_video = status
    }
  },
  actions: {
    /**
     * Has on going upload
     */
    hasUpload({ state }, { id, type }) {
      const job = state.queues.filter((job) => {
        return job.id === id &&
          job.type === type &&
          job.status === UPLOAD_STATUSES.UPLOADING
      })

      return job.length > 0
    },
    /**
     * Set the job property
     *
     * @param {commit} commit
     * @param jobID
     * @param status
     */
    setJobProperty({ state, commit }, { index, property, value }) {
      const job = state.queues[index]
      job[property] = value

      commit('UPDATE_JOB', { index: index, job: job })
    },
    /**
     * Set the job progress
     *
     * @param {commit} commit
     * @param jobID
     * @param status
     */
    setJobProgress({ state, dispatch }, { type, id, progress }) {
      // find the job index
      const index = state.queues.findIndex(job => {
        return job.type === type && job.id === id
      })

      if (progress < 100) {
        dispatch('setJobProperty', { index: index, property: 'progress', value: progress })
      }
    },
    /**
     * Register Job
     *
     * @param {commit} commit
     * @param {*} job
     */
    registerJob({ commit, dispatch, state }, job) {
      job.status = UPLOAD_STATUSES.UPLOADING
      commit('ADD_TO_QUEUE', job)

      const jobIndex = state.queues.length - 1

      if (job.request) {
        return job.request()
          .then((data) => {
            dispatch('setJobProperty', { index: jobIndex, property: 'progress', value: 100 })
            dispatch('setJobProperty', { index: jobIndex, property: 'status', value: UPLOAD_STATUSES.FINISHED })
            job.onSuccess(data)
          })
          .catch(({ response }) => {
            dispatch('setJobProperty', { index: jobIndex, property: 'status', value: UPLOAD_STATUSES.FAILED })
            if (job.type !== 'RESUME') {
              dispatch('openUploadManager')
            }
            job.onFailed(response)
          })
          .finally(() => job.onComplete())
      }
    },
    /**
     * Shows the upload manager
     *
     * @param {commit} commit
     */
    openUploadManager({ commit }) {
      commit('SET_OPEN', true)
    },
    /**
     * Close the upload manager
     *
     * @param {commit} commit
     */
    closeUploadManager({ commit }) {
      commit('SET_OPEN', false)
    },
    resetQueues({ commit }) {
      commit('RESET_QUEUES')
    },
    setProfileVideo({ commit }, status) {
      commit('SET_PROFILE_VIDEO', status)
    },
  }
}
