import lang from '@/services/lang'

const en = [
  { value: 'male', text: 'Male' },
  { value: 'female', text: 'Female' },
  { value: 'other', text: 'Other' }
]

const ja = [
  { value: 'male', text: '男性' },
  { value: 'female', text: '女性' },
  { value: 'other', text: 'その他' }
]

const locale = lang.get() ? lang.get().value : null

let genders

switch (locale) {
  case 'ja':
    genders = ja
    break

  default:
    genders = en
    break
}

export default {
  namespaced: true,
  state () {
    return {
      genders: genders
    }
  }
}
