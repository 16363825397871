import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"max-width":"750","flat":"","persistent":""},model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c(VCard,[_c(VCardTitle,{staticClass:"dim px-3 py-0",class:{'py-2': _vm.$vuetify.breakpoint.smAndDown}},[_c(VToolbar,{attrs:{"flat":"","color":"dim","dark":""}},[_c('span',[_vm._v(_vm._s(!_vm.profile_video ? _vm.$t('videoInterview.uploadVideoInterviewTitle') : _vm.$t('videoInterview.profileVideo')))])])],1),_c(VDivider),(_vm.queues.length > 0)?_c(VCardText,{staticClass:"mb-0 py-0 justify-center"},[_c(VSheet,{staticClass:"title my-8 text-center"},[_c(VIcon,{staticClass:"mr-2",attrs:{"color":"primary"}},[_vm._v(" mdi-information ")]),_c('span',{staticClass:"primary--text"},[_vm._v(_vm._s(_vm.$t('videoInterview.uploadInfoMessage')))])],1)],1):_vm._e(),_c(VDivider),_c(VCardText,{staticClass:"px-3"},[(_vm.queues.length > 0)?_c(VList,[_vm._l((_vm.queues),function(job,index){return [_c('UploadItem',{key:`job-${index}`,attrs:{"title":job.title,"status":job.status,"link":job.link,"progress":job.progress,"quarantined-files":_vm.quarantinedFiles,"quarantined-files-error-message":_vm.quarantinedFilesErrorMessage}}),(index !== (_vm.queues.length - 1))?_c(VDivider,{key:`divider-${index}`}):_vm._e()]})],2):_c(VList,[_c(VListItemContent,{staticClass:"pt-5 px-5"},[_c(VListItemTitle,[_vm._v(_vm._s(_vm.$t('videoInterview.noUploadMessage')))])],1)],1)],1),_c(VDivider),_c(VCardActions,{staticClass:"blank justify-center py-5"},[_c(VBtn,{staticClass:"text-none px-3",attrs:{"small":"","color":"primary"},on:{"click":_vm.closeUploadManager}},[_c(VIcon,{staticClass:"mr-2"},[_vm._v(" mdi-close ")]),_vm._v(" "+_vm._s(_vm.$t('common.close'))+" ")],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }