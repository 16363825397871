export default {
  advanceSearchBtn: {
    search: 'Search',
    filter: 'Filters'
  },
  saveSearchBtn: {
    saveSearch: 'Save search',
    result: 'result'
  },
  advanceSearchConditionBtn: 'Save Search Condition',
  searchesBtn: 'Saved Searches',
  noResult: {
    title: 'We couldn\'t find any jobs matching your search',
    subtitle: 'No result',
    one: 'Make sure all words are spelled correctly',
    two: 'Try more general keywords.',
    three: 'Replace abbreviations with the entire word.',
    four: 'Check your spelling.'
  },
  subtitle: "Are you sure you'd like to apply?",
  profileEdit: 'Check your profile',
  profileEditBtn: 'Check Profile',
  backBtn: 'Back to Apply',
  applyPage: 'Check Details',
  applyComplete: 'Your application has been sent!',
  similarJobs: 'Similar jobs you applied just now',
  searchJobs: 'Search jobs',
  moreJobs: 'Search More Jobs',
  searchMoreJobs: 'Search Jobs',

  /**
   * Advance Search Messages
   */
  keyword: 'Keyword',
  searchFilters: 'All Filters',
  popularKeyword: 'Keywords',
  preferredLocation: 'Location',
  selectLocation: 'Select Location',
  city: 'City',
  addLocation: 'Add Other Location',
  jobRole: 'Job Roles',
  selectJobRole: 'Select Job Role',
  addJobRole: 'Add Job Role',
  priorityJob: 'Preferences',
  employmentType: 'Employment Type',
  industry: 'Industry',
  selectIndustry: 'Select Industry',
  addIndustry: 'Add Industry',
  featuredITSkill: 'Experienced Area / Job',
  featuredITSkillPlaceholder: 'Select Area of Expertise',
  requiredJapanese: 'Required Japanese',
  requiredEnglish: 'Required English',
  ITSkills: 'Skill',
  selectITSkill: 'Select IT skills',
  addITSkill: 'Add IT Skills',
  language: 'Required Language Skills',
  languageScoreLevel: 'Language Score Level',
  typeLanguage: 'Type Language...',
  selectLevel: 'Select your level',
  selectLanguageLevel: 'Select Level',
  jlpt: 'JLPT',
  preferredIncome: 'Minimum Preferred Annual Income',
  selectAnnualIncome: 'Select Annual Income',
  postedTime: 'Date posted',
  lastWeek: 'Last Week',
  last2Weeks: 'Last 2 Weeks',
  last3Weeks: 'Last 3 Weeks',
  last4Weeks: 'Last 4 Weeks',

  confirmation: {
    title: 'Confirmation',
    description: 'Please answer the following questions to apply',
    error: 'Kindly answer all questions',
    yes: 'Yes',
    no: 'No'
  },
  nationwide: 'Nationwide',
  fromJapan: 'From Japan',
  remoteWorkInJapan: 'Remote work in Japan',
  fromOverseas: 'From Overseas',
  remoteWorkFromOutsideJapan: 'Remote work from outside Japan',
  rateOfForeigners: 'Rate of foreigners within the organization *approximation',
  frequencyOfEnglishUsage: 'Frequency of English usage for this job *approximation',
  remoteWorkTitle: 'Remote Work',
  selectAreaOfExpertise: 'Select Area of Expertise'
}
