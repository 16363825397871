export default {
  title: {
    text: '個人情報の取扱いについて'
  },
  mainHeader: {
    text1: 'ビズメイツ株式会社（以下、「当社」）は、当社が運営する求人情報提供サービス「GitTap」への会員登録に際して取得した個人情報について、個人情報に関する法令にしたがって取り扱うものとします。'
  },
  article1: {
    title: '利用目的',
    headerInfo: '当社では、ご入力頂きました個人情報に関しては、下記目的の範囲内で利用いたします。',
    info1: '当社および求人企業からの仕事紹介、仕事に関するご連絡等、サービス提供のため。',
    info2: '商品、サービスに関する当社からのご案内、お知らせのため。',
    info3: '顧客動向の分析、商品開発等の調査・分析のため。',
    info4: 'トラブル発生時や災害等の緊急事態が発生した際の対応のため。',
    info5: '損害賠償金等の回収のため。',
    info6: '本人確認のため。'
  },
  article2: {
    title: '個人情報の第三者提供',
    headerInfo1: '当社は、以下の要領で、フィリピン共和国に所在する当社子会社（Bizmates Philippines, Inc.）、その業務委託先、および求人企業に対して取得した会員情報を開示する場合があります。',
    headerInfo1_1: 'フィリピン共和国における個人情報保護制度の概要については、https://www.ppc.go.jp/files/pdf/philippin_report.pdfをご参照ください。なお、当該子会社では、個人情報の取扱いについて我が国の個人情報取扱事業者に求められる措置に準じた措置を講じているものの、取得した個人情報についての利用目的の通知・公表を行っていません。',
    headerInfo2: 'また、原則的には取得した会員情報は子会社、求人企業を除く第三者に開示しませんが、以下においてはサービスの提供を実施する為に法律等に従い、当社規定に定めた手段にて取得した個人情報を開示する場合があります。',
    headerInfo2_2: '＜個人情報を第三者に開示する場合＞',
    info1: '第三者に提供する目的：求職者情報提供の目的',
    info2: '提供する個人情報の項目：氏名、学歴、職歴、資格、等採用活動に係わる必要情報',
    info3: '提供の手段又は方法：クラウドデータベースおよび暗号化によるデータ通信',
    info4: '提供を受ける者の組織の種類、属性：当社グループ会社、求人企業の採用担当者',
    info5: '個人情報の取扱いに関する契約：個人情報の取り扱いにつきましては、契約書により提供先が適切に個人情報保護を行なうよう義務付けています。',
    info6: 'ご本人の同意がある場合',
    info7: '法令に基づく場合',
    info8: '人の生命、身体又は財産の保護のために必要がある場合であって、人の同意を得ることが困難であるとき',
    info9: '公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって本人の、同意を得ることが困難であるとき',
    info10: '国の機関若しくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることによって当該事務の遂行に支障を及ぼすおそれがあるとき',
    info11: '当社の権利、財産を保護する為に必要であると判断した場合。',
  },
  article3: {
    title: '個人情報の委託',
    info1: '当社の業務の全部または一部を外部に業務委託する際、個人情報を適切に保護できる管理体制を敷き実行していることを条件として委託先を厳選したうえで、機密保持契約を委託先と締結し、お客様の個人情報を厳密に管理しています。'
  },
  article4: {
    title: '個人情報を提供されることの任意性について',
    info1: 'お客様が当社に個人情報を提供されるかどうかは、お客様の任意によるものです。ただし、必要な項目をいただけない場合、各サービス等が適切な状態で提供できない場合があります。'
  },
  article5: {
    title: 'お客様からの開示等の申込みへの応諾',
    info1: '第三者への提供の停止、消去に関して、弊社問合せ窓口に申し出ることができます。その際、弊社はご本人を確認させていただいたうえで、合理的な期間内に対応いたします。なお、個人情報に関する弊社問合せ先は、次の通りです。'
  },
  companyName: {
    text: 'ビズメイツ株式会社'
  },
  personInChargeLabel: {
    text: '担当'
  },
  PersonInChargeName: {
    text: '個人情報保護管理者： コーポレートデザイン本部 人事総務ディレクター'
  },
  contactLabel: {
    text: '連絡先'
  },
  contactNumber: {
    text: 'privacy@bizmates.jp'
  }
}
