<template>
  <v-app>
    <ProfileAccountSettingHeader v-if="settingHeader" />
    <router-view />
    <vue-progress-bar />
    <AppAlertDialog />
    <AppConfirmationDialog />
    <AppSnackbar />
    <AppUploadManager />
  </v-app>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

import AppAlertDialog from '@/components/AppAlertDialog'
import AppConfirmationDialog from '@/components/AppConfirmationDialog'
import AppSnackbar from '@/components/AppSnackbar'
import AppUploadManager from '@/components/UploadManager/AppUploadManager'
import ProfileAccountSettingHeader from '@/components/ProfileAccountSettingHeader'
import luxon from '@/mixins/luxon'
import language from '@/mixins/language'

export default {
  name: 'App',
  components: {
    AppAlertDialog,
    AppConfirmationDialog,
    AppSnackbar,
    AppUploadManager,
    ProfileAccountSettingHeader,
  },
  mixins: [luxon, language],
  data() {
    return {
      //
    }
  },
  computed: {
    ...mapState('core', ['settingHeader']),
    ...mapGetters('core', ['selectedLanguage']),
    ...mapState('applicant', ['auth']),
    ...mapState('profileSetting', {
      applicantSavePayload: 'applicantSavePayload',
    }),

    currentBreakpoint() {
      return this.$vuetify.breakpoint.name
    },
  },
  watch: {
    currentBreakpoint() {
      switch (this.currentBreakpoint) {
        case 'md':
          this.setFooter(false)
          break
        case 'sm':
          this.setFooter(false)
          break
        case 'xs':
          this.setFooter(false)
          break
        default:
          this.setFooter(true)
          break
      }
    },
    auth() {
      if (this.selectedLanguage.value !== this.auth.locale) {
        this.setLanguage(this.auth.locale)
      }
    },
    /**
     * Watch for the selected language and set the locale
     *
     */
    selectedLanguage: {
      handler() {
        if (Object.keys(this.applicantSavePayload).length > 0) {
          this.openConfirmationDialog({
            title: this.$t('common.unsavedChangesTitle'),
            body: this.$t('common.unsavedChangesBody'),
            actionLabel: this.$t('common.cancelDialog.proceed'),
            cancelLabel: this.$t('common.cancel'),
            color: 'success',
            action: () => {
              this.clearSavePayload()
              this.changeLanguage()
            }
          })
        } else {
          this.changeLanguage()
        }
      },
    },
  },
  async mounted() {
    //  [App.vue specific] When App.vue is finish loading finish the progress bar
    this.$Progress.finish()

    // Hide Recaptcha Badge
    await this.$recaptchaLoaded()
    this.$recaptchaInstance.hideBadge()
  },
  created() {
    //  [App.vue specific] When App.vue is first loaded start the progress bar
    this.$Progress.start()
    //  hook the progress bar to start before we move router-view
    this.$router.beforeEach((to, from, next) => {
      //  start the progress bar
      this.$Progress.start()
      //  continue to next page
      next()
    })
    //  hook the progress bar to finish after we've finished moving router-view
    this.$router.afterEach(() => this.$Progress.finish())

    if (this.$vuetify.breakpoint.mdAndDown) this.setFooter(false)
  },
  methods: {
    ...mapActions('core', ['setLanguage', 'setFooter']),
    ...mapActions('dialog', ['openConfirmationDialog']),
    ...mapActions('profileSetting', ['clearSavePayload']),

    changeLanguage() {
      this.setLocale()

      this.$router.go()

      this.clearMasterData()
    },

    /**
     * Set the locale for the i18n libraries.
     *
     */
    setLocale() {
      this.setDefaultLanguage()
    },

    /**
     * Clear the master data from session storage
     *
     */
    clearMasterData() {
      sessionStorage.removeItem('appealPoints')
      sessionStorage.removeItem('certificates')
      sessionStorage.removeItem('itCertificateCategories')
      sessionStorage.removeItem('countries')
      sessionStorage.removeItem('cities')
      sessionStorage.removeItem('japanPrefectures')
      sessionStorage.removeItem('educationalLevels')
      sessionStorage.removeItem('industries')
      sessionStorage.removeItem('industryCategories')
      sessionStorage.removeItem('jobPositionCategories')
      sessionStorage.removeItem('jobPositions')
      sessionStorage.removeItem('jobSearchStatuses')
      sessionStorage.removeItem('nationalities')
      sessionStorage.removeItem('skillCategories')
      sessionStorage.removeItem('visaStatuses')
      sessionStorage.removeItem('workPermissions')
      sessionStorage.removeItem('yoes')
      sessionStorage.removeItem('companySizes')
      sessionStorage.removeItem('currencies')
      sessionStorage.removeItem('employmentTypes')
      sessionStorage.removeItem('incomeRanges')
      sessionStorage.removeItem('japaneseLanguageExperiences')
      sessionStorage.removeItem('jlptScoreLevels')
      sessionStorage.removeItem('languageCertificates')
      sessionStorage.removeItem('languageScoreLevels')
      sessionStorage.removeItem('languages')
      sessionStorage.removeItem('workingStatuses')
      sessionStorage.removeItem('writingLevels')
      sessionStorage.removeItem('deactivateReasons')
      sessionStorage.removeItem('skills')
      sessionStorage.removeItem('popularKeywords')
      sessionStorage.removeItem('emailNotifications')
      sessionStorage.removeItem('scoutDeclineReasons')
      sessionStorage.removeItem('japanRegions')
    },
  },
  head() {
    const meta = []

    if (process.env.VUE_APP_BUILD_ENV !== 'production') {
      meta.push({ name: 'robots', content: 'noindex,nofollow' })
    }

    return { meta }
  },
}
</script>

<style>
#app {
  font-family: Roboto, 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3',
    sans-serif;
}

html {
  overflow-y: auto;
}

/* Apply global style for outlined text fields only */
.v-text-field--outlined:not(.v-text-field--rounded):not(.v-input--is-label-active):not(.v-input--is-focused):not(.v-text-field--placeholder)>.v-input__control>.v-input__slot>.v-text-field__slot>.v-label {
  top: 14px !important;
  font: normal normal normal 16px/21px Roboto !important;
  color: #b7bcc9 !important;
}

.v-text-field--outlined:not(.v-text-field--rounded):not(.v-input--is-focused):not(.error--text)>.v-input__control>.v-input__slot>.v-text-field__slot>.v-label {
  color: #b7bcc9 !important;
}

.v-text-field--outlined:not(.v-text-field--rounded):not(.v-input--is-label-active):not(.v-input--is-focused):not(.v-text-field--placeholder)>.v-input__control>.v-input__slot>.v-select__slot>.v-label {
  top: 14px !important;
  font: normal normal normal 16px/21px Roboto !important;
  color: #b7bcc9 !important;
}

.mdi-checkbox-blank-outline::before {
  color: #b7bcc9 !important;
}

.v-list-item__action {
  margin-right: 12px !important;
}

.v-select:not(.v-input--is-focused) .v-input__control .v-label {
  color: #b7bcc9;
}

.nothing-to-display {
  font: normal normal normal 15px Roboto;
  color: #b7bcc9;
  margin: 10px;
}

.small-divider {
  height: 46px;
  background: #edf0f7;
  width: 2px;
}

.select-from-list {
  text-transform: none;
  font: normal normal bold 15px/20px Roboto;
  letter-spacing: 0px;
  background: #f4f4f4 0% 0% no-repeat padding-box;
  border-radius: 3px;
  height: 49px !important;
}

.add-btn {
  width: 160px !important;
  height: 48px !important;
  font: normal normal bold 14px/19px Roboto !important;
  letter-spacing: 0px;
  border-radius: 2px;
}

.theme--light.add-btn.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
  background: #edf0f7 0% 0% no-repeat padding-box !important;
  border-radius: 2px;
  border: 1px solid #b7bcc9 !important;
}

.v-text-field--outlined:not(.v-select--is-menu-active,
  .v-input--is-focused,
  .error--text) fieldset {
  border: 1px solid #b7bcc9 !important;
}

@media (max-width: 320px) {
  .select-from-list {
    width: 120px;
  }
}

.theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)>.v-input__control>.v-input__slot fieldset {
  color: #b7bcc9 !important;
}

.cs-registration-hint {
  font: normal normal bold 11px/15px Roboto !important;
  color: var(--v-disabled-base) !important;
}

.with-new-line {
  white-space: pre-line;
}

.unread-bubble {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: var(--v-unread-base);
  display: inline-block;
  margin-left: 8px;
}

.font-12 {
  font-size: 12px !important;
}

.font-13 {
  font-size: 13px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-15 {
  font-size: 15px !important;
}

.font-16 {
  font-size: 16px !important;
}

.font-17 {
  font-size: 17px !important;
}

.font-18 {
  font-size: 18px !important;
}

.font-19 {
  font-size: 19px !important;
}

.font-20 {
  font-size: 20px !important;
}

.new-tab-button {
  position: absolute;
  right: 16px;
  top: 16px;
}

.relative {
  position: relative;
}

.two-lines {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.right-box {
  padding: 16px 8px 16px 16px !important;
  border: 1px solid #e3e3e3;
  border-radius: 0px 4px 0px 0px;
  position: relative;
}

html.modal-open {
  overflow: hidden;
}

.pointer-events-none {
  pointer-events: none;
}

.cursor-not-allowed {
  cursor: not-allowed;
}
</style>
