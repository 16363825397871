import applicantContactInformation from '@/api/customer/applicant-contact-information'

export default {
  namespaced: true,
  state () {
    return {
      loading: false,
      details: {}
    }
  },
  getters: {
    emptyDetails: state => {
      return !Object.keys(state.details).length
    }
  },
  mutations: {
    /**
     * Set the loading.
     *
     * @param {*} state
     * @param {*} loading
     */
    setLoading (state, loading) {
      state.loading = loading
    },
    /**
     * Set the details.
     *
     * @param {*} state
     * @param {*} details
     */
    setDetails (state, details) {
      state.details = {
        ...details
      }
    }
  },
  actions: {
    /**
     * Get Applicant Contact Information
     *
     * @param {commit, dispatch }
     */
    getApplicantContactInformation ({ commit, dispatch }) {
      commit('setLoading', true)

      return applicantContactInformation.index()
        .then(({ data }) => commit('setDetails', data))
        .catch(({ response }) => dispatch('httpException/handle', response, { root: true }))
        .finally(() => commit('setLoading', false))
    }
  }
}
