/**
 * =====================================================
 * Stepper store
 * =====================================================
 *
 * Handles core states and behavior of the application.
 *
 * =====================================================
 */
export default {
  namespaced: true,
  state () {
    return {
      step: 1,
      headers: [
        {
          title: 'profileRegistration.steppers.01',
          step: '01',
          route: 'Channel'
        },
        {
          title: 'profileRegistration.steppers.02',
          step: '02',
          route: 'ExperienceRegistrationStepOne'
        },
        {
          title: 'profileRegistration.steppers.03',
          step: '03',
          route: 'PreferenceRegistrationStepOne'
        },
        {
          title: 'profileRegistration.steppers.✓',
          step: '✓',
          route: 'FinishRegistration'
        }
      ],
      steppers: [
        {
          title: 'profileRegistration.simpleRegistration.skill',
          step: 'Skill'
        },
        {
          title: 'profileRegistration.simpleRegistration.job',
          step: 'Job'
        },
        {
          title: 'profileRegistration.simpleRegistration.japanese',
          step: 'Japanese'
        },
        {
          title: 'profileRegistration.simpleRegistration.preference',
          step: 'Preference'
        }
      ],
      header: {},
      isShown: true
    }
  },
  mutations: {
    /**
     * Set the step.
     *
     * @param state
     * @param step
     */
    SET_STEP (state, step) {
      state.step = step
    },
    /**
     * Set the header.
     *
     * @param state
     * @param header
     */
    SET_HEADER (state, header) {
      state.header = header
    },
    /**
     * Set the stepper visibility.
     *
     * @param state
     * @param isShown
     */
    SET_VISIBILITY (state, isShown) {
      state.isShown = isShown
    }
  },
  actions: {
    /**
     * Change active step.
     *
     * @param {commit}
     * @param step
     */
    setActiveStep ({ commit }, step) {
      commit('SET_STEP', step)
    },
    /**
     * Reset.
     *
     * @param {commit}
     */
    reset ({ commit }) {
      commit('SET_STEP', 1)
    },
    /**
     * Change active header.
     *
     * @param {commit}
     * @param header
     */
    setProfileRegistrationHeader ({ commit }, header) {
      commit('SET_HEADER', header)
    },
    /**
     * Change stepper visibility.
     *
     * @param {commit}
     * @param isShown
     */
    setStepperVisibility ({ commit }, isShown) {
      commit('SET_VISIBILITY', isShown)
    }
  }
}
