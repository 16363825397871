export default {
  language: 'Language',
  firstLanguage: 'Native Language',
  speakingEnglish: 'English Speaking Level',
  speakingJapanese: 'Japanese Speaking Level',
  jlpt: 'JLPT',
  jlptConfirmation: 'Do you change your JLPT level?  Please check carefully your JLPT certificate. Companies you apply could request you to submit the certification.',
  otherLanguage: 'Other Languages',
  chooseLanguage: 'Choose Language',
  addOtherLanguages: 'Add Other Languages',
  success: 'Language setting has been saved',
  japanese: 'Japanese',
  japaneseLevel: 'Japanese Level',
  speaking: 'Speaking',
  comprehension: 'Comprehension',
  comprehensionHint: '(Listening/Reading)',
  listeningReading: 'Listening/Reading',
  writing: 'Writing',
  japaneseExperience: 'Using Japanese at Work',
  english: 'English',
  englishLevel: 'English Level',
  revert: 'Revert',
  level: 'Level',
  edit: 'Edit Language Information',
  certificate: {
    label: 'Certificate',
    levelScore: 'Level/Score',
    add: 'Add Certification/Accolades',
    addCertificate: 'Add Certificate',
    validations: {
      certificate: {
        required: 'Certification/Accolades are required'
      },
      score: {
        required: 'Level/Score is required',
        decimal: 'Level/Score must be a number',
        range: 'Enter a value between 1 to 999999.99'
      }
    }
  },
  otherLanguages: {
    validations: {
      language: {
        required: 'Language is required'
      },
      level: {
        required: 'Language is required'
      }
    }
  },
  updated: 'Language Updated Successfully',
  clearHint: 'Click "X" to delete the current language you selected.',
  jlptSelections: {
    N1: 'The ability to understand Japanese used in a variety of circumstances.',
    N2: 'The ability to understand Japanese used in everyday situations, and in a variety of circumstances to a certain degree.',
    N3: 'The ability to understand Japanese used in everyday situations to a certain degree.',
    N4: 'The ability to understand basic Japanese.',
    N5: 'The ability to understand some basic Japanese.'
  },
  languageLevelSelections: {
    native: 'The level of speaking as a native speaker (1st language / mother tongue).',
    fluent: 'Well advanced in any occupation or branch of knowledge.',
    business: 'Capable of using a language easily and accurately in business.',
    dailyConversation: 'The level of speaking with certain phrases and simple word in a casual situation.',
    greetingOrNone: 'The level of speaking with simple phrases or YES  NO for greeting or self-introduction.'
  },
  jlptNone: 'None',
  usingJaAtWork: 'Using JA at Work',
  addLanguage: 'Add Language',
  translate: {
    japanese: 'Japanese',
    english: 'English'
  }
}
