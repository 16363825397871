export default [
  {
    conditions: {
      utmSource: 'google',
      utmMedium: 'cpc',
      utmCampaign: 'brand',
      utmTerm: 'speciality',
      utmContent: 'EN'
    },
    accessRoute: 'Google_Paid Search_EN／指名_EN'
  },
  {
    conditions: {
      utmSource: 'google',
      utmMedium: 'cpc',
      utmCampaign: 'brand',
      utmTerm: 'skill',
      utmContent: 'JP'
    },
    accessRoute: 'Google_Paid Search_JA／指名_JA'
  },
  {
    conditions: {
      utmSource: 'google',
      utmMedium: 'cpc',
      utmCampaign: 'foreigner_occupation',
      utmTerm: 'others',
      utmContent: 'EN'
    },
    accessRoute: 'Google_Paid Search_EN／既存（職種）_EN'
  },
  {
    conditions: {
      utmSource: 'google',
      utmMedium: 'cpc',
      utmCampaign: 'foreigner_industry',
      utmContent: 'EN'
    },
    accessRoute: 'Google_Paid Search_EN／既存（業界）_EN'
  },
  {
    conditions: {
      utmSource: 'google',
      utmMedium: 'cpc',
      utmCampaign: 'foreigner_specializes',
      utmContent: 'EN'
    },
    accessRoute: 'Google_Paid Search_専門領域_EN'
  },
  {
    conditions: {
      utmSource: 'google',
      utmMedium: 'cpc',
      utmCampaign: 'foreigner_ITskills',
      utmContent: 'EN'
    },
    accessRoute: 'Google_Paid Search_ITスキル_EN'
  },
  {
    conditions: {
      utmSource: 'google',
      utmMedium: 'cpc',
      utmCampaign: 'foreigner_tokyo_occupation',
      utmContent: 'EN'
    },
    accessRoute: 'Google_Paid Search_EN／東京（職種）_EN'
  },
  {
    conditions: {
      utmSource: 'google',
      utmMedium: 'cpc',
      utmCampaign: 'foreigner_tokyo_industry',
      utmContent: 'EN'
    },
    accessRoute: 'Google_Paid Search_EN／東京（業界）_EN'
  },
  {
    conditions: {
      utmSource: 'google',
      utmMedium: 'cpc',
      utmCampaign: 'foreigner_machine_electronic',
      utmContent: 'EN'
    },
    accessRoute: 'Google_Paid Search_機電系KW_EN'
  },
  {
    conditions: {
      utmSource: 'facebook',
      utmMedium: 'display',
      utmCampaign: 'foreigner',
      utmContent: 'EN'
    },
    accessRoute: 'Facebook_Display_ENユーザー／日本_EN'
  },
  {
    conditions: {
      utmSource: 'facebook',
      utmMedium: 'display',
      utmCampaign: 'foreigner',
      utmContent: 'JA'
    },
    accessRoute: 'Facebook_Display_JAユーザー／日本_JA'
  },
  {
    conditions: {
      utmSource: 'email',
      utmMedium: 'direct',
      utmCampaign: 'linkedin'
    },
    accessRoute: 'Email_Direct_LinkedInスカウト_'
  },
  {
    conditions: {
      utmSource: 'email',
      utmMedium: 'direct',
      utmCampaign: 'signup'
    },
    accessRoute: 'Email_Direct_SignUpユーザー向け_'
  },
  {
    conditions: {
      utmSource: 'email',
      utmMedium: 'direct',
      utmCampaign: 'gtalent'
    },
    accessRoute: 'Email_Direct_G Talentユーザー向け_'
  },
  {
    conditions: {
      utmSource: 'google',
      utmMedium: 'cpc',
      utmCampaign: 'brand',
      utmContent: 'EN'
    },
    accessRoute: 'google_cpc_brand__EN'
  },
  {
    conditions: {
      utmSource: 'google',
      utmMedium: 'cpc',
      utmCampaign: 'brand',
      utmContent: 'JP'
    },
    accessRoute: 'google_cpc_brand__JP'
  },
  {
    conditions: {
      utmSource: 'google',
      utmMedium: 'cpc',
      utmCampaign: 'english',
      utmContent: 'EN'
    },
    accessRoute: 'google_cpc_english__EN'
  },
  {
    conditions: {
      utmSource: 'google',
      utmMedium: 'cpc',
      utmCampaign: 'english_specializes',
      utmContent: 'EN'
    },
    accessRoute: 'google_cpc_english_specializes__EN'
  },
  {
    conditions: {
      utmSource: 'google',
      utmMedium: 'cpc',
      utmCampaign: 'english_ITskills',
      utmContent: 'EN'
    },
    accessRoute: 'google_cpc_english_ITskills__EN'
  },
  {
    conditions: {
      utmSource: 'google',
      utmMedium: 'cpc',
      utmCampaign: 'english_tokyo',
      utmContent: 'EN'
    },
    accessRoute: 'google_cpc_english_tokyo__EN'
  },
  {
    conditions: {
      utmSource: 'google',
      utmMedium: 'cpc',
      utmCampaign: 'japanese',
      utmContent: 'JA'
    },
    accessRoute: 'google_cpc_japanese__JA'
  },
  {
    conditions: {
      utmSource: 'google',
      utmMedium: 'cpc',
      utmCampaign: 'english_machine_electronic',
      utmContent: 'EN'
    },
    accessRoute: 'google_cpc_english_machine_electronic__EN'
  },
  {
    conditions: {
      utmSource: 'google',
      utmMedium: 'display',
      utmCampaign: 'rt',
      utmContent: 'EN'
    },
    accessRoute: 'google_display_rt__EN'
  },
  {
    conditions: {
      utmSource: 'google',
      utmMedium: 'display',
      utmCampaign: 'rt_sign_up_complete',
      utmContent: 'EN'
    },
    accessRoute: 'google_display_rt_sign_up_complete__EN'
  },
  {
    conditions: {
      utmSource: 'microsoft',
      utmMedium: 'cpc',
      utmCampaign: 'brand',
      utmContent: 'EN'
    },
    accessRoute: 'microsoft_cpc_brand__EN'
  },
  {
    conditions: {
      utmSource: 'microsoft',
      utmMedium: 'cpc',
      utmCampaign: 'brand',
      utmContent: 'JP'
    },
    accessRoute: 'microsoft_cpc_brand__JP'
  },
  {
    conditions: {
      utmSource: 'microsoft',
      utmMedium: 'cpc',
      utmCampaign: 'foreigner_occupation',
      utmContent: 'EN'
    },
    accessRoute: 'microsoft_cpc_foreigner_occupation__EN'
  },
  {
    conditions: {
      utmSource: 'microsoft',
      utmMedium: 'cpc',
      utmCampaign: 'foreigner_industry',
      utmContent: 'EN'
    },
    accessRoute: 'microsoft_cpc_foreigner_industry__EN'
  },
  {
    conditions: {
      utmSource: 'microsoft',
      utmMedium: 'cpc',
      utmCampaign: 'japanese',
      utmContent: 'JP'
    },
    accessRoute: 'microsoft_cpc_japanese__JP'
  },
  {
    conditions: {
      utmSource: 'facebook',
      utmMedium: 'display',
      utmCampaign: 'english',
      utmContent: 'EN'
    },
    accessRoute: 'facebook_display_english__EN'
  }
];