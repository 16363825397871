export default {
  title: 'Inform Got Offer',
  description: 'If you got a new job at a company you applied for through GitTap, GitTap will give you a 5,000 yen Amazon Gift Card as a congratulatory gift. Please click the button below to contact the GitTap office.',
  reminders: [
    {
      text: 'For more information about the detailed regulations of Amazon Gift Card, please visit <a target="_blank" href="http://amazon.co.jp/giftcard/tc">http://amazon.co.jp/giftcard/tc</a>'
    },
    {
      text: '※If you cancel your GitTap membership, your gift certificate will be forfeited.'
    },
    {
      text: '※Please do not cancel your membership until you receive your gift certificate.'
    },
    {
      text: '※if you have any concerns, Please contact us at <a href="mailto:info@gittap.jp">info@gittap.jp</a>'
    }
  ],
  companyName: 'Company Name',
  startDate: 'Start Date',
  salary: 'Salary',
  aboutService: 'About Service',
  interview: {
    label: 'Interview',
    description1: 'Would you give us feedback on this job change and the usability of GitTap?',
    description2: 'If you are willing to help us, we will send you an email later with information about the interview method and rewards.'
  },
  placeholder: {
    companyName: 'New company name',
    startDate: 'Expected date of employment',
    salary: 'Annual salary at new company',
    aboutService: 'Please feel free to write your thoughts about GitTap’s services.'
  },
  confirmation: 'Confirmation'
}
