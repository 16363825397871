export default {
  title: 'Personal Information',
  edit: 'Edit Basic Personal Information',
  success: 'Basic personal information was successfully saved',
  labels: {
    name: 'Name',
    furigana: 'Furigana (フリガナ）',
    dateOfBirth: 'Date Of Birth',
    gender: 'Gender',
    nationality: 'Nationality (Country)',
    currentCountryResidence: 'Current Country Residence',
    currentCityResidence: 'Current City Residence',
    currentPrefectureResidence: 'Current Prefecture Residence',
    currentAddress: 'Current Address',
    permissionToWork: 'Permission To Work (VISA)',
    visaExpirationDate: 'Visa Expiration Date'
  },
  photoAndMovie: 'Photo and Movie',
  residence: 'residence',
  visa: 'visa data'
}
