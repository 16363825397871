export default {
  title: 'Document',
  placeholder: 'UPLOAD CV (Maximum 5MB)',
  downloading: 'Downloading file...',
  downloaded: 'File downloaded successfully',
  uploading: 'Uploading file...',
  uploaded: 'File uploaded successfully',
  cvUploaded: 'Your CV has been uploaded successfully.',
  remove: {
    title: 'Remove Uploaded File',
    body: 'Are you sure you want to remove this file?'
  },
  removing: 'Removing file...',
  removed: 'File removed successfully',
  file_too_big_title: 'File size is too big.',
  file_too_big_message: 'Maximum file size is up to 5MB.'
}
