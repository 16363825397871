export default {
  title: {
    text: 'Handling of Personal Information'
  },
  mainHeader: {
    text1: 'Bizmates, Inc. (hereinafter referred to as the "Company" or "We") handles personal information obtained when registering as a member of "GitTap", a job information service operated by the Company, in accordance with the laws and regulations concerning personal information.'
  },
  article1: {
    title: 'Purpose of Use',
    headerInfo: 'We will use the personal information you provide within the scope of the following purposes:',
    info1: 'To provide services such as job introductions and job-related communications from the Company and the hiring companies',
    info2: 'To inform you about the Company’s products and services',
    info3: 'To analyze customer trends and conduct research and analysis for product development, etc.',
    info4: 'To respond to emergencies such as troubles or disasters',
    info5: 'To collect compensation for damages, etc.',
    info6: 'To conduct identification'
  },
  article2: {
    title: 'Provision of Personal Information to Third Parties',
    headerInfo1: 'We may disclose acquired member information in the following manner to our subsidiary (Bizmates Philippines, Inc.) and its subcontractors located in the Republic of the Philippines and recruiting companies.',
    headerInfo1_1: 'Please visit https://www.ppc.go.jp/files/pdf/philippin_report.pdf to see an overview of the personal information protection system in the Republic of the Philippines. Although this subsidiary takes measures for the handling of personal information which are equivalent to those required of business operators handling personal information in Japan, it does not notify or publicly announce the purpose of use of the personal information it has acquired.',
    headerInfo1_2: '<When disclosing personal information to a third party>',
    headerInfo2: 'In principle, we do not disclose acquired member information to third parties except for our subsidiaries and recruiting companies. However, in the following cases, we may disclose acquired personal information in accordance with the law and in the manner stipulated in our regulations in order to provide services.',
    headerInfo2_2: '<When disclosing personal information to a third party>',
    info1: 'Purpose for the disclosure to the third party: for the purpose of providing job seekers’ information',
    info2: 'Items of personal information to be provided: necessary information for recruitment such as name, education, work experience, qualifications, etc.',
    info3: 'Manner of providing the personal information: cloud database and encrypted data communication',
    info4: 'Type of organizations which receives the personal information: our group company and person in charge of recruitment of the recruiting companies',
    info5: 'Contract for handling personal information: in terms of handling personal information, we oblige the party which is provided the personal information to protect such information properly by contract',
    info6: 'When the person in question consents to the use of his/her personal information.',
    info7: 'When required by law and regulations',
    info8: 'When it is necessary for the protection of the life, body, or property of an individual and it is difficult to obtain the consent of the individual',
    info9: 'When it is especially necessary for improving public health or promoting the sound growth of children and it is difficult to obtain the consent of the person in question.',
    info10: 'When it is necessary to cooperate with a national agency, a local government, or an individual or entity entrusted by either a national agency or local government to execute affairs prescribed by laws and regulations, and obtaining the consent of the individual is likely to impede the execution of such affairs',
    info11: 'When we deem it necessary to protect our rights or property.'
  },
  article3: {
    title: 'Outsourcing of Personal Information',
    info1: "When we outsource all or part of our operations to outside contractors, we carefully select contractors on the condition that they have established and implemented a management system capable of appropriately protecting personal information, and we conclude nondisclosure agreements with them to strictly manage our customers' personal information."
  },
  article4: {
    title: 'Voluntary Nature of Providing Personal Information',
    info1: 'Whether or not you provide us with your personal information is at your discretion. However, if you do not provide us with the required information, we may not be able to provide you with the appropriate services.'
  },
  article5: {
    title: "Acceptance of the Customer's Request for Disclosure, etc.",
    info1: 'You may contact our inquiry desk to request the cessation of provision to a third party, deletion of your personal information. We will respond to your request within a reasonable period of time after confirming your identity. The contact information for inquiries regarding personal information is as follows'
  },
  companyName: {
    text: 'Bizmates Inc.'
  },
  personInChargeLabel: {
    text: 'Contact Person'
  },
  PersonInChargeName: {
    text: 'Personal Information Protection Manager: Director, Information Systems Office, Corporate Design Division'
  },
  contactLabel: {
    text: 'Contact Email Address'
  },
  contactNumber: {
    text: 'privacy@bizmates.jp'
  }
}
