import applicantNotificationApi from '@/api/common/applicant-notification'
import constants from '@/config/constants'
import auth from '@/services/auth'

const data = {
  unread_count: 0,
  scouts_notification: [],
  pinned_notification: {},
  admin_notification: [],
  started: false,
  message_reload: false,
  allUnreadMessages: {}
}

/**
 * ===============================================
 * Message Notification store
 * ===============================================
 *
 * Handles Message related notifications.
 *
 * ===============================================
 */
export default {
  namespaced: true,
  state () {
    return {
      ...data
    }
  },
  getters: {
    /**
     * Check if polling has started
     */
    hasStarted: state => {
      return state.started
    }
  },
  mutations: {
    /**
     * Set reload messages
     *
     * @param {*} state
     */
    SET_MESSAGE_RELOAD (state, status) {
      state.message_reload = status
    },
    /**
     * Sets unread messages count.
     *
     * @param {*} state
     */
    SET_UNREAD_COUNT (state, count) {
      state.unread_count = count
    },

    /**
     * Sets unread messages count.
     *
     * @param {*} state
     */
    SET_ALL_UNREAD_MESSAGES (state, messages) {
      state.allUnreadMessages = messages
    },

    /**
     * Sets scout notification
     *
     * @param {*} state
     */
    SET_SCOUT_NOTIFICATION (state, notification) {
      state.scouts_notification = notification
    },

    /**
     * Set pinned notification
     *
     * @param {*} state
     */
    SET_PINNED_NOTIFICATION (state, notification) {
      state.pinned_notification = notification
    },

    /**
     * Set admin notification
     *
     * @param {*} state
     */
    SET_ADMIN_NOTIFICATION (state, notification) {
      state.admin_notification = notification
    },

    /**
     * Sets started to true.
     *
     * @param {*} state
     */
    SET_STARTED (state) {
      state.started = true
    }
  },
  actions: {
    /**
     * Trigger messages reload
     *
     * @param {commit} commit
     */
    reloadMessages ({ commit }) {
      commit('SET_MESSAGE_RELOAD', true)
      setTimeout(() => {
        commit('SET_MESSAGE_RELOAD', false)
      }, 100)
    },
    /**
     * Subscribe to unread message count.
     *
     * @param error
     */
    subscribeUnreadCount ({ getters, commit }) {
      if (getters.hasStarted) return

      commit('SET_STARTED')

      messageUnreadCountSubscriber(commit)
      setInterval(() => {
        messageUnreadCountSubscriber(commit)
      }, constants.NOTIFICATIONS.MESSAGE.TIMINGS)
    },

    /**
     * Get the notifications
     *
     * @param {*} param0
     */
    getNotifications ({ commit, dispatch }) {
      return applicantNotificationApi.all()
        .then(({ data }) => {
          commit('SET_UNREAD_COUNT', data.messages.unread)
          commit('SET_SCOUT_NOTIFICATION', data.scouts)
          commit('SET_PINNED_NOTIFICATION', data.pinned)
          commit('SET_ADMIN_NOTIFICATION', data.admin_notifications)
        })
        .catch(({ response }) => dispatch('httpException/handle', response, { root: true }))
    }
  }
}

// unread message subscriber
function messageUnreadCountSubscriber (commit) {
  if (!auth.isAuthenticated()) return

  applicantNotificationApi.all()
    .then(({ data }) => {
      commit('SET_UNREAD_COUNT', data.messages.unread)
      commit('SET_SCOUT_NOTIFICATION', data.scouts)
      commit('SET_PINNED_NOTIFICATION', data.pinned)
      commit('SET_ADMIN_NOTIFICATION', data.admin_notifications)
    })
}
