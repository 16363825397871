export default {
  tabs: {
    interested: '興味あり',
    applied: '応募済み',
    saved: '保存済み',
    interestedTitle: '「話を聞いてみたい」をした求人',
    invisible: '非表示',
    scouted: 'スカウト',
    recommendations: 'おすすめ求人'
  },
  pinned: {
    title: '気になる',
    caption: '（企業から「気になる」された求人）'
  },
  applied: {
    title: '応募した求人'
  },
  matched: {
    title: 'マッチ済み',
    caption: '(あなたが「話を聞いてみたい」を送り、企業が「気になる」を送った求人)'
  },
  buttons: {
    checkMsg: 'メッセージ',
    revisible: '再表示'
  },
  invisible: {
    title: '「非表示」にした求人',
    hide: '非表示'
  },
  actions: {
    iLikeToKnowMore: '話を聞いてみたい',
    iLikeToKnowMoreMessage: 'オンラインカジュアルミーティングが予定される場合、いつがよろしいですか？',
    iLikeToKnowMoreSubMessage: '（候補を３つ以上選択すると早く日程が決まる可能性が高いです。）',
    dayOfTheWeek: '曜日',
    timeInJapan: '日本での時間',
    localTime: '現地時間',
    casualOnlineMeeting: 'カジュアルなオンライン会議',
    movedToMatched: 'マッチしたリストに移動しました',
    save: '保存',
    saved: '保存済み'
  },
  common: {
    endOfSelection: '選考終了',
    underSelection: '選考中'
  },
  closed: 'この求人はクローズしました',
  savedNoDisplay: {
    header: '表示する求人がありません。',
    body: 'ここには保存した求人が表示されます。気になる求人を探しましょう！',
    body1: '求人を保存すると企業から「スカウト」や「興味あり」が届く場合があります。',
    button: '求人検索'
  },
  saved: {
    header: '保存した求人',
    body: 'ここには保存した求人が表示されます。気になる求人を探しましょう！',
    body1: '求人を保存すると企業から「スカウト」や「興味あり」が届く場合があります',
    body2: '※「非表示」ボタンを押した場合は、非表示のタブに移動します。',
  },
  interested: {
    header: '企業からのアプローチ',
    body: 'ここには企業から「興味あり」された求人が表示されます。',
    body1: 'プロフィールを更新し、企業へアピールしましょう。'
  },
  interestedNoData: {
    header: '表示する求人がありません。',
    body: 'ここには企業から「興味あり」された求人が表示されます。',
    body1: 'プロフィールを更新し、企業へアピールしましょう。',
    button: 'プロフィール編集'
  },
  scouted: {
    header: '企業からのアプローチ',
    body: '求人の内容を確認し、気になる求人には',
    body1: '「応募」をしましょう。',
  },
  scoutedNoData: {
    header: '表示する求人がありません。',
    body: 'ここには企業から「スカウト」された求人が表示されます。',
    body1: '企業へアピールしましょう。',
    button: 'プロフィール編集'
  },
  appliedNoDisplay: {
    header: '表示する求人がありません',
    body: 'ここには応募した求人が表示されます。',
    body1: '気になる求人を探しましょう！',
    button: '求人検索'
  },
  applied: {
    header: '応募した求人',
    body: 'ここには応募した求人が表示されます。',
    body1: '気になる求人を探しましょう！',
  },
  jobPostingClosed: '掲載中止'
}
