import { Settings } from 'luxon'
import lang from '@/services/lang'
import Config from '@/config/app'

export default {
  methods: {
    setDefaultLanguage () {
      const selectedLanguage = this.$cookies.get(Config.COOKIES.LANGUAGE_KEY)
      if (!selectedLanguage) return this.updateBrowserLanguage()
      return this.setLocaleLang(selectedLanguage.value)
    },

    updateBrowserLanguage () {
      const tempLang = this.$cookies.get('browser-language')
      const browserLanguage = window.navigator.language === 'ja' ? 'ja' : 'en'
      if (!tempLang || (tempLang !== browserLanguage)) lang.setBrowserLanguage(browserLanguage)
      this.setLocaleLang(browserLanguage)
    },

    setLocaleLang (language) {
      this.$i18n.locale = language
      this.$vuetify.lang.current = language
      Settings.defaultLocale = language
    }
  },

  created () {
    this.setDefaultLanguage()
  }
}
