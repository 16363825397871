export default {
  maximumSelect: '最大15個まで選択可能です',
  maximumFiveSelect: '最大5個まで選択可能です',
  simpleRegistration: {
    skill: 'スキル',
    job: '経験職種',
    japanese: '日本語レベル',
    japaneseLevel: '日本語レベル',
    preference: '希望条件',
    basicInformation: '基本情報'
  },
  steps: {
    step1: 'ステップ 1',
    step2: 'ステップ 2',
    step3: 'ステップ 3',
    step4: 'ステップ 4'
  },
  next: '次へ',
  back: '戻る',
  complete: '完了',
  steppers: {
    '01': '言語',
    '02': 'スキル/経験',
    '03': '希望条件',
    '✓': '登録完了'
  },
  finish: {
    completed: '登録完了',
    find: '求人サーチをして応募してみましょう！',
    search: '求人を探す',
    failed: '登録できませんでした。',
    try: '後でもう一度試すか、お問い合わせください。',
    back: 'ホーム画面に戻る',
    next: '次へ進む',
    profile: 'プロフィール詳細を入力',
    info: {
      line1: '必須情報の入力が完了しました',
      line2: '続けて、詳細情報を入力しましょう',
      line3: 'プロフィール情報を充実させることで、選考通過率・スカウト受信率が大幅に上昇します'
    }
  },
  experience: {
    skill: 'スキル',
    currentStatus: '現在の就業状況',
    work: '職務経験',
    education: '教育',
    personalInformation: '個人情報'
  },
  help: {
    title: 'ヘルプ',
    nativeLanguage: {
      title: '母国語',
      body: '二つ以上の母国語をお持ちの方も、いずれか一つを選択してください。'
    },
    speakingEnglish: {
      title: '英会話力/日本語会話力',
      body: `<strong class="foreground--text">母国語:</strong>
      様々な文法や複雑な言葉を含め、すべての面で完全に習得している

      <strong class="foreground--text">流暢:</strong>
      高度な会話においても苦労せずに会話ができ、相手と自然に意思疎通ができる
      
      <strong class="foreground--text">ビジネス:</strong>
      会議・プレゼンテーションなど、ビジネスの場でコミュニケーションをとり業務を行える
      
      <strong class="foreground--text">日常会話:</strong>
      雑談や日常会話であれば内容を理解し、相手とコミュニケーションをとることができる

      <strong class="foreground--text">挨拶以下:</strong>
      挨拶や簡単な自己紹介、「はい」「いいえ」は言える。またはそれ以下`
    },
    jlpt: {
      title: 'JLPT（日本語能力試験）',
      body: `<strong class="foreground--text">N1:</strong>
      幅広い場面で使われる日本語を理解することができる
      
      <strong class="foreground--text">N2:</strong>
      日常的な場面で使われる日本語の理解に加え、より幅広い場面で使われる日本語をある程度理解することができる

      <strong class="foreground--text">N3:</strong>
      日常的な場面で使われる日本語をある程度理解することができる

      <strong class="foreground--text">N4:</strong>
      基本的な日本語を理解することができる

      <strong class="foreground--text">N5:</strong>
      基本的な日本語をある程度理解することができる
      `
    },
    autoFillOut: {
      title: '自動入力',
      body: 'CVや職務経歴書ファイルをお持ちの場合、アップロードすることで入力をサポートします。'
    },
    gtalent: {
      title: 'G Talentとは?',
      body: '日本に住む外国人IT人材やバイリンガル日本人向けの人材紹介サービスです。あなたの日本の就職、転職をIT専門のリクルーターがサポートします。'
    }
  },
  tips: {
    default: {
      header: 'Welcome to GitTap',
      tip: `あなたの情報を入力してください。
      ご不明な点がございましたら、？マークをクリックしてください。
      
      ヘルプがここに表示されます。`
    },
    prefectures: {
      header: '都道府県を選択する',
      tip: '都道府県を選んでください'
    },
    industry: {
      header: '業界を選択する',
      tip: '業界を選んでください'
    },
    itSkill: {
      header: 'ITスキルを選択する',
      tip: 'あなたのITスキルを選んでください。'
    },
    jobRole: {
      header: '経験した職種を選択する',
      tip: 'あなたの過去に経験した職種を選んでください。'
    },
    school: {
      header: '学校情報を入力する',
      tip: '学歴を入力してください。'
    },
    company: {
      header: '会社を追加する',
      tip: '追加された会社は自動的にソートされます。'
    }
  },
  registerToGTalent:
    '<span style="font-size:13px;">同時に転職支援サービス<span class="primary--text">G Talent</span>へも登録する。</span>',
  workRemotelyFromOverseas: '日本国外からのリモートワークを希望する',
  gTalentHelp:
    '日本に住む外国人IT人材やバイリンガル日本人向けの人材紹介サービスです。あなたの日本の就職、転職をIT専門のリクルーターがサポートします。',
  gTalentDescription:
    '同時に転職支援サービス<strong>G Talent</strong>へも登録する。（サービスお申し込みにあたり、<span class="primary--text">利用規約</span>及び<span class="primary--text">個人情報の取り扱い（会員情報）</span>への同意が必要となります。）',
  yearsOfExperience: {
    fiveYears: '5年以上',
    threeToFiveYears: '3〜5年',
    oneToThreeYears: '1〜3年',
    lessThanOneYear: '1年未満'
  },
  welcome: {
    header: 'GitTapへようこそ',
    item1: '登録はまだ完了していません <br> プロフィールを充実させましょう',
    item2: '選考通過率・スカウト受信率が大幅に高まります',
    enterManually: '手動で入力'
  }
}
