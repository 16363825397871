export default {
  scoutTemplate: {
    header: 'From GitTap team',
    body1: 'Congratulations!',
    body2: 'You\'ve received Scout! Let\'s reply immediately.',
    knowMore: 'Know More',
    apply: 'Apply',
    applied: 'Applied',
    jobDetails: 'CHECK THE JOB DETAILS',
    decline: 'Decline',
    noThanks: 'No Thanks',
    knowMoreSent: '“Know More” has been sent',
    noThanksSent: '“No thanks” has been sent',
    save: 'Save',
    saved: 'Saved'
  },
  interviewResult: {
    labelYes: 'Yes',
    labelNo: 'No'
  },
  gittapMessages: {
    applyReply: 'You applied this position. Please wait for the selection result to be notified.',
    declineReply: 'Thank you for answering. You declined the scout.',
    knowMoreReply: 'Job offer has been saved. <br> *You can check it in Approach > Saved'
  },
  applyDialog: {
    header: 'Confirmation',
    label: 'You can add a message',
    optional: 'Optional',
    apply: 'Apply'
  },
  declineDialog: {
    header: 'Are you sure you want to decline?',
    decline: 'Decline'
  },
  noThanksDialog: {
    header: 'Please choose a reason',
    noThanks: 'No Thanks'
  },
  closedPosition: 'DECLINED THE SCOUT.',
  alreadyApplied: 'You have already applied to this position!',
  backToMessage: 'Back to Message',
  idLikeToKnowMoreSent: 'Thank you for reaching out, I would like to learn more about this is sent.'
}
