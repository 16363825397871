import api from '../index'

const {
  http,
  show,
  store,
  update,
  destroy,
  simpleSearch,
  advancedSearch
} = api

/**
 * ===================
 * Applicant Experiences API
 * ===================
 */
export default {
  baseUrl: `${process.env.VUE_APP_CUSTOMER_URL}/api/personal/v1`,
  url: 'applicant-experiences',
  http,
  show,
  store,
  update,
  destroy,
  simpleSearch,
  advancedSearch,

  /**
   * Sync the Applicant Experiences records from the request.
   *
   * @param payload
   */
  sync (payload) {
    return this.http(this.baseUrl)
      .post(`${this.url}/sync`, payload)
  }
}
