export default {
  videoQuestion: 'VIDEO QUESTION',
  stepper: {
    step1: '開始',
    step2: '問',
    step3: '終了'
  },
  continue: '動画選考を開始する',
  question: '問',
  back: '戻る',
  next: '次へ',
  uploadVideo: '動画ファイルをアップロード',
  recordingTips: '最大50MBまでのmp4またはmovファイルをアップロード可能です。画質1280x720以下、30秒程度の長さの動画を推奨します。',
  send: '提出',
  close: '閉じる',
  submit: '回答を送信します。よろしいですか？',
  validation: '必要事項をご記入ください',
  confirmation: {
    fix: '変更',
    title: 'あなたの答えの確認'
  },
  freeTextError: '回答は400文字を超えてはなりません',
  videoError1: 'ファイル形式が無効です！',
  videoError2: 'アップロードできる動画のファイルサイズは50MBまでです。',
  videoError3: 'ファイルはビデオファイルである必要があります',
  uploadVideoInterviewTitle: 'ファイルアップロード',
  uploadCompleteMessage: '動画アップロードに成功しました',
  uploadInfoMessage: 'アップロードが完了するまでお待ちください。',
  noUploadMessage: 'まだ何もアップロードしていません',
  beforeLeaveMessage: 'ページを更新するとアップロードがキャンセルされますがよろしいですか？',
  videoInterviewAnswerSent: '動画面接の回答を送信しました',
  uploadFinalizeMessage: '完了',
  videoDeleted: '動画ファイルは削除されました',
  videoConfirmationDeleted: '提出した動画ファイルは30日後に自動的に削除されます。',
  videoMessageDeleted: '動画ファイルは30日後に削除されます。',
  profileVideo: '自己紹介動画'
}
