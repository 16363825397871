export default {
  stepOne: {
    title: 'How active are you looking for a job?',
    success: 'Job search status has been saved'
  },
  stepTwo: {
    title: 'Which work locations do you prefer?',
    choices: {
      1: 'Anywhere in Japan',
      2: 'SELECT PREFECTURES'
    },
    add: 'ADD MORE PREFECTURES',
    success: 'Preference Saved Successfully',
    dialog: 'Select Prefecture',
    workRemotely: 'If possible, work remotely from outside Japan',
    required: 'Required'
  },
  stepThree: {
    title: 'Which industries do you prefer?',
    choices: {
      1: 'Any Industry',
      2: 'SELECT INDUSTRY'
    },
    add: 'ADD MORE INDUSTRY',
    success: 'Preference Saved Successfully',
    dialog: 'Select Industry',
    required: 'Required'
  },
  stepFour: {
    title: 'Tell us your priority to choose a job',
    subtitle: 'You can choose items up to 5 from total {0} items.',
    success: 'Preference Saved Successfully',
    optional: 'Optional',
    applicantBlacklistedCompanyInfo:
      'Your profile will not be available to the excluded companies. You can search for companies in Japanese, Katakana, or English. If you can not find the company, let us know at <span style="color: #298895">info@gittap.jp</span>.'
  },
  exclude: {
    title: 'Companies to Exclude',
    description:
      'Your profile will not be available to the excluded companies. You can search for companies in Japanese, Katakana, or English. If you can not find the company, let us know company’s name and URL at <span style="color: #4192DF">info@gittap.jp</span>'
  },
  jobSearchStatus: 'Job Search Status',
  workRemotely: 'If possible, work remotely from outside Japan',
  preferredLocation: 'Preferred Location',
  preferredType: 'Preferred Type',
  preferredIndustry: 'Preferred Industry',
  preferredJob: 'Preferred Job',
  excludedCompanies: 'Companies to exclude',
  excludedCompaniesTip:
    'select a company from the suggested items. If nothing is suggested, Please input the company name as precise as possible (English and Japanese).',
  desiredAnnualSalary: 'Desired Annual Salary',
  priority: 'Priority',
  character: 'Character',
  edit: 'Edit Preference',
  priorityMax: '5 ITEMS MAXIMUM ',
  characterSettingSelector: 'Character Setting Selector',
  preferredMinimumSalary: 'Preferred Minimum Annual Income',
  preferredPosition: 'Preferred Position',
  specialist: 'Specialist',
  generalist: 'Generalist',
  creator: 'Create new',
  improver: 'Improve existing products',
  bold: 'Bold',
  cautious: 'Cautious',
  flexible: 'Flexible to Change',
  steady: 'Stick to the plan',
  independent: 'Independent',
  collaborative: 'Collaborative',
  workRemotely: 'Work remotely from overseas',
  anything: 'Anything',
  createCompanyName: 'Add',
  emailNotifications: {
    label: 'E-mail Notifications',
    note: '*You can select which email notification you want to receive'
  },
  firstName: 'First Name',
  lastName: 'Last Name',
  nationality: 'Nationality',
  dateOfBirth: 'Date of Birth',
  latestAnnualIncome: 'Latest Annual Income',
  countryResidence: 'Current Country Residence',
  currency: 'Currency',
  preferredConditions: 'Preferred Conditions',
  birthDayHint: 'Example:1990/01/01'
}
