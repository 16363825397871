export default {
  required: 'この項目は必須です',
  required2: '必須',
  email: '有効なメールアドレス形式で入力してください。',
  max: {
    string: '{max}文字以下で入力してください。'
  },
  maxLength: '最大{max}文字でなければなりません。',
  unique: '入力した条件は既に保存されています',
  saveDuplicatedMessage: 'その条件はすでに保存されています'
}
