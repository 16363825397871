import api from '../../index'

const {
  http,
  index
} = api

/**
 * ==================
 * Income Range API
 * ==================
 */
export default {
  baseUrl: `${process.env.VUE_APP_CUSTOMER_URL}/api/v1`,
  url: 'income-ranges',
  http,
  index
}
