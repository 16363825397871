import api from '../index'

const {
  http,
  index,
  updateOrCreate,
  simpleSearch
} = api

/**
 * =======================================================
 * Applicant Basic Information API
 * =======================================================
 */
export default {
  baseUrl: `${process.env.VUE_APP_CUSTOMER_URL}/api/personal/v1`,
  url: 'applicant-basic-personal-information',
  http,
  index,
  updateOrCreate,
  simpleSearch
}
