export default {
  tags: {
    title: 'Login',
    meta: {
      description: 'This page is the login page.',
      keywords: 'Login, Japan, Jobs, Foreigners, Job Change, IT, Web, Engineers, GitTap',
      og: {
        site_name: 'GitTap',
        title: 'Login | Find IT Jobs in Japan - GitTap',
        description: 'GitTap Login Here! GitTap is a matching platform of IT Jobs in Japan for foreigners. We specialize in finding jobs for all IT Engineers, such as software developers, programmers, and project managers at high-profile Japanese companies. '
      }
    }
  },
  username: 'Username / E-mail',
  password: 'Password',
  login: 'Log In',
  forgotPassword: 'Forgot Password?',
  registration: {
    tagline: 'New to GitTap?',
    callToAction: 'Join Now'
  },
  placeholders: {
    email: 'Please input your email',
    password: 'Please input your password'
  },
  rememberMe: 'Keep me login',
  social: {
    google: 'Login with Google',
    facebook: 'Login with Facebook',
    linkedIn: 'Login with LinkedIn',
    github: 'Login with Github'
  }
}
