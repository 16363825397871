<template>
  <v-app-bar
    app
    color="background"
    :height="$vuetify.breakpoint.smAndUp ? '56' : ''"
    :class="$vuetify.breakpoint.smAndUp ? 'white' : 'secondary'"
    flat
    style="border-bottom: 1px solid #edf0f7 !important"
  >
    <v-container
      :class="{
        'pa-0': !$vuetify.breakpoint.smAndUp,
        'expanded-width': onRegistrationProcess,
        'signup': isSignUpPage
      }"
      class="px-0 padding-x"
    >
      <v-toolbar flat dense>
        <v-toolbar-title class="mr-4 mt-2">
          <img
            alt="GitTap Logo"
            contain
            :src="require(`@/assets/${logo}`)"
            width="85"
            style="cursor: pointer"
            @click="navigateToHome"
          />
        </v-toolbar-title>

        <v-toolbar-items style="margin-left: 42px">
          <template v-for="(item, index) in navList">
            <v-btn
              v-if="isRegistered"
              :key="'nav-' + index"
              text
              rounded
              :to="item.to"
              class="text-none nav-list"
              style="margin-right: 25px"
              small
              @click="pushGtm(item.code)"
            >
              <span v-if="!item.hasBadge">
                {{ item.title }}
              </span>

              <v-badge
                v-else
                color="red"
                overlap
                :content="
                  index === 1 ? unreadApproachFormat : unreadMessageCountFormat
                "
                :value="index === 1 ? !!unreadApproachFormat : unreadCount"
              >
                <span>{{ item.title }}</span>
              </v-badge>
            </v-btn>
          </template>
        </v-toolbar-items>

        <v-spacer />

        <v-toolbar-items v-if="isRegistered" class="notif-toolbar">
          <NotificationToolbarMenu
            @customActionPush="pushGtm('notification')"
          />
        </v-toolbar-items>

        <AccountToolbarMenu />
        <AuthorizationToolbarItem />
      </v-toolbar>
    </v-container>
  </v-app-bar>
</template>

<script>
import { mapState } from 'vuex'

import NotificationToolbarMenu from '@/components/Menus/NotificationToolbarMenu'
import AccountToolbarMenu from '@/components/Menus/AccountToolbarMenu'
import AuthorizationToolbarItem from '@/components/Toolbar/AuthorizationToolbarItem'
import GtagAction from '@/mixins/gtag-action'

export default {
  components: {
    NotificationToolbarMenu,
    AccountToolbarMenu,
    AuthorizationToolbarItem
  },
  mixins: [GtagAction],
  props: {
    unreadCount: {
      type: [String, Number],
      default: 0
    },
    approachUnreadCount: {
      type: [String, Number],
      default: 0
    },
    gtmParams: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      navList: [
        {
          to: '/jobs',
          title: this.$t('bottomNav.jobs'),
          hasBadge: false,
          code: 'jobs'
        },
        {
          to: '/approach',
          title: this.$t('bottomNav.approach'),
          hasBadge: true,
          code: 'approach'
        },
        {
          to: '/messages',
          title: this.$t('bottomNav.message'),
          hasBadge: true,
          code: 'message'
        },
        {
          to: '/profile-settings',
          title: this.$t('bottomNav.profile'),
          hasBadge: false,
          code: 'profile'
        }
      ]
    }
  },
  computed: {
    ...mapState('applicant', ['auth']),

    isRegistered() {
      return this.auth ? !!this.auth.registered_at : false
    },
    /**
     * Theme logo
     *
     */
    logo() {
      return this.$vuetify.theme.isDark
        ? 'gittap_logo_white.png'
        : 'gittap_logo.png'
    },

    // formats unread count
    unreadMessageCountFormat() {
      return this.unreadCount
    },
    // Get unread pinned notification count
    unreadApproachFormat() {
      return this.approachUnreadCount
    },
    onRegistrationProcess() {
      return (
        this.$route.path.includes('profile-registration') &&
        this.$vuetify.breakpoint.lgAndUp
      )
    },
    isSignUpPage() {
      return this.$route.name?.toLowerCase() === 'signup'
    }
  },
  methods: {
    pushGtm(code) {
      const param =
        this.gtmParams[this.$route.name] &&
        this.gtmParams[this.$route.name][code]
      param && this.customActionPush(param)
    },
    navigateToHome() {
      if (!this.isRegistered) location.replace(process.env.VUE_APP_VISITOR_URL)

      if (this.$route.name !== 'MyPage') this.$router.push({ name: 'MyPage' })
    }
  }
}
</script>

<style scoped>
.padding-x >>> .v-toolbar__content,
.v-toolbar__extension {
  padding: 4px 0px !important;
}
.nav-list:first-child {
  margin-left: 16px;
}
.nav-list {
  font: normal normal bold 14px/19px Roboto;
  height: 24px !important;
  vertical-align: middle;
  letter-spacing: 0px;
  border-radius: 56px;
}
.nav-list span >>> .v-badge__badge {
  position: absolute;
  inset: -15px -18px auto auto !important;
  z-index: 1;
  font-size: 9px;
  text-align: center;
  background: #d62f2f !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.nav-list::before {
  background: none;
}
.nav-list:hover {
  background: #f4f4f4 0% 0% no-repeat padding-box;
  border-radius: 56px;
}
.nav-list.v-btn--active {
  background: #ebebeb;
  color: var(--v-primary-base);
  border-radius: 56px;
}
.theme--light.v-btn--active:hover::before,
.theme--light.v-btn--active::before {
  opacity: 0 !important;
}
.v-toolbar__items {
  display: flex;
  align-items: center;
}
.notif-toolbar >>> .v-btn::before {
  background: none !important;
}
.notif-toolbar >>> .v-btn .v-ripple__container {
  display: none !important;
}
.notif-toolbar >>> .v-btn.active i {
  color: var(--v-primary-base) !important;
}
.expanded-width {
  max-width: 83.3333% !important;
}
.container.signup {
  margin: 0 90px;
  max-width: 100% !important;
}
</style>
