export default {
  stepOne: {
    title: 'どれくらい積極的に仕事を探していますか？',
    success: '転職意向が正常に保存されました'
  },
  stepTwo: {
    title: '勤務地の希望はありますか？',
    choices: {
      1: '日本国内のどこでも',
      2: 'ご希望の勤務地（都道府県）を選択してください'
    },
    add: '希望勤務地を追加',
    success: '希望設定が正常に保存されました',
    dialog: '都道府県を選択',
    workRemotely: '日本国外からのリモートワークを希望する',
    required: '必須'
  },
  stepThree: {
    title: '希望する業界はありますか？',
    add: '業界をさらに追加',
    success: '希望設定が正常に保存されました',
    choices: {
      1: 'どの業界でも可',
      2: 'ご希望の業界を選択してください'
    },
    dialog: '業種を選択',
    required: '必須'
  },
  stepFour: {
    title: '仕事選びにおいて、どんなことを重視しますか？',
    subtitle: '{0}の項目から5つまで選択することができます',
    success: '希望設定が正常に保存されました',
    optional: '任意',
    applicantBlacklistedCompanyInfo:
      '「非表示にしたい企業」で選んだ企業は、あなたのプロフィールを見られません。日本語・カタカナ・英語で、企業名を検索できます。企業が見つからない場合は、<span style="color: #298895">info@gittap.jp</span>までお知らせください。'
  },
  exclude: {
    title: '企業ブロック設定',
    description:
      'ブロック設定した企業からはあなたのプロフィールが見えなくなります。ブロックする企業を日本語、カタカナまたは英語名で検索することができます。もし見つからない場合はブロックしたい企業の社名とURLを<span style="color: #4192DF">info@gittap.jp</span>までお知らせください。'
  },
  jobSearchStatus: '転職活動状況',
  workRemotely: '日本国外からのリモートワークを希望する',
  preferredLocation: '希望勤務地',
  preferredType: '雇用形態',
  preferredIndustry: '希望業界',
  preferredJob: '希望職種',
  excludedCompanies: '企業ブロック設定',
  excludedCompaniesTip:
    'キーワードを入れると表示される候補から選択してください。ない場合はできるだけ正確な会社名（可能であれば英語と日本語）を入力してください。',
  desiredAnnualSalary: '希望年収',
  priority: 'こだわりたいポイント',
  character: 'キャラクター',
  edit: '希望条件を編集',
  priorityMax: '最大５つ',
  characterSettingSelector: 'キャラクター選択',
  preferredMinimumSalary: '希望最低年収',
  preferredPosition: '希望職種',
  specialist: '専門',
  generalist: '全般',
  creator: '新規創出',
  improver: '既存改善',
  bold: '大胆',
  cautious: '慎重',
  flexible: '変化に柔軟',
  steady: '計画通り',
  independent: '独立',
  collaborative: '連携',
  workRemotely: '海外からの勤務',
  anything: '指定なし',
  createCompanyName: '追加 ',
  emailNotifications: {
    label: 'メール通知設定',
    note: '※受信したいメール通知を選択できます。'
  },
  firstName: '名',
  lastName: '姓',
  nationality: '国籍',
  dateOfBirth: '生年月日',
  latestAnnualIncome: '現在の年収',
  countryResidence: '今住んでいる国',
  currency: '年収の通貨',
  preferredConditions: '希望条件',
  birthDayHint: '例：1990/01/01'
}
