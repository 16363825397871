export default {
  title: 'Inquiry Form',
  agreement: {
    line_1: 'I agree to the ',
    link: 'Privacy Policy',
    line_2: 'of Bizmates, Inc.'
  },
  form: {
    name: {
      label: 'Name',
      placeholder: 'Your Name'
    },
    email: {
      label: 'Email',
      placeholder: 'Your Email'
    },
    country: {
      label: 'Country',
      placeholder: 'Choose your country'
    },
    question: {
      label: 'Question',
      placeholder: 'Your question'
    }
  },
  confirmation: {
    title: 'Confirmation of Contents',
    body: 'It will be sent with the following content',
    table: {
      contact_information: 'Contact Information',
      contents: 'Contents of Inquiry'
    },
    success: {
      title: 'Inquiry Sent Successfully',
      body: 'Your inquiry has been sent. GitTap Secretariat will confirm the contents and we will reply to you within 5 business days.'
    }
  }
}
