<template>
  <v-list-item>
    <v-list-item-content class="my-3">
      <div class="d-flex align-center justify-center">
        <v-chip
          small
          :color="display.color"
          class="mr-2 justify-center"
          outlined
          style="min-width: 120px"
        >
          <v-icon
            small
            class="mr-1"
            :class="display.class"
          >
            {{ display.icon }}
          </v-icon>
          {{ display.text }}
        </v-chip>
        <v-btn
          v-if="withLink"
          text
          link
          :to="link"
          class="text-none"
        >
          {{ title }}
        </v-btn>
        <span
          v-else
          class="mr-5"
        >{{ title }}</span>
      </div>
      <div v-if="quarantinedFiles">
        <div
          v-for="(item,index) in quarantinedFiles"
          :key="'quarantinedFiles' + index"
          class="d-flex align-center justify-center"
        >
          <v-chip
            small
            color="error"
            class="mr-2 justify-center my-1"
            outlined
            style="min-width: 120px"
          >
            <v-icon
              small
              class="mr-1"
            >
              mdi-exclamation-thick
            </v-icon>
            {{ quarantinedFilesErrorMessage }}
          </v-chip>
          <span
            class="mr-5"
          >
            {{ item.filename }}
          </span>
        </div>
      </div>
      <v-progress-linear
        :color="status.toUpperCase() === 'FAILED'? 'red': 'disabled'"
        height="15"
        striped
        rounded
        :value="progress"
        :clickable="false"
        class="my-2"
      >
        <span>{{ progress }}%</span>
        <span
          v-if="progress > 99"
          class="pl-1"
        >{{ $t('videoInterview.uploadFinalizeMessage') }}</span>
      </v-progress-linear>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    status: {
      type: String,
      required: true
    },
    link: {
      type: String,
      default: String
    },
    progress: {
      type: Number,
      default: 0
    },
    quarantinedFiles: {
      type: Array,
      default: null
    },
    quarantinedFilesErrorMessage: {
      type: String,
      default: null
    }
  },
  computed: {
    /**
     * Identify if has link
     */
    withLink () {
      const currentRoute = this.$router.currentRoute
      if (this.link) {
        return this.link !== currentRoute
      }

      return false
    },
    /**
     * Identify chip & icon display
     * @return object
     */
    display () {
      const status = this.status.toUpperCase()
      let display = {}

      switch (status) {
        case 'UPLOADING': display = {
          color: 'info',
          text: this.$t('common.uploading'),
          icon: 'mdi-rotate-right',
          class: 'mdi-spin'
        }; break

        case 'FAILED': display = {
          color: 'error',
          text: this.$t('common.failed'),
          icon: 'mdi-exclamation-thick'
        }; break

        case 'FINISHED': display = {
          color: 'success',
          text: this.$t('common.finished'),
          icon: 'mdi-check'
        }; break

        case 'CANCELLED': display = {
          color: 'default',
          text: this.$t('common.cancelled'),
          icon: 'mdi-cancel'
        }; break

        default: display = {
          text: this.$t('common.unknown'),
          icon: 'mdi-progress-question',
          color: 'black'
        }; break
      }

      return display
    }
  }
}
</script>
