import api from '../index'

const {
  http,
  index
} = api

/**
 * ===================
 * Popular Keyword API
 * ===================
 */
export default {
  baseUrl: `${process.env.VUE_APP_CLIENT_URL}/api/v1`,
  url: 'popular-keywords',
  http,
  index,
  /**
   * Search for popular keywords
   */
  search (payload) {
    return this.http(this.baseUrl).post(`${this.url}/search`, payload)
  }
}
