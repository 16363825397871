export default {
  title: 'メッセージ',
  count: '未読メッセージ {0} 件',
  searchPlaceholder: '会社名または求人名で検索',
  textareaPlaceholder: '{0}へメッセージ',
  sharedFiles: '共有されたファイル',
  sharedPhotos: '共有されたイメージファイル',
  file_too_big_title: 'ファイルサイズが大きすぎます。',
  file_too_big_message: 'アップロード可能なファイルサイズは5MBまでです',
  file_too_big_video_message: 'アップロード可能なファイルサイズは50MBまでです',
  today: '今日',
  yesterday: '昨日',
  preview: 'プレビューできません。ダウンロードしてください。',
  file_limit_title: 'ファイルの上限を超えています',
  file_limit_body: '同時に添付できるのは最大５つまで、または１つの動画ファイル（50MB）までです。',
  file_type_invalid: '{attribute}には、{value}タイプのファイルを指定してください。',
  job_posting_closed: 'このポジションはクローズされました。',
  is_reported: 'このメッセージは受信者側に非表示になりました。不適切な情報が含まれていないかご確認ください。',
  is_filtered: '連絡先情報は書類選考通過後に表示されます。',
  closed: 'クローズされました。',
  video_interview: '動画面接の案内を受信しました',
  video_interview_alert: '動画は30日で削除されます',
  video_interview_completion: '動画面接の回答送信済み',
  unread: '未読',
  pressEnter: 'Enterで改行',
  send: '送信',
  lessThanOrEqualTo: '{0}以下でなければなりません。',
}
