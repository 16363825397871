export default {
  onlineInterview: 'オンライン面談',
  interviewResult: {
    labelYes: 'あり',
    labelNo: 'なし'
  },
  jobDescription: '仕事内容',
  workingCondition: '労働条件（勤務時間、待遇・福利厚生、休日・休暇）',
  requiredSkills: '必要なスキルと経験',
  selectionFlow: '選考フロー',
  appealingPoints: '求人の魅力',
  rateForeigners: '外国籍メンバーの割合',
  rateForeignersHelp: '業務で関わる方々の中で外国籍メンバーの割合',
  frequencyEnglish: '英語利用割合',
  frequencyEnglishHelp: '業務において英語を利用する割合',
  estimatedCharacteristic: 'マッチする人物像',
  jobSalary: '求人詳細',
  company: '会社',
  languageSkill: '必要な言語力',
  language: {
    english: '英語',
    japanese: '日本語'
  },
  noResult: '検索結果はありません',
  interested: '話を聞いてみたい',
  apply: '応募する',
  applied: '応募済み',
  applySuccess: '求人応募は成功しました！',
  workRemotely: '海外リモートワーク',
  remoteWorkOverseas: '海外リモートワーク',
  remoteWorkJapan: '国内リモートワーク',
  yes: 'あり',
  no: 'なし',
  incomeRangeUndecided: '応相談',
  foundedDate: '{date}設立',
  more: 'すべて表示',
  skill: 'スキル',
  job: '仕事',
  languageRequirements: '言語要件',
  required: '必須',
  preferred: '歓迎',
  noData: '必要',
  recommendedJobs: 'おすすめ求人',
  save: '保存',
  saved: ' 保存済み'
}
