import scoutDeclineReasonsApi from '@/api/common/scout-decline-reasons'

const data = {
  items: sessionStorage.getItem('scoutDeclineReasons')
    ? JSON.parse(sessionStorage.getItem('scoutDeclineReasons'))
    : []
}

export default {
  namespaced: true,
  state () {
    return {
      ...data
    }
  },
  mutations: {
    /**
     * Set the items.
     *
     * @param {*} state
     * @param {*} items
     */
    SET_ITEMS (state, items) {
      state.items = items
    }
  },
  actions: {
    /**
     * Get all items
     *
     * @param {*} param
     */
    all ({ commit, dispatch, state }) {
      if (state.items.length) return

      return scoutDeclineReasonsApi.index()
        .then(({ data }) => {
          commit('SET_ITEMS', data)

          sessionStorage.setItem('scoutDeclineReasons', JSON.stringify(data))
        })
        .catch(({ response }) => dispatch('httpException/handle', response, { root: true }))
    }
  }
}
