<template>
  <v-fade-transition>
    <div v-if="$vuetify.breakpoint.mdAndDown" v-show="drawer" class="btn-account" tabindex="0">
      <div class="dropdown">
        <div class="menu-list profile-image">
          <v-icon @click.stop="toggleDrawer(false)" v-text="'mdi-close'" />
          <router-link to="/profile-settings">
            <v-avatar size="72" class="mx-auto" @click.stop="toggleDrawer(false)">
              <v-img :src="profileImage" />
            </v-avatar>
            <div class="name text-center mt-3" @click.stop="toggleDrawer(false)">
              {{ fullName }}
            </div>
          </router-link>
        </div>
        <div class="menu-list">
          <ul>
            <li v-if="isRegistered" @click="redirect('/account-settings')">
              <a>
                {{ $t('navigation./account-settings') }}
              </a>
            </li>
            <li @click="redirect('/faq-inquiry')">
              <a>
                {{ $t('navigation./faq-inquiry') }}
              </a>
            </li>
          </ul>
        </div>
        <div class="menu-list">
          <ul>
            <li v-if="authenticated" @click="logout">
              <a>
                {{ $t('common.logout') }}
                <svg xmlns="http://www.w3.org/2000/svg" width="15.029" height="15.029" viewBox="0 0 15.029 15.029">
                  <g id="Icon_feather-log-out" data-name="Icon feather-log-out" transform="translate(1 1)">
                    <path id="パス_305" data-name="パス 305"
                      d="M8.843,17.529h-2.9A1.448,1.448,0,0,1,4.5,16.082V5.948A1.448,1.448,0,0,1,5.948,4.5h2.9"
                      transform="translate(-4.5 -4.5)" fill="none" stroke="#626262" stroke-linecap="round"
                      stroke-linejoin="round" stroke-width="2" />
                    <path id="パス_306" data-name="パス 306" d="M24,17.738l3.619-3.619L24,10.5"
                      transform="translate(-14.59 -7.605)" fill="none" stroke="#626262" stroke-linecap="round"
                      stroke-linejoin="round" stroke-width="2" />
                    <path id="パス_307" data-name="パス 307" d="M22.186,18H13.5" transform="translate(-9.157 -11.485)"
                      fill="none" stroke="#626262" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
                  </g>
                </svg>
              </a>
            </li>
            <li v-else @click="$router.push({ path: 'Login' })">
              <a>{{ $t('login.login') }}</a>
            </li>
          </ul>
        </div>
        <div class="menu-list language">
          <v-row no-gutters>
            <v-col cols="5" class="d-flex align-center">
              <span>
                {{ $t('language.language') }}
              </span>
            </v-col>
            <v-col cols="7">
              <v-select id="selectLanguage" v-model="language" outlined item-value="value" item-text="label"
                append-icon="mdi-chevron-down" height="32" :items="languages"
                :menu-props="{ bottom: true, offsetY: true }" @change="($event) => onChange($event)" />
            </v-col>
          </v-row>
        </div>
        <div class="menu-list social">
          <v-row>
            <v-col cols="6" class="d-flex align-center">
              <span>
                SNS
              </span>
            </v-col>
            <v-col cols="6" class="d-flex justify-end">
              <span>
                <v-btn v-for="(item, index) in socialPageDetails" :key="index" icon class="mr-1"
                  @click="goToLink(item.link)">
                  <v-img :src="require(`@/assets/${item.iconFileName}`)" height="24px" width="24px" contain />
                </v-btn>
              </span>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </v-fade-transition>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import Auth from '@/mixins/auth'
import toolbar from '@/mixins/toolbar'
import placeholder from '@/assets/profile_picture_placeholder.png'

export default {
  mixins: [Auth, toolbar],
  data() {
    return {
      language: this.$i18n.locale === 'en' ? 'en' : 'ja',
      lang: 'English',
      items: ['English', 'Japanese'],
      visitorUrl: process.env.VUE_APP_VISITOR_URL,
      socialPageDetails: [
        {
          iconFileName: 'facebook-square.svg',
          link: process.env.VUE_APP_GITTAP_FACEBOOK_URL
        },
        {
          iconFileName: 'linkedin.svg',
          link: process.env.VUE_APP_GITTAP_LINKEDIN_URL
        }
      ]
    }
  },
  computed: {
    ...mapState('core', ['drawer']),
    ...mapState('applicant', ['auth']),
    ...mapState('applicantBasicInfo', ['basicInfo']),
    ...mapGetters('core', ['selectedLanguage']),
    ...mapGetters('applicant', ['hasApplicant']),

    isRegistered() {
      return (this.auth) ? !!this.auth.registered_at : false
    },
    faqLink() {
      if (this.selectedLanguage.value === 'en') {
        return `${process.env.VUE_APP_VISITOR_URL}/faq-inquiry`
      } else return `${process.env.VUE_APP_VISITOR_URL}/ja/faq-inquiry`
    },
    profileImage() {
      if (this.auth && this.auth.profile_photo.url) {
        return this.auth.profile_photo.url
      } else {
        return placeholder
      }
    },
    fullName() {
      try {
        if (!this.hasApplicant) return ''

        return (this.auth.basic_personal_information?.full_name) ? this.auth.basic_personal_information.full_name : 'ID: ' + this.auth.id
      } catch (error) {
        return ''
      }
    }
  },
  created() {
    this.language = this.selectedLanguage.value
  },
  mounted() {
    if (document.querySelector('.btn-account')) {
      document.querySelector('.btn-account').onclick = (e) => {
        this.checkElement(e)
      }
    }
  },
  destroyed() {
    if (document.querySelector('.btn-account')) {
      document.querySelector('.btn-account').removeEventListener('onclick', this.checkElement, true)
    }
  },
  methods: {
    ...mapActions('core', ['toggleDrawer']),
    onChange() {
      this.toggleDrawer(false)
      this.setLanguage(this.language)
    },
    goToLink(link) {
      window.open(link, '_blank')
    },
    checkElement(e) {
      const el = e
      if (el.target.className === 'btn-account') {
        this.toggleDrawer(false)
      }
    },
    redirect(path) {
      if (path !== this.$route.path) {
        if (!this.authenticated) {
          path = this.faqLink
          window.open(path, '_blank')
        } else {
          if (!this.isRegistered) {
            path = this.faqLink
            window.open(path, '_blank')
          } else this.$router.push({ path })
        }
      }
      this.toggleDrawer(false)
    }
  }
}
</script>
<style scoped>
.btn-account {
  position: fixed;
  z-index: 9999;
  background: #1A1A1A99 0% 0% no-repeat padding-box;
  height: 100%;
  width: 100%;
}

.btn-account .name {
  font: normal normal bold 14px/19px Roboto;
  cursor: pointer;
  color: var(--v-text-base);
}

.btn-account .name a {
  font: normal normal bold 14px/19px Roboto;
  cursor: pointer;
  color: var(--v-text-base);
}

.btn-account .name.active {
  color: var(--v-primary-base);
}

.btn-account .dropdown {
  cursor: default;
  width: 343px;
  position: relative;
  top: 58px;
  margin: auto;
  border: 1px solid var(--background-edf0f7);
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: -4px 0px 16px #00000015;
  border: 1px solid #EDF0F7;
  border-radius: 8px;
}

.btn-account .dropdown .menu-list {
  width: 100%;
  border-bottom: 1px solid #DEE0E8;
}

.btn-account .dropdown .menu-list li:hover {
  background: #F4F4F4 0% 0% no-repeat padding-box;
}

.btn-account .dropdown .menu-list.profile-image a {
  padding: 24px 24px 15px;
  display: grid;
  font: normal normal bold 14px/19px Roboto;
  color: var(--v-text-base);
  text-decoration: none;
}

.btn-account .dropdown .menu-list.profile-image button {
  position: absolute;
  right: 0;
  padding: 18px 15px;
}

.btn-account .dropdown .menu-list.profile-image a .v-avatar {
  cursor: pointer;
}

.btn-account .dropdown .menu-list.language,
.btn-account .dropdown .menu-list.social {
  padding: 16px 22px;
  display: grid;
  position: relative;
}

.btn-account .dropdown .menu-list.language select {
  border: 1px solid #B7BCC9;
  border-radius: 3px;
  width: 160px;
  height: 32px;
  text-align: center;
  cursor: pointer;
}

.btn-account .dropdown .menu-list.language .icon-dropdown {
  position: absolute;
  right: 20px;
}

.btn-account .dropdown ul {
  list-style: none;
  padding: 5px;
}

.btn-account .dropdown ul li {
  font: normal normal normal 16px/21px Roboto;
  padding: 10px 16px;
  color: var(--v-text-base);
}

.btn-account .dropdown ul li svg {
  position: relative;
  right: -5px;
  top: 2px;
}

.btn-account .dropdown ul li a {
  text-decoration: none;
  color: var(--v-text-base);
  cursor: pointer;
}

.btn-account .v-select>>>.v-text-field__details {
  display: none;
}

.btn-account .v-select>>>.v-input__slot {
  margin-bottom: 0;
  min-height: auto !important;
}

.btn-account .v-select>>>.v-input__control .v-select__slot .v-input__append-inner {
  margin-top: 5px !important;
}

.btn-account .v-select>>>.v-input__control .v-select__slot .v-select__selections {
  display: block;
  text-align: center;
}

.btn-account .v-select>>>.v-input__control .v-select__slot .v-select__selection {
  margin-right: 0 !important;
  max-width: 100%;
  margin-left: 10px;
}

.btn-account .v-select>>>.v-input__control .v-select__slot .v-select__selections input {
  display: none;
}

/** Dropdowns */
.v-select-list>>>.v-list-item__content {
  height: 32px !important;
  padding: 0px !important;
}

.v-list-item {
  height: 32px !important;
  min-height: 32px !important;
  padding: 0px 8px !important;
}

.v-select-list>>>.v-list-item {
  min-height: 32px !important;
  padding-left: 8px !important;
  padding-right: 8px !important;
}
</style>
