import AuthService from '@/services/auth'
import AuthRequest from '@/api/auth/auth'
import applicantApi from '@/api/customer/applicant'
import Constants from '@/config/constants'
import Cookies from 'vue-cookies'

/**
 * =======================================
 * Route Guards
 * =======================================
 */
export default {
  /**
   * Route guard for protected endpoints.
   *
   * @param next
   * @return next
   */
  async authorized(to, next) {
    AuthService.requestedPage = to.fullPath

    // Proceed to login page if the user is unauthenticated
    if (!AuthService.isAuthenticated()) {
      AuthService.flush()
      return next({ path: '/login', query: to.query })
    }

    // Attempt to refresh token if token is expired
    if (AuthService.isAccessTokenExpired()) {
      await AuthService.refreshToken()
        .then(({ data }) => {
          AuthService.setAuth(data)
        })
        .catch(() => {
          AuthService.flush()
          return next({ path: '/login', query: to.query })
        })
    }

    // If user is authenticated, verify the access token
    AuthService.verifyAccessToken()
      .then(async ({ data }) => {
        AuthService.setUser(data)

        // If invalid user type proceed to login
        if (AuthService.getUser().type !== Constants.USER_TYPE.CS) {
          AuthService.flush()
          return next({ path: '/login', query: to.query })
        }

        // These codes used for setting the registration status
        const applicant = await applicantApi.auth().then(({ data }) => data)

        if (applicant) {
          AuthService.setRegistrationStatus(applicant.current_step)
          AuthService.setApplicantIdToCookie(applicant.id)
        }

        if (AuthService.getRegistrationStatus() === 4) {
          if (to.path === '/initial-password-change') {
            return next('/')
          }
          return next()
        } else if (
          AuthService.isHrbcRegistration() ||
          AuthService.isFirstTimeLogin()
        ) {
          if (to.path === '/initial-password-change') {
            return next()
          }
          return next('/initial-password-change')
        } else {
          return next('/profile-registration')
        }
      })
      .catch(() => {
        AuthService.flush()
        return next({ path: '/login', query: to.query })
      })
  },

  /**
   * Route guard for protected endpoints.
   *
   * @param next
   * @return next
   */
  async authorizedNotRegistered(to, next) {
    // Proceed to login page if the user is unauthenticated
    if (!AuthService.isAuthenticated()) {
      AuthService.flush()
      return next({ path: '/login', query: to.query })
    }

    // Attempt to refresh token if token is expired
    if (AuthService.isAccessTokenExpired()) {
      await AuthService.refreshToken()
        .then(({ data }) => {
          AuthService.setAuth(data)
        })
        .catch(() => {
          AuthService.flush()
          return next({ path: '/login', query: to.query })
        })
    }

    // If user is authenticated, verify the access token
    AuthService.verifyAccessToken()
      .then(async ({ data }) => {
        AuthService.setUser(data)

        // If invalid user type proceed to login
        if (AuthService.getUser().type !== Constants.USER_TYPE.CS) {
          AuthService.flush()
          return next({ path: '/login', query: to.query })
        }

        // Attempt to fetch applicant if it doesn't exist
        if (!AuthService.getRegistrationStatus()) {
          const applicant = await applicantApi.auth().then(({ data }) => data)

          if (applicant) {
            AuthService.setRegistrationStatus(applicant.current_step)
            AuthService.setApplicantIdToCookie(applicant.id)
          }
        }

        if (
          AuthService.isHrbcRegistration() ||
          AuthService.isFirstTimeLogin()
        ) {
          if (to.path === '/initial-password-change') {
            return next()
          }
          return next('/initial-password-change')
        } else if (
          AuthService.getRegistrationStatus() < 4 &&
          AuthService.getRegistrationStatus() >= 1
        ) {
          return next()
        } else {
          return next('/profile-settings')
        }
      })
      .catch(() => {
        AuthService.flush()
        return next({ path: '/login', query: to.query })
      })
  },

  /**
   * Route Guard for registration endpoint (login, sign-up)
   *
   * @param {*} from
   * @param {*} next
   * @param {*} to
   * @return next
   */
  registration(to, from, next) {
    // Detecting Access Route
    this.accessRoute(to, from)

    if (this.redirectToSignupSuccess(to, from)) return next()

    // Proceed to login page if the user is already authenticated
    if (AuthService.isAuthenticated() && !AuthService.isAccessTokenExpired())
      return next({ path: from.path, query: to.query })

    // If user is authenticated, verify the access token
    AuthService.verifyAccessToken()
      .then(({ data }) => {
        AuthService.setUser(data)

        // If invalid user type proceed to login
        if (AuthService.getUser().type !== Constants.USER_TYPE.CS) {
          AuthService.flush()
          return next()
        }

        AuthService.refreshToken()
          .then(({ data }) => {
            AuthService.setAuth(data)
            return next({ path: from.path, query: to.query })
          })
          .catch(() => {
            AuthService.flush()
            return next()
          })
      })
      .catch(() => {
        AuthService.flush()
        return next()
      })
  },

  /**
   * detecting route
   *
   */
  accessRoute(to, from) {
    var option = {
      path: '/',
      maxAge: 60 * 60 * 24 * 7,
      domain: process.env.VUE_APP_COOKIE_DOMAIN
    }

    const referer = document.referrer || ''
    if (referer && !referer.includes(process.env.VUE_APP_COOKIE_DOMAIN)) {
      Cookies.set('referer', referer, option)
    }

    const utmSource = to.query.utm_source || ''
    if (utmSource) {
      Cookies.set('utmSource', utmSource, option)
    }

    const utmMedium = to.query.utm_medium || ''
    if (utmMedium) {
      Cookies.set('utmMedium', utmMedium, option)
    }

    const utmCampaign = to.query.utm_campaign || ''
    if (utmCampaign) {
      Cookies.set('utmCampaign', utmCampaign, option)
    }

    const utmTerm = to.query.utm_term || ''
    if (utmTerm) {
      Cookies.set('utmTerm', utmTerm, option)
    }

    const utmContent = to.query.utm_content || ''
    if (utmContent) {
      Cookies.set('utmContent', utmContent, option)
    }
  },

  /**
   * Skip navigation guard on signup success page
   *
   */
  redirectToSignupSuccess(to, from) {
    if (
      to.name === 'SignUpSuccessPage' &&
      (from.name === 'LpEn' || from.name === 'LpJp')
    )
      return true

    return false
  }
}
