export default {
  license: {
    edit: '資格を編集',
    title: '資格',
    add: 'さらに追加',
    dialog: {
      exists: '資格はすでに存在します。',
      title: '資格を追加する',
      form: {
        name: '資格名',
        year: '資格取得年'
      }
    },
    success: 'ライセンス/認証が正常に保存されました'
  },
  experiencedJobs: {
    title: '経験した職種',
    subtitle: '経験職種',
    placeholder: 'あなたが経験した職種を選択してください',
    placeholder1: '職種を追加する',
    dialog: '職種を検索する',
    experiencePlaceholder: '経験を選択してください',
    edit: '経験した職種を編集する'
  },
  technicalSkill: {
    title: '技術スキル',
    subtitle: 'ITスキル',
    placeholder: 'あなたのスキルを選択してください',
    placeholder1: 'スキルを追加する',
    dialog: 'スキルを検索する',
    featured: '経験分野',
    itSkill: 'スキル',
    edit: '技術スキルを編集',
    socialProfile: 'アカウントを接続する'
  },
  stepOne: {
    success: '経験した職種が保存されました。',
    title: 'ご経験について教えていただけますか？',
    body: '多くの情報を入力すると、{breakpoint} 多くの企業からスカウトが届きます'
  },
  currentWorkStatus: {
    title: '現在の就業状況',
    edit: '現在の就業状況を編集',
    info: '現在の就業状況を選んでください。就業経験がない場合は「その他」を選んでください。',
    form: {
      label: '雇用状況',
      placeholder: '雇用状況を選択してください'
    }
  },
  latestAnnualIncome: {
    title: '現在の年収',
    currency: {
      label: '年収の通貨',
      placeholder: '通貨を選択'
    },
    incomeRange: {
      label: '年収',
      about: '約',
      placeholder: 'おおよその金額を選択してください'
    }
  },
  stepTwo: {
    success: '現在の作業状況/現在の就業状況・最新の年収が保存されました'
  },
  workExperience: {
    title: '経歴の詳細',
    subtitle: 'リストは経歴の新しいもの順に自動的にソートされます。',
    add: '経験企業を追加する',
    current: '現行',
    previous: '以前の会社',
    period: '期間',
    workDetail: 'プロジェクトの詳細/作業の詳細',
    detail: {
      workType: '雇用形態',
      industry: '業界',
      jobTitle: '役職名',
      companySize: '会社（従業員）の規模'
    },
    remove: {
      title: 'この職歴を削除',
      body: 'この職歴を削除してもよろしいですか？',
      removing: '職務経験を削除します。',
      success: '職歴の削除に成功しました'
    },
    edit: '作業編集',
    editMode: '職歴の編集',
    summary: '職歴の簡単な説明',
    seeMoreProjects: '他のプロジェクトを見る',
    form: {
      summary: {
        title: '経歴の概要',
        label: '経験のまとめ',
        placeholder: '職務経験を書いて、企業にアピールしましょう。'
      },
      company: '職務経歴',
      formCompany: '経験企業',
      experienceCompany: '企業',
      companyName: '会社名',
      typeCompanyName: '会社名を入力してください',
      industry: '業界',
      selectIndustry: '業界を選択してください',
      japaneseCompany: '日本での勤務経験',
      employmentType: '雇用形態',
      selectEmploymentType: '雇用形態を選択してください',
      projectWork: '具体的な仕事内容',
      jobTitle: '職名',
      typeJobTitle: '役職名を入力してください',
      companySize: '会社（従業員）の規模',
      selectCompanySize: '会社（従業員）の規模を選択してください',
      workDetail: 'プロジェクトの詳細/作業の詳細',
      itSkills: '使用ITスキル',
      roleSize: '役割/規模',
      period: '期間',
      from: '開始年月',
      to: '終了年月',
      periodPlaceholder: 'YYYY/MM',
      current: '現在の仕事',
      selectTemplate: 'テンプレートを選択',
      useTemplate: 'テンプレートを使用',
      addProject: '項目を追加',
      limit: '少なくとも200語または1,000文字',
      confirmMsg: 'テンプレートの内容が上書きされます。よろしいですか？',
      validations: {
        company: {
          required: '会社名は必須です'
        },
        industry: {
          required: '業界が必須です'
        },
        companySize: {
          required: '会社（従業員）の規模は必須項目です'
        },
        employmentType: {
          required: '雇用形態は必須項目です'
        },
        jobTitle: {
          required: '役職は必須です'
        },
        workDetail: {
          required: 'プロジェクトの詳細/作業の詳細を入力してください',
          maxLength: 'プロジェクトの詳細/作業の詳細は2000文字以内で入力してください。',
          notIn: 'この項目はテンプレート文のまま利用できません'
        },
        itSkill: {
          maxLength: '使用ITスキル は2000文字以内で入力してください。'
        },
        roleSize: {
          maxLength: '役割/規模は2000文字以内で入力してください。'
        },
        from: {
          required: '開始年月が必須です'
        },
        to: {
          required: '終了年月が必須です'
        },
        summary: {
          required: '要約が必要です',
          maxLength: '概要は10000文字を超えてはいけません'
        },
        workExperienceRequired: {
          title: '必要な実務経験',
          body: '少なくとも1つの実務経験を追加してください'
        },
        keywordValidations: {
          head1: 'こちらはテンプレート文です',
          head2: '以下を参考にご経験をご入力ください',
          head3: 'This is a template',
          head4: 'Please fill in your experience with below reference',
          dateFormat1: 'xx/20xx',
          dateFormat2: 'yy/20yy',
          dateFormat3: '〇〇/20xx',
          dateFormat4: '〇〇/20yy'
        }
      },
      templates: [
        {
          title: 'General',
          content:
          `※こちらはテンプレート文です
  以下を参考にご経験をご入力ください

  ・プロジェクト概要
  プロジェクトとエンジニアをマッチさせるwebアプリの開発
  (〇〇/20xx~〇〇/20yy)

  ・背景
  .NET、SQLサーバーとOracleから移行したアメリカのシステムに基づくWebサービスです。
  移行に伴い、様々な新しい機能も追加されてます。
  リモートデータベースしたデータの同期は、
  その一つでありOAuth XMLデータ交換をベースにしてます。

  ・主な役割
    ・フロントとバックエンドサイドのWebアプリの設計、開発
  そしてテストを行うと同時に、メールでの情報報告と
  「Spring Boot」「Java」「React」「Express」「AWS」の
  スキルセットの提示をプロジェクトで行いました。
    ・「DOCX」「DOC」「XLSX」「XLS」「PDF」 を解析するという
  課題に向けてのアイデア提案と実際のスキルシートから
  実装を検証しました。
    ・Web ページの読み込み時間の代わりに
  サーバーの初期化ステップでデータを読み込みをし、
  Web ページの読み込み時間を14分から10秒に短縮させました。`,
          usedITSkills: `.NET
SQL server
Oracle`,
          roleOrSize: `役割：リーダー
開発チーム：5名`
        },
        {
          title: 'サーバー・DB エンジニア',
          content:
          `※こちらはテンプレート文です
以下を参考にご経験をご入力ください

プロジェクト概要
顧客の業務拡大に伴う、ICT運用の改善プロジェクト
(xx/20xx~yy/20yy)

背景
本社内にあった運用システムをデータセンターに移行。
一部システムはAWSクラウドに移行し、ICT運用の改善を図りました。

・主な役割
  ・サーバ移管プランの作成のため、顧客の運用コスト削減と
持続可能なICT運用体制を考えたシステム設計で提案を支援
  ・サブリーダーとして、複数の協力会社と連携して
データセンターの構築チームとの納期調整や進行フロー調整を施行
・クラウド連携の経験によるAWSにおける
基本的な設計、開発についての知識習得`,
          usedITSkills: `PHP
Java
AIX
Windows
SQL Server
Oracle`,
          roleOrSize: `役割：サブリーダー
プロジェクトチーム：10名`
        },
        {
          title: 'システムエンジニア(SE)',
          content:
          `※こちらはテンプレート文です
以下を参考にご経験をご入力ください

プロジェクト概要
営業業務の実績、予算、顧客などを一元管理する
営業支援システムの開発プロジェクト
(〇〇/20xx~〇〇/20yy)

背景
業務効率化を目的とした既存勘定系システムの改修と
追加機能の導入および運用

主な役割
  ・顧客へのヒアリングから、随時システム改善を想定して
ソースコードを書き換えやすいように設計
  ・本番環境でのトラブル発生防止のため、
詳細にテスト設計し、エラーの解消を徹底
・顧客からの問い合わせ、トラブル対応`,
          usedITSkills: `PHP
Java
AIX
Windows
SQL Server
Oracle`,
          roleOrSize: `役割：開発者
開発チーム：50名`
        }
      ]
    }
  },
  stepThree: {
    success: '職務経験情報が保存されました。'
  },
  education: {
    title: '教育',
    success: '学歴が保存されました',
    add: '学歴を追加する',
    remove: {
      button: '教育を削除',
      body: 'この学歴情報を削除しますか'
    },
    form: {
      school: '学歴',
      schoolUrl: '学校のURL',
      degree: '学位・修了証',
      major: '専攻',
      location: '所在地',
      start: '入学',
      end: '卒業',
      current: '在学中',
      term: '期間'
    }
  },
  basicPersonalInformation: {
    title: '基本的な個人情報',
    success: '基本個人情報が保存されました',
    form: {
      firstName: '名',
      lastName: '姓',
      furiganaFirst: 'ふりがな名',
      furiganaLast: 'ふりがな姓',
      furiganaLabel: '日本語の「フリガナ」表記がわかる方のみ',
      dateOfBirth: '生年月日',
      gender: '性別',
      nationality: '国籍',
      prefecture: '現在の居住都道府県',
      city: {
        label: '今住んでいる都市',
        autocomplete: {
          placeholder: '入力して都市を検索します',
          searching: '検索中',
          noMatch: '一致する都市がありません',
          noMatchWithCountry: '選択した国で'
        }
      },
      country: '今住んでいる国',
      streetAddress: '現住所',
      workPermission: '日本の就労許可（ビザ）',
      visaExpirationDate: 'ビザの有効期限',
      photo: '写真',
      photoUpload: '写真のアップロード',
      photoRemove: '写真を削除',
      noPhoto: '写真なし',
      profileMovie: 'プロフィール動画',
      videoUpload: '動画をアップロードする',
      videoRemove: 'ビデオを削除する',
      noVideo: 'ビデオなし',
      agreement: {
        termsOfUse: '利用規約',
        personalInfo: '個人情報の取り扱い（会員情報）',
        first: '同時に転職支援サービスG Talentへも登録する。（サービスお申し込みにあたり、',
        second: '及び',
        last: 'への同意が必要となります。）',
        bold: '同時に転職支援サービスG Talentへも登録する。'
      }
    },
    hints: {
      name: 'パスポート表記のアルファベットで入力してください。'
    },
    validations: {
      invalidFileFormat: 'ファイルは画像（.jpg、.jpeg、.png）である必要があります。',
      invalidFileSize: '最大ファイルサイズは5MBまでです。',
      removeConfirmation: '写真を削除してもよろしいですか？',
      invalidVideoFormat: 'ファイルはビデオ（.mp4、.mov、.qt、.avi、.webm、.wmv、.mpeg4、.asf）である必要があります。',
      invalidVideoSize: '最大ファイルサイズは50MBまでです。',
      removeVideoConfirmation: 'この動画を削除してもよろしいですか？',
      yes: 'はい',
      no: 'いいえ'
    }
  },
  fileUpload: '自動入力',
  lessThan: '{count}文字以内である必要があります'
}
