export default {
  profile_panel_title: 'プロフィール完成状況',
  profile: 'プロフィール',
  skill_and_experience: 'スキル／経験',
  preference: '希望',
  complete: '100%',
  profile_update: 'プロフィール更新',
  recommended_jobs: 'おすすめポジション',
  recommended_job_description: '"あなたのスキルや経験からマッチしやすいポジションをおすすめします”',
  saved_search: '保存した検索条件',
  new_search: '新規検索',
  my_searches: '保存条件',
  scout_panel_title: '企業からのスカウト',
  unread_message: '未読メッセージなし',
  view_all: 'すべてを見る',
  pinned_panel_title: 'あなたを「気になる」企業とポジション',
  information_panel_title: 'GitTapからの情報',
  recommened_jobs_more: 'もっと',
  required_skill: '必要なスキル',
  scouted_label: 'おめでとうございます！スカウトが届いています！',
  pinned_label: '企業からの気になる',
  interested_label: '企業から「興味あり」が届きました',
  left_panel: {
    saved_search: '保存した検索条件',
    skill_search: 'あなたのスキルから検索',
    position_search: 'あなたの職種から検索'
  }
}
