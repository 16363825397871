export default {
  /**
   * Services Configuration
   */
  services: {
    /**
     * ===========================================
     * Auth Service Configuration
     * ===========================================
     *
     */
    auth: {
      url: process.env.VUE_APP_AUTH_URL,
      api_client_id: process.env.VUE_APP_API_CLIENT_ID,
      api_client_secret: process.env.VUE_APP_API_CLIENT_SECRET
    },
    /**
     * ============================================
     * Customer Service Configuration
     * ============================================
     *
     */
    customer: {
      url: process.env.VUE_APP_CUSTOMER_URL
    },
    /**
     * ============================================
     * Common Service Configuration
     * ============================================
     */
    common: {
      url: process.env.VUE_APP_COMMON_URL
    },
    /**
     * ===========================================
     * Visitor Page Configuration
     * ===========================================
     *
     */
    visitor: {
      url: process.env.VUE_APP_VISITOR_URL
    }
  },

  /**
   * Customer type ID
   */
  CS_TYPE: 1,

  /**
   * Types of Company Image
   */
  LOGO_IMAGE: 1,
  COVER_IMAGE: 2,
  APPEAL_TOP_IMAGE: 3,
  APPEAL_MIDDLE_IMAGE: 4,
  APPEAL_BOTTOM_IMAGE: 5,

  /**
   * Cookies Setup
   */
  COOKIES: {
    LANGUAGE_KEY: process.env.VUE_APP_LANGUAGE_COOKIE_KEY,
    DOMAIN: process.env.VUE_APP_COOKIE_DOMAIN
  },

  SOCIALS: {
    GOOGLE: {
      CLIENT_ID: process.env.VUE_APP_GOOGLE_CLIENT_ID
    }
  }
}
