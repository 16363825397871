import api from '../index'

const {
  http,
  show
} = api

/**
 * ===================
 * Job Posting API
 * ===================
 */
export default {
  baseUrl: `${process.env.VUE_APP_CLIENT_URL}/api/v1`,
  url: 'job-postings',
  http,
  show,

  /**
   * Get all job postings.
   *
   */
  search (params) {
    return this.http(this.baseUrl)
      .post('customer-job-postings/search', params)
  },

  /**
   * Get all job postings.
   *
   */
  searchSimilar (params) {
    return this.http(this.baseUrl)
      .get('/customer-job-postings/similar-to-applied', { params })
  },

  /**
   * Get my page job postings.
   *
   */
  myPage (params) {
    return this.http(this.baseUrl)
      .post('customer-job-postings/my-page', params)
  },

  /**
   * Get detailed job postings.
   *
   */
  details (params) {
    return this.http(this.baseUrl)
      .get(`${this.url}/details`, { params })
  },

  /**
   * Get recommended job postings.
   *
   */
  recommended (params) {
    return this.http(this.baseUrl)
      .post(`${this.url}/recommended`, { params })
  }
}
