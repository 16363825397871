export default {
  apply: '応募する',
  ok: 'はい',
  add: '追加',
  cancel: 'キャンセル',
  close: '閉じる',
  done: '完了',
  edit: '編集する',
  gotIt: '了解',
  remove: '削除する',
  required: '必須',
  save: '保存する',
  update: '更新',
  saveAndNext: '保存して次へ',
  logout: 'ログアウト',
  back: '戻る',
  next: '次へ',
  noDisplay: '該当するものがありません。',
  unsaved: '変更は保存されませんでした',
  abort: '保存せずに終了しますか？',
  leave: 'このページを離れる',
  success: '正常に保存',
  removeSuccess: '正常に削除されました',
  seeMore: '続きを見る',
  seeAll: 'すべてを見る',
  to: 'に',
  'The page you are looking for is not found': 'お探しのページが見つかりません。',
  'Please direct to': 'に遷移してください',
  'home page': 'ホームページ',
  thankYou: 'ありがとうございました',
  send: '送信する',
  parsing: '解析中',
  fileUploadedAndParsed: 'ファイルのアップロードおよび解析が完了しました。',
  unableToParseContactSupport:
    'アップロードしたファイルを解析できないようです。正しいCVファイルを使用するか、サポートにお問い合わせください。',
  search: '検索',
  select: '選択する',
  submit: '送信する',
  plusCountOthers: '+{count} その他',
  orMore: '以上',
  orLess: '以下',
  cancelDialog: {
    title: '動画面接をキャンセルしますか？',
    content1: 'キャンセルすると、',
    content2: '回答は保存されません。',
    proceed: '続行',
    no: 'いいえ'
  },
  finished: '完了しました',
  failed: '失敗しました',
  uploading: 'アップロード中',
  cancelled: 'キャンセル',
  unknown: 'わからない',
  and: 'そして',
  yes: 'はい',
  no: '番号',
  optional: '任意',
  readMore: '続きを読む',
  new: 'New',
  jobs: '求人',
  jp_currency_unit: '万円',
  years: '1年未満 | 1年 | {count}年',
  homeButton: 'ホームページ',
  confirm: '入力内容を確認する',
  delete: '削除',
  selected: '選択中',
  closeDialog: 'Close',
  deleteConfirmation: 'この項目を削除しますか？',
  saveNoticeAfterDelete: '削除後に変更を保存してください。',
  unsavedChangesTitle: '保存されていない項目があります。',
  unsavedChangesBody: 'よろしいですか？',
  multipleChoiceAllowed: '複数選択可',
  searching: '検索中...',
  noDataAvailable: 'データなし',
  reset: '条件リセット',
  showResult: '検索',
  csFilter: 'フィルター',
  fileNotSupported: 'ファイルはサポートされていません',
  savedFilterSelect: '保存した条件で検索',
  saveCurrentFilter: '検索条件を保存',
  myFilter: '保存した条件',
  saveFilterName: '保存する条件名を入力',
  resetAll: '条件リセット'
}
