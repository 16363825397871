export default {
  terms: {
    text: 'Terms of Use'
  },
  mainHeader: {
    text1: 'In relation to the recruitment support service called “GitTap” (hereinafter, the “Services”) operated by Bizmates, Inc. (hereinafter, the “Company”), these terms of use (hereinafter, these “Terms of Use”) set forth the terms of provision of the Services and the relationship of rights and obligations between the Company and the users of the Services.',
    text2: 'Upon using the Services, you are required to read the entire text of these Terms of Use and to give your consent to these Terms of Use.'
  },
  article1: {
    title: 'Article 1 Application of these Terms of Use',
    info1: 'The purposes of these Terms of Use are to set forth the terms of provision of the Services and the relationship of rights and obligations between the Company and the users of the Services, and these Terms of Use shall apply to any and all relationships related to the use of the Services between the Company and the users of the Services.',
    info2: 'The rules related to the use of the Services which the Company displays upon the Company’s website shall constitute a part of these Terms of Use.',
    info3: 'In the event that the contents of these Terms of Use differ from the rules referred to in the preceding paragraph and any other explanations, etc. of the Services other than those in these Terms of Use, the provisions of these Terms of Use shall apply with priority.',
    info4: 'Any person using the Services shall use the Services in accordance with these Terms of Use.'
  },
  article2: {
    title: 'Article 2 Definitions',
    headerInfo: 'The following terms used in these Terms of Use shall each have the following meanings.',
    info1: '“Services Use Agreement” means the agreement for use of the Services executed between the Company and the registered users with these Terms of Use being the contractual terms.',
    info2: '“Registered Users” means the persons registered as the users of the Services in accordance with Article 3 (Registration of the Services).',
    info3: '“Registered Information” means the contents (including but not limited to sentences, images, movies, messages and other data) submitted or otherwise transmitted by the Registered Users using the Services.',
    info4: '“Registered Companies” means the companies registered as the recruiting companies of the Services upon separately contracting with the Company.',
    info5: '“Recruitment Information” means the contents (including but not limited to sentences, images, movies, messages and other data) submitted or otherwise transmitted by the Registered Companies using the Services.',
    info6: '“Matching” means the cases falling under any of the following.',
    list1: 'In the case that a Registered User takes any action showing its interest in the Recruitment Information of a specific Registered Company in the Services, and such Registered Company responds thereto;',
    list2: 'In the case that a Registered Company attempts to contact a specific Registered User in the Services, and such Registered User responds thereto.'
  },
  article3: {
    title: 'Article 3 Registration of the Services',
    info1: 'Any person desiring to use the Services may apply for registration of the Services towards the Company by giving his/her consent to the contents of these Terms of Use and providing to the Company the Registered Information determined by the Company in accordance with the procedures determined by the Company.',
    info2: 'The Services Use Agreement shall be formed between a Registration Applicant (defined below) and the Company at the time that the Company, upon receiving the application referred to in the preceding paragraph, registers the use of the person applying for user registration of the Services (hereinafter, the “Registration Applicant) and notifies to such Registration Applicant the completion of the registration.',
    info3: 'In the case that it falls under any of the following, the Company may reject a registration application referred to in Article 3.1 at the Company’s judgment, in which case the Company shall not be obliged to make any disclosure whatsoever of the reasons therefor.',
    info4: 'The Company shall not collect from a Registered User any usage fees, charges or other consideration for the use of the Services by the Registered User, except where Registered Users apply for the use of paid services provided by the Company.',
    list1: 'The Registration Applicant does not exist;',
    list2: 'All or a part of the Registration Information is false, erroneous or missing;',
    list3: 'The Registration Applicant applies for registration not in accordance with the methods referred to in Article 3.1;',
    list4: 'The Registration Applicant previously breached these Terms of Use or the Services Use Agreement, or the Company has previously deleted his/her registration of the Services;',
    list5: 'The Registration Applicant has already registered for the Services by a separate account, etc.;',
    list6: 'The Registration Applicant is a minor or any other person requiring the consent of a legal representative, and the consent, etc. of the legal representative has not been obtained;',
    list7: 'The Company deems that the Registration Applicant is an organized crime group or its related person or any other anti-social force;',
    list8: 'The Company otherwise deems that the Registration Applicant is unsuitable as the user of the Services.'
  },
  article4: {
    title: 'Article 4 Changes to the Registered Information',
    info1: 'If there are any changes to the Registered Information, a Registered User shall notify the Company without delay of such changed matters in accordance with the method determined by the Company.'
  },
  article5: {
    title: 'Article 5 ID and Password',
    info1: 'The Company shall issue and grant an ID and password to a Registered User.',
    info2: 'A Registered User shall appropriately use and manage the ID and password related to the Services at his/her own responsibility, and shall not cause a third party to use the same, nor lend, assign, change name, nor sell, etc. the same to any third party.',
    info3: 'Any liability related to the damages occurred due to insufficient management, misuse, or use of third party, etc. of the ID or password shall be borne by the Registered User.',
    info4: 'In the event that it is deemed necessary for the operation of the Services, the Company shall be entitled to use the ID and password of a Registered User and to view the Registered Information of the Registered User.'
  },
  article6: {
    title: 'Article 6 Prohibited Matters',
    info1: 'Upon using the Services, a Registered User shall not perform any of the following acts or any acts similar thereto or any acts which the Company deems that there is the likelihood thereof.',
    info2: 'With respect to any and all damages occurring to the Company or other Registered Users of the Services, the Registered Companies or other third parties due to the acts listed in the preceding paragraph, a Registered User shall bear the liability to compensate, and the Company shall be entitled to take any and all civil and criminal legal means.',
    list1: 'Acts to register false information (including but not limited to name, address, nationality, age, education, history, qualifications, etc. of the Registered User);',
    list2: 'Acts to directly contact a Registered Company not through the Services (provided, however, that this shall not apply after the determination of a recruitment interview with the Registered Company);',
    list3: 'Acts to reproduce or publish, or otherwise use beyond the scope of the purposes of the Services, or divulge or disclose to any third party, any information learned through the Services or the Registered Company;',
    list4: 'Acts to be absent from the interview with the Registered Company without reasonable grounds;',
    list5: 'Acts to cooperate with acts by the Registered Company which breach or conflict with the contracts between the Company and the Registered Company (such as the concealment of the fact of hiring by the Registered Company), or laws and regulations or public morals;',
    list6: 'Acts not to answer any contact from the Company without reasonable grounds;',
    list7: 'Acts to assign the rights to use the Services to any other person or to cause any other person to use the Services, or to change to the name of any other person;',
    list8: 'Acts to damage or impair the trust of the Services, or to obstruct the operations of the Services;',
    list9: 'Acts to advertise, solicit or sell upon the Services, to which the Company does not give prior consent;',
    list10: 'Acts to damage or disadvantage the other Registered Users of the Services, the Registered Companies, or other third parties or the Company;',
    list11: 'Acts to obstruct the Company’s customer support services such as abusive language towards the customer support services;',
    list12: 'Acts to use the same account by multiple persons, or to use multiple accounts by the same person;',
    list13: 'Acts to record the sound or image of, and disclose to a third party, the usage status of the Services (provided, however, that this shall not apply if the Company gives its prior consent);',
    list14: 'Acts with the purposes to meet a person of the opposite sex whom she/he does not know;',
    list15: 'Acts to infringe upon the intellectual property rights, portrait rights, privacy rights, reputation or other rights or interests of the other Registered Users of the Services, the Registered Companies, or other third parties or the Company;',
    list16: 'Acts to breach laws and regulations or acts related to crime;',
    list17: 'Acts in contravention of public morals;',
    list18: 'Other acts deemed inappropriate by the Company.'
  },
  article7: {
    title: 'Article 7 Handling of Registered Information, etc.',
    info1: 'The information gathered by the Company pursuant to the Services shall include the following depending upon the gathering method.  A Registered User shall give his/her consent to the Company’s gathering of the information listed below.',
    info1list1: 'Registered Information provided by the Registered User',
    list1detail1: 'Name;',
    list1detail2: 'Information related to profile such as date of birth and history;',
    list1detail3: 'Contact related information such as email address, telephone number and address;',
    list1detail4: 'Still images or movie information including portrait of the Registered User;',
    list1detail5: 'Information inputted or transmitted by the Registered User by the input form or other method determined by the Company;',
    info1list2: 'Information gathered by the Company upon the Registered User’s use of the Services',
    list2detail1: 'Terminal information',
    list2detail2: 'Cookie information (action history information such as URLs and page movement, etc. of the website pages visited by the Registered User)',
    info2: 'The handling of the Registered Information of the Registered User by the Company shall be in accordance with the “Privacy Policy” set forth by the Company, and the Registered User shall give his/her consent to the Company’s handling of the Registered Information of the Registered User in accordance with such Privacy Policy.',
    info3: 'The Company may provide to a third party the information related to the Registered User gathered in accordance with Article 7.1 for the following purposes, and the Registered User shall give his/her consent thereto.',
    info3list1: 'To provide the Registered Information set forth in Article 7.1.(1) to the Registered Company in accordance with the following stages for the purposes of the Registered Company performing recruitment activities, etc.',
    info3detail1: 'To provide the information referred to in Article 7.1.(1).b to the Registered Company at the stage of completion of user registration of the Services pursuant to Article 3;',
    info3detail2: 'To provide the information referred to in Article 7.1.(1).a to the Registered Company at the stage of Matching of the Registered User and a specific Registered Company or the stage of application by a Registered User towards a specific Registered Company through the Services;',
    info3detail3: 'To provide the information referred to in Article 7.1.(1).c to the Registered Company at the stage of determination of a Registered User to have a recruitment interview with the Registered Company.',
    info3list2: 'To provide the information set forth in Article 7.1.(2) to the business persons, etc. distributing action targeting advertisements for the purposes of delivering useful information to the Registered Users by distributing optimal advertisements suited and customized for the needs or interests of the Registered Users.'
  },
  article8: {
    title: 'Article 8 Provision of the Services',
    info1: 'A Registered User shall give his/her prior consent to the Company’s contacting the Registered User by messaging functions of the Services and email, etc. upon providing the Services.',
    info2: 'A Registered User shall give his/her prior consent to the possibilities of being contacted by a Registered Company by messaging functions of the Services, email, mail or telephone, etc.',
    info3: 'The Company shall not be liable to compensate for the damages incurred by a Registered User unless there is willful misconduct or gross negligence on the part of the Company, in relation to any and all damages occurring from the contents listed in each of the above.'
  },
  article9: {
    title: 'Article 9 Suspension, etc. of the Services',
    headerInfo: 'In the event that it falls under any of the following, the Company shall be entitled to suspend or terminate the provision of all or a part of the Services without giving prior notice to the Registered User.',
    info1: 'Emergency performance of inspection or maintenance services of the computer system related to the Services;',
    info2: 'Inability to operate the Services due to malfunction, erroneous operation, excessive access concentration, unauthorized access, hacking, etc. of computer or telecommunication lines, etc.',
    info3: 'Inability to operate the Services due to force majeure such as earthquake, lightening, fire, typhoon, water disaster, electricity outage, epidemic, Acts of God;',
    info4: 'The Company otherwise deems it necessary to suspend or terminate the provision of all or a part of the Services.'
  },
  article10: {
    title: 'Article 10 To Whom the Rights Belong',
    info1: 'Any and all intellectual property rights related to the Company’s website and the Services shall belong to the Company or the person granting license to the Company, and the permission of use of the Services pursuant to these Terms of Use does not mean the permission of use of the intellectual property rights of the Company or the person granting license to the Company in relation to the Company’s website or the Services.',
    info2: 'The Registered User represents and warrants to the Company that he/she has the lawful rights to submit or otherwise transmit the Registered Information and that the Registered Information does not infringe upon any rights of a third party.',
    info3: 'The Registered User grants to the Company a worldwide, nonexclusive, free and sublicensable license for the use, reproduction, distribution, preparation of derivative copyrighted works, indication and implementation of the still images and movie information set forth in Article 7.1.(1).d.',
    info4: 'The Registered User gives his/her consent that he/she shall not exercise copyright holder moral rights towards the Company or any person succeeding, or receiving permission of, the rights by the Company.',
    info5: 'The Company shall be entitled to prepare data such as figures and statistics which does not identify a specific individual from the Registered Information, and the ownership and copyright as well as any other rights and interests of such data shall belong to the Company, and the Company shall be entitled to use such data without any limitation.'
  },
  article11: {
    title: 'Article 11 Registration Deletion, etc.',
    headerInfo: 'In the event that a Registered User falls under any of the following, the Company shall be entitled to delete or hide the Registered Information, temporarily suspend the Services in relation to such Registered User, or delete the registration as the Registration User, at the Company’s judgment without being required to give any notice or demand.',
    info1: 'He/she breaches any provisions of these Terms of Use;',
    info2: 'He/she falls under any of the items of Article 3.3;',
    info3: 'The Company otherwise determines that it is inappropriate to continue the usage of the Services or the registration as a Registered User.'
  },
  article12: {
    title: 'Article 12 Termination by the Registered User',
    info1: 'A Registered User shall be entitled to terminate these Terms of Service in accordance with the procedures determined by the Company.'
  },
  article13: {
    title: 'Article 13 Changes to the Contents of and Termination of the Services',
    info1: 'The Company shall be entitled to change the contents of or to terminate the provision of the Services at the Company’s convenience.',
    info2: 'In the event that the Company terminates the provision of the Services, the Company shall give prior notice to the Registered Users.'
  },
  article14: {
    title: 'Article 14 Exemption of Liability',
    info1: 'The Company shall not be liable to compensate a Registered User for the damages incurred thereby due to or in relation to the use of the Services unless there is willful misconduct or gross negligence on the part of the Company.',
    info2: 'With respect to communications and disputes, etc. occurring between a Registered User and other Registered Users, Registered Companies or any other third party due to or in relation to the use of the Services, the Registered User shall resolve the same at its own responsibility and the Company shall not be liable.',
    info3: 'The Company does not warrant, expressly or impliedly, the matters listed in the following.  The Registered User shall take prior measures required to prevent any expenses and occurrence of damages due to the absence of these warranties at his/her own responsibility and expense.',
    info4: 'The Company does not warrant, expressly or impliedly, the matters listed in the following.  The Registered User shall take prior measures required to prevent any expenses and occurrence of damages due to the absence of these warranties at his/her own responsibility and expense.',
    list1: 'Recruitment Information of the Registered Company is accurate, complete, safe and valid;',
    list2: 'The remarks and acts of the Registered Company are lawful, moralistic, trustworthy and accurate;',
    list3: 'Interview or employment by the Registered Company shall be conducted;',
    list4: 'Registered Information of the Registered User is accurate, complete, safe and valid;',
    list5: 'The use of the Services by the Registered User or the Registered Company conform with the laws and regulations or the internal regulations of industry organizations, etc., applicable to the Registered User or the Registered Company;',
    list6: 'The use of the Services by the Registered User and the Registered Company do not infringe upon the rights of any third party;',
    list7: 'There are no technical inaccuracies, mistaken wordings or errors to the contents of the Services;',
    list8: 'The system of the Services have complete security, does not have any bugs, or cannot be infected by viruses due to its use;',
    list9: 'Recruitment Information recommended and indicated to the Registered User pursuant to the Matching disposition by the Company’s unique algorithm is useful for the Registered User;',
    list10: 'The contents of the external sites linked from the information provided by the Services are lawful, moralistic, trustworthy and accurate.'
  },
  article15: {
    title: 'Article 15 Confidentiality',
    info1: 'A Registered User shall maintain in confidence any nonpublic information which the Company disclosed to the Registered User in relation to the Services with the request that it be maintained confidential, with the exception of the cases in which the Company gives prior written consent.'
  },
  article16: {
    title: 'Article 16 Changes these Terms of Use, etc.',
    info1: 'The Company shall be entitled to change these Terms of Use if it deems necessary. In the event of changing these Terms of Use, the Company shall notify on the Company website the fact that it will change these Terms of Use as well as the contents of these Terms of Use after change and the effectuation timing; provided, however, that if the Company makes changes that laws and regulations require them to obtain the consent of users, the Company shall obtain such consent in the manner that it deems appropriate. A user shall be deemed to have agreed to the changes of these Terms of Use if such user does not take procedures to terminate these Terms of Use within the period set by or the effectuation timing notified by the Company , except in cases where such effect is denied by laws and regulations.'
  },
  article17: {
    title: 'Article 17 Sending Out Information',
    info1: 'The Company shall be entitled to distribute information to users such as notifications, advertisements and questionnaires, etc. by email.'
  },
  article18: {
    title: 'Article 18 Assignment, etc. of the Status under the Services Use Agreement ',
    info1: 'A Registered User shall not be entitled to assign, transfer, create security over or perform other disposition for his/her status under the Service Use Agreement or his/her rights and obligations under these Terms of Use, without the Company’s prior written consent.',
    info2: 'In the event that the Company assigns to another company the business related to the Services (including company division and any and all cases in which the business transfers), the Company shall be entitled to assign to the assignee of such business transfer the status under the Service Use Agreement, the rights and obligations under these Terms of Use as well as the Registration Information of the Registered Users and other customer information along with such business transfer, and the Registered Users shall be deemed to have given their prior consent in this paragraph for such assignment.'
  },
  article19: {
    title: 'Article 19 Severability',
    info1: 'Even if any provision or any part of these Terms of Use are deemed invalid or unenforceable due to consumer contract laws and other laws and regulations, etc., the remaining provisions of these Terms of Use or the remaining parts of the provisions deemed partially invalid or unenforceable shall continue to remain in full effect.'
  },
  article20: {
    title: 'Article 20 Exclusion of Anti-Social Forces such as Organized Crime Groups',
    info1: 'A Registered User represents and warrants the matters listed in the following both currently and in the future.',
    info2: 'In the event that it becomes evident that a Registered User falls under any of the above, the Company shall be entitled to delete the registration without giving any notice; provided, however, that the Company shall not be liable for the compensation of damages occurring to the user due to the deletion of registration under this paragraph, and the user must compensate any damages in the case that any occur to the Company.',
    list1: 'The Registered User is not an anti-social force;',
    list2: 'The Registered User shall not use anti-social forces nor perform any acts similar thereto;',
    list3: 'The Registered User shall not provide funds to anti-social forces nor perform any acts similar thereto;',
    list4: 'The Registered User shall not impair the reputation, etc. or obstruct business of, nor make unreasonable requests to, the Company by calling himself/herself an anti-social force, nor perform any acts similar thereto.'
  },
  article21: {
    title: 'Article 21 Notice',
    info1: 'Notices from the Company to a Registration Applicant or a Registered User shall be dispatched addressed to the email address and other contact registered at the time of the application of user application for the Services, and shall be deemed to have arrived at the time that it should normally arrive.'
  },
  article22: {
    title: 'Article 22 Effect After Termination of the Services Use Agreement',
    info1: 'Even in the event that the Services Use Agreement terminates, the provisions of Articles 6, 7, 10, 14, 15, 18, 19, 22 and 23 shall remain in effect.'
  },
  article23: {
    title: 'Article 23 Governing Law and Jurisdiction',
    info1: 'These Terms of Use shall be construed in accordance with the laws of Japan.',
    info2: 'Any and all disputes occurring in relation to the Services or these Terms of Use shall submit to the exclusive agreed jurisdiction of the Tokyo District Court at the first instance.'
  },
  date: {
    text: '[Revised on November 13, 2023]'
  }
}
