import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VRow,{attrs:{"justify":"center"}},[_c(VDialog,{attrs:{"value":_vm.confirmationDialog,"persistent":"","max-width":"512","scrollable":""}},[_c(VCard,{attrs:{"height":"279"}},[_c(VCardText,{staticClass:"text-center pa-3"},[_c(VRow,{staticClass:"fill-height",attrs:{"no-gutters":"","justify":"center","align":"center"}},[_c(VCol,{attrs:{"align-self":"center"}},[_c('p',{staticClass:"headline ma-0"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_vm._l((_vm.content),function(line,index){return [_c('p',{key:index,staticClass:"ma-0",class:{'body-pc': _vm.$vuetify.breakpoint.lgAndUp, 'body-sp': _vm.$vuetify.breakpoint.mdAndDown}},[_vm._v(" "+_vm._s(line)+" ")])]})],2)],1)],1),_c(VCardActions,{staticClass:"justify-center text-center",staticStyle:{"padding-bottom":"16px"}},[_c(VRow,{attrs:{"no-gutters":"","align":"center","justify":"center"}},[_c(VCol,{attrs:{"cols":"6","align-self":"center"}},[_c(VBtn,{staticClass:"custom-letter-spacing text-capitalize",staticStyle:{"border-radius":"2px"},attrs:{"color":"primary","height":"48","width":_vm.$vuetify.breakpoint.lgAndUp ? 232 : 148,"depressed":""},on:{"click":function($event){return _vm.closeAndAgree()}}},[_vm._v(" "+_vm._s(_vm.actionLabel)+" ")])],1),_c(VCol,{attrs:{"cols":"6","align-self":"center"}},[_c(VBtn,{staticClass:"custom-letter-spacing text-capitalize",staticStyle:{"border-radius":"2px"},attrs:{"color":"primary","outlined":"","height":"48","width":_vm.$vuetify.breakpoint.lgAndUp ? 232 : 148,"depressed":""},on:{"click":function($event){return _vm.closeAndDisagree()}}},[_vm._v(" "+_vm._s(_vm.cancelLabel)+" ")])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }