<template>
  <div v-if="toolbar">
    <AppToolbarDesktop v-if="$vuetify.breakpoint.lgAndUp" :unread-count="message_unread_count"
      :approach-unread-count="approachUnreadCount" :gtm-params="gtmParams" />

    <AppToolbarMobile v-else />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import AuthService from '@/services/auth'
import AppToolbarMobile from '@/components/Toolbar/AppMobile'
import AppToolbarDesktop from '@/components/Toolbar/AppDesktop'
import logger from '@/services/logger'

export default {
  components: {
    AppToolbarMobile,
    AppToolbarDesktop,
  },
  data() {
    return {
      gtmParams: {
        MyPage: {
          jobs: { page: 'MYTOP', part: 15 },
          approach: { page: 'MYTOP', part: 16 },
          message: { page: 'MYTOP', part: 17 },
          profile: { page: 'MYTOP', part: 18 },
        },
        JobSearch: {
          approach: { page: 'LOGJOB', part: 6 },
          message: { page: 'LOGJOB', part: 7 },
          profile: { page: 'LOGJOB', part: 8 },
          notification: { page: 'LOGJOB', part: 9 },
        },
        Pinned: {
          jobs: { page: 'PIN', part: 5 },
          message: { page: 'PIN', part: 6 },
          profile: { page: 'PIN', part: 7 },
        },
        Matched: {
          jobs: { page: 'MATCH', part: 3 },
          message: { page: 'MATCH', part: 4 },
          profile: { page: 'MATCH', part: 5 },
        },
        Applied: {
          jobs: { page: 'APPLY', part: 3 },
          message: { page: 'APPLY', part: 4 },
          profile: { page: 'APPLY', part: 5 },
        },
        Interested: {
          jobs: { page: 'INTR', part: 3 },
          message: { page: 'INTR', part: 4 },
          profile: { page: 'INTR', part: 5 },
        },
        Invisible: {
          jobs: { page: 'HIDE', part: 5 },
          message: { page: 'HIDE', part: 6 },
          profile: { page: 'HIDE', part: 7 },
        },
        Threads: {
          jobs: { page: 'MESS', part: 13 },
          approach: { page: 'MESS', part: 14 },
          profile: { page: 'MESS', part: 15 },
        },
      },
    }
  },

  computed: {
    ...mapState('core', ['toolbar']),
    ...mapState('approachList', ['unread']),
    ...mapState('applicant', ['auth']),
    ...mapState('messageNotifications', {
      message_unread_count: 'unread_count',
    }),

    /**
     * Get the approach unread count.
     *
     */
    approachUnreadCount() {
      try {
        return this.unread.total_count
      } catch (error) {
        logger.error(error)

        return 0
      }
    },

    authenticated() {
      return AuthService.isAuthenticated()
    },
  },

  mounted() {
    if (this.$route.path.includes('approach') || !this.authenticated) return
    this.fetchUnreadApproachCount()
  },

  methods: {
    ...mapActions('approachList', ['fetchUnreadApproachCount']),
  },
}
</script>
