<template>
  <v-bottom-navigation
    fixed
    grow
    height="64"
    active-class="primary--text"
  >
    <v-btn to="/jobs" @click="pushGtm('jobs')">
      <span
        class="text-nav"
        style="position: relative; top: 4px;"
        v-text="$t('bottomNav.jobs')"
      />
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 18.691 18.691"
      >
        <path
          id="search"
          d="M18.984-2.809,13.516-8.277v-.477a6.757,6.757,0,0,1-4,1.239A6.836,6.836,0,0,1,4.547-9.535,6.718,6.718,0,0,1,2.5-14.484a6.8,6.8,0,0,1,2.045-4.971A6.8,6.8,0,0,1,9.516-21.5a6.718,6.718,0,0,1,4.949,2.047,6.836,6.836,0,0,1,2.019,4.969,6.916,6.916,0,0,1-.5,2.4,8.8,8.8,0,0,1-.776,1.6h.515l5.469,5.469ZM14.516-8.691l4.469,4.469.793-.793L15.309-9.484h-.793Zm-5-11.809a5.754,5.754,0,0,0-4.264,1.752A5.754,5.754,0,0,0,3.5-14.484a5.678,5.678,0,0,0,1.75,4.238A5.793,5.793,0,0,0,9.516-8.516,5.691,5.691,0,0,0,13.407-9.94l.352-.306.01.01.281-.346a6.712,6.712,0,0,0,1-1.853,5.938,5.938,0,0,0,.437-2.05,5.793,5.793,0,0,0-1.731-4.266A5.678,5.678,0,0,0,9.516-20.5Zm0,11.016a4.842,4.842,0,0,1-3.541-1.459,4.842,4.842,0,0,1-1.459-3.541,4.842,4.842,0,0,1,1.459-3.541,4.842,4.842,0,0,1,3.541-1.459,4.842,4.842,0,0,1,3.541,1.459,4.842,4.842,0,0,1,1.459,3.541,4.842,4.842,0,0,1-1.459,3.541A4.842,4.842,0,0,1,9.516-9.484Zm0-9a3.825,3.825,0,0,0-2.834,1.166,3.825,3.825,0,0,0-1.166,2.834A3.825,3.825,0,0,0,6.682-11.65a3.825,3.825,0,0,0,2.834,1.166A3.825,3.825,0,0,0,12.35-11.65a3.825,3.825,0,0,0,1.166-2.834,3.825,3.825,0,0,0-1.166-2.834A3.825,3.825,0,0,0,9.516-18.484Z"
          transform="translate(-2.5 21.5)"
          fill="#1a1a1a"
        />
      </svg>
    </v-btn>

    <v-btn to="/approach" @click="pushGtm('approach')">
      <span
        class="text-nav"
        style="position: relative; top: 2px;"
        v-text="$t('bottomNav.approach')"
      />
      <v-badge
        color="#D62F2F"
        :content="approachUnreadCount"
        dot
        :value="!!approachUnreadCount"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 25.43 25.43"
        >
          <g
            id="グループ_1137"
            data-name="グループ 1137"
            transform="translate(-1765.167 -19415.898)"
          >
            <path
              id="パス_274"
              data-name="パス 274"
              d="M10.921,6.95V1.986h.993a1,1,0,0,0,.993-.993h0A1,1,0,0,0,11.914,0H1.986A1,1,0,0,0,.993.993h0a1,1,0,0,0,.993.993h.993V6.95A2.975,2.975,0,0,1,0,9.928H0v1.986H5.927v6.95l.993.993.993-.993v-6.95H13.9V9.928h0A2.975,2.975,0,0,1,10.921,6.95Z"
              transform="translate(1780.061 19416.605) rotate(45)"
              fill="none"
              stroke="#1a1a1a"
              stroke-width="1"
            />
          </g>
        </svg>
      </v-badge>
    </v-btn>

    <v-btn to="/messages" @click="pushGtm('message')">
      <span
        class="text-nav"
        style="position: relative; top: 2px;"
        v-text="$t('bottomNav.message')"
      />
      <v-badge
        color="#D62F2F"
        :content="unreadMessageCountFormat"
        :value="unreadCount"
        dot
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="24"
          height="24"
          viewBox="0 0 24 20"
        ><defs><clipPath id="a"><rect
          width="24"
          height="20"
          fill="none"
        /></clipPath></defs><g clip-path="url(#a)"><path
          d="M80.46,442.182a.477.477,0,0,1-.152-.025.433.433,0,0,1-.3-.406v-3.665a.457.457,0,0,1,.913,0v2.45l2.338-2.721a.475.475,0,0,1,.642-.063.415.415,0,0,1,.067.606l-3.148,3.665a.467.467,0,0,1-.354.159"
          transform="translate(-77.677 -425.631)"
          fill="#231f20"
        /><path
          d="M205.536,564.019h-12a.432.432,0,1,1,0-.862h12a.432.432,0,1,1,0,.862"
          transform="translate(-187.468 -547.684)"
          fill="#231f20"
        /><path
          d="M714.575,138.388h-.361a.432.432,0,1,1,0-.862h.361a1.471,1.471,0,0,0,1.509-1.426v-8.311a1.471,1.471,0,0,0-1.509-1.426.432.432,0,1,1,0-.862A2.361,2.361,0,0,1,717,127.789V136.1a2.36,2.36,0,0,1-2.422,2.288"
          transform="translate(-692.997 -122.053)"
          fill="#231f20"
        /><path
          d="M609.125,567.682a.467.467,0,0,1-.354-.159l-3.148-3.665a.415.415,0,0,1,.067-.606.475.475,0,0,1,.642.063l2.338,2.721v-2.45a.457.457,0,0,1,.913,0v3.665a.433.433,0,0,1-.3.406.48.48,0,0,1-.152.025"
          transform="translate(-587.909 -547.682)"
          fill="#231f20"
        /><path
          d="M17.928,0H2.422A2.361,2.361,0,0,0,0,2.288V10.6a2.361,2.361,0,0,0,2.422,2.288h.361v-.862H2.422A1.471,1.471,0,0,1,.913,10.6V2.288A1.471,1.471,0,0,1,2.422.862H17.928a1.471,1.471,0,0,1,1.509,1.426V10.6a1.471,1.471,0,0,1-1.509,1.426h-12v.862h12A2.361,2.361,0,0,0,20.35,10.6V2.288A2.361,2.361,0,0,0,17.928,0"
          fill="#231f20"
        /><path
          d="M224.341,208.537a.756.756,0,1,0-.8-.755A.781.781,0,0,0,224.341,208.537Z"
          transform="translate(-217.04 -201.339)"
          fill="#231f20"
        /><path
          d="M323.931,207.783h0a.8.8,0,1,0-.8.755.781.781,0,0,0,.8-.755"
          transform="translate(-312.957 -201.34)"
          fill="#231f20"
        /><path
          d="M422.722,207.783h0a.8.8,0,1,0-.8.755.781.781,0,0,0,.8-.755"
          transform="translate(-408.874 -201.34)"
          fill="#231f20"
        /></g></svg>
      </v-badge>
    </v-btn>

    <v-btn to="/profile-settings" @click="pushGtm('profile')">
      <span
        class="text-nav"
        style="position: relative; top: 5px;"
        v-text="$t('bottomNav.profile')"
      />
      <svg
        id="グループ_1388"
        data-name="グループ 1388"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        width="24"
        height="24"
        viewBox="0 0 22.931 22.931"
      >
        <defs>
          <clipPath id="clip-path">
            <rect
              id="長方形_2193"
              data-name="長方形 2193"
              width="22.931"
              height="22.931"
              fill="#1a1a1a"
            />
          </clipPath>
        </defs>
        <g
          id="グループ_1387"
          data-name="グループ 1387"
          clip-path="url(#clip-path)"
        >
          <path
            id="パス_463"
            data-name="パス 463"
            d="M11.465,6.094a4.324,4.324,0,0,1,1.908,8.2,6.787,6.787,0,0,1,4.773,5.54,10.708,10.708,0,1,0-13.363,0A6.787,6.787,0,0,1,9.557,14.3a4.323,4.323,0,0,1,1.908-8.2m6.749,14.64h0l-.036.025a11.4,11.4,0,0,1-5.039,2.048l-.02,0-.1.013-.059.008-.085.011-.058.007-.07.008-.086.009-.055.006-.1.009-.04,0-.117.01-.026,0-.118.008H12.2l-.128.007h-.023l-.131.006H11.9l-.134,0h-.01l-.138,0h-.307l-.138,0h-.01l-.134,0h-.012l-.131-.006h-.023l-.128-.007h-.019L10.6,22.9l-.026,0-.117-.01-.04,0-.1-.009-.054-.005-.086-.009-.069-.008-.06-.007-.082-.01-.062-.008-.095-.013-.02,0a11.4,11.4,0,0,1-5.038-2.048l-.037-.026h0a11.46,11.46,0,1,1,13.5,0M5.487,20.351a5.994,5.994,0,0,1,11.958,0,10.693,10.693,0,0,1-1.57.876h0l-.117.052,0,0-.114.049-.008,0-.113.047,0,0-.113.045-.012,0-.112.043-.011,0-.111.042-.011,0-.112.04-.009,0-.117.04-.007,0a10.656,10.656,0,0,1-2.47.519h0q-.135.012-.27.021h0l-.133.008h-.006l-.134.006h0l-.132,0h-.009l-.13,0h-.285l-.13,0h-.009l-.132,0h0l-.134-.006h-.006l-.133-.008h0q-.136-.009-.27-.021h0a10.656,10.656,0,0,1-2.471-.519l-.006,0-.118-.041-.008,0-.114-.041-.009,0-.113-.042-.011,0-.112-.043-.012,0-.113-.045,0,0L7.3,21.334l-.007,0-.115-.05,0,0-.117-.052h0a10.69,10.69,0,0,1-1.57-.876m8.5-12.458a3.567,3.567,0,1,1-2.522-1.045,3.556,3.556,0,0,1,2.522,1.045"
            transform="translate(0 0.001)"
            fill="#1a1a1a"
            fill-rule="evenodd"
          />
        </g>
      </svg>
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
import GtagAction from '@/mixins/gtag-action'

export default {
  mixins: [GtagAction],
  props: {
    unreadCount: {
      type: [String, Number],
      default: 0
    },
    approachUnreadCount: {
      type: [String, Number],
      default: 0
    }
  },
  data () {
    return {
      gtmParams: {
        MyPage: {
          jobs: { page: 'MYTOP', part: 15 },
          approach: { page: 'MYTOP', part: 16 },
          message: { page: 'MYTOP', part: 17 },
          profile: { page: 'MYTOP', part: 18 }
        },
        JobSearch: {
          approach: { page: 'LOGJOB', part: 6 },
          message: { page: 'LOGJOB', part: 7 },
          profile: { page: 'LOGJOB', part: 8 },
          notification: { page: 'LOGJOB', part: 9 }
        },
        Pinned: {
          jobs: { page: 'PIN', part: 5 },
          message: { page: 'PIN', part: 6 },
          profile: { page: 'PIN', part: 7 }
        },
        Matched: {
          jobs: { page: 'MATCH', part: 3 },
          message: { page: 'MATCH', part: 4 },
          profile: { page: 'MATCH', part: 5 }
        },
        Applied: {
          jobs: { page: 'APPLY', part: 3 },
          message: { page: 'APPLY', part: 4 },
          profile: { page: 'APPLY', part: 5 }
        },
        Interested: {
          jobs: { page: 'INTR', part: 3 },
          message: { page: 'INTR', part: 4 },
          profile: { page: 'INTR', part: 5 }
        },
        Invisible: {
          jobs: { page: 'HIDE', part: 5 },
          message: { page: 'HIDE', part: 6 },
          profile: { page: 'HIDE', part: 7 }
        },
        Threads: {
          jobs: { page: 'MESS', part: 13 },
          approach: { page: 'MESS', part: 14 },
          profile: { page: 'MESS', part: 15 }
        }
      }
    }
  },
  computed: {
    // formats unread count
    unreadMessageCountFormat () {
      return this.unreadCount > 9 ? '9+' : this.unreadCount
    }
  },
  methods: {
    pushGtm (code) {
      const param = this.gtmParams[this.$route.name] && this.gtmParams[this.$route.name][code]
      param && this.customActionPush(param)
    },
  }
}
</script>

<style scoped>
.theme--light.v-bottom-navigation .v-btn:not(.v-btn--active) {
  color: var(--v-disabled-base) !important;
}
.text-nav {
  font: normal normal normal 10px/13px Roboto;
  color: var(--v-text-base);
  letter-spacing: 0px;
}
a.v-btn--active {
  background: var(--v-activeNavBottom-base) !important;
}
</style>
