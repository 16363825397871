export default {
  header: {
    text1: 'Those who showed up for interview or casual meeting',
    text1En: {
      description1: 'Those who showed up',
      description2: 'for interview or casual meeting'
    },
    text2: {
      amazonGiftCard: 'Amazon Gift Card',
      number: '2,000 ',
      yen: 'yen'
    },
    text3: {
      toYear: 'To 2022',
      nextStepCampaign: 'The Next Step campaign'
    },
    text4: 'Campaign Period: Wednesday, Dec. 1, 2021 - Monday, Jan. 31, 2022',
    text4En: {
      campaignPeriod: 'Campaign Period',
      description: 'Wednesday, Dec. 1, 2021 - Monday, Jan. 31, 2022'
    }
  },
  content1: {
    headerText: 'Conditions',
    body1: 'All users who click “Apply” or “I want to learn more” in response to a scout of a posted job or company and proceed to an “interview” or “casual meeting” will receive a 2,000 yen Amazon Gift Card.',
    body2: 'May 2022 be a wonderful year for you, we are here to help you take the next step in your job search!Apply for a variety of jobs and talk to a lot of companies.',
    jobSearch: 'Go to job search'
  },
  content2: {
    headerText: 'Details and cautions',
    list1: 'A gift will be sent once per person (you will receive a gift for your first interview or casual meeting during the campaign period). ',
    list2: 'Campaign period: entries between Wednesday, December 1, 2021 - Monday, January 31, 2022.',
    list3: 'The Amazon Gift Card will be emailed to you with a gift code. Please note that the email will be sent in January and February 2022.',
    list4: {
      text1: 'If you have proceeded to an "interview" or "casual interview", please report ',
      textLinkHere: 'here',
      text2: '. Please note that if you do not report, we may not be able to give you a gift.'
    }
  }
}
