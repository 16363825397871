export default {
  language: '言語',
  firstLanguage: '母国語',
  speakingEnglish: '英会話力',
  speakingJapanese: '日本語会話力',
  jlpt: 'JLPT（日本語能力試験）',
  jlptConfirmation: 'JLPT（日本語能力試験）の資格を変更しますか？応募した会社から資格証の提出を求められることもあるのでご注意ください。',
  otherLanguage: 'その他の言語',
  chooseLanguage: '言語を選択',
  addOtherLanguages: 'その他の言語を追加する',
  success: '言語が正常に保存されました',
  japanese: '日本語',
  japaneseLevel: '日本語レベル',
  speaking: 'スピーキング',
  comprehension: '理解力',
  comprehensionHint: '（聞く・読む）',
  listeningReading: '聞く・読む',
  writing: 'ライティング',
  japaneseExperience: 'ビジネス現場の日本語使用経験',
  english: '英語',
  englishLevel: '英語レベル',
  revert: '元に戻す',
  level: 'レベル',
  edit: '言語情報を編集する',
  certificate: {
    label: '資格名',
    levelScore: 'レベル／スコア',
    add: '資格・表彰を追加',
    addCertificate: '資格・表彰を追加',
    validations: {
      certificate: {
        required: '資格・表彰が必要です'
      },
      score: {
        required: 'レベル／スコアが必要です',
        decimal: 'レベル/スコアは数値でなければなりません',
        range: '1から999999.99の値を入力してください'
      }
    }
  },
  otherLanguages: {
    validations: {
      language: {
        required: '言語情報の登録が必要です'
      },
      level: {
        required: '言語レベルの登録が必要です'
      }
    }
  },
  updated: '言語情報が正常に更新されました',
  clearHint: '変更する場合は「X」をクリックして現在の選択を解除してください。',
  jlptSelections: {
    N1: '幅広ひろい場面で使われる日本語を理解することができる',
    N2: '日常的な場面で使われる日本語の理解に加くわえ、より幅広い場面で使われる日本語をある程度理解することができる',
    N3: '日常的な場面で使われる日本語をある程度理解することができる',
    N4: '基本的な日本語を理解することができる',
    N5: '基本的な日本語をある程度理解することができる'
  },
  languageLevelSelections: {
    native: '母国語として話すことができるレベル',
    fluent: 'どのような状況や分野おいても高度な会話力できる流暢さがある',
    business: 'ビジネスにおいて容易かつ正確に言語を使いこなすことができる',
    dailyConversation: '決まった語句等で基本的な日常会話はできる',
    greetingOrNone: '挨拶や簡単な自己紹介、「はい」「いいえ」は言える。またはそれ以下'
  },
  jlptNone: '未選択',
  usingJaAtWork: '職場で日本語を使う',
  addLanguage: '言語を追加',
  translate: {
    japanese: '日本語翻訳',
    english: '英語翻訳'
  }
}
