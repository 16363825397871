export default {
  tabs: {
    interested: 'Interested',
    applied: 'Applied',
    saved: 'Saved',
    interestedTitle: 'Know More',
    invisible: 'Hidden',
    scouted: 'Scouted',
    recommendations: 'My Recommendations'
  },
  pinned: {
    title: 'Pinned',
    caption: '(Job posts that the company has pinned you)'
  },
  applied: {
    title: 'Applied Jobs'
  },
  matched: {
    title: 'Matched',
    caption: '(Companies of job posts you interested are also interested in you)'
  },
  buttons: {
    checkMsg: 'Message',
    revisible: 'Re-visible'
  },
  invisible: {
    title: 'Hidden Jobs',
    hide: 'Hide'
  },
  actions: {
    iLikeToKnowMore: 'Know More',
    iLikeToKnowMoreMessage: 'When would you like to schedule an online casual meeting?',
    iLikeToKnowMoreSubMessage: '(To select three or more is recommended.)',
    dayOfTheWeek: 'Day of the Week',
    timeInJapan: 'Time in Japan',
    localTime: 'Local Time',
    casualOnlineMeeting: 'Casual Online Meeting',
    movedToMatched: 'Moved to matched list',
    save: 'Save',
    saved: 'Saved'
  },
  common: {
    endOfSelection: 'End of Selection',
    underSelection: 'Under Selection'
  },
  closed: 'This position has been closed.',
  savedNoDisplay: {
    header: 'Nothing to show here',
    body: 'Jobs that you saved will be shown here. Let’s find a job you’re interested!',
    body1: 'If you Save a job, you may receive Scout or Interested from companies.',
    button: 'Search Job'
  },
  saved: {
    header: 'Saved Jobs',
    body: 'Jobs that you saved will be shown here.Let’s find a job you’re interested!',
    body1: 'If you Save a job, you may receive Scout or Interested from companies.',
    body2: '※If you press the “Hidden” , the job will be moved to the Hidden tab.'
  },
  interested: {
    header: 'Approached by a company',
    body: 'If you’ve received a 「Interest」 from a company, it’s shown here.',
    body1: 'Please Update your profile to appeal to the company.'
  },
  interestedNoData: {
    header: 'Nothing to show here',
    body: 'If you’ve received a 「Interest」 from a company, it’s shown here.',
    body1: 'Please Update your profile to appeal to the company.',
    button: 'Profile Edit'
  },
  scouted: {
    header: 'Approached by a company',
    body: 'Let’s check the job description and',
    body1: '「Apply」 If yor’re interested.',
  },
  scoutedNoData: {
    header: 'Nothing to show here',
    body: 'If you’ve received a 「Scout」 from a company, it’s shown here.',
    body1: 'Please Update your profile to appeal to the company.',
    button: 'Profile Edit'
  },
  appliedNoDisplay: {
    header: 'Nothing to show here',
    body: 'Jobs that you applied will be shown here.',
    body1: 'Let’s find a job you’re interested!',
    button: 'Search Job'
  },
  applied: {
    header: 'Jobs that you applied for',
    body: 'Jobs that you applied will be shown here.',
    body1: 'Let’s find a job you’re interested!'
  },
  jobPostingClosed: 'Closed'
}
