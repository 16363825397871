import applicantPositionExperience from '@/api/customer/applicant-position-experience'
import uniqBy from 'lodash/uniqBy'

export default {
  namespaced: true,
  state () {
    return {
      loadingSave: false,
      loadingContent: true,
      selectedJobRole: [],
      newJobRole: [],
      addJobCategory: null,
      addJobRole: null,
      addYearExperience: null
    }
  },
  mutations: {
    /**
     * Set the add job category.
     *
     * @param {*} state
     * @param value
     */
    SET_ADD_JOB_CATEGORY (state, value) {
      state.addJobCategory = value
      state.addJobRole = null
      state.addYearExperience = null
    },
    /**
     * Set the add job role.
     *
     * @param {*} state
     * @param value
     */
    SET_ADD_JOB_ROLE (state, value) {
      state.addJobRole = value
      state.addYearExperience = null
    },
    /**
     * Set the add year experience.
     *
     * @param {*} state
     * @param value
     */
    SET_ADD_YEAR_EXPERIENCE (state, value) {
      state.addYearExperience = value
    },
    /**
     * Set the selected job role.
     *
     * @param {*} state
     * @param items
     */
    SET_SELECTED_JOB_ROLE (state, items) {
      state.selectedJobRole = items
    },
    /**
     * Set new job role.
     *
     * @param {*} state
     * @param items
     */
    SET_NEW_JOB_ROLE (state, items) {
      state.newJobRole = items
      state.addJobCategory = null
      state.addJobRole = null
      state.addYearExperience = null
    },
    /**
     * Clear the add job role form.
     *
     * @param {*} state
     * @param items
     */
    CLEAR_ADD_JOB_ROLE_FORM (state) {
      state.addJobCategory = null
      state.addJobRole = null
      state.addYearExperience = null
    },
    /**
     * Update Year of Experience or Remove Job Role in selectedJobRole and newJobRole state.
     *
     * @param {*} state
     * @param items
     */
    UPDATE_OR_REMOVE_JOB_ROLE_SELECTED_OR_NEW (state, { newJobRole, selectedJobRole }) {
      state.newJobRole = newJobRole
      state.selectedJobRole = selectedJobRole
    },
    /**
     * Set the loading status.
     *
     * @param {*} state
     * @param {*} items
     */
    SET_LOADING_CONTENT (state, loading) {
      state.loadingContent = loading
    },
    /**
     * Set the loading status.
     *
     * @param {*} state
     * @param {*} items
     */
    SET_LOADING_SAVE (state, loading) {
      state.loadingSave = loading
    },
    /**
     * Clear Add Job Role Values.
     *
     * @param {*} state
     * @param {*} items
     */
    CLEAR_ADD_JOB_ROLE (state) {
      state.addJobCategory = null
      state.addJobRole = null
      state.addYearExperience = null
    }
  },
  actions: {
    /**
     * Commit the SET_ADD_JOB_CATEGORY mutation
     *
     */
    setAddJobCategory ({ commit }, value) {
      commit('SET_ADD_JOB_CATEGORY', value)
    },
    /**
     * Commit the SET_ADD_JOB_ROLE mutation
     *
     */
    setAddJobRole ({ commit }, value) {
      commit('SET_ADD_JOB_ROLE', value)
    },
    /**
     * Commit the SET_ADD_YEAR_EXPERIENCE mutation
     *
     */
    setAddYearExperience ({ commit }, value) {
      commit('SET_ADD_YEAR_EXPERIENCE', value)
    },
    /**
     * Commit the SET_NEW_JOB_ROLE mutation
     *
     */
    setNewJobRole ({ commit }, items) {
      commit('SET_NEW_JOB_ROLE', items)
    },
    /**
     * Commit the SET_NEW_JOB_ROLE mutation
     *
     */
    setSelectedJobRole ({ commit }, items) {
      commit('SET_SELECTED_JOB_ROLE', items)
    },
    /**
     * Commit the CLEAR_ADD_JOB_ROLE mutation
     *
     */
    clearAddJobRole ({ commit }) {
      commit('CLEAR_ADD_JOB_ROLE')
    },
    /**
     * Commit the UPDATE_OR_REMOVE_JOB_ROLE_SELECTED_OR_NEW mutation
     *
     */
    updateOrRemoveJobRoleSelectedOrNew ({ commit, state }, { value, positionId, action }) {
      const newJobRole = state.newJobRole
      const selectedJobRole = state.selectedJobRole
      for (let a = 0; a < newJobRole.length; a++) {
        const item = newJobRole[a]
        if (item.id === positionId) {
          if (action === 'delete') {
            newJobRole.splice(a, 1)
          } else {
            newJobRole[a].yoe = value
          }
          break
        }
      }
      for (let a = 0; a < selectedJobRole.length; a++) {
        const item = selectedJobRole[a]
        if (item.id === positionId) {
          if (action === 'delete') {
            selectedJobRole.splice(a, 1)
          } else {
            selectedJobRole[a].yoe = value
          }
          break
        }
      }
      commit('UPDATE_OR_REMOVE_JOB_ROLE_SELECTED_OR_NEW', { newJobRole, selectedJobRole })
    },
    /**
     * Get Applicant Position Experience
     *
     * @param {commit, dispatch}
     * @param {*} params
     */
    getApplicantPositionExperience ({ commit, dispatch }, params) {
      commit('SET_LOADING_CONTENT', true)
      return applicantPositionExperience.simpleSearch(params)
        .then(({ data }) => {
          const form = data.slice()
          const records = form.map((item) => {
            item = {
              id: item.job_position_id,
              yoe: item.years_of_experience_range_id
            }

            return item
          })
          commit('SET_SELECTED_JOB_ROLE', records)
        })
        .catch(({ response }) => {
          dispatch('httpException/handle', response, { root: true })
        })
        .finally(() => {
          commit('SET_LOADING_CONTENT', false)
        })
    },
    /**
     * Store job positions.
     *
     * @param {commit, dispatch, state}
     */
    storeData ({ commit, dispatch, state }) {
      commit('SET_LOADING_SAVE', true)
      const payload = {}
      const data = [...state.selectedJobRole, ...state.newJobRole]
      payload.applicant_position_experiences = uniqBy(data.map((item) => {
        item = {
          job_position_id: item.id,
          years_of_experience_range_id: item.yoe
        }

        return item
      }), 'job_position_id')
      return applicantPositionExperience.sync(payload)
        .then(({ data }) => data)
        .catch(({ response }) => {
          dispatch('httpException/handle', response, { root: true })
        })
        .finally(() => {
          commit('SET_LOADING_SAVE', false)
        })
    }
  }
}
