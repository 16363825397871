import applicant from '@/api/customer/applicant'

const data = {
  auth: null,
  parsedData: null,
  loading: false,
  applicant: null
}

export default {
  namespaced: true,
  state () {
    return {
      ...data
    }
  },
  getters: {
    /**
     * Check if state has an applicant data
     */
    hasApplicant: state => {
      return state.auth !== null
    }
  },
  mutations: {
    /**
     * Set the status.
     *
     * @param {*} state
     * @param {*} items
     */
    SET_LOADING (state, loading) {
      state.loading = loading
    },

    /**
     * Reset the state to default.
     *
     * @param {*} state
     */
    RESET (state) {
      for (const key in state) {
        state[key] = data[key]
      }
    },

    /**
     * Set the item.
     *
     * @param {*} state
     * @param {*} auth
     */
    SET_AUTH (state, auth) {
      state.auth = auth
    },

    /**
     * Set the applicant that will be used for ApplyDialog etc.
     *
     * @param {*} state
     * @param {*} applicant
     */
    SET_APPLICANT (state, applicant) {
      state.applicant = applicant
    },

    /**
     * Set parsed data
     *
     * @param {*} state
     * @param {*} parsedData
     */
    SET_PARSED_DATA (state, parsedData) {
      state.parsedData = parsedData
    },

    /**
     * Set the Image
     *
     */
    SET_PHOTO (state, url) {
      state.auth.profile_photo = { url: url }
    },
    /**
     * Set the Video
     *
     */
    SET_VIDEO (state, url) {
      state.auth.profile_video = { url: url }
    }
  },
  actions: {
    /**
     * Soft fetch data
     * If data is already populated skip the process
     *
     * @param { getters, dispatch}
     */
    async softFetch ({ getters, dispatch, state }, params) {
      if (getters.hasApplicant || state.loading) return

      await dispatch('getAuthProfile', params)
    },

    /**
     * Get the applicant record of the authenticated user.
     *
     * @param {*} param
     */
    getAuth ({ commit, dispatch }, params) {
      commit('SET_LOADING', true)

      return applicant.auth(params)
        .then(({ data }) => {
          commit('SET_AUTH', data)

          return data
        })
        .catch(({ response }) => dispatch('httpException/handle', response, { root: true }))
        .finally(() => commit('SET_LOADING', false))
    },

    /**
     * Get the applicant record of the authenticated user
     * with the related data for the profile view.
     *
     * @param {*} param
     * @param {*} params
     */
    getAuthProfile ({ commit, dispatch }) {
      const params = {
        with: [
          'applicantEnglishLevel.speakingLevel',
          'applicantEnglishLevel.comprehensionLevel',
          'applicantEnglishLevel.writingLevel',
          'applicantFirstLanguage.language',
          'applicantFirstChannel.channel',
          'applicantJapaneseLevel.speakingLevel',
          'applicantJapaneseLevel.comprehensionLevel',
          'applicantJapaneseLevel.writingLevel',
          'applicantJapaneseLevel.japaneseLanguageExperience',
          'applicantJlptScoreLevel.jlptScoreLevel',
          'applicantLanguageCertificates.languageCertificate',
          'applicantOtherLanguageLevels.language',
          'applicantOtherLanguageLevels.languageScoreLevel',
          'applicantWorkingStatus',
          'applicantCvFiles',
          'applicantIncomeRange',
          'applicantSocialProfiles',
          'profileVideo',
          'basicPersonalInformation',
          'applicantExperienceSummary'
        ]
      }

      commit('SET_LOADING', true)

      return applicant.auth(params)
        .then(({ data }) => commit('SET_AUTH', data))
        .catch(({ response }) => dispatch('httpException/handle', response, { root: true }))
        .finally(() => commit('SET_LOADING', false))
    },

    /**
     * Get the applicant record of the authenticated user
     * with the related data for the apply job dialog.
     *
     * @param {*} param
     */
    getApplicant ({ state, commit, dispatch }) {
      if (state.applicant !== null) return

      const params = {
        with: [
          'applicantEducation.educationalLevel',
          'applicantEnglishLevel.speakingLevel',
          'applicantEnglishLevel.comprehensionLevel',
          'applicantEnglishLevel.writingLevel',
          'applicantExperienceSummary',
          'applicantExperiences.companySize',
          'applicantExperiences.applicantExperienceWorkDetails',
          'applicantExperiences.applicantExperienceIndustries.industry.industryCategory',
          'applicantExperiences.employmentType',
          'applicantFirstLanguage.language',
          'applicantFirstChannel.channel',
          'applicantIncomeRange.incomeRange.currency',
          'applicantJapaneseLevel.japaneseLanguageExperience',
          'applicantJapaneseLevel.speakingLevel',
          'applicantJapaneseLevel.comprehensionLevel',
          'applicantJapaneseLevel.writingLevel',
          'applicantJlptScoreLevel.jlptScoreLevel',
          'applicantLanguageCertificates.languageCertificate',
          'applicantOtherLanguageLevels.language',
          'applicantOtherLanguageLevels.languageScoreLevel',
          'applicantPositionExperiences.jobPosition.jobPositionCategory',
          'applicantPositionExperiences.yearsOfExperienceRange',
          'applicantPreferredAppealPoints.appealPoint.appealPointCategory',
          'applicantPreferredJobPositions.jobPosition.jobPositionCategory',
          'applicantPreferredPrefectures.japanPrefecture.japanRegion',
          'applicantSkills.skill.skillCategory',
          'applicantSkills.yearsOfExperienceRange',
          'applicantSocialProfiles',
          'basicPersonalInformation.city.country.region',
          'basicPersonalInformation.nationality',
          'basicPersonalInformation.japanPrefecture.japanRegion',
          'employmentType',
          'profileVideo'
        ]
      }

      return applicant.auth(params)
        .then(({ data }) => commit('SET_APPLICANT', data))
        .catch(({ response }) => dispatch('httpException/handle', response, { root: true }))
    },

    /**
     * Commit the update mutation
     *
     * @param {commit}
     * @param {*} payload
     */
    setParsedData ({ commit }, payload) {
      commit('SET_PARSED_DATA', payload)
    },
    setPhoto ({ commit }, payload) {
      commit('SET_PHOTO', payload)
    },
    setVideo ({ commit }, payload) {
      commit('SET_VIDEO', payload)
    }
  }
}
