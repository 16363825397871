export default {
  terms: {
    text: '利用規約'
  },
  mainHeader: {
    text1: 'この利用規約（以下「本規約」といいます。）には、ビズメイツ株式会社（以下「当社」といいます。）が運営する人材採用支援サービス「GitTap」（以下「本サービス」といいます。）について、本サービスの提供条件及び当社と本サービスの利用者との間の権利義務関係が定められています。',
    text2: '本サービスの利用に際しては、本規約の全文をお読みいただいた上で、本規約に同意いただく必要があります。'
  },
  article1: {
    title: '第1条（本規約の適用）',
    info1: '本規約は、本サービスの提供条件及び当社と本サービスの利用者との間の権利義務関係を定めることを目的とし、当社と本サービスの利用者との間の本サービスの利用に関する一切の関係に適用されます。',
    info2: '当社が当社ウェブサイト上で掲載する本サービスの利用に関するルールは、本規約の一部を構成するものとします。',
    info3: '本規約の内容が、前項のルールその他本規約以外における本サービスの説明等と異なる場合には、本規約の規定が優先して適用されるものとします。',
    info4: '本サービスを利用する者は、本規約に従い本サービスを利用するものとします。'
  },
  article2: {
    title: '第2条（定義）',
    headerInfo: '本規約において使用される以下の用語は、それぞれ以下の意味を有するものとします。',
    info1: '「サービス利用契約」とは、本規約を契約条件として当社と登録ユーザーとの間で締結される、本サービスの利用契約を意味します。',
    info2: '「登録ユーザー」とは、第3条（本サービスの登録）に基づいて、本サービスの利用者としての登録がなされた者を意味します。',
    info3: '「登録情報」とは、登録ユーザーが本サービスを利用して投稿その他送信するコンテンツ（文章、画像、動画、メッセージその他のデータを含みますがこれらに限りません。）を意味します。',
    info4: '「登録企業」とは、当社と別途契約の上、本サービスの求人企業としての登録がなされた企業を意味します。',
    info5: '「求人情報」とは、登録企業が本サービスを利用して投稿その他送信するコンテンツ（文章、画像、動画その他のデータを含みますがこれらに限りません。）を意味します。',
    info6: '「マッチング」とは、次のいずれかに該当する場合を意味します。',
    list1: '登録ユーザーが、本サービスにおいて、特定の登録企業の求人情報につき、興味を示す行動をとった場合において、当該登録企業がこれに応答したとき',
    list2: '登録企業が、本サービスにおいて、特定の登録ユーザーに対しての連絡を試み、登録ユーザーがこれに応答したとき'
  },
  article3: {
    title: '第3条（本サービスの登録）',
    info1: '本サービスの利用を希望する者は、本規約の内容に同意した上で、当社が定める手続に従って、当社の定める登録情報を当社に提供することにより、当社に対し、本サービスの登録を申請することができます。',
    info2: '前項の申請を受けて、当社が本サービスのユーザー登録の申請をした者（以下「登録申請者」といいます。）の利用登録をし、当該登録申請者に対し登録完了の通知をした時点で、登録申請者と当社との間でサービス利用契約が成立するものとします。',
    info3: '当社は、次の各号のいずれかに該当する場合には、当社の判断により、本条第1項の登録申請を拒否することがあり、その理由について一切開示義務を負いません。',
    info4: '当社は、登録ユーザーから利用料、手数料その他登録ユーザーが本サービス利用の対価を徴収しないものとします。ただし、登録ユーザーが当社の提供する有料サービスの利用を申し込んだ場合は、この限りではありません。',
    list1: '登録申請者が実在しない場合',
    list2: '登録情報の全部又は一部に虚偽、誤記又は記入漏れがあった場合',
    list3: '登録申請者が本条第1項の方法によらずに登録申込を行った場合',
    list4: '登録申請者が過去に本規約若しくはサービス利用契約に違反し、又は当社により本サービスの登録を抹消されたことがある場合',
    list5: '登録申請者が既に別のアカウント等で本サービスに登録している場合',
    list6: '未成年者又はその他法定代理人の同意が必要な者であり、法定代理人の同意等を得ていなかった場合',
    list7: '登録申請者が暴力団員若しくはその関係者又はその他の反社会的勢力であると当社が判断した場合',
    list8: 'その他本サービスの利用者として不適当であると当社が判断した場合'
  },
  article4: {
    title: '第4条（登録情報の変更）',
    info1: '登録ユーザーは、登録情報に変更があった場合には、当社の定める方法により、当該変更事項を遅滞なく当社に通知するものとします。'
  },
  article5: {
    title: '第5条（ID及びパスワード）',
    info1: '当社は、登録ユーザーに対して、ID及びパスワードを発行し、付与します。',
    info2: '登録ユーザーは、自己の責任において、本サービスに関するID及びパスワードを適切に使用及び管理するものとし、これを第三者に利用させ、又は貸与、譲渡、名義変更、売買等をしてはならないものとします。',
    info3: 'ID又はパスワードの管理不十分、使用上の過誤、第三者の使用等により生じた損害に関する責任は登録ユーザーが負うものとします。',
    info4: '当社は、本サービスの運営に必要と判断される場合には、登録ユーザーのID及びパスワードを使用し、登録ユーザーの登録情報を閲覧できるものとします。'
  },
  article6: {
    title: '第6条（禁止事項）',
    info1: '登録ユーザーは、本サービスの利用に際して、以下に定める行為若しくはこれに準じる行為又はそのおそれがあると当社が判断する行為を行ってはなりません。',
    info2: '前項に掲げる行為により当社又は本サービスの他の登録ユーザー、登録企業若しくはその他第三者に生じた一切の損害について、登録ユーザーは賠償する責任を負担し、当社は、民事及び刑事の一切の法的手段をとることができます。',
    list1: '虚偽の情報（登録ユーザーの氏名、住所、国籍、年齢、学歴、経歴、資格等を含みますがこれらに限りません。）を登録する行為',
    list2: '本サービスを介さず、登録企業に対し、直接連絡をとる行為（ただし、登録企業との採用面接の決定後は、この限りではありません。）',
    list3: '本サービス又は登録企業を通じて知り得た情報を複製し、出版し、若しくはその他サービスの目的の範囲を超えて使用し、又は第三者に漏洩し、若しくは開示する行為',
    list4: '正当な理由なく、登録企業との面接を欠席する行為',
    list5: '登録企業による採用事実の隠蔽その他の当社と登録企業との間の契約、法令又は公序良俗に違反し、又は抵触する行為に協力する行為',
    list6: '正当な理由なく、当社からの連絡に返信しない行為',
    list7: '本サービスを利用する権利を他の者に譲渡し、若しくは使用させ、又は他の者の名義に変更する行為',
    list8: '本サービスの信用を失墜若しくは棄損させ、又は本サービスの運用を妨げる行為',
    list9: '当社が事前に承諾しない本サービス上での宣伝、広告、勧誘又は営業行為',
    list10: '本サービスの他の登録ユーザー、登録企業若しくはその他第三者又は当社に損害又は不利益を与える行為',
    list11: '当社のカスタマーサポートスタッフへの暴言等カスタマーサポート業務を妨げる行為',
    list12: '複数人で同一のアカウントを使用し、又は同一人で複数のアカウントを使用する行為',
    list13: '本サービスの利用状況を録音又は録画して第三者に開示する行為（ただし、当社の事前の承諾がある場合は、この限りではありません。）',
    list14: '面識のない異性との出会いを目的とした行為',
    list15: '本サービスの他の登録ユーザー、登録企業若しくはその他第三者又は当社の知的財産権、肖像権、プライバシーの権利、名誉その他の権利又は利益を侵害する行為',
    list16: '法令に違反する行為又は犯罪に関連する行為',
    list17: '公序良俗に反する行為',
    list18: 'その他当社が不適当と判断する行為'
  },
  article7: {
    title: '第7条（登録情報等の取扱い）',
    info1: '本サービスに基づき当社が収集する情報は、その収集方法に応じて、以下のものが含まれます。登録ユーザーは、当社が以下に掲げる情報を収集することについて、同意するものとします。',
    info1list1: '当社は、本条1項に基づき収集する登録ユーザーに関する情報について、以下に掲げる目的に従い、第三者に提供することがあり、登録ユーザーはこれについて同意するものとします。',
    list1detail1: '氏名',
    list1detail2: '生年月日、経歴等プロフィールに関する情報',
    list1detail3: 'メールアドレス、電話番号、住所等連絡先に関する情報',
    list1detail4: '登録ユーザーの肖像を含む静止画又は動画情報',
    list1detail5: '入力フォームその他当社が定める方法を通じて、登録ユーザーが入力又は送信する情報',
    info1list2: '登録ユーザーが本サービスを利用するにあたり当社が収集する情報',
    list2detail1: '端末情報',
    list2detail2: 'クッキー情報（登録ユーザーが訪問したウェブサイトのページのURLやページ変遷等の行動履歴情報）',
    info2: '当社による登録ユーザーの登録情報の取扱いについては、当社が定める「プライバシーポリシー」の定めによるものとし、登録ユーザーは、当社がこのプライバシーポリシーに従い登録ユーザーの登録情報を取り扱うことについて同意するものとします。',
    info3: '当社は、本条1項に基づき収集する登録ユーザーに関する情報について、以下に掲げる目的に従い、第三者に提供することがあり、登録ユーザーはこれについて同意するものとします。',
    info3list1: '本条1項1号に定める登録情報を、登録企業が採用活動等を行うことを目的として、以下に掲げる段階に応じて、登録企業に提供すること',
    info3detail1: '第3条に基づく本サービスのユーザー登録が完了した段階で、本条1項1号bの情報を登録企業に提供すること',
    info3detail2: '登録ユーザーと特定の登録企業がマッチングした段階又は登録ユーザーが本サービスを通じて特定の登録企業に対して応募をした段階で、本条1項1号aの情報を登録企業に提供すること',
    info3detail3: '登録ユーザーが、登録企業と採用面接を行うことが確定した段階で、本条1項1号cの情報を登録企業に提供すること',
    info3list2: '本条1項2号に定める情報を、登録ユーザーのニーズ、興味又は関心に合わせてカスタマイズした最適な広告を配信することにより、登録ユーザーに有益な情報を届けることを目的として、行動ターゲティング広告を配信する事業者等に提供すること'
  },
  article8: {
    title: '第8条（本サービスの提供）',
    info1: '登録ユーザーは、当社が本サービスを提供する際に、登録ユーザーに対し、本サービスのメッセージ機能、Eメール等により連絡をすることについて、あらかじめ同意するものとします。',
    info2: '登録ユーザーは、登録企業から、本サービスにおけるメッセージ機能、Eメール、郵便又は電話等により連絡を受ける場合があることについて、あらかじめ同意するものとします。',
    info3: '前各号に掲げる内容から発生する一切の損害について、当社は、故意又は重大な過失がある場合を除き、登録ユーザーが被った損害を賠償する責任を負わないものとします。'
  },
  article9: {
    title: '第9条（本サービスの停止等）',
    headerInfo: '当社は、以下のいずれかに該当する場合には、登録ユーザーに事前に通知することなく、本サービスの全部又は一部の提供を停止又は中断することができるものとします。',
    info1: '本サービスにかかるコンピューター・システムの点検又は保守作業を緊急に行う場合',
    info2: 'コンピューター、通信回線等の障害、誤操作、過度なアクセス集中、不正アクセス、ハッキング等により本サービスの運営ができなくなった場合',
    info3: '地震、落雷、火災、台風、水害、停電、疫病、天災地変等の不可抗力により本サービスの運営ができなくなった場合',
    info4: 'その他当社が本サービスの全部又は一部の提供の停止又は中断を必要と判断した場合'
  },
  article10: {
    title: '第10条（権利帰属）',
    info1: '当社ウェブサイト及び本サービスに関する知的財産権は全て当社又は当社にライセンスを許諾している者に帰属しており、本規約に基づく本サービスの利用許諾は、当社ウェブサイト又は本サービスに関する当社又は当社にライセンスを許諾している者の知的財産権の使用許諾を意味するものではありません。',
    info2: '登録ユーザーは、登録情報について、自らが投稿その他送信することについての適法な権利を有していること、及び登録情報が第三者の権利を侵害していないことについて、当社に表明し、保証するものとします。',
    info3: '登録ユーザーは、第7条1項1号dに定める静止画又は動画情報について、当社に対し、世界的、非独占的、無償、サブライセンス可能かつ譲渡可能な使用、複製、配布、派生著作物の作成、表示及び実行に関するライセンスを付与します。',
    info4: '登録ユーザーは、当社及び当社から権利を承継し、又は許諾された者に対して、著作者人格権を行使しないことに同意するものとします。',
    info5: '当社は、登録情報から個別の個人が特定されない数字、統計等のデータを作成することができるものとし、当該データの所有権及び著作権その他一切の権利又は利益は当社に帰属し、当該データを何らの制限なく利用することができるものとします'
  },
  article11: {
    title: '第11条（登録抹消等）',
    headerInfo: '当社は、登録ユーザーが以下の各号のいずれかの事由に該当する場合には、事前に通知又は催告することなく、当社の判断により、登録情報を削除若しくは非表示にし、当該登録ユーザーについて本サービスの利用を一時的に停止し、又は登録ユーザーとしての登録を抹消することができます。',
    info1: '本規約のいずれかの条項に違反した場合',
    info2: '第3条3項各号に該当する場合',
    info3: 'その他当社が本サービスの利用又は登録ユーザーとしての登録の継続を適当でないと判断した場合'
  },
  article12: {
    title: '第12条（登録ユーザーによる解約）',
    info1: '登録ユーザーは、当社が定める手続に従って、サービス利用契約を解約することができます。'
  },
  article13: {
    title: '第13条（本サービスの内容の変更、終了）',
    info1: '当社は、当社の都合により、本サービスの内容を変更し、又は提供を終了することができます。',
    info2: '当社が本サービスの提供を終了する場合には、当社は、登録ユーザーに事前に通知するものとします。'
  },
  article14: {
    title: '第14条（免責）',
    info1: '当社は、故意又は重大な過失がある場合を除き、本サービスの利用に起因又は関連して、登録ユーザーが被った損害を賠償する責任を負わないものとします。',
    info2: '本サービスの利用に起因又は関連して、登録ユーザーと他の登録ユーザー、登録企業又はその他第三者との間において生じた連絡、紛争等については、登録ユーザーが自己の責任より解決するものとし、当社は責任を負わないものとします。',
    info3: '当社は、次に掲げる事項について、明示又は黙示を問わず何ら保証するものではありません。登録ユーザーは、これらの保証がないことによる費用や損害発生を防止するために必要な措置をあらかじめ自己の責任及び費用において行うものとします。',
    info4: '登録ユーザーは、本サービスにおいて送受信したメッセージ、登録情報又は求人情報について当社に保存義務がないことを認識し、必要な情報については適宜バックアップをとるものとします。',
    list1: '登録企業の求人情報が正確性、完全性、安全性及び有効性を有すること',
    list2: '登録企業の言動又は行動が合法性、道徳性、信頼性及び正確性を有すること',
    list3: '登録企業による面接又は採用が行われること',
    list4: '登録ユーザーの登録情報が正確性、完全性、安全性及び有効性を有すること',
    list5: '登録ユーザー又は登録企業による本サービスの利用が登録ユーザーに適用のある法令又は業界団体の内部規則等に適合すること',
    list6: '登録ユーザー又は登録企業による本サービスの利用が第三者の権利を侵害するものではないこと',
    list7: '本サービスの内容についての技術的不正確さ又は誤字若しくは誤植がないこと',
    list8: '本サービスのシステムについて、セキュリティが完全なものであり、バグがなく、及びその利用によりウィルスに感染しないこと',
    list9: '当社の独自のアルゴリズムによるマッチング処理に基づき、登録ユーザーに推薦され、表示される求人企業情報が、登録ユーザーに対して有用性を有すること',
    list10: '本サービスの提供情報からリンクしている外部サイトの内容が合法性、道徳性、信頼性、正確性を有すること'
  },
  article15: {
    title: '第15条（秘密保持）',
    info1: '登録ユーザーは、本サービスに関連して当社が登録ユーザーに対して秘密に取り扱うことを求めて開示した非公知の情報について、当社の事前の書面による承諾がある場合を除き、秘密に取り扱うものとします。'
  },
  article16: {
    title: '第16条（本規約等の変更）',
    info1: '当社は、当社が必要と認めた場合には、本規約を変更することができます。本規約を変更する場合には、当社は、当社ウェブサイト上において、本規約を変更する旨及び変更後の本規約の内容並びにその効力発生時期を告知します。ただし、法令上利用者の同意が必要となる変更を行う場合は、甲が適当と判断した方法により同意を得るものとします。なお、乙が定める期間内または告知した効力発生時期までに利用者が解約の手続を取らなかったときは、法令上その効力を否定される場合を除き、利用者が本規約の変更に同意したものとみなします。'
  },
  article17: {
    title: '第17条（情報発信）',
    info1: '当社は、利用者に対して、電子メールによる通知、広告及びアンケート等の情報配信を実施できるものとします。'
  },
  article18: {
    title: '第18条（サービス利用契約上の地位の譲渡等）',
    info1: '登録ユーザーは、当社の書面による事前の承諾なく、サービス利用契約上の地位又は本規約に基づく権利若しくは義務につき、第三者に対し、譲渡、移転、担保設定その他の処分をすることはできません。',
    info2: '当社は、本サービスにかかる事業を他社に譲渡した場合（会社分割その他事業が移転するあらゆる場合を含むものとします。）には、当該事業譲渡に伴い、サービス利用契約上の地位、本規約に基づく権利及び義務並びに登録ユーザーの登録情報その他の顧客情報を当該事業譲渡の譲受人に譲受することができるものとし、登録ユーザーは、かかる譲渡につき本項においてあらかじめ同意したものとみなします。'
  },
  article19: {
    title: '第19条（分離可能性）',
    info1: '本規約のいずれかの条項又はその一部が消費者契約法その他の法令等により無効又は執行不能と判断された場合であっても、本規約の残りの規定及び一部が無効又は執行不能と判断された規定の残りの部分は、継続して完全に効力を有するものとします。'
  },
  article20: {
    title: '第20条（暴力団等反社会的勢力の排除）',
    info1: '登録ユーザーは、現在及び将来において、次の各号に掲げる事項を表明し、保証するものとします。',
    info2: '当社は、登録ユーザーが前項各号のいずれかに該当することが明らかになった場合には、通知をすることなく登録を抹消することができるものとします。ただし、当社は、本項に基づく登録抹消により利用者に生じた損害について賠償する責任を負わないものとし、当社に損害が生じた場合には、利用者は損害を賠償しなければならないものとします。',
    list1: '登録ユーザーが反社会的勢力ではないこと',
    list2: '反社会的勢力を利用しないこと及びこれに準ずる行為をしないこと',
    list3: '反社会的勢力に資金提供を行わないこと及びこれに準ずる行為をしないこと',
    list4: '反社会的勢力を名乗る等して、当社の名誉等の毀損、業務妨害、不当要求行為又はこれに準ずる行為をしないこと'
  },
  article21: {
    title: '第21条（通知）',
    info1: '当社から登録申請者又は登録ユーザーへの通知は、本サービスのユーザー登録申請時に登録した電子メールアドレスその他の連絡先に宛てて発し、その通知が通常到達すべきであった時に到達したものとみなします。'
  },
  article22: {
    title: '第22条（サービス利用契約終了後の効力）',
    info1: 'サービス利用契約が終了した場合であっても、第6条、第7条、第10条、第14条、第15条、第18条、第19条、本条及び第23条の規定は有効に存続するものとします。'
  },
  article23: {
    title: '第23条（準拠法及び管轄）',
    info1: '本規約は、日本法に基づき解釈されるものとします。',
    info2: '本サービス及び本規約に関して生じる一切の紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。'
  },
  date: {
    text: '【2023年11月13日改定】'
  }
}
