export default {
  license: {
    edit: 'Edit License/Certification',
    title: 'License/Certification',
    add: 'Add More',
    dialog: {
      exists: 'License/Certification already exists.',
      title: 'Add License/Certification',
      form: {
        name: 'License/Certification Name',
        year: 'Year of acquisition'
      }
    },
    success: 'License/Certification Saved Successfully'
  },
  experiencedJobs: {
    title: 'Job Experience',
    subtitle: 'Job Role',
    placeholder: 'Select your job role',
    placeholder1: 'add your job role',
    dialog: 'Search Job Role',
    experiencePlaceholder: 'Select your year of experience',
    edit: 'Edit Experienced Jobs'
  },
  technicalSkill: {
    title: 'Technical Skills',
    subtitle: 'IT Skill',
    placeholder: 'select your skills',
    placeholder1: 'add your skills',
    dialog: 'Search Skill',
    featured: 'Experienced area / job',
    itSkill: 'Skill',
    edit: 'Edit Technical Skills',
    socialProfile: 'Connect accounts'
  },
  stepOne: {
    success: 'Job Experience/Technical Skills Saved Successfully',
    title: 'Tell us your skills and experiences',
    body: 'Fill in your IT skills and experience, {breakpoint} and more companies will scout you.'
  },
  currentWorkStatus: {
    title: 'Current Work Status',
    edit: 'Edit Current Work Status',
    info: 'Select your current work status. If you have no experience, select “Other”.',
    form: {
      label: 'Working Status',
      placeholder: 'Select Working Status'
    }
  },
  latestAnnualIncome: {
    title: 'Latest Annual Income',
    currency: {
      label: 'Currency',
      placeholder: 'Select Currency'
    },
    incomeRange: {
      label: 'Annual Income',
      about: 'About',
      placeholder: 'Select approximates amount'
    }
  },
  stepTwo: {
    success: 'Current Work Status/Latest Annual Income Saved Successfully'
  },
  workExperience: {
    title: 'Work Details',
    subtitle: 'List is automatically sorted by the recent work history.',
    add: 'Add new company',
    current: 'Current',
    previous: 'Previous Company',
    period: 'Period',
    workDetail: 'Project detail / Work Detail',
    detail: {
      workType: 'Work Type',
      industry: 'Industries',
      jobTitle: 'Job Title',
      companySize: 'Size of Company'
    },
    remove: {
      title: 'Remove Work Experience',
      body: 'Are you sure you want to remove this work experience?',
      removing: 'Removing work experience..',
      success: 'Work experience removed successfully'
    },
    edit: 'Edit Work',
    editMode: 'Edit Work Experience',
    summary: 'Short Description of Work Experience',
    seeMoreProjects: 'See More Projects',
    form: {
      summary: {
        title: 'Work Summary',
        label: 'Summary',
        placeholder: 'Give a summary of your work experience to show your talent.'
      },
      company: 'Company',
      formCompany: 'Company',
      experienceCompany: 'Company',
      companyName: 'Company Name',
      typeCompanyName: 'Type Company Name',
      industry: 'Industry',
      selectIndustry: 'Select Industry',
      japaneseCompany: 'Worked in Japan',
      employmentType: 'Employment Type',
      selectEmploymentType: 'Select Employment Type',
      projectWork: 'Project / Work',
      jobTitle: 'Job Title',
      typeJobTitle: 'Type Job Title',
      companySize: 'Size of Company',
      selectCompanySize: 'Select Size of Company',
      workDetail: 'Project detail / Work detail',
      itSkills: 'Used IT Skills',
      roleSize: 'Role / Size',
      period: 'Period',
      from: 'From',
      to: 'To',
      periodPlaceholder: 'YYYY/MM',
      current: 'Current Work',
      selectTemplate: 'Select Template',
      useTemplate: 'Use Template',
      addProject: 'Add Project',
      limit: 'At least 200 words or 2,000 characters',
      confirmMsg: 'Are you sure to overwrite with the new template?',
      validations: {
        company: {
          required: 'Company is required'
        },
        industry: {
          required: 'Industry is required'
        },
        companySize: {
          required: 'Company size is required'
        },
        employmentType: {
          required: 'Employment type is required'
        },
        jobTitle: {
          required: 'Job title is required'
        },
        workDetail: {
          required: 'Project detail / Work detail is required',
          maxLength: 'Project detail / Work detail must not exceed 4000 characters',
          notIn: 'This field is to be modified from the template.'
        },
        itSkill: {
          maxLength: 'IT skill must not exceed 2000 characters'
        },
        roleSize: {
          maxLength: 'Role / Size must not exceed 2000 characters'
        },
        from: {
          required: 'From is required'
        },
        to: {
          required: 'To is required'
        },
        summary: {
          required: 'Summary is required',
          maxLength: 'Summary must not exceed 10000 characters'
        },
        workExperienceRequired: {
          title: 'Work Experience Required',
          body: 'Kindly add at least one work experience'
        },
        keywordValidations: {
          head1: 'This is a template',
          head2: 'Please fill in your experience with below reference',
          head3: 'こちらはテンプレート文です',
          head4: '以下を参考にご経験をご入力ください',
          dateFormat1: 'xx/20xx',
          dateFormat2: 'yy/20yy',
          dateFormat3: '〇〇/20xx',
          dateFormat4: '〇〇/20yy'
        }
      },
      templates: [
        {
          title: 'General',
          content: `*This is a template
Please fill in your experience with below reference

Company Name:
Company Duration:
Role:
Job type:

【Project detail】

・Project overview
Development of a web app for matching engineers and projects
(xx/20xx~yy/20yy)

・Background
This is basically a web-service based American system which was migrated from .Net & SQL Server to Java & Oracle. Along with the migration, several new functionalities are also added. One of them is Data Synchronization with a remote database based on oAuth XML data exchange.

・Responsibilities
▪ Designed, developed and tested the frontend and the backend sides of a web app that parsed the engineers’ skill sheets as well as the project information emails and showed the skill matches in Spring Boot, Java, React, Express, and AWS.
▪ Came up with multiple approaches to tackle the challenges of parsing the various formats (DOCX, DOC, XLSX, XLS, & PDF) of engineers’ skill sheets and verified the implementation from actual skill sheets.
▪ Loaded data in the server initialization step instead of the time of webpage loading to reduce the first webpage load time from 14 minutes to 10 seconds.
▪ Wrote design documents & test documents, and executed test cases.`,
          usedITSkills: `.NET
SQL server
Oracle`,
          roleOrSize: `Role : Leader
Integration team : 5`
        },
        {
          title: 'Server・DB engineer',
          content: `*This is a template
Please fill in your experience with below reference

・Project Overview
Improving of ICT operations for the client’s business expanded and projects
(xx/20xx~yy/20yy)

・Background
The operating system located at the headquarters moved to the data center. Part of the system was moved to the AWS cloud to improve ICT operations. 

・Responsibilities
  ▪ Support the proposal with a system design that supports operational cost reduction and the client's sustainable ICT operating system for creating a server transfer plan
  ▪ Coordinated schedules and flow with the data center construction team as the cloud component was done in collaboration with multiple partner companies
  ▪ Experience in cloud integration with gaining knowledge of basic design and development in AWS

・Technology
Python、Java、Shell、Linux、Windows 10 Pro、PostgreSQL、AWS、Docker、Kibana

・Member/Role
Database engineer
5 members`,
          usedITSkills: `Python
Java
Shell
Linux
Windows 10 Pro
PostgreSQL
AWS
Docker
Kibana`,
          roleOrSize: `Role : Sub leader
Project team : 10`
        },
        {
          title: 'System Engineer',
          content: `*This is a template
Please fill in your experience with below reference

・Project Overview
Development of a sales support system to centrally manage sales performance, budget, and customers from the project proposal

・Background
Modification of existing account system, introduction of additional functions and operation for the purpose of improving operational efficiency

・Responsibilities
▪  Designed the source code of sales support system which is easy to constantly improve from clients’ feedback
▪  Tested in detail and fixed bugs thoroughly to prevent problems from occurring in the actual environment
▪  Handled customer inquiries and troubleshooting

・Technology
PHP、Java、AIX、Windows、SQL Server、Oracle

・Member/Role
Development engineer
50 members`,
          usedITSkills: `PHP
Java
AIX
Windows
SQL Server
Oracle`,
          roleOrSize: `Role : Developer
Integration team : 50`
        }
      ]
    }
  },
  stepThree: {
    success: 'Work Experiences Saved Successfully'
  },
  education: {
    title: 'Education',
    success: 'Education Saved Successfully',
    add: 'Add Education',
    remove: {
      button: 'Remove Education',
      body: 'Are you sure you want to delete this education history?'
    },
    form: {
      school: 'School Name',
      schoolUrl: 'School URL',
      degree: 'Degree / Certificate',
      major: 'Major',
      location: 'Location',
      start: 'Start',
      end: 'End',
      current: 'Currently Studying',
      term: 'Term'
    }
  },
  basicPersonalInformation: {
    title: 'Basic Personal Information',
    success: 'Basic Personal Information Saved Successfully',
    form: {
      firstName: 'First Name',
      lastName: 'Last Name',
      furiganaFirst: 'Furigana First Name',
      furiganaLast: 'Furigana Last Name',
      furiganaLabel: 'Only those who know “Furigana” as a Japanese pronunciation',
      dateOfBirth: 'Date of Birth',
      gender: 'Gender',
      nationality: 'Nationality',
      prefecture: 'Current Prefecture Residence',
      city: {
        label: 'Current City Residence',
        autocomplete: {
          placeholder: 'Start typing to search for city',
          searching: 'Searching for',
          noMatch: 'No match city for',
          noMatchWithCountry: 'with the selected country'
        }
      },
      country: 'Current Country Residence',
      streetAddress: 'Current Address',
      workPermission: 'Permission to Work (VISA)',
      visaExpirationDate: 'Visa Expiration Date',
      photo: 'Photo',
      photoUpload: 'Upload photo',
      photoRemove: 'Remove photo',
      noPhoto: 'no photo',
      profileMovie: 'Profile Movie',
      videoUpload: 'Upload video',
      videoRemove: 'Remove video',
      noVideo: 'no video',
      agreement: {
        termsOfUse: ' the terms of use ',
        personalInfo: ' the handling of personal information (member information). ',
        first: 'Register to G Talent, our job hunting services, as well. (When applying to our service, it is necessary to agree to',
        second: 'and',
        last: 'Please carefully read and review the terms and conditions of this Agreement before using the Service.)',
        bold: 'Register to G Talent, our job hunting services, as well.'
      }
    },
    hints: {
      name: 'Please enter the printed name on your passport.'
    },
    validations: {
      invalidFileFormat: 'File must be an image (.jpg, .jpeg, .png).',
      invalidFileSize: 'Maximum file size is up to 5MB.',
      removeConfirmation: 'Are you sure you want to remove photo?',
      invalidVideoFormat: 'File must be a video (.mp4, .mov, .qt, .avi, .webm, .wmv, .mpeg4, .asf).',
      invalidVideoSize: 'Maximum file size is up to 50MB.',
      removeVideoConfirmation: 'Are you sure you want to remove video?',
      yes: 'Yes',
      no: 'No'
    }
  },
  fileUpload: 'Auto fill out',
  lessThan: 'Must be {count} characters or less'
}
