export default {
  tags: {
    title: 'Sign Up',
    meta: {
      description: 'This page is the Sign Up page.',
      keywords:
        'Sign Up, Japan, Jobs, Foreigners, Job Change, IT, Web, Engineers, GitTap',
      og: {
        site_name: 'GitTap',
        title: 'Entry Form | Find IT Jobs in Japan - GitTap',
        description:
          'Entry Form of GitTap. GitTap is a matching platform of IT Jobs in Japan for foreigners. We specialize in finding jobs for all IT Engineers at high-profile Japanese companies. '
      }
    }
  },
  banner: 'Discover ideal IT jobs in Japan',
  title: 'Create your account',
  subtitle: 'By creating an account, you agree to GitTap',
  and: 'and',
  more: '',
  links: {
    termsPre: 'I agree to the ',
    terms: 'Terms of Use',
    and: 'and',
    termsPost: '',
    privacy: 'Privacy Policy',
    handlingPersonalInformation: 'Handling Personal Information',
    login: 'here'
  },
  footer: {
    one: 'or sign in with account below',
    two: 'If you already have a GitTap Account, you can log in'
  },
  form: {
    email: {
      label: 'Email',
      placeholder: 'Please input your email'
    },
    password: {
      label: 'Password',
      placeholder: 'Please create your password'
    }
  },
  create: 'Create a new account',
  validation: {
    password:
      'Password must be at least 8 characters of a combination of numbers and letters',
    required: 'Required.',
    confirm: 'Password and confirm password must be the same'
  },
  success: {
    title: 'Check your email to verify your account.',
    checkEmail:
      'Please check your email from GitTap to Login and complete your profile registration.',
    body: 'Registration is in Process!',
    or: 'or',
    login: 'Login'
  },
  verified: {
    title: 'Your account has been verified!',
    body: 'Please continue to fill in your personal info and let us help you find your ideal job',
    failed: {
      title: 'Verification failed!',
      body: 'The URL is invalid or expired.',
      actionLabel: 'Register Again'
    }
  },
  social: {
    google: 'Create Account with Google',
    facebook: 'Create Account with Facebook',
    linkedIn: 'Create Account with LinkedIn',
    github: 'Create Account with Github'
  },
  loginLabel: {
    google: 'Continue with Google',
    linkedIn: 'Continue with LinkedIn',
    github: 'Continue with Github',
    facebook: 'Continue with Facebook'
  },
  tokenError:
    'This URL has been already used. Please request your inviter to send another URL',
  signupToGitTap: 'Sign up to GitTap',
  alreadyMember: 'Already a Member?',
  orContinueWithEmail: 'or continue with email'
}
