import api from '../index'

const {
  http,
  index,
  show,
  simpleSearch
} = api

/**
 * ===================
 * Company API
 * ===================
 */
export default {
  baseUrl: `${process.env.VUE_APP_CLIENT_URL}/api/v1`,
  url: 'companies',
  http,
  index,
  show,
  simpleSearch,
  /**
   * Search companies
   */
  search (payload) {
    return this.http(this.baseUrl).post(`${this.url}/search`, payload)
  },
  /**
   * company logos
   */
  logos (payload) {
    return this.http(this.baseUrl).get('visitor/company-logo-jobs', payload)
  }
}
