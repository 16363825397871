export default {
  basicInformation: 'Basic Information',
  experience: 'Experience',
  skill: 'Skill',
  career: 'Career',
  education: 'Education',
  language: 'Language',
  licenseAward: 'License / Award',
  preference: 'Preference',
  preview: 'Preview',
  edit: 'Edit Items',
  save: 'Save',
  messageHeader: 'Do you want to delete the item?',
  messageBody: 'After deleting, you need to save.',
  buttonCancel: 'Cancel',
  buttonAction: 'Delete',
  invalidFileSize: 'Photo file size is up to 5MB.',
  invalidFileFormat: 'File must be an image (.jpg, .jpeg, .png).',
  currentWork: 'Current Work',
  periodFrom: 'Period from',
  periodTo: 'to',
  workedInJapan: 'Worked in Japan',
  jobTitle: 'Job Title',
  companyName: 'Company Name',
  companySize: 'Size of Company',
  employmentType: 'Employment Type',
  workDetail: 'Project detail / Work detail',
  selectTemplate: 'Select Template',
  experienceSection: {
    area: 'Area'
  },
  itSkills: 'Used IT Skills',
  roleSize: 'Role / Size',
  addProject: 'Add Project',
  selectTemplate: 'Select Template',
  deleteProject: 'Delete Project',
  experienceSection: {
    area: 'Area'
  },
  careerSection: {
    workSummary: 'Work Summary',
    currentWorkStatus: 'Current Work Status',
    currency: 'Currency',
    annualIncome: 'Annual Income',
    workDetail: 'Work Detail',
    listIsAutomatically: 'list is automatically sorted by the recent work history.'
  },
  introductionVideo: 'Introduction Video',
  uploadVideo: {
    text1: 'Upload file',
    text2: '(up to 50MB)'
  },
  invalidVideoSize: 'Video file size is up to 50MB.',
  invalidVideoFormat: 'File must be an video (.mp4, .mov, .webm).',
  shortVideo: 'Short Video',
  shortVideoDescription: 'You can demonstrate your language skills to companies with a video.',
  deleteVideo: 'Delete Video',
  deleteMessage: 'Do you want to delete the video?',
  languageSection: {
    englishCertificate: 'English Certificate',
    otherLanguage: 'Other Language',
    score: 'Score',
    level: 'Level'
  },
  profileVideo: 'Video',
  fiveSelectMaximum: '5 Items maximum',
  male: 'Male',
  female: 'Female',
  other: 'Other',
  uploadHeader: {
    header: 'Auto-fill profile items',
    body: 'Upload your resume to easy entry',
    button: 'Upload'
  },
  dialogUpload: {
    header: 'Upload your resume to easy entry',
    body: 'Drag and Drop or Browse File',
    message: {
      message1:
        '“Please upload a detailed resume that provides information about your skills and work history.',
      message2: 'You can upload one resume file.”'
    },
    supportedFiles: {
      message: 'Supported file types: .pdf, .doc, .docx, .rtf, .txt',
      fileSize: '(Max size: 5 MB)'
    },
    blankItems: 'Only blank items',
    overwriteItems: 'All overwrite'
  },
  uploadSuccess: {
    header: 'Auto-fill successful',
    body: 'Please confirm the accuracy of the information and make any necessary corrections.',
    checkbox: {
      item1: 'You can also add any items.',
      item2: 'You need to save.'
    }
  },
  emptyField: 'There are unentered data items'
}
