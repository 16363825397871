export default {
  criticalError: {
    title: 'Server Error',
    body: 'We have encountered issues with our server, and our team is working on it. Apologies for any inconvenience this may have caused.'
  },
  internalServerError: 'Oops! Something went wrong.',
  notFound: 'The requested resource was not found.',
  unauthenticated: 'Please login to continue.',
  unauthorized: 'Forbidden',
  unprocessable: 'There was an error with your request.',
  tooManyRequestsError: 'Too many requests.',
  thereWasErrorOnYourRequest: 'There was an error on your request.'
}
