<template>
  <v-app-bar
    app
    color="secondary"
    elevation="0"
    class="toolbar-shadow padding-x"
    absolute
  >
    <v-toolbar flat color="secondary">
      <v-toolbar-title class="mr-2 mt-2">
        <a v-if="isRegistered" href="/">
          <img
            alt="GitTap Logo"
            contain
            :src="require('@/assets/gittap_logo_white.png')"
            width="80"
          />
        </a>
        <a v-else :href="visitorUrl">
          <img
            alt="GitTap Logo"
            contain
            :src="require('@/assets/gittap_logo_white.png')"
            width="80"
          />
        </a>
      </v-toolbar-title>

      <v-spacer />

      <v-toolbar-items v-if="isRegistered">
        <v-btn
          @click="
            gtmParams[$route.name] &&
              customActionPush({ ...gtmParams[$route.name] })
          "
          text
          color="white"
          x-small
          to="/notifications"
        >
          <v-badge
            color="disabled"
            overlap
            dot
            :value="admin_notification.unread"
          >
            <v-icon color="disabled" v-text="'mdi-bell'" />
          </v-badge>
        </v-btn>
      </v-toolbar-items>

      <v-app-bar-nav-icon v-if="show" @click.stop="toggleDrawer(true)">
        <v-icon color="disabled" v-text="menuIcon" />
      </v-app-bar-nav-icon>
    </v-toolbar>
  </v-app-bar>
</template>

<script>
import toolbar from '@/mixins/toolbar'
import AuthService from '@/services/auth'
import GtagAction from '@/mixins/gtag-action'

import { mapState, mapActions } from 'vuex'

export default {
  mixins: [toolbar, GtagAction],
  data() {
    return {
      visitorUrl: process.env.VUE_APP_VISITOR_URL,
      show: false,
      gtmParams: {
        JobSearch: { page: 'LOGJOB', part: 9 },
      },
    }
  },
  computed: {
    ...mapState('applicant', ['auth']),
    ...mapState('messageNotifications', ['admin_notification']),
    ...mapState('core', ['drawer']),
    isRegistered() {
      return this.auth ? !!this.auth.registered_at : false
    },
    menuIcon() {
      if (this.isRegistered) {
        return 'mdi-cog'
      } else return 'mdi-menu'
    },
  },
  async created() {
    if (AuthService.isAuthenticated()) await this.getApplicant()
    this.onShow()
  },
  methods: {
    ...mapActions('applicant', { getApplicant: 'softFetch' }),
    onShow() {
      this.$nextTick(() => {
        this.show = true
      })
    },
  },
}
</script>

<style scoped>
.toolbar-shadow {
  box-shadow: 0 0px 2px 0px rgb(0 0 0 / 2%), 0 4px 5px 0 rgb(0 0 0 / 4%),
    0 2px 2px 0 rgb(0 0 0 / 4%) !important;
}
.padding-x >>> .v-toolbar__content,
.v-toolbar__extension {
  padding: 4px 8px !important;
}
</style>
