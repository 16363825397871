import Vue from 'vue'
import Vuex from 'vuex'

import core from './modules/core'
import dialog from './modules/dialog'
import form from './modules/form'
import httpException from './modules/http-exception'
import snackbar from './modules/snackbar'
import stepper from './modules/stepper'
import pagination from './modules/pagination'
import searchCondition from './modules/search-condition'
import advanceJobSearch from './modules/advance-job-search'
import visitor from './modules/visitor'
import uploadManager from './modules/upload-manager'
import help from './modules/help'
import simpleRegistration from './modules/simple-registration'
import profileSetting from './modules/profile-setting'
import signUp from './modules/sign-up'

// List
import gender from './modules/list/gender'
import nationality from './modules/list/nationality'
import country from './modules/list/country'
import city from './modules/list/city'
import visaStatus from './modules/list/visa-status'
import workPermission from './modules/list/work-permission'
import educationalLevel from './modules/list/educational-level'
import jobPositionCategory from './modules/list/job-position-category'
import yoe from './modules/list/yoe'
import skillCategory from './modules/list/skill-category'
import skill from './modules/list/skill'
import appealPoint from './modules/list/appeal-point'
import industry from './modules/list/industry'
import industryCategory from './modules/list/industry-category'
import jobPosition from './modules/list/job-position'
import jobSearchStatus from './modules/list/job-search-status'
import companySize from './modules/list/company-size'
import currency from './modules/list/currency'
import employmentType from './modules/list/employment-type'
import incomeRange from './modules/list/income-range'
import jlptScoreLevel from './modules/list/jlpt-score-level'
import japaneseLanguageExperience from './modules/list/japanese-language-experience'
import languageCertificate from './modules/list/language-certificate'
import languageScoreLevel from './modules/list/language-score-level'
import language from './modules/list/language'
import workingStatus from './modules/list/working-status'
import writingLevel from './modules/list/writing-level'
import japanPrefecture from './modules/list/japan-prefecture'
import approachList from './modules/list/approach-list'
import deactivateReason from './modules/list/deactivate-reason'
import certificate from './modules/list/certificate'
import emailNotification from './modules/list/email-notification'
import itCertificateCategory from './modules/list/it-certificate-category'
import scoutDeclineReasons from './modules/list/scout-decline-reasons'
import dialingCode from './modules/list/dialing-code'
import jobRole from './modules/list/job-role'
import itSkills from './modules/list/it-skills'
import channel from './modules/list/channel'

// Models
import applicant from './modules/models/applicant'
import applicantPositionExperience from './modules/models/applicant-position-experience'
import applicantBasicInfo from './modules/models/applicant-basic-info'
import applicantEducation from './modules/models/applicant-education'
import applicantSkill from './modules/models/applicant-skill'
import applicantExperience from './modules/models/applicant-experience'
import jobPosting from './modules/models/client/job-posting'
import interest from './modules/models/common/interest'
import apply from './modules/models/common/apply'
import company from './modules/models/company'
import jobPostingDetails from './modules/models/client/job-posting-details'
import popularKeyword from './modules/models/client/popular-keyword'
import videoInterview from './modules/models/common/video-interview'
import applicantCertificate from './modules/models/applicant-certificate'
import applicantAward from './modules/models/applicant-award'
import applicantContactInformation from './modules/models/applicant-contact-information'
import peoplePercent from './modules/models/client/people-percent'
import faq from './modules/models/faq'
import japanRegions from './modules/models/client/japan-regions'
import companyLogoJobs from './modules/models/client/company-logo-jobs'
import autoAuth from './modules/models/client/auto-auth'

// Notifications

import messageNotifications from './modules/notifications/message'

Vue.config.devtools = process.env.VUE_APP_ENABLE_DEV_TOOLS === 'true'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    core,
    dialog,
    form,
    httpException,
    snackbar,
    stepper,
    pagination,
    searchCondition,
    advanceJobSearch,
    visitor,
    uploadManager,
    help,
    simpleRegistration,
    profileSetting,
    signUp,

    // common list
    gender,
    nationality,
    country,
    city,
    visaStatus,
    workPermission,
    educationalLevel,
    jobPositionCategory,
    yoe,
    skillCategory,
    skill,
    appealPoint,
    industry,
    industryCategory,
    jobPosition,
    jobSearchStatus,
    companySize,
    employmentType,
    japaneseLanguageExperience,
    jlptScoreLevel,
    language,
    languageCertificate,
    languageScoreLevel,
    workingStatus,
    currency,
    incomeRange,
    writingLevel,
    japanPrefecture,
    approachList,
    deactivateReason,
    certificate,
    emailNotification,
    itCertificateCategory,
    scoutDeclineReasons,
    dialingCode,
    jobRole,
    itSkills,
    channel,

    // Customer Models
    applicant,
    applicantBasicInfo,
    applicantEducation,
    applicantPositionExperience,
    applicantSkill,
    applicantExperience,
    applicantContactInformation,
    applicantAward,
    applicantCertificate,
    faq,

    // Notifications
    messageNotifications,
    // Client Models
    jobPosting,
    jobPostingDetails,
    popularKeyword,
    peoplePercent,
    japanRegions,
    companyLogoJobs,
    autoAuth,

    // Common Models
    interest,
    apply,
    videoInterview,

    // company Model
    company
  }
})
