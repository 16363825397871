/**
 * -------------------------------------------------
 * Mixins For Auth
 * -------------------------------------------------
 *
 * All form validation must be in "rules" property
 *
 */
import { mapState, mapActions } from 'vuex'

import AuthService from '@/services/auth'
import AuthRequest from '@/api/auth/auth'
import Config from '@/config/app'
import ApiValidator from '@/mixins/api-validator'
import SwitchControl from '@/mixins/switch-control'
import UserRequest from '@/api/auth/user'
import Redirect from '@/mixins/redirect'
import SocialRequest from '@/api/auth/social'
import logger from '@/services/logger'
import utmMappings from '@/utils/utm-mappings'

export default {
  mixins: [ApiValidator, SwitchControl, Redirect],
  data() {
    return {
      redirectRegistrationPage: '/profile-registration',
      redirectChangePasswordPage: '/initial-password-change',
      redirectPage: '/',
      redirectWelcomePage: '/welcome',
      loading: false,
      utmMappings: utmMappings
    }
  },
  computed: {
    ...mapState('applicant', { applicant: 'auth' }),
    /**
     * Is not authenticated user
     *
     * @return bool
     */
    guest() {
      return !AuthService.isAuthenticated()
    },
    /**
     * Is Authenticated User
     *
     * @return bool
     */
    authenticated() {
      return AuthService.isAuthenticated()
    },
    /**
     * Is not authenticated user and not on sign-up route
     *
     * @return bool
     */
    showSignUp() {
      return !AuthService.isAuthenticated() && this.$route.path !== '/sign-up'
    },
    /**
     * Auth User
     *
     * @return {*}
     */
    user() {
      return AuthService.getUser()
    }
  },
  methods: {
    ...mapActions('applicant', { getApplicantRecords: 'getAuth' }),
    ...mapActions('core', ['setLanguage']),
    /**
     * Logout the user
     *
     * @return void
     */
    logout() {
      this.enable('loading')
      AuthRequest.logout().finally(() => {
        AuthService.requestedPage = '/'
        AuthService.flush()
        window.location.replace(`${window.location.origin}/login`)
      })
    },
    /**
     * Action Login
     *
     * @return void
     */
    login() {
      this.enable('loading')

      AuthRequest.login({
        email: this.email,
        password: this.password,
        type: Config.CS_TYPE,
        remember_me: this.rememberMe
      })
        .then(({ data }) => this.onSuccess(data))
        .catch(({ response }) => this.onFailed(response))
        .finally(() => this.disable('loading'))
    },
    /**
     * Action Get Auth for Social
     *
     * @return void
     */
    googleAuth() {
      let { code, state } = this.$route.query
      if (!code) return

      this.enable('loadingGoogle')
      state = JSON.parse(state)
      let param = this.$route.query

      param.type = state.type
      param.token = this.googleToken
      if (this.$route.query.invitation_token !== undefined)
        param.invitation_token = this.$route.query.invitation_token

      // setting access route from cookie
      param = this.getAccessRoute(param)

      // setting access route from cookie
      param = this.getAccessRoute(param)

      SocialRequest.authGoogle(param)
        .then(({ data }) => {
          // flush cookies of access_route
          this.flushAccessRoute()
          this.onSuccess(data)
        })
        .catch(({ response }) => {
          this.onFailed(response)
          this.googleErrorResponse()
        })
        .finally(() => this.disable('loadingGoogle'))
    },

    /**
     * Action Authentication for Facebook Social
     *
     * @return void
     */
    facebookAuth() {
      this.enable('loadingFacebook')
      var param = { type: Config.CS_TYPE }
      if (this.$route.query.invitation_token !== undefined)
        param.invitation_token = this.$route.query.invitation_token

      // setting access route from cookie
      param = this.getAccessRoute(param)
      this.facebookPopup(param)
    },

    /**
     * Action Authentication for LinkedIn Social
     *
     * @return void
     */
    async linkedinAuth() {
      this.enable('loadingLinkedin')
      var param = {
        token: this.linkedinToken,
        type: Config.CS_TYPE
      }
      if (this.$route.query.invitation_token !== undefined)
        param.invitation_token = this.$route.query.invitation_token

      // setting access route from cookie
      param = this.getAccessRoute(param)

      await SocialRequest.authLinkedin(param)
        .then(async ({ data }) => {
          // flush cookies of access_route
          this.flushAccessRoute()

          await this.onSuccess(data)
        })
        .catch(({ response }) => this.onFailed(response))
        .finally(() => this.disable('loadingLinkedin'))
    },

    /**
     * Action Authentication for Github Social
     *
     * @return void
     */
    async githubAuth() {
      this.enable('loadingGithub')
      var param = {
        token: this.githubToken,
        type: Config.CS_TYPE
      }
      if (this.$route.query.invitation_token !== undefined)
        param.invitation_token = this.$route.query.invitation_token

      // setting access route from cookie
      param = this.getAccessRoute(param)

      await SocialRequest.authGithub(param)
        .then(async ({ data }) => {
          // flush cookies of access_route
          this.flushAccessRoute()

          await this.onSuccess(data)
        })
        .catch(({ response }) => this.onFailed(response))
        .finally(() => this.disable('loadingGithub'))
    },

    /**
     * Setup user on success response
     *
     * @param {*} auth
     * @return void
     */
    async onSuccess(auth) {
      AuthService.setAuth(auth)
      let user = null
      try {
        const resp = await UserRequest.auth()
        user = resp.data
      } catch (error) {
        logger.error(error)
      }

      if (user) {
        AuthService.setUser(user)

        const applicant = await this.getApplicantRecords().then((data) => data)

        if (applicant) {
          AuthService.setRegistrationStatus(applicant.current_step)
          AuthService.setApplicantIdToCookie(applicant.id)

          if (applicant.current_step === 4) {
            await this.delay(1000, () =>
              window.location.replace(
                `${window.location.origin + AuthService.requestedPage}`
              )
            )
          } else if (
            AuthService.isHrbcRegistration() ||
            AuthService.isFirstTimeLogin()
          ) {
            await this.delay(1000, () =>
              window.location.replace(
                `${window.location.origin + this.redirectChangePasswordPage}`
              )
            )
          } else {
            await this.delay(1000, () =>
              window.location.replace(
                `${window.location.origin + this.redirectRegistrationPage}`
              )
            )
          }
          // Delayed so that we can redirect first then apply any changes to the locale
          setTimeout(() => this.setLanguage(applicant.locale), 100)
        }
      }
    },

    /**
     * Handle setTimeout in Promise
     *
     * @param {*} response
     * @return void
     */
    delay(time, action) {
      return new Promise((resolve) => {
        setTimeout(action, time)
      })
    },

    /**
     * Handle failed response
     *
     * @param {*} response
     * @return void
     */
    onFailed(response) {
      if (response.status === 422) this.handleApiErrors(response.data.errors)
    },

    /**
     * setting access route
     *
     * @param {*} params
     * @returns
     */
    getAccessRoute (params) {
      let accessRoute = null

      if (this.$cookies.get('utmSource') && this.$cookies.get('utmMedium')) {
        const matchingRoute = this.utmMappings.find(mapping =>
          Object.keys(mapping.conditions).every(key =>
             mapping.conditions[key] === this.$cookies.get(key)
          )
        )

        accessRoute = (typeof matchingRoute === 'undefined') ? null : matchingRoute.accessRoute
      } else {
        let referer = this.$cookies.get('referer') || ''

        if (referer !== '') {
          switch (true) {
            case referer.includes('google'):
              accessRoute = 'Google_Organic Search__'
              break
            case referer.includes('t.co'):
              accessRoute = 'X_Organic Social__'
              break
            case referer.includes('linkedin'):
              accessRoute = 'LinkedIn_Organic Social__'
              break
            case referer.includes('instagram'):
              accessRoute = 'Instagram_Organic Social__'
              break
            case referer.includes('facebook'):
              accessRoute = 'Facebook_Organic Social__'
              break

            default:
              accessRoute = null
              break
          }
        }
      }

      params.access_route = accessRoute
      return params
    },

    /**
     * flush cookie of access route
     */
    flushAccessRoute() {
      this.$cookies.remove('utmSource')
      this.$cookies.remove('utmMedium')
      this.$cookies.remove('referer')
    }
  }
}
