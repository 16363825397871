export default {
  methods: {
    /**
     * Convert an 'Asia/Tokyo' date to local timezone.
     *
     * @param string date 'yyyy-LL-dd hh:mm:ss'
     * @returns DateTime
     */
    asiaTokyoToLocal (date) {
      // Important Note: The format is patterned to the API response timestamps. If ever the format is changed, this will result to Invalid DateTime format.
      return this.$luxon.fromSQL(date, { zone: 'Asia/Tokyo' }).toLocal()
    }
  }
}
