<template>
  <v-row
    v-if="$route.name !== 'Conversation' && !isCampaignPage"
    no-gutters
  >
    <v-col
      cols="12"
      :class="{ 'pt-3 mb-5': !isRegistered }"
    >
      <span
        v-for="(item, index) in links"
        :key="index"
      >
        <v-btn
          class="text-none caption"
          text
          small
          @click="goToLink(item.path)"
        >
          <strong>{{ item.title }}</strong>
        </v-btn>
        <span v-if="index != links.length-1">|</span>
      </span>
    </v-col>

    <v-col
      cols="12"
      :class="{ 'copyright-margin': isRegistered, 'mb-5': !isRegistered }"
    >
      <span class="caption">
        GitTap&copy; Bizmates Inc. All Rights Reserved {{ year }}
      </span>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: {
    year: {
      type: String,
      required: true,
      default: String
    },
    links: {
      type: Array,
      required: true,
      default: Array
    }
  },
  computed: {
    ...mapState('applicant', ['auth']),
    isRegistered () {
      return (this.auth) ? !!this.auth.registered_at : false
    },
    isCampaignPage () {
      return this.$route.name === 'CampaignPage' && this.$vuetify.breakpoint.smAndDown
    }
  },
  methods: {
    goToLink (link) {
      window.open(link, '_blank')
    }
  }
}
</script>

<style scoped>
.copyright-margin {
 margin-bottom: 70px
}
</style>
