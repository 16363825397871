import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VListItem,[_c(VListItemContent,{staticClass:"my-3"},[_c('div',{staticClass:"d-flex align-center justify-center"},[_c(VChip,{staticClass:"mr-2 justify-center",staticStyle:{"min-width":"120px"},attrs:{"small":"","color":_vm.display.color,"outlined":""}},[_c(VIcon,{staticClass:"mr-1",class:_vm.display.class,attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.display.icon)+" ")]),_vm._v(" "+_vm._s(_vm.display.text)+" ")],1),(_vm.withLink)?_c(VBtn,{staticClass:"text-none",attrs:{"text":"","link":"","to":_vm.link}},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_c('span',{staticClass:"mr-5"},[_vm._v(_vm._s(_vm.title))])],1),(_vm.quarantinedFiles)?_c('div',_vm._l((_vm.quarantinedFiles),function(item,index){return _c('div',{key:'quarantinedFiles' + index,staticClass:"d-flex align-center justify-center"},[_c(VChip,{staticClass:"mr-2 justify-center my-1",staticStyle:{"min-width":"120px"},attrs:{"small":"","color":"error","outlined":""}},[_c(VIcon,{staticClass:"mr-1",attrs:{"small":""}},[_vm._v(" mdi-exclamation-thick ")]),_vm._v(" "+_vm._s(_vm.quarantinedFilesErrorMessage)+" ")],1),_c('span',{staticClass:"mr-5"},[_vm._v(" "+_vm._s(item.filename)+" ")])],1)}),0):_vm._e(),_c(VProgressLinear,{staticClass:"my-2",attrs:{"color":_vm.status.toUpperCase() === 'FAILED'? 'red': 'disabled',"height":"15","striped":"","rounded":"","value":_vm.progress,"clickable":false}},[_c('span',[_vm._v(_vm._s(_vm.progress)+"%")]),(_vm.progress > 99)?_c('span',{staticClass:"pl-1"},[_vm._v(_vm._s(_vm.$t('videoInterview.uploadFinalizeMessage')))]):_vm._e()])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }