export default {
  header: {
    referAFriend: {
      text1: 'Refer-a-friend program',
      text2: 'Once your friend comes to interview via GitTap, you will receive 5,000 Yen Amazon Gift Certificate.'
    },
    referAFriend2: '',
    amazon: 'Get',
    amount: '5,000',
    subTitle: 'JPY Amazon Gift certificate!!',
    gittapMessage: 'Why not introduce GitTap to your friends? ',
    subHeaderInvite1: {
      header1: 'INVITE',
      header2: 'YOUR FRIENDS'
    },
    subHeaderInvite2: {
      header1: 'YOUR FRIEND',
      header2: 'HAVE AN INTERVIEW'
    },
    subHeaderInvite3: {
      header1: 'YOU AND YOUR FRIEND',
      header2: 'GET Amazon gift card ¥5,000'
    }
  },
  copyUrl: 'Copy the referral to the clipboard.',
  createNewLink: 'Update URL in template',
  section1: {
    title: 'How to refer a friend',
    subheader: {
      content1: "It's very easy to introduce GitTap to your friends in the following ways:",
      content2: ''
    },
    step1: {
      step: 'Step1',
      content: 'Click "Copy friend referral to clipboard".'
    },
    step2: {
      step: 'Step2',
      content: "And paste the copied referral into your email device's messaging app or social media device."
    },
    step3: {
      step: 'Step3',
      content: 'Your friend can access the GitTap registration screen from the URL link displayed in the message and complete a free registration.',
      content2: '※ Friends who have already registered as a free member can continue to use the registered account.'
    }
  },
  section2: {
    title1: 'Referral benefits are available at one URL per friend.',
    title2: 'If you want to introduce to another friend in a row, please click the "Update URL in template" button, change to another URL and send.',
    content1: '※ If you refresh the screen, the URL will also be updated.',
    content2: '※ If you send the same URL, only those who have completed the procedure from that URL first will be eligible.',
    content3: '※ When sending by LINE or email, the URL will be updated automatically each time you send.'
  },
  section3: {
    title: {
      content1: 'When a friend receives a job application or scout at GitTap and proceeds to an interview with a company',
      content2: ''
    },
    amazon: 'Amazonギフト券',
    amount: '5,000',
    subTitle: '円分をプレゼント！',
    title3: {
      content1: 'In addition, your friend will receive a voucher worth 5,000 yen!',
      amount: '',
      content2: ''
    },
    subcontent1: '※ Your friend must go to the interview within 30 days of their profile registration. ',
    subcontent2: '※ At the end of the month following the month your friend reaches the interview stage, you will receive an email with a code for an Amazon Gift Certificate.'
  },
  section4: {
    title: 'Condition and Important Notice',
    content1: '※ This program is only valid if your friend completes signup and profile registration within one week of the date of your referral message and reaches the interview stage within 30 days. Simply creating an account or applying to some jobs does not qualify you for this campaign.',
    content2: '※ If your friend is already a GitTap user, he or she is not eligible for this campaign.',
    content3: '※ For each referral, we will send you an Amazon Gift Certificate worth 5,000 yen for the referrer and 5,000 yen for the friend. In the unlikely event that you are unable or refuse to receive your reward email (Amazon gift code), you will be deemed to have waived your right to receive it.',
    content4: '※ You may send as many referral messages as you wish, but the maximum number of promotional rewards that can be applied in a month is 5 per referral.',
    content5: '※ The time period in which you may send a referral message to qualify for the campaign is from 10:00 AM on 07 07, 2021 to 11:59 PM on 07 31, 2021.',
    content6: '※ If it is determined that the same user has registered multiple accounts, both the referrer and friend will not be eligible for the campaign and the account may be suspended.',
    content7: '※ This program is subject to change or termination without notice.'
  },
  message:
`This is GitTap Administration Office.
{0} invited to GitTap as "GitTap Refer-a-Friend Program". 
Please sign in using the following URL created only for you. 
{1}

-------------------------
▼ About the GitTap Refer-a-Friend Program
https://cs.gittap.jp/referral-program

If you register your profile on GitTap using the referral URL above and interview with a company within 30 days, both you and your referrer will receive a 5,000 yen Amazon Gift Certificate. 

This is a great opportunity for both the registrar and the referrer. On GitTap, you can apply for jobs and get scouts from companies to get into the selection process (interviews). 
Please use GitTap to have a good job search. 
I wish you the best of luck with your future company! 

*At the end of the month following the month you advance to interview, we will send you an email with a code for an Amazon Gift Certificate.
*If you are selected for an interview, Please report to the GitTap office through the inquiry form. Please note that if you do not get in touch, we can not be able to deliver your gift.
*Please note that your recommender will be notified that you have reached the interview stage, as this is a gift for both you and your recommender. (The companies which are in selection will not be notified to your recommender, only that you have proceeded to the selection stage).

-------------------------
▼ What is GitTap?
〇 Selected jobs for highly skilled engineers.
〇 Find companies that are serious about hiring foreigners.
〇 Apply for jobs, get scouts, and connect directly with companies.
https://www.gittap.jp/
`
}
