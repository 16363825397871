import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.showFooter)?_c(VFooter,{staticClass:"font-weight-regular body-2 footer-position",attrs:{"padless":""}},[_c(VContainer,{staticClass:"pa-0"},[(_vm.footer)?_c(VRow,{attrs:{"justify":"center","no-gutters":""}},[(_vm.footer)?_c(VCol,{attrs:{"cols":"12"}},[_c('AppFooterMobile',{staticClass:"text-center d-flex d-lg-none d-xl-none",attrs:{"year":_vm.year,"links":_vm.links}}),_c('AppFooterDesktop',{staticClass:"d-none d-lg-flex d-xl-flex",attrs:{"year":_vm.year,"links":_vm.links}})],1):_vm._e()],1):_vm._e(),_c(VRow,{attrs:{"no-gutters":""}},[(
          _vm.$vuetify.breakpoint.mdAndDown && _vm.isRegistered && _vm.bottomNavigation
        )?_c(VCol,{staticClass:"pt-15",attrs:{"cols":"12"}},[_c('AppMobileNavigation',{attrs:{"unread-count":_vm.message_unread_count,"approach-unread-count":_vm.approachUnreadCount}})],1):_vm._e()],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }