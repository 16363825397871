export default {
  title: '個人情報',
  edit: '個人情報を編集',
  success: '基本的な個人情報が正常に保存されました',
  labels: {
    name: '名前',
    furigana: 'ふりがな',
    dateOfBirth: '生年月日',
    gender: '性別',
    nationality: '国籍',
    currentCountryResidence: '今住んでいる国',
    currentCityResidence: '今住んでいる都市',
    currentPrefectureResidence: '現在の居住都道府県',
    currentAddress: '現住所',
    permissionToWork: '日本の就労許可（ビザ）',
    visaExpirationDate: 'ビザの有効期限'
  },
  photoAndMovie: '写真と動画',
  residence: '現在の住所',
  visa: 'ビザについて'
}
