/**
 * =======================================
 * API Request for Message threads
 * =======================================
 */
import Config from '@/config/app'
import api from '../index'

const {
  http
} = api

export default {
  baseUrl: Config.services.common.url,
  url: '/api/v2/customer/notification',
  http,

  /**
   * Get applicant notification
   *
   */
  all () {
    return this.http(this.baseUrl).get(`${this.url}/all`)
  }
}
