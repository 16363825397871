export default {
  information: {
    ceoName: 'CEO name',
    location: 'Location',
    founded: 'Founded',
    capital: 'Capital',
    capitalAmount: 'Capital',
    size: 'Company size',
    type: 'Public/Private',
    industry: 'Industry',
    website: 'Website'
  },
  business: 'Business',
  businessDescription: '(description what they do)',
  availableJobs: {
    availableJobs: 'Available Jobs in {company_name}',
    retrievingAvailableJobs: 'Retrieving available jobs...',
    seeAll: 'See all'
  },
  private: 'Private',
  public: 'Public'
}
