import approachList from '@/api/common/approach-list'
import logger from '@/services/logger'

const data = {
  loading: false,
  pinned: {},
  matched: {},
  applied: {},
  interested: {},
  saved: {},
  hidden: {},
  scouted: [],
  recommend: {},
  jobDetailMode: false,
  jobDetails: {},
  spJobDetailsDialogData: {},
  jobDetailsDialogData: {},
  detailsLoading: false,
  hasApproach: [],
  unread: {
    scouted: 0,
    saved: 0,
    applied: 0,
    interested: 0,
    total_count: 0
  },
  tab: 0
}

export default {
  namespaced: true,
  state () {
    return {
      ...data
    }
  },
  mutations: {
    /**
     * Reset the state to default.
     *
     * @param {*} state
     */
    RESET (state) {
      for (const key in state) {
        state[key] = data[key]
      }
    },

    /**
     * Set the loading state.
     *
     * @param {*} state
     * @param {*} loading
     */
    SET_LOADING (state, loading) {
      state.loading = loading
    },

    /**
     * Set the pinned state.
     *
     * @param {*} state
     */
    SET_PINNED (state, pinned) {
      state.pinned = pinned
    },

    /**
     * Set the applied state.
     *
     * @param {*} applied
     */
    SET_APPLIED (state, applied) {
      state.applied = applied
    },

    /**
     * Set the interested state.
     *
     * @param {*} interested
     */
    SET_INTERESTED (state, interested) {
      state.interested = interested
    },

    /**
     * Set the saved state.
     *
     * @param {*} saved
     */
    SET_SAVED (state, saved) {
      state.saved = saved
    },

    /**
     * Set the matched state.
     *
     * @param {*} matched
     */
    SET_MATCHED (state, matched) {
      state.matched = matched
    },

    /**
     * Set the invisible state.
     *
     * @param {*} hidden
     */
    SET_HIDDEN (state, hidden) {
      state.hidden = hidden
    },

    /**
     * Set the scouted state.
     *
     * @param {*} scouted
     */
    SET_SCOUTED (state, scouted) {
      state.scouted = scouted
    },

    /**
     * Set the recommend state.
     *
     * @param {*} recommend
     */
    SET_RECOMMEND (state, recommend) {
      state.recommend = recommend
    },

    /**
     * Set the jobDetailMode state.
     *
     * @param {*} jobDetailMode
     */
    SET_JOB_DETAIL_MODE (state, jobDetailMode) {
      state.jobDetailMode = jobDetailMode
    },

    SET_JOB_DETAILS (state, jobDetails) {
      state.jobDetails = jobDetails
    },

    SET_SP_JOB_DETAILS_DIALOG_DATA (state, data) {
      state.spJobDetailsDialogData = data
    },

    /**
     * Set the showJobDetail state.
     *
     * @param {*} jobDetailMode
     */
    SET_JOB_DETAILS_DIALOG_DATA (state, jobDetailsDialogData) {
      state.jobDetailsDialogData = jobDetailsDialogData
    },
    /**
     * Set the details loading state.
     *
     * @param {*} state
     * @param {*} loading
     */
    SET_DETAIL_LOADING (state, detailsLoading) {
      state.detailsLoading = detailsLoading
    },

    /**
     * Set the scouted state.
     *
     * @param {*} scouted
     */
    SET_UNREAD (state, unread) {
      state.unread = unread
    },

    SET_TAB (state, tab) {
      state.tab = tab
    },

    SET_HAS_APPROACH (state, approach) {
      state.hasApproach = approach
    }
  },
  actions: {
    /**
     * Commit the SET_LOADING mutation.
     *
     * @param {*} loading
     */
    setLoading ({ commit }, loading) {
      commit('SET_LOADING', loading)
    },

    /**
     * Get the pinned list and set the pinned state.
     *
     * @param {*} params
     */
    setPinned ({ commit, dispatch }, params) {
      return approachList.pinned(params)
        .then(({ data }) => commit('SET_PINNED', data))
        .catch(({ response }) => dispatch('httpException/handle', response, { root: true }))
    },

    /**
     * Get the applied list and set the applied state.
     *
     * @param {*} params
     */
    setApplied ({ commit, dispatch }, params) {
      commit('SET_LOADING', true)
      
      return approachList.applied(params)
        .then(({ data }) => commit('SET_APPLIED', data))
        .catch(({ response }) => dispatch('httpException/handle', response, { root: true }))
        .finally(() => commit('SET_LOADING', false))
    },

    /**
     * Get the interested list and set the interested state.
     *
     * @param {*} params
     */
    setInterested ({ commit, dispatch }, params) {
      commit('SET_LOADING', true)

      return approachList.interests(params)
        .then(({ data }) => commit('SET_INTERESTED', data))
        .catch(({ response }) => dispatch('httpException/handle', response, { root: true }))
        .finally(() => commit('SET_LOADING', false))
    },

    /**
     * Get the interested list and set the interested state.
     *
     * @param {*} params
     */
    setSaved ({ commit, dispatch }, params) {
      commit('SET_LOADING', true)

      return approachList.saved(params)
        .then(({ data }) => commit('SET_SAVED', data))
        .catch(({ response }) => dispatch('httpException/handle', response, { root: true }))
        .finally(() => commit('SET_LOADING', false))
    },

    /**
     * Get the matched list and set the matched state.
     *
     * @param {*} params
     */
    setMatched ({ commit, dispatch }, params) {
      return approachList.matched(params)
        .then(({ data }) => commit('SET_MATCHED', data))
        .catch(({ response }) => dispatch('httpException/handle', response, { root: true }))
    },

    /**
     * Get the pinned list and set the pinned state.
     *
     * @param {*} params
     */
    setHidden ({ commit, dispatch }, params) {
      commit('SET_LOADING', true)

      return approachList.hidden(params)
        .then(({ data }) => commit('SET_HIDDEN', data))
        .catch(({ response }) => dispatch('httpException/handle', response, { root: true }))
        .finally(() => commit('SET_LOADING', false))
    },

    /**
     * Get the scouted list and set the scouted state.
     *
     * @param {*} params
     */
    setScouted ({ commit, dispatch }) {
      commit('SET_LOADING', true)
      
      return approachList.scouts()
        .then(({ data }) => commit('SET_SCOUTED', data))
        .catch(({ response }) => dispatch('httpException/handle', response, { root: true }))
        .finally(() => commit('SET_LOADING', false))
    },

    /**
     * Get the recommendation list and set the recommend state.
     *
     * @param {*} params
     */
    setRecommend ({ commit, dispatch }) {
      commit('SET_LOADING', true)
      
      return approachList.recommend()
        .then(({ data }) => commit('SET_RECOMMEND', data))
        .catch(({ response }) => dispatch('httpException/handle', response, { root: true }))
        .finally(() => commit('SET_LOADING', false))
    },

    /**
     * Fetch unread approach count.
     *
     * @param {*} params
     */
    fetchUnreadApproachCount ({ commit, dispatch }) {
      return approachList.unread()
        .then(({ data }) => commit('SET_UNREAD', data))
        .catch(({ response }) => dispatch('httpException/handle', response, { root: true }))
    },

    /**
     * Commit the RESET mutation
     *
     * @param {*} param
     */
    reset ({ commit }) {
      commit('RESET')
    },

    /**
     * Commit the jobDetailMode
     *
     * @param {*} jobDetailMode
     */
    setJobDetailMode ({ commit }, jobDetailMode) {
      commit('SET_JOB_DETAIL_MODE', jobDetailMode)
    },

    setSpJobDetailsDialogData ({ commit }, data) {
      commit('SET_SP_JOB_DETAILS_DIALOG_DATA', data)
    },

    fetchApproachJobDetails ({ commit, dispatch }, id) {
      commit('SET_DETAIL_LOADING', true)

      return approachList.jobDetails(id)
        .then(({ data }) => {
          commit('SET_JOB_DETAILS', data)

          return data
        })
        .catch(({ response }) => {
          dispatch('httpException/handle', response, { root: true })
        })
        .finally(() => {
          commit('SET_DETAIL_LOADING', false)
        })
    },

    storeSave ({ commit, dispatch, state }, id) {
      commit('SET_DETAIL_LOADING', true)

      const params = { job_posting_id: id }

      approachList.save(params)
        .then(() =>  {
          dispatch('refreshItems')
          dispatch('fetchApproachJobDetails', id)
        })
        .catch(error => logger.error(error))
        .finally(() =>  commit('SET_DETAIL_LOADING', false))
    },

    setTab ({ commit }, tab) {
      commit('SET_TAB', tab)
    },

    refreshItems({ state, dispatch }) {
      switch (state.tab) {
        case 'scouted':
          dispatch('setScouted')
          break
        case 'interested':
          dispatch('setInterested')
          break
        case 'saved':
          dispatch('setSaved')
          break
        case 'applied':
          dispatch('setApplied')
          break
      }
      dispatch('fetchUnreadApproachCount')
    },

    readJobDetails ({ commit, dispatch }, params) {
      return approachList.read(params)
        .then(({ data }) => {
          return data
        })
        .catch(({ response }) => {
          dispatch('httpException/handle', response, { root: true })
        })
    },

    async fetchHasApproach ({ commit, state }, params) {
      // Check if approach has already exists
      const approach = state.hasApproach.find((item) => (item.job_posting_id === params.job_posting_id))
      if (approach) return

      return await approachList.hasApproach(params)
        .then(({ data }) =>  {
          const approaches = state.hasApproach
          if (!data.job_posting_id) data.job_posting_id = params.job_posting_id
          approaches.push(data)
          commit('SET_HAS_APPROACH', approaches)
        })
        .catch(error => logger.error(error))
    },

    /**
     * Commit the jobDetailMode
     *
     * @param {*} jobDetailMode
     */
    setJobDetailsDialogData ({ commit }, jobDetailsDialogData) {
      commit('SET_JOB_DETAILS_DIALOG_DATA', jobDetailsDialogData)
    },

    /**
     * Commit the SET_DETAIL_LOADING mutation.
     *
     * @param {*} loading
     */
    setDetailsLoading ({ commit }, detailsLoading) {
      commit('SET_DETAIL_LOADING', detailsLoading)
    }
  }
}
