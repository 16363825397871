<template>
  <v-form>
    <!-- For Employer Redirection in Visitor -->
    <!-- <v-btn
      v-if="guest"
      text
      class="text-none mr-3"
      @click="goToVisitor"
    >
      <span class="font-weight-medium">
        {{ $t('toolbar.forEmployer') }}
      </span>
    </v-btn> -->
    <v-menu
      v-if="guest"
      bottom
      left
      offset-y
    >
      <template v-slot:activator="{ on }">
        <v-btn
          text
          class="align-self-center text-none"
          v-on="on"
          :ripple="false"
        >
          <v-icon
            left
            size="24"
            color="grey"
            v-text="'mdi-web'"
          />
          <span class="font-weight-medium"> {{ selected.label }}</span>
          <v-icon
            right
            size="24"
            color="grey"
            v-text="'mdi-menu-down'"
          />
        </v-btn>
      </template>

      <v-card>
        <v-card-text>
          <small>{{ $t('toolbar.language') }}</small>

          <v-radio-group
            :value="selected.value"
            dense
            @change="onLanguageChange"
          >
            <v-radio
              v-for="(item, i) in languages"
              :key="i"
              :label="item.label"
              :value="item.value"
            />
          </v-radio-group>
        </v-card-text>
      </v-card>
    </v-menu>
    <v-btn
      v-if="guest"
      text
      to="/login"
      class="text-none mr-3 ml-3"
      :ripple="false"
    >
      <v-icon
        left
        size="24"
        color="grey"
        v-text="'mdi-account-circle'"
      />
      <span class="font-weight-medium">{{ $t('navigation./login') }}</span>
    </v-btn>

    <v-btn
      v-if="showSignUp"
      class="elevation-0 warning"
      width="150"
      depressed
      to="/sign-up"
      tile
      height="48"
      :ripple="false"
    >
      <span>{{ $t('navigation./sign-up') }}</span>
    </v-btn>
  </v-form>
</template>
<script>
import Auth from '@/mixins/auth'

import toolbar from '@/mixins/toolbar'

export default {
  mixins: [Auth, toolbar],
  data () {
    return {
      visitorUrl: process.env.VUE_APP_VISITOR_URL
    }
  },
  methods: {
    goToVisitor () {
      window.open(`${this.visitorUrl}/client`, '_blank')
    }
  }

}
</script>

<style scoped>
.v-btn {
  letter-spacing: 0;
  color: var(--v-text-base);
}
.v-btn:hover::before {
  opacity: 0;
}
.v-btn >>> .v-btn__content i {
  color: var(--v-disabled-base) !important;
}
</style>
