export default {
  tags: {
    title: 'Login',
    meta: {
      description: 'This page is the login page.',
      keywords: 'Login, Japan, Jobs, Foreigners, Job Change, IT, Web, Engineers, GitTap',
      og: {
        site_name: 'GitTap',
        title: 'Login | Find IT Jobs in Japan - GitTap',
        description: 'GitTap Login Here! GitTap is a matching platform of IT Jobs in Japan for foreigners. We specialize in finding jobs for all IT Engineers, such as software developers, programmers, and project managers at high-profile Japanese companies. '
      }
    }
  },
  username: 'ユーザー名/メール',
  password: 'パスワード',
  login: 'ログイン',
  forgotPassword: 'パスワードをお忘れの方',
  registration: {
    tagline: 'GitTapは初めてですか？',
    callToAction: '今すぐ参加'
  },
  placeholders: {
    email: 'メールアドレスを入力してください',
    password: 'パスワードを入力してください'
  },
  rememberMe: 'ログイン情報を保存する',
  social: {
    google: 'Googleでログイン',
    facebook: 'Facebookでログイン',
    linkedIn: 'LinkedInでログイン',
    github: 'Githubでログイン'
  }
}
