import currency from '@/api/customer/master/currency'

const data = {
  items: sessionStorage.getItem('currencies')
    ? JSON.parse(sessionStorage.getItem('currencies'))
    : [],
  selected: {},
  loading: false
}

export default {
  namespaced: true,
  state () {
    return {
      ...data
    }
  },
  mutations: {
    /**
     * Set the status.
     *
     * @param {*} state
     * @param {*} items
     */
    SET_LOADING (state, loading) {
      state.loading = loading
    },

    /**
     * Set the items.
     *
     * @param {*} state
     * @param {*} items
     */
    SET_ITEMS (state, items) {
      state.items = items
    },

    /**
     * Set the items.
     *
     * @param {*} state
     * @param {*} selected
     */
    SET_SELECTED (state, selected) {
      state.selected = selected
    },

    /**
     * Reset the state to default.
     *
     * @param {*} state
     */
    RESET (state) {
      for (const key in state) {
        state[key] = data[key]
      }
    }
  },
  actions: {

    /**
     * Get all currency records
     */
    all ({ commit, dispatch, state }) {
      if (state.items.length) return

      const params = {
        with: ['incomeRanges']
      }

      commit('SET_LOADING', true)

      return currency.simpleSearch(params)
        .then(({ data }) => {
          commit('SET_ITEMS', data)

          sessionStorage.setItem('currencies', JSON.stringify(data))
        })
        .catch(({ response }) => dispatch('httpException/handle', response, { root: true }))
        .finally(() => commit('SET_LOADING', false))
    },

    /**
     * Set item in selected state
     *
     * @param {*} param0
     * @param {*} item
     */
    setSelectedItem ({ commit }, item) {
      commit('SET_SELECTED', item)
    }
  }
}
