export default {
  advanceSearchBtn: {
    search: 'Search', // DRP-2103 - English even on JP display mode.
    filter: 'Filters' // DRP-2103 - English even on JP display mode.
  },
  saveSearchBtn: {
    saveSearch: '検索結果',
    result: 'を保存'
  },
  advanceSearchConditionBtn: '検索条件を保存',
  searchesBtn: '保存した検索結果',
  noResult: {
    title: '検索に一致する求人は見つかりませんでした',
    subtitle: '結果がありません',
    one: 'すべての単語のスペルが正しいことを確認してください',
    two: 'より一般的なキーワードを試す',
    three: '略語を単語全体に置き換える',
    four: 'スペルを確認してください'
  },
  subtitle: '応募してもよろしいですか？',
  profileEdit: 'プロフィールを確認する',
  profileEditBtn: 'プロフィールを確認',
  backBtn: '応募に戻る',
  applyPage: '詳細を確認',
  applyComplete: '求人の応募が完了しました！',
  similarJobs: 'あなたにおすすめの求人',
  searchJobs: '求人検索',
  moreJobs: 'もっと求人を探す',
  searchMoreJobs: '求人一覧へ',
  /**
   * Advance Search Messages
   */
  keyword: 'キーワード',
  searchFilters: '検索フィルター',
  popularKeyword: '人気のキーワード',
  preferredLocation: '勤務地',
  selectLocation: '勤務地を選択',
  city: '勤務地を選択',
  addLocation: '他の場所を追加',
  jobRole: '職種',
  selectJobRole: '職種の選択',
  addJobRole: 'ジョブロールの追加',
  priorityJob: 'こだわりポイント',
  employmentType: '雇用形態',
  industry: '業界',
  selectIndustry: '業界の選択',
  addIndustry: '業界の追加',
  featuredITSkill: '経験分野',
  featuredITSkillPlaceholder: '経験分野を選択',
  requiredJapanese: '必須日本語',
  requiredEnglish: '必要な英語',
  ITSkills: 'スキル',
  selectITSkill: 'ITスキルの選択',
  addITSkill: 'ITスキルの追加',
  language: '必要な言語能力',
  languageScoreLevel: 'ライティング',
  typeLanguage: '選択する',
  selectLevel: 'レベルの選択',
  selectLanguageLevel: 'レベルの選択',
  jlpt: 'JLPT（日本語能力試験）',
  preferredIncome: '年収',
  selectAnnualIncome: '年収を選択',
  postedTime: '掲載日',
  lastWeek: '先週',
  last2Weeks: '2週間以内',
  last3Weeks: '3週間以内',
  last4Weeks: '4週間以内',

  confirmation: {
    title: '最終確認',
    description: '応募にあたって以下の質問にお答えください。',
    error: 'すべての質問に親切に答えてください',
    yes: 'はい',
    no: 'いいえ'
  },
  nationwide: '全国',
  fromJapan: '国内リモートワーク',
  fromOverseas: '海外リモートワーク',
  remoteWorkInJapan: '国内リモートワーク',
  remoteWorkFromOutsideJapan: '海外リモートワーク',
  rateOfForeigners: '業務で関わる方々の中で外国籍メンバーの割合（概算で可）',
  frequencyOfEnglishUsage: '業務において英語を利用する割合（概算で可）',
  remoteWorkTitle: 'リモートワーク',
  selectAreaOfExpertise: '専門分野を選択'
}
