import applicantEducationApi from '@/api/customer/applicant-education'

export default {
  namespaced: true,
  state () {
    return {
      maxEducation: 7,
      loading: false,
      educations: [],
      softDeletedItems: [],
      form: {
        id: 0,
        school: null,
        url: null,
        educational_level_id: null,
        major: null,
        location: null,
        start_date: null,
        end_date: null
      }
    }
  },
  getters: {
    /**
     * Check if educations is empty
     * @return bool
     */
    isEmptyList: state => {
      return state.educations.length <= 0
    },
    /**
     * Check if records has unsaved item
     * @return bool
     */
    hasUnsaved: state => {
      return state.educations.filter((item) => item.id === 0).length
    },
    /**
     * Check if records has soft deleted item
     * @return bool
     */
    hasSoftDeleted: state => {
      return state.softDeletedItems.length > 0
    }
  },
  mutations: {
    /**
     * Set the status.
     *
     * @param {*} state
     * @param {*} items
     */
    setLoading (state, loading) {
      state.loading = loading
    },
    /**
     * Add Item
     *
     * @param {*} state
     */
    addEducation (state) {
      state.educations.push(state.form)
    },
    /**
     * Remove an item
     *
     * @param {*} state
     * @param {*} index
     */
    removeEducation (state, index) {
      const item = state.educations[index]
      if (item.id !== 0) state.softDeletedItems.push(item)
      state.educations.splice(index, 1)
    },
    /**
     * Set the item.
     *
     * @param {*} state
     * @param {*} data
     */
    setEducations (state, data) {
      state.educations = data
    },
    /**
     * Set soft deleted items
     * @param {*} state
     * @param {*} data
     */
    setSoftDeletedItems (state, data) {
      state.softDeletedItems = data
    }
  },
  actions: {
    /**
     * Restore soft deleted item
     * @param {state, commit}
     */
    restore ({ state, commit }) {
      const items = state.educations.concat(state.softDeletedItems)

      commit('setSoftDeletedItems', [])
      commit('setEducations', items)
    },
    /**
     * Remove unsaved deleted item
     *
     * @param { state, commit}
     */
    removeUnsaved ({ state, commit }) {
      const items = state.educations.filter((item) => {
        return item.id !== 0
      })

      commit('setEducations', items)
    },
    /**
     * Soft fetch data
     * If data is already populated skip the process
     *
     * @param { getters, dispatch}
     */
    async softFetch ({ getters, dispatch }, params) {
      if (getters.isEmptyList) {
        await dispatch('fetch', params)
      }
    },
    /**
     * Get Applicant Education
     *
     * @param {commit, dispatch, getters }
     * @param {*} params
     */
    fetch ({ commit, dispatch }, params) {
      commit('setLoading', true)
      return applicantEducationApi.simpleSearch(params)
        .then(({ data }) => {
          commit('setEducations', data)
        })
        .catch(({ response }) => {
          dispatch('httpException/handle', response, { root: true })
        })
        .finally(() => {
          commit('setLoading', false)
        })
    }
  }
}
