export default {
  title: 'Award/Pinned Works',
  edit: 'Edit Award/Pinned Works',
  success: 'Award/Pinned Works Saved Successfully',
  add: 'Add More',
  dialog: {
    exists: 'Award/Pinned Work already exists.',
    title: 'Add Award/Pinned Work',
    form: {
      name: 'Award Name'
    }
  }
}
