export default {
  profileSettings: {
    title: 'Profile Settings',
    basicInformation: {
      title: 'Basic Information',
      subtitle: 'Photo / Personal / Residence / Visa'
    },
    job: {
      title: 'Job',
      subtitle: 'Job Experience',
      selectFromList: {
        cantFind: 'Can\'t find?',
        selected: 'Selected',
        new: 'New',
        add: 'Add',
        cancel: 'Cancel',
        seeMore: 'See More',
        selectJobRole: 'Select Job Role',
        list: 'List'
      },
      required: 'Required',
      years: 'Years',
      selectFromListText: 'Select from list',
      jobRole: 'Job Role',
      save: 'Save',
      category: 'Category',
      selectedJobRole: 'Selected Job Role',
      addJobRole: 'Add Job Role'
    },
    skill: {
      title: 'Skill',
      subtitle: 'IT Skill / Git / Experienced area',
      selectFromList: {
        selectITSkill: 'Select IT Skill'
      },
      languageOrTools: 'Language or Tools',
      addITSkill: 'Add IT Skill',
      selectedITSkill: 'Selected IT Skill'
    },
    workExperience: {
      title: 'Work Experience',
      subtitle: 'Summary / Details'
    },
    preference: {
      title: 'Preference',
      subtitle: 'Preferred Items'
    },
    licenseAward: {
      title: 'License / Award',
      subtitle: 'Certification / Pinned Works',
      licenseOrAwardPlaceholder: 'License or Award',
      addLicenseOrAward: 'Add License / Award',
      licenseOrCertification: 'License / Certification',
      yearOfAcquisition: 'Year of Acquisition',
      award: 'Award',
      license: 'License'
    },
    education: {
      title: 'Education',
      subtitle: 'School'
    },
    language: {
      title: 'Language',
      subtitle: 'Language Skill / Certification'
    },
    preview: 'Preview',
    addFromList: 'Add from List'
  },
  accountSettings: {
    title: 'Account Settings',
    credentials: {
      title: 'ID / Password',
      subtitle: 'ID / Password / Phone / Additional Information',
      loginFields: {
        login: 'Login ID',
        password: 'Password'
      },
      phoneFields: {
        label: 'Phone',
        countryCode: 'Country Code',
        phoneNumber: 'Phone Number'
      },
      additionalFields: {
        label: 'Additional Information',
        linkedIn: 'LinkedIn'
      }
    },
    email: {
      title: 'Email',
      subtitle: 'Subscribe Email'
    },
    exclude: {
      title: 'Exclude',
      subtitle: 'Companies to exclude'
    },
    inform: {
      title: 'Inform',
      subtitle: 'Inform got offer / Get Amazon gift'
    },
    private: {
      title: 'Private',
      subtitle: 'Private / Delete'
    },
    delete: {
      title: 'Delete',
      subtitle: 'Delete Account'
    }
  }
}
