export default {
  policy: {
    text: 'プライバシーポリシー'
  },
  mainHeader: {
    text: 'ビズメイツ株式会社（以下、「当社」）は、以下のとおりプライバシーポリシー（以下、「当ポリシー」）を定め、これに従い、当社が提供するサービス（有償無償を問わず、またサイト訪問や電子メールによる照会等を含みます。以下、「サービス」）をご利用いただくお客様（以下、「お客様」）から当社が収集した個人情報の管理に最大限の注意を払い、これを取り扱うものとします。'
  },
  numeric: [
    {
      title: '個人情報',
      content: '個人情報とは、当社のサービスをご利用いただくにあたりご提供いただく氏名、性別、生年月日、メールアドレスなど個人を識別できる情報を指します。当社が収集する個人情報は、お客様が当社のウェブサイト（以下「当サイト」）にてご登録いただく情報その他サービスに関連してご提供いただく情報及びお客様が当サイトをご利用されるにあたって当社が収集する情報に限られます。'
    },
    {
      title: '個人情報の適切な取得、利用、提供に関して',
      numbered: true,
      bullets: [
        {
          content: '当社では、事業の内容および規模を考慮し、業務遂行に必要な範囲で個人情報を適切な方法で取得します。個人情報の利用は、特定した利用目的の範囲内とし、利用目的を変更する際には、あらかじめご本人に通知し、同意を得た上でおこないます。'
        },
        {
          content: '個人情報は、利用目的を遂行するために業務を委託する場合を除き、ご本人の同意なく、第三者に提供することはございません。但し、以下の場合は、ご本人の同意なく個人情報を提供する場合があります。',
          romans: [
            {
              numeral: 'a.',
              content: '法令に基づく場合'
            },
            {
              numeral: 'b.',
              content: '人の生命、身体または財産の保護のために必要がある場合であって、ご本人の同意を得ることが困難である場合'
            },
            {
              numeral: 'c.',
              content: '公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、ご本人の承諾を得ることが困難である場合'
            },
            {
              numeral: 'd.',
              content: '国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合で、ご本人の同意を得ることによりその事務の遂行に支障を及ぼすおそれがある場合'
            },
            {
              numeral: 'e.',
              content: '合併その他の事由による事業の承継に伴い個人情報を提供する場合であって、承継前の利用目的の範囲で取り扱われる場合'
            }
          ]
        },
        {
          content: '当社は、個人情報の取り扱いを第三者に委託する場合であっても、お客様の個人情報の安全管理が図れるよう、当社は当該委託先に対して、必要かつ適切な監督を行います。'
        },
        {
          content: '目的外の利用や提供を防止するため、個人情報の利用および提供は、個人情報保護管理者が、その適正性を確認した上でおこなっています。また、自主点検、内部監査等の活動を通じた統制活動も強化しております。'
        }
      ]
    },
    {
      title: '個人情報保護のための安全管理策',
      numBullets: [
        {
          label: '個人情報の漏えい、滅失、き損等に対する予防処置',
          content: '当社では、個人情報の漏洩、滅失、き損等を防止するための適切なセキュリティ対策を実施しております。また、これらを徹底するため、従業員への教育・監督をおこなっています。個人情報取り扱い業務を社外に委託する場合は、委託先の選定や監督を厳重におこなっております。'
        },
        {
          label: '個人情報の漏えい、滅失、き損等に対する是正処置',
          content: '個人情報の漏えい、滅失、き損等が発生した場合は、被害拡大防止を最優先とし、速やかに是正処置を講じるとともに、原因究明をおこない、再発防止に努めます。また、事故に関する情報について、必要な範囲で関係者、関係機関に報告します。'
        }
      ]
    },
    {
      title: '法令、国が定める指針、その他の規範の遵守',
      content: '当社は、個人情報の取り扱いに関する法律および関連法令、国が定める指針、所属団体等の規範等を遵守します。'
    },
    {
      title: '個人情報保護マネジメントシステムの継続的改善',
      content: '当社は、個人情報の保護に関するマネジメントシステムを実行し、維持するとともに定期的に見直しをおこない、継続的改善に努めます。'
    },
    {
      title: '統計データの利用',
      content: '当社は提供を受けた個人情報をもとに、個人を特定できないように加工した統計データを作成することがあります。個人を特定できない統計データについては、当社は何ら制限なく利用することができるものとします。'
    },
    {
      title: '注意事項',
      numBullets: [
        {
          content: 'オンライン英会話事業（オンライン英会話事業、海外現地研修事業等）及びオンライン日本語会話事業について',
          romans: [
            {
              numeral: 'a.',
              content: 'スカイプ名は、スカイプのソフトウェアなどで検索可能な公開情報であり、また、レッスン提供を目的として当社のトレーナーに対して公開する情報です。 そのため、スカイプ名は、当社が必要かつ適切な措置を講じて保護・管理する対象の個人情報には該当しません。お客様は、プライベートで使用するものとは異なるスカイプ名で当社に登録するなど、ご自身の個人情報を保護・管理するにあたり合理的かつ必要な予防措置を講じていただきますようお願いいたします。'
            },
            {
              numeral: 'b.',
              content: '当社のトレーナーがレッスン中に収集する情報のうち、レッスンで利用する目的で得た個人を識別できる情報は個人情報に含まれます。'
            },
            {
              numeral: 'c.',
              content: 'オンライン、オフラインを問わず、お客様が当社のサービス以外においてトレーナーと接触することを禁じます。トレーナーとの個人的な接触が原因となってお客様の個人情報が流出した場合は、当社は一切の責任を負いません。'
            }
          ]
        },
        {
          content: '人材紹介事業について',
          romans: [
            {
              numeral: 'a.',
              content: '当社のアドバイザーがカウンセリング中に収集する情報のうち、個人を識別できる情報は個人情報に含まれます。'
            },
            {
              numeral: 'b.',
              content: 'お客様が当社のサービス以外においてアドバイザーと接触することを禁じます。アドバイザーとの個人的な接触が原因となってお客様の個人情報が流出した場合は、当社は一切の責任を負いません。'
            }
          ]
        }
      ]
    },
    {
      title: '個人情報に関する苦情、相談への対応',
      content: '当社に対して、個人情報の取り扱いに関する苦情、相談をおこなう場合は、下記の「個人情報お問い合わせ窓口」までご連絡ください。また、当社の個人情報保護に関するお問い合わせについても下記にて対応いたします。 ビズメイツ株式会社 support@bizmates.jp'
    },
    {
      title: '本方針の変更',
      content: '本方針の内容は事前承認を得ることなく変更されることがあります。 変更後の本方針については、当社が別途定める場合を除いて、当サイトに掲載した時から効力を生じるものとします。'
    }
  ],
  childHeader: {
    numeric: true,
    text: '個人情報に関する公表事項',
    title: '個人情報の利用目的の公表に関する事項',
    content: 'ビズメイツ株式会社（以下、「当社」という）が取り扱う個人情報の利用目的は、下表の通りです。（※）が付された個人情報については、開示対象個人情報として、当社に対して利用目的の通知、開示、内容の訂正、追加または削除、利用の停止、消去および第三者への提供の停止のご請求をおこなうことができます。'
  },
  tables: {
    headers: ['個人情報の種類', '利用目的'],
    numeric: true,
    content: [
      {
        label: '全部事業共通',
        rows: [
          {
            content: [
              {
                desc: [
                  'お客様情報※',
                  '（ご登録頂いた氏名、性別、生年月日、メールアドレス等の情報及び端末情報、ログ情報、Cookie情報、位置情報、インターネット上の行動履歴情報等。）'
                ]
              },
              {
                desc: [
                  'お申し込みいただいた商品、サービスの提供のため',
                  '商品、サービスに関する当社からのご案内、お知らせのため',
                  '顧客動向の分析、商品開発等の調査・分析のため',
                  'トラブル発生時や災害等の緊急事態が発生した際の対応のため',
                  'サービス利用料金の精算、遅延金等の回収のため',
                  '本人確認のため'
                ]
              }
            ]
          },
          {
            content: [
              {
                desc: [
                  '取引先情報※'
                ]
              },
              {
                desc: [
                  '顧客管理・マーケティング利用のため'
                ]
              }
            ]
          },
          {
            content: [
              {
                desc: [
                  'セミナー参加者情報※'
                ]
              },
              {
                desc: [
                  '商品、サービスに関する当社からのご案内、お知らせのため'
                ]
              }
            ]
          },
          {
            content: [
              {
                desc: [
                  '各種お問い合わせ情報※'
                ]
              },
              {
                desc: [
                  '各種お問い合わせ対応のため'
                ]
              }
            ]
          },
          {
            content: [
              {
                desc: [
                  '採用応募者情報※'
                ]
              },
              {
                desc: [
                  '弊社が必要とする人材の採用判断のため'
                ]
              }
            ]
          },
          {
            content: [
              {
                desc: [
                  '在職者情報※'
                ]
              },
              {
                desc: [
                  '人事管理業務および業務の連絡のため'
                ]
              }
            ]
          },
          {
            content: [
              {
                desc: [
                  '当社の役員、従業員とその家族および退職者に関する情報※'
                ]
              },
              {
                desc: [
                  '法令に基づく従業員情報の取得、管理のため',
                  '従業員の健康管理のため',
                  '人事給与業務の履行のため',
                  '福利厚生のため',
                  '災害等の緊急時の連絡のため',
                  'その他、人事・労務管理上必要な処理のため'
                ]
              }
            ]
          }
        ]
      },
      {
        label: 'オンライン英会話事業及びオンライン日本語会話事業',
        rows: [
          {
            content: [
              {
                desc: [
                  '法人受講者情報'
                ]
              },
              {
                desc: [
                  '上記「お客様情報」の利用目的と同様'
                ]
              }
            ]
          }
        ]
      },
      {
        label: '人材紹介事業',
        rows: [
          {
            content: [
              {
                desc: [
                  '申し込み希望者および利用者の情報※'
                ]
              },
              {
                desc: [
                  '当社からの仕事紹介、仕事に関するご連絡等、サービス提供のため',
                  '商品、サービスに関する当社からのご案内、お知らせのため',
                  '顧客動向の分析、商品開発等の調査・分析のため',
                  'トラブル発生時や災害等の緊急事態が発生した際の対応のため',
                  '損害賠償金等の回収のため',
                  '本人確認のため'
                ]
              }
            ]
          },
          {
            content: [
              {
                desc: [
                  '求人企業の情報※'
                ]
              },
              {
                desc: [
                  '当社からの人材の紹介、お申し込みいただいた商品、サービスの提供のため',
                  '商品、サービスに関する当社からのご案内、お知らせのため',
                  '顧客動向の分析、商品開発等の調査・分析のため',
                  'トラブル発生時や災害等の緊急事態が発生した際の対応のため',
                  'サービス利用料金の精算、遅延金等の回収のため'
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  info: [
    {
      label: '開示対象個人情報に関する周知事項',
      numeric: true,
      content: [
        '当社は、開示対象個人情報の取り扱いにあたり、下記の事項を公表いたします。'
      ],
      numBullet: [
        {
          label: '当社の名称',
          content: [
            'ビズメイツ株式会社（東京都千代田区）'
          ]
        },
        {
          label: '個人情報保護管理者',
          content: [
            'マーケティング責任者（連絡先：privacy@bizmates.jp）'
          ]
        },
        {
          label: 'すべての開示対象個人情報の利用目的',
          content: [
            '「１．個人情報の利用目的の公表に関する事項」参照'
          ]
        },
        {
          label: '開示対象個人情報の取り扱いに関するお問い合わせ・苦情等の申し出先',
          content: [
            '個人情報お問い合わせ窓口：個人情報保護管理者',
            '住所：東京都千代田区神田須田町1-7-9 VORT秋葉原maxim 10階',
            '連絡先：privacy@bizmates.jp'
          ]
        },
        {
          label: '当社が属する認定個人情報保護団体の名称および苦情の解決の申し出先',
          content: [
            '認定個人情報保護団体の名称：一般財団法人日本情報経済社会推進協会',
            '苦情の解決の申出先：個人情報保護苦情相談室',
            '住所：〒106-0032 東京都港区六本木一丁目９番９号六本木ファーストビル内',
            '電話番号：03-5860-7565、0120-700-779'
          ]
        },
        {
          label: '開示等の請求方法',
          content: [
            '「３．個人情報の開示等のご請求に応じる手続き」参照'
          ]
        }
      ]
    },
    {
      label: '個人情報の開示等のご請求に応じる手続き',
      numeric: true,
      content: [
        '当社は個人情報の利用目的の通知、開示、内容の訂正・追加・削除、利用の停止、消去または第三者への提供停止のご請求および個人情報に関する苦情・ご相談については、個人情報お問い合わせ窓口（privacy@bizmates.jp）にて承ります。ただし、ご請求いただいた個人情報につき、法令等の規定により個人情報を開示できない場合や当社の保管期間を過ぎた場合等については、お客様の個人情報の利用目的の通知、開示、内容の訂正・追加・削除、利用の停止、消去、または第三者への提供停止に応じることができませんのでご了承ください。',
        '個人情報の開示等をご請求される皆さまは、個人情報お問い合わせ窓口までお問い合わせください。所定の様式（個人情報お問い合わせ申請書）をお送りしますので、必要事項をご記入の上、下記住所までご送付ください。'
      ],
      note: [
        '【ご送付いただくもの】',
        '【ご送付先】'
      ],
      numBullet: [
        {
          label: '「個人情報お問い合せ申請書」',
          content: [
            '所定の用紙に以下の事項をご記入ください。',
            '(1) ご本人様の住所・氏名・電話番号とご捺印',
            '(2) 請求日',
            '(3) 開示をお求めになる個人情報を特定するための情報'
          ]
        },
        {
          label: 'ご本人様、代理人の方であることを示す証明書',
          content: [
            '<ご本人様の場合>',
            '• 運転免許証、パスポート、健康保険の被保険者証、在留カードの写しのいずれか１つ',
            '<代理人の方の場合>',
            '• 代理人である事を示す委任状（必須）',
            '• 委任状に押印された本人の印鑑証明書、代理人の本人確認書類のいずれか1つ'
          ]
        },
        {
          label: '手数料：1,000円（利用目的の通知または開示の場合のみ）'
        }
      ]
    }
  ],
  companyInfo: [
    '住所：東京都千代田区神田須田町1-7-9 VORT秋葉原maxim 10階',
    'ビズメイツ株式会社　個人情報保護管理者'
  ],
  closing: {
    req: '※代理人の方がご請求される場合は、お問い合わせください。なお、代理人請求ができる方は下記の方に限らせていただきます。',
    list: [
      '• 未成年者または成年被後見人の法定代理人',
      '• 開示等の求めをすることにつき本人が委任した代理人'
    ],
    msg: '当社では、上記の請求を受領した場合は、必要な調査を実施した上で、2週間以内に書面にて回答致します。回答にあたっては、なりすましによる個人情報の漏えいを防止する目的で、本人限定受取郵便を用いますので、ご了承ください。'
  },
  remark: {
    name: '以上',
    position: 'ビズメイツ株式会社',
    company: '代表取締役社長　鈴木 伸明'
  }
}
