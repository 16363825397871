/**
 * =======================================
 * API Request for Authorization
 * =======================================
 */
import axios from 'axios'
import Config from '@/config/app'

export default {
  baseUrl: Config.services.auth.url,

  /**
   * Logout Request
   *
   * @param {*} params
   * @return {*} http
   */
  auth () {
    return axios.post(`${this.baseUrl}/oauth/token`, {
      grant_type: 'client_credentials',
      client_id: Config.services.auth.api_client_id,
      client_secret: Config.services.auth.api_client_secret,
      scope: '*'
    }, {
      headers: {
        Accept: 'application/json'
      }
    })
  }
}
