export default {
  title: 'よくある質問',
  search: '探す',
  instruction: 'よくある質問（FAQ）で解決できない場合は、恐れ入りますが以下お問合せフォームを入力の上送信ください。',
  instructionAsterisk1: ' ＊お問合せ内容は可能な限り、詳細に記載ください。',
  instructionAsterisk2: ' ＊土・日・祝日・年末年始はお休みをいただいております。お問合せへの返信が遅れますこと、予めご了承ください。',
  items: [
    {
      title: '「初期登録」に関して',
      topics: [
        {
          title: '「プロフィール登録」関連の質問',
          questions: [
            {
              question: 'すべて完璧に作成しなくても登録できますか？',
              answer: 'はい、赤い文字で表示される必須項目のみ記入しても登録できます。',
              show: 'both'
            },
            {
              question: 'プロフィール登録は必須ですか？',
              answer: `はい、経歴を登録なしではスカウトをもらったり応募をすることができませんので是非入力ください。
              記入が難しい場合には作成サポート機能の自動読み込みをお使いください。
              履歴書や職務経歴書を添付すると自動で読み込むことがっできます。`,
              show: 'both'
            },
            {
              question: '初期登録をスキップすることはできますか？',
              answer: '初期登録なしでのサービス利用はできませんのでご登録ください。',
              show: 'both'
            },
            {
              question: 'プロフィールを充実させると何がいいですか？',
              answer: '企業側が貴方様に対してより詳しい情報を得られるのでマッチング率の高いスカウトがもらえます！',
              show: 'after login'
            },
            {
              question: '登録した情報はどこまで公開されますか？',
              answer: `登録されたと同時に、「氏名」「メールアドレス」「電話番号」「生年月日」以外の情報はすべて公開されます。
              求人へ応募された場合には、「氏名」「生年月日」も公開されます。
              「メールアドレス」「電話番号」に関しては1次面接後に公開されます。`,
              show: 'after login'
            },
            {
              question: '日本在中でなくても登録できますか？',
              answer: 'はい、海外在中でも問題ございません。',
              show: 'both'
            },
            {
              question: '日本語ができなくても登録できますか？',
              answer: 'はい、可能です。ページの右上の言語設定を英語に切り替えて登録のお進みください。',
              show: 'both'
            },
            {
              question: '求人により何パタンがの職務経歴書を登録することは可能ですか？',
              answer: '登録できる職務経歴書は1つのみです。予めご了承下さい。',
              show: 'after login'
            }
          ]
        }
      ]
    },
    {
      title: '「仕事検索」に関して"',
      topics: [
        {
          title: '「仕事検索」関連の質問',
          questions: [
            {
              question: '検索フィルターとは何ですか？',
              answer: `お好みの条件に合わせて、求人を検索できる機能です。
              人気のキーワード 、場所、職種、 雇用形態、 業界、 ITスキル、言語、JLPT、 年収、 掲載日などで検索できます。`,
              show: 'after login'
            },
            {
              question: '保存した検索条件とは何ですか？',
              answer: `特定のキーワード・絞り込み条件（フィルター）で、検索結果を保存できます。
              後で、同じように検索できます。`,
              show: 'after login'
            },
            {
              question: '私の検索リストは何個まで作れますか？',
              answer: '現在制限はありません。',
              show: 'after login'
            }
          ]
        },
        {
          title: '「話を聞いてみたい」関連の質問',
          questions: [
            {
              question: '「話を聞いてみたい」とは何ですか？',
              answer: `あなたが興味がある企業を、保存できる機能です。
              企業からも「気になる」が届いたら、カジュアル面談ができます。`,
              show: 'both'
            },
            {
              question: '「話を聞いてみたい」は無制限にできますか？',
              answer: '現在制限はありません。',
              show: 'both'
            }
          ]
        }
      ]
    },
    {
      title: '「応募」に関して',
      topics: [
        {
          title: '「応募する」関連の質問',
          questions: [
            {
              question: '同じ求人にまた応募できますか？',
              answer: '同じ案件に重複した応募はできません。',
              show: 'after login'
            },
            {
              question: '応募できる数は、決まっていますか？',
              answer: '個数制限はございません。気になる仕事がありましたらご自由に応募ください。',
              show: 'after login'
            },
            {
              question: '応募してから１週以上経ちましたが、結果が届きません。どうすればいいですか？',
              answer: `GitTapのメッセージをご確認ください。個人のメールではなく、GitTapのメッセージで通知します。
              もしメッセージが届かなければ、企業の担当者にメッセージを送るか、GitTapチーム（info@gittap.jp）にお問い合わせください。
              GitTapチームに問い合わせるときは、「応募した求人名」とあなたの「ユーザーID」を送ってください。`,
              show: 'after login'
            }
          ]
        }
      ]
    },
    {
      title: '「面接」に関して',
      topics: [
        {
          title: '「面接」関連の質問',
          questions: [
            {
              question: '英語面接はできますか？',
              answer: '企業によって可能です。求人の詳細をご確認ください。',
              show: 'both'
            },
            {
              question: 'オンライン面接はできますか？',
              answer: '企業によって可能です。求人の詳細をご確認ください。',
              show: 'both'
            }
          ]
        }
      ]
    },
    {
      title: '「アプローチリスト」に関して',
      topics: [
        {
          title: '「気になる」に関する質問',
          questions: [
            {
              question: '気になるとは何ですか？　',
              answer: `企業が、あなたの経歴に興味があるときに送ります。「気になる」が届いたら、求人の詳細を確認して、「応募」または「詳しく知りたい」を送りましょう。
              「詳しく知りたい」を送ると、カジュアル面談ができます。すぐに送信して、ライバルに差をつけましょう。`,
              show: 'both'
            }
          ]
        },
        {
          title: '「マッチ」に関する質問　',
          questions: [
            {
              question: 'マッチとは何ですか？',
              answer: `「話を聞いてみたい」を送った企業から、「気になる」が送られると「マッチ」になります。
              できるだけ早く応募して、転職を成功させましょう！カジュアル面談をして、企業をよく知ることもできます。`,
              show: 'both'
            },
            {
              question: 'やり取り中の企業がマッチでは確認できません。なぜですか？',
              answer: '「マッチ」に表示されるのは、あなたと企業の両方が「気になる」「話を聞いてみたい」を送っている求人です。スカウトをもらった場合は、メッセージをご確認ください。',
              show: 'after login'
            }
          ]
        },
        {
          title: '「応募済み」に関する質問',
          questions: [
            {
              question: '応募済みの企業一覧は編集できますか？',
              answer: 'できません。',
              show: 'after login'
            },
            {
              question: '応募履歴はいつまで残りますか？',
              answer: '求人が終了するまでです。',
              show: 'after login'
            },
            {
              question: '辞退した企業に再度応募したいです。どうすればいいですか？',
              answer: '一度辞退した企業には、応募できません。',
              show: 'after login'
            },
            {
              question: '応募する前に企業に相談したいです。どうすればいいですか？',
              answer: `相談には、下記のどちらかが必要です。
              ・あなたが「話を聞いてみたい」を送り、企業が「気になる」を送った求人
              ・企業からスカウトをもらい、あなたが「もっと知りたい」を送った求人。`,
              show: 'after login'
            }
          ]
        },
        {
          title: '「話を聞いてみたい」に関する質問',
          questions: [
            {
              question: '気になると話を聞いてみたいは何が違いますか？',
              answer: `話を聞いてみたいは「あなたが企業へ興味がある場合」、
              気になるは「企業があなたが気になる場合」になります。`,
              show: 'both'
            },
            {
              question: '話を聞いてみたいを取り消すことはできますか？',
              answer: 'できません。',
              show: 'after login'
            }
          ]
        },
        {
          title: '「非表示」に関する質問',
          questions: [
            {
              question: '非表示を取り消すことはできますか？',
              answer: `はい、非表示一覧から「表示」を選択してください。
              「表示」を選択すると気になる一覧に戻ります。`,
              show: 'after login'
            },
            {
              question: '非表示の理由などメモできますか？',
              answer: 'できません。',
              show: 'after login'
            }
          ]
        }
      ]
    },
    {
      title: '「メッセージ」に関して',
      topics: [
        {
          title: '「メッセージ」に関する質問',
          questions: [
            {
              question: '応募した企業から結果の通知が来ません。確認はできませんか？',
              answer: `通常、応募日から1週間以内に通知が送られます。
              1週間以上連絡がない場合は、GitTapチームにお問い合わせください。`,
              show: 'after login'
            },
            {
              question: '返信していないメッセージが自動で消えました。なぜですか？',
              answer: `企業からのスカウトには返信期間があります。
              期間を超えると返信できません。ご注意ください。`,
              show: 'after login'
            },
            {
              question: 'イメージやドキュメントファイル以外に動画などを送ることは可能ですか？',
              answer: `イメージファイル、ドキュメントファイルは可能です。
              （イメージファイルはJPEG,PNG形式の5MBまで可能です。）`,
              show: 'after login'
            },
            {
              question: '企業に直接メッセージを送ることは可能ですか？',
              answer: `下記の場合のみ、メッセージを送れます。
              ・企業からの「気になる」に返信する場合
              ・スカウトに返信する場合
              ・選考に進んだ場合`,
              show: 'after login'
            },
            {
              question: '返信が遅れる場合、ペナルティなどはありますか？',
              answer: `ペナルティはありませんが、企業からのスカウトには返信期間があります。
              期間を超えると返信できません。ご注意ください。`,
              show: 'after login'
            },
            {
              question: '内容を間違って送りました。取り消しできますか？',
              answer: '取り消しはできません。送信前に必ずご確認ください。',
              show: 'after login'
            },
            {
              question: '既読の確認はできますか？',
              answer: 'できません。',
              show: 'after login'
            },
            {
              question: '企業に直接問い合わせできますか？',
              answer: `企業との連絡は、下記の場合のみできます。
              ・企業からの「気になる」に「もっと知りたい」を返信する場合
              ・スカウトに返信する場合
              ・選考に進んだ場合
              それ以外の場合、求人内容に関する問い合わせには回答できません。`,
              show: 'after login'
            }
          ]
        }
      ]
    },
    {
      title: '「スカウト」に関して',
      topics: [
        {
          title: 'スカウトに関する質問',
          questions: [
            {
              question: 'スカウトされた企業に応募・返信しようとしたら、掲載が終わっていました。どうすればいいですか？',
              answer: '求人情報の掲載は、企業の判断で行われています。求人の掲載が終わると、応募・返信ができません。',
              show: 'after login'
            },
            {
              question: '応募した求人にスカウトが届きました。なぜですか？',
              answer: `応募するとき、あなたの個人情報は企業に送られません。 
              そのため、応募した求人に再度スカウトが届くことがあります。`,
              show: 'after login'
            },
            {
              question: 'スカウトに返信期間はありますか？',
              answer: 'ありませんが、できるだけ1週以内に返信してください。1週間以上経つと、採用につながりにくいです。',
              show: 'after login'
            },
            {
              question: 'スカウトメールに履歴書を添付して応募してもいいですか？',
              answer: 'スカウトメールには、ファイルを添付できません（企業に表示されません）。スカウトされた求人の応募ボタンから、応募してください。',
              show: 'after login'
            }
          ]
        }
      ]
    },
    {
      title: '「利用料金」に関して',
      topics: [
        {
          title: '「利用料金」関連の質問',
          questions: [
            {
              question: 'GitTapにお金はかかりますか。',
              answer: 'お金は全くかかりません。安心してお使いください。',
              show: 'both'
            }
          ]
        }
      ]
    },
    {
      title: 'その他',
      topics: [
        {
          title: '「その他」関連の質問',
          questions: [
            {
              question: '転職活動をしていることを、働いている企業に知られたくないです。何か方法はありますか？',
              answer: 'はい。「非表示にしたい企業」を設定すれば、その企業はあなたのプロフィールを見られません。',
              show: 'after login'
            },
            {
              question: '「非表示にしたい企業」に企業の名前が出てこない場合、どうすればいいですか？',
              answer: `リストに表示されない企業は、GitTapを使っていません。
              しかし、今後利用する可能性がありますので、こまめなチェックが必要です。`,
              show: 'after login'
            },
            {
              question: '日本語の履歴書と職務経歴書の書き方を教えてください。',
              answer: `下のリンクを見てください。
              履歴書の書き方＆フォーマットダウンロード
              https://www.gtalent.jp/blog/japanwork/job-hunting/rirekisho

              職務経歴書の書き方＆フォーマットダウンロード
              https://www.gtalent.jp/blog/japanwork/job-hunting/career_view_it_engineer`,
              show: 'both'
            },
            {
              question: 'エラーが発生したのですが、どうしたらよいでしょうか？',
              answer: `ブラウザの再読込（リロード）等を実施しても頻発するようでしたら、申し訳ありませんが、下の情報をGitTapチームに送ってください。
              ・ご使用の端末／機種名（スマホ、タブレット、PC）
              ・ご使用のブラウザとバージョン ※動作を保証しているブラウザは以下の最新版です。
              （Google Chrome、Safari、Firefox、Microsoft Edge） 
              ・アカウントのメールアドレス
              ・エラーの起こったページURL
              ・エラーが起きたとき、行った作業・動作
              ・お試しになった処置（キャッシュクリアなど）`,
              show: 'both'
            }
          ]
        }
      ]
    }
  ]
}
