import interestApi from '@/api/common/interests'

export default {
  namespaced: true,
  state () {
    return {
      loading: false,
      items: []
    }
  },
  mutations: {
    /**
     * Set loading.
     *
     * @param {*} state
     * @param {*} loading
     */
    SET_LOADING (state, loading) {
      state.loading = loading
    },
    /**
     * Set items.
     *
     * @param {*} state
     * @param {*} items
     */
    SET_ITEMS (state, items) {
      state.items = items
    }
  },
  actions: {
    /**
     * add item
     *
     * @param { commit, state } item
     */
    addItem ({ commit, state }, item) {
      let data = state.items

      data = data.concat(item)

      commit('SET_ITEMS', data)
    },
    /**
     * Fetch items
     * If items is already populated skip the process
     *
     * @param { getters, dispatch} params
     */
    softFetch ({ getters, dispatch }, params) {
      if (getters.hasItems) return
      dispatch('fetch', params)
    },
    /**
     * Fetch Items
     *
     * @param {commit, dispatch} object
     */
    fetch ({ commit, dispatch }, params) {
      commit('SET_LOADING', true)

      return interestApi.search(params)
        .then(({ data }) => {
          commit('SET_ITEMS', data)
        })
        .catch(({ response }) => {
          dispatch('httpException/handle', response, { root: true })
        })
        .finally(() => {
          commit('SET_LOADING', false)
        })
    }
  }
}
