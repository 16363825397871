export default {
  title: '入社決定連絡',
  description: 'GitTapから応募した企業に入社が決まった方には、転職お祝い金としてAmazon ギフト券5,000円分をプレゼントさせていただきます。以下のボタンからGitTap事務局へお知らせください。',
  reminders: [
    {
      text: '※Amazon ギフト券の詳細については  <a target="_blank" href="http://amazon.co.jp/giftcard/tc">http://amazon.co.jp/giftcard/tc</a> をご確認ください。'
    },
    {
      text: '※GitTapを退会された場合は無効となります。ギフト券プレゼントまでは退会しないようにご注意ください。'
    },
    {
      text: '※お問い合わせは  <a href="mailto:info@gittap.jp">info@gittap.jp</a> までご連絡ください。'
    }
  ],
  companyName: '会社名',
  startDate: '入社日',
  salary: '給与',
  aboutService: 'GitTapについて',
  interview: {
    label: 'インタビュー',
    description1: 'もしよろしければ、今回の転職とGitTapのユーザビリティについてお話をお伺いさせていただけませんか？',
    description2: '※ご協力いただける方には、取材方法や謝礼について、後日メールを送らせていただきます。'
  },
  placeholder: {
    companyName: '入社する企業名',
    startDate: '入社する予定日',
    salary: '転職先での年収',
    aboutService: 'GitTapのサービスについて、自由に感想をお書きください'
  },
  confirmation: '入力内容の確認'
}
