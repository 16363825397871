export default {
  scoutTemplate: {
    header: 'GitTap事務局より',
    body1: 'おめでとうございます！',
    body2: 'スカウトを受け取りました。早速返信してみましょう。',
    knowMore: '話を聞いてみたい',
    apply: '応募する',
    applied: '応募完了',
    jobDetails: '求人詳細を確認',
    decline: '辞退する',
    noThanks: '辞退する',
    knowMoreSent: '"話を聞いてみたい"完了',
    noThanksSent: '辞退済み',
    saveSent: '"保存"完了',
    save: '保存',
    saved: '保存済み'
  },
  interviewResult: {
    labelYes: 'Yes',
    labelNo: 'No'
  },
  gittapMessages: {
    applyReply: 'この求人に応募しました。選考結果のご連絡をお待ちください。',
    declineReply: '回答ありがとうございます。スカウトを辞退しました。',
    knowMoreReply: '求人情報が保存されました。<br> ※アプローチ > 保存 で確認できます'
  },
  applyDialog: {
    header: '確認',
    label: '企業にメッセージを送れます',
    optional: '任意',
    apply: '応募する'
  },
  declineDialog: {
    header: '辞退します。よろしいですか？',
    decline: '辞退する'
  },
  noThanksDialog: {
    header: '本当に辞退しますか？',
    noThanks: '辞退する'
  },
  closedPosition: 'スカウトを辞退しました。',
  alreadyApplied: 'あなたはすでにこのポジションに応募しています！',
  backToMessage: 'メッセージに戻る',
  idLikeToKnowMoreSent: 'スカウト連絡をいただきありがとうございます。詳しいお話を聞かせていただけますでしょうか？'
}
