<template>
  <v-dialog
    v-model="open"
    max-width="750"
    flat
    persistent
  >
    <v-card>
      <v-card-title
        class="dim px-3 py-0"
        :class="{'py-2': $vuetify.breakpoint.smAndDown}"
      >
        <v-toolbar
          flat
          color="dim"
          dark
        >
          <span>{{ !profile_video ? $t('videoInterview.uploadVideoInterviewTitle') : $t('videoInterview.profileVideo') }}</span>
        </v-toolbar>
      </v-card-title>
      <v-divider />
      <v-card-text
        v-if="queues.length > 0"
        class="mb-0 py-0 justify-center"
      >
        <v-sheet
          class="title my-8 text-center"
        >
          <v-icon
            class="mr-2"
            color="primary"
          >
            mdi-information
          </v-icon>
          <span class="primary--text">{{ $t('videoInterview.uploadInfoMessage') }}</span>
        </v-sheet>
      </v-card-text>
      <v-divider />
      <v-card-text class="px-3">
        <v-list v-if="queues.length > 0">
          <template v-for="(job, index) in queues">
            <UploadItem
              :key="`job-${index}`"
              :title="job.title"
              :status="job.status"
              :link="job.link"
              :progress="job.progress"
              :quarantined-files="quarantinedFiles"
              :quarantined-files-error-message="quarantinedFilesErrorMessage"
            />
            <v-divider
              v-if="index !== (queues.length - 1)"
              :key="`divider-${index}`"
            />
          </template>
        </v-list>
        <v-list v-else>
          <v-list-item-content class="pt-5 px-5">
            <v-list-item-title>{{ $t('videoInterview.noUploadMessage') }}</v-list-item-title>
          </v-list-item-content>
        </v-list>
      </v-card-text>
      <v-divider />
      <v-card-actions class="blank justify-center py-5">
        <v-btn
          small
          color="primary"
          class="text-none px-3"
          @click="closeUploadManager"
        >
          <v-icon class="mr-2">
            mdi-close
          </v-icon>
          {{ $t('common.close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import { mapState, mapActions, mapGetters } from 'vuex'
import UploadItem from './UploadItem'
import reverse from 'lodash/reverse'

export default {
  components: { UploadItem },
  data () {
    return {
      //
    }
  },
  computed: {
    ...mapState('httpException', ['error']),
    ...mapState('uploadManager', ['open', 'queues', 'profile_video']),
    ...mapGetters('uploadManager', ['hasOngoingUpload']),
    /**
     * Queues reverse order
     */
    reverseQueues () {
      return reverse(this.queues)
    },

    /**
     * Quarantined Files
     */
    quarantinedFiles () {
      if (this.error) {
        if ('data' in this.error) {
          if ('quarantined_files' in this.error.data) {
            return this.error.data.quarantined_files
          }
        }
      }
      return null
    },
    quarantinedFilesErrorMessage () {
      if (this.error) {
        if ('data' in this.error) {
          if ('message' in this.error.data) {
            return this.error.data.message
          }
        }
      }
      return null
    }
  },
  created () {
    //
  },
  beforeMount () {
    window.addEventListener('beforeunload', this.checkNavigation)
  },
  beforeDestroy () {
    window.removeEventListener('beforeunload', this.checkNavigation)
  },
  methods: {
    ...mapActions('uploadManager', ['closeUploadManager']),
    ...mapActions('dialog', ['openConfirmationDialog']),
    /**
     * Check on going uploads before page leave
     */
    checkNavigation (event) {
      if (this.hasOngoingUpload) {
        event.preventDefault()
        event.returnValue = this.$t('videoInterview.beforeLeaveMessage')
      } else {
        return true
      }
    }
  }
}
</script>
