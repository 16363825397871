export default {
  link: 'アカウントを削除する',
  title: '退会申請',
  caption: 'これまでの登録情報、履歴が使えなくなります。本当に退会されますか？',
  subtitle: 'サービス向上のためのフィードバックをお待ちしています。',
  reason: {
    label: '退会理由',
    placeholder: '退会の理由を選択してください'
  },
  comment: {
    label: 'コメント',
    placeholder: 'コメント...'
  },
  validation: {
    reasonIsRequired: '選択必須'
  },
  newDesign: {
    title: '退会手続き',
    sentences: [
      {
        item: '退会手続きが完了しますと、これまでに入力いただいた内容や応募履歴、企業からのアプローチ履歴は確認できなくなります。'
      },
      {
        item: '次回の転職活動の際に再度入力を行いたくない場合は、退会されずに登録情報を残しておくことをおすすめいたします。'
      },
      {
        item: 'また、GitTapの機能、各種メッセージ配信サービスも全てご利用できなくなりますので、ご注意ください。'
      }
    ],
    paragraph: [
      {
        item: '＊注意事項'
      },
      {
        item: '退会手続きをされてから1週間ほどはメールマガジンが届く可能性があります。'
      },
      {
        item: '退会後も応募企業での採用状況の確認等で連絡をさせていただく場合がございます。'
      },
      {
        item: 'あらかじめご了承いただけますと幸いです。'
      }
    ]
  }
}
