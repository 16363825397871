import api from '../../index'

const {
  http,
  simpleSearch
} = api

/**
 * ===================
 * City API
 * ===================
 */
export default {
  baseUrl: `${process.env.VUE_APP_CUSTOMER_URL}/api/v1`,
  url: 'cities',
  http,
  simpleSearch
}
