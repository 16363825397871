export default {
  title: 'Saved Searches',
  saveDuplicatedMessage: 'That condition is already saved',
  putSearchConditionMessage: 'Please put search conditions',
  alert: 'Alert',
  subText:
    'You will be notified by email of job openings that match your search criteria.',
  confirmDelete: {
    header: 'Delete the saved filter',
    description: 'Are you sure you want to delete this saved condition?',
    delete: 'Remove',
    cancel: 'Cancel'
  }
}
