<template>
  <v-menu
    offset-y
    bottom
    left
    tile
    max-width="450"
    content-class="notif-menu"
  >
    <template
      v-slot:activator="{ on }"
    >
      <v-btn
        text
        style="padding-right: 8px !important; "
        class="text-none btn-notif"
        :class="{active}"
        @click="toggle"
        v-on="on"
      >
        <v-badge
          v-if="admin_notification.unread === 0 || admin_notification.unread === undefined"
          class="nav-list notif-disabled"
          overlap
          dot
          :value="admin_notification.unread"
        >
          <v-icon
            color="#626262"
            v-text="'mdi-bell'"
          />
        </v-badge>
        <v-badge
          v-else
          class="nav-list"
          color="red"
          overlap
          :content="unreadAdminNotifications"
          :value="admin_notification.unread"
        >
          <v-icon
            color="#626262"
            v-text="'mdi-bell'"
          />
        </v-badge>
      </v-btn>
    </template>

    <NotificationList
      :items="admin_notification.items"
    />
    <v-divider />
    <div class="see-all">
      <span
        class="primary--text text-none"
        @click="$router.push({name: 'NotificationList'})"
      >
        {{ $t('notification.see_all') }}
      </span>
    </div>
  </v-menu>
</template>

<script>

import { mapActions, mapState } from 'vuex'
import NotificationList from '@/components/Notification/QuickViewList'

export default {
  components: {
    NotificationList
  },
  props: {
    customActionPush: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      loading: false,
      notifications: [],
      active: false,
      test: 15
    }
  },
  computed: {
    ...mapState('messageNotifications', ['admin_notification']),
    // formats unread count
    unreadAdminNotifications () {
      return this.admin_notification.unread > 9 ? '9+' : this.admin_notification.unread
    }
  },
  mounted () {
    if (document.querySelector('.btn-notif')) {
      document.querySelector('.btn-notif').addEventListener('focusout', (e) => {
        if (e.sourceCapabilities !== null) {
          this.active = false
        }
      })
    }
  },
  destroyed () {
    if (document.querySelector('.btn-notif')) {
      document.querySelector('.btn-notif').removeEventListener('focusout', null, true)
    }
  },
  methods: {
    ...mapActions('httpException', { handleException: 'handle' }),
    toggle () {
      this.$emit('customActionPush')
      this.active = !this.active
    }
  }
}
</script>

<style scoped>
.nav-list >>> .v-badge__badge {
  position: absolute;
  inset: -3px -3px auto auto !important;
  height: 18px;
  font-size: 9px;
  background: #D62F2F !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.nav-list.notif-disabled >>> .v-badge__badge {
  display: none;
}
.nav-list {
  background: #F4F4F4;
  padding: 10px;
  border-radius: 54px;
}
.nav-list:hover {
  background: #F4F4F4;
  padding: 10px;
  border-radius: 54px;
}
.v-btn:hover::before{
  opacity: 0
}
.notif-menu {
  background: #FFFFFF !important;
  top: 65px !important;
  border-radius: 8px !important;
  width: 312px !important;
  color: #1A1A1A !important;
}
.see-all {
  text-align: center;
  padding: 14px;
  cursor: pointer;
  font: normal normal normal 14px/18px Roboto;
  letter-spacing: 0px;
}
</style>
