import language from '@/api/customer/master/language'

const data = {
  items: sessionStorage.getItem('languages')
    ? JSON.parse(sessionStorage.getItem('languages'))
    : [],
  selected: []
}

export default {
  namespaced: true,
  state () {
    return {
      ...data
    }
  },
  mutations: {
    /**
     * Reset the state to default.
     *
     * @param {*} state
     */
    RESET (state) {
      for (const key in state) {
        state[key] = data[key]
      }
    },

    /**
     * Set the items.
     *
     * @param {*} state
     * @param array items
     */
    SET_ITEMS (state, items) {
      state.items = items
    },

    /**
     * Select the selected items.
     *
     * @param {*} state
     * @param array selected
     */
    SET_SELECTED (state, selected) {
      state.selected = selected
    }
  },
  actions: {
    /**
     * Get all language records.
     *
     * @param { commit, dispatch, state }
     */
    all ({ commit, dispatch, state }) {
      if (state.items.length) return

      return language.index()
        .then(({ data }) => {
          commit('SET_ITEMS', data)

          sessionStorage.setItem('languages', JSON.stringify(data))
        })
        .catch(({ response }) => dispatch('httpException/handle', response, { root: true }))
    },

    /**
     * Add item in selected state
     *
     * @param {*} { commit, state }
     * @param {*} item
     */
    addSelectedItem ({ commit, state }, item) {
      const selected = [...state.selected]

      if (selected.includes(item)) return

      selected.push(item)

      commit('SET_SELECTED', selected)
    },

    /**
     * Remove item in selected state
     *
     * @param {*} { commit, state }
     * @param {*} item
     */
    removeSelectedItem ({ commit, state }, item) {
      const selected = state.selected.slice()

      const languages = selected.filter(i => i.id === item.id)

      if (languages.length) {
        languages.forEach(language => {
          const index = selected.indexOf(language)

          selected.splice(index, 1)
        })

        commit('SET_SELECTED', selected)
      }
    },

    /**
     * Reset the state to default
     *
     * @param {*} { commit }
     */
    reset ({ commit }) {
      commit('RESET')
    }
  }
}
