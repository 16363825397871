export default {
  header: {
    referAFriend: {
      text1: 'Refer-a-friend program',
      text2: 'ご友人がGitTap経由で'
    },
    referAFriend2: '企業との面接まで進む',
    amazon: 'Amazonギフト券',
    amount: '5,000',
    subTitle: '円分をプレゼント！',
    gittapMessage: 'GitTapをお友達に紹介して仲間をつくりませんか？',
    subHeaderInvite1: {
      header1: '友人を招待',
      header2: ''
    },
    subHeaderInvite2: {
      header1: '紹介した友人が採用面接',
      header2: ''
    },
    subHeaderInvite3: {
      header1: 'あなたと招待した友人が両方',
      header2: 'Amazonギフト5,000円分ゲット！'
    }
  },
  copyUrl: '紹介文をクリップボードにコピー',
  createNewLink: 'テンプレート内のURLを更新',
  section1: {
    title: 'ご紹介方法',
    subheader: {
      content1: '以下の方法で、とても簡単にGitTapをご友人に',
      content2: 'ご紹介することができます。'
    },
    step1: {
      step: 'Step1',
      content: '「紹介文をクリップボードにコピー」をクリック'
    },
    step2: {
      step: 'Step2',
      content: 'メールやSNSなどのメッセージアプリにコピーした紹介文をペーストしてください。'
    },
    step3: {
      step: 'Step3',
      content: 'ご友人は、送信されたメッセージ内に記載されたURLからGitTapの登録画面を表示し、無料会員登録していただけます。',
      content2: '※既に無料会員にご登録済のご友人は、その登録アカウントを継続してご利用いただけます。'
    }
  },
  section2: {
    title1: 'ご友人お一人につき一つのURLにて、紹介特典をご利用いただけます。',
    title2: '連続して別のご友人にご紹介いただく際には「テンプレート内のURLを更新」ボタンを押下し、別のURLへ変更して送信してください。',
    content1: '※画面を更新すると、URLも更新されます。',
    content2: '※同じURLを送った場合、先にそのURLから手続きされた方のみ対象となります。',
    content3: '※LINEやメールで送る場合には、送信の都度、自動でURLは更新されます。'
  },
  section3: {
    title: {
      content1: 'その後、ご友人がGitTapで求人応募やスカウトを',
      content2: '受け取り、企業との面接まで進んだら'
    },
    amazon: 'Amazonギフト券',
    amount: '5,000',
    subTitle: '円分をプレゼント！',
    title3: {
      content1: 'さらに、ご友人にも',
      amount: '5,000',
      content2: '円分をプレゼント！'
    },
    subcontent1: '※ご友人がプロフィール登録をされてから30日以内に、面接まで進む必要があります。',
    subcontent2: '※ご友人が面接まで進まれた月の翌月末に、Amazonギフト券のコードをメールでお送りいたします。'
  },
  section4: {
    title: '注意事項',
    content1: '※本制度はご友人ご紹介メッセージを送られた日を含む1週間以内に、ご友人がサインアップおよびプロフィール登録を完了させ、その後30日以内に面接まで進まれた場合に限り有効です。ユーザーアカウントを作成しただけや何かの求人に応募しただけでは本キャンペーンの対象にはなりません。',
    content2: '※ご友人がすでにGitTapのユーザーである場合には本キャンペーンの対象外になります。',
    content3: '※キャンペーン特典は、紹介１名につき、Amazonギフトコードで、ご紹介者様5,000円分、ご友人5,000円分をお送りします。万一、特典（Amazonギフトコード）のメールが受信できない場合や受信拒否をされた場合は、受け取り権利を放棄したものとさせていただきます。',
    content4: '※ご紹介メッセージは何通でも送信可能ですが、1ヵ月の間に適用されるキャンペーン報酬は１ご紹介者様５件までとなります。',
    content5: '※キャンペーン対象となるご紹介メッセージ送付可能な期間は、2021年7月7日10:00～2021年7月31日23:59までとなります。',
    content6: '※同一ユーザーによる複数アカウント登録が発覚した場合は、ご紹介者およびご友人双方がキャンペーンの対象外となり、アカウント停止となる場合があります。 面接まで進むことが、ご友人・ご紹介者様、双方へのプレゼント条件となるため、ご友人が面接に進んだこという情報がご紹介者様に通知されますこと、予めご了承ください。（選考企業などは通知されず、選考に進んだという情報のみ通知）',
    content7: '※本制度は予告なく、内容変更および終了する場合があります。'
  },
  message:
`こちらGitTap運営事務局です。  
この度「GitTapご友人ご紹介制度」にて、{0} 様よりご紹介いただきました。
下記のあなた様専用URLからサインアップをお願いいたします。
{1}

-------------------------
▼GitTapご友人ご紹介制度について
https://cs.gittap.jp/referral-program

上記、ご紹介用URLからGitTapにプロフィール登録いただき、その後30日以内に企業との面接に進むと、ご登録者様・ご紹介者様、双方に、Amazonギフト券5,000円分をプレゼントさせていただきます。

GitTapでは、求人への応募や、企業からスカウトを受け取ることで、選考（面接）に進むことができます。
ぜひ、GitTapを活用して良い転職活動を行ってください。企業との素晴らしいご縁があることをお祈りしています！

※面接まで進まれた月の翌月末に、Amazonギフト券のコードをメールでお送りいたします。
※面接まで進まれた際には、お問い合わせフォームからGitTap事務局に報告をしてください。報告がない場合、プレゼントをお届けできませんので、ご注意ください。
※面接まで進むことが、ご登録者様・ご紹介者様、双方へのプレゼント条件となるため、面接に進んだことがご紹介者様にも通知されますこと、予めご了承ください。（選考企業などは通知されず、選考に進んだという情報のみ通知）

-------------------------
▼GitTapとは？
〇 高いスキルを持つエンジニアのための求人を厳選
〇 外国人採用に本気の企業に出会える
〇 求人応募・スカウト受信、企業と直接つながれる
https://www.gittap.jp/ja
`
}
