export default {
  link: 'Delete account',
  title: 'service cancellation request',
  caption: 'Would you really leave?',
  subtitle: 'We really appreciate if you feedback for improving our service.',
  reason: {
    label: 'Reason',
    placeholder: 'Reason of cancellation...'
  },
  comment: {
    label: 'Comment',
    placeholder: 'Comment...'
  },
  validation: {
    reasonIsRequired: 'Reason is required'
  },
  newDesign: {
    title: 'Delete Account',
    sentences: [
      {
        item: 'Once you have completed the cancel membership process, you will no longer be able to view the information you have entered so far, your application history, or approach history from companies.'
      },
      {
        item: 'If you do not want to enter your information again when you are looking for a new job, we recommend that you keep your registration information without canceling your membership.'
      },
      {
        item: 'Also, please note that you will not be able to use all GitTap functions and various email delivery services.'
      }
    ],
    paragraph: [
      {
        item: 'There is a possibility that you will receive an e-mail magazine for about a week after you cancel your membership.'
      },
      {
        item: 'Please note that we may contact you to confirm your employment status at the company you applied to even after you cancel your membership. '
      },
      {
        item: 'We would appreciate your understanding in advance.'
      }
    ]
  }
}
