import employmentType from '@/api/customer/master/employment-type'

const data = {
  items: sessionStorage.getItem('employmentTypes')
    ? JSON.parse(sessionStorage.getItem('employmentTypes'))
    : [],
  loading: false
}

export default {
  namespaced: true,
  state () {
    return {
      ...data
    }
  },
  mutations: {
    /**
     * Reset the state to default.
     *
     * @param {*} state
     */
    RESET (state) {
      for (const key in state) {
        state[key] = data[key]
      }
    },

    /**
     * Set the items.
     *
     * @param {*} state
     * @param {*} items
     */
    SET_ITEMS (state, items) {
      state.items = items
    },

    /**
     * Set the status.
     *
     * @param {*} state
     * @param {*} items
     */
    SET_LOADING (state, loading) {
      state.loading = loading
    }
  },
  actions: {
    /**
     * Get all EmploymentType.
     *
     * @param {*} param
     */
    all ({ commit, dispatch, state }, params) {
      if (state.items.length) return

      commit('SET_LOADING', true)

      return employmentType.index(params)
        .then(({ data }) => {
          commit('SET_ITEMS', data)

          sessionStorage.setItem('employmentTypes', JSON.stringify(data))
        })
        .catch(({ response }) => dispatch('httpException/handle', response, { root: true }))
        .finally(() => commit('SET_LOADING', false))
    },

    /**
     * Commit the SET_ITEMS mutation
     *
     */
    setItems ({ commit }, items) {
      commit('SET_ITEMS', items)

      sessionStorage.setItem('employmentTypes', JSON.stringify(items))
    }
  }
}
