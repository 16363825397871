import SignUpRequest from '@/api/auth/signup'

const handleErrors = (errors) => {
  let errorMessage = ''
  for (const key in errors) {
    const messages = errors[key]
    if (messages.length) {
      errorMessage = messages[0]
      break
    }
  }
  return errorMessage
}

export default {
  namespaced: true,
  state () {
    return {
      dialogInvalidUrl: false,
      loading: false,
      error: null,
      email: null
    }
  },
  mutations: {
    SET_LOADING (state, loading) {
      state.loading = loading
    },

    SET_ERROR_MESSAGE (state, message) {
      state.error = message
    },

    SET_DIALOG (state, dialog) {
      state.dialogInvalidUrl = dialog
    },

    SET_EMAIL (state, email) {
      state.email = email
    }
  },
  actions: {
    signup ({ commit }, params) {
      commit('SET_LOADING', true)
      return SignUpRequest
        .signup(params)
        .then((response) => (response))
        .catch(({ response }) => {
          commit('SET_ERROR_MESSAGE', { type: 'email', message: handleErrors(response.data.errors)})
          setTimeout(() => commit('SET_ERROR_MESSAGE', null), 3000)
        })
        .finally(() => commit('SET_LOADING', false))
    },

    setDialogInvalidUrl ({ commit }, dialog) {
      commit('SET_DIALOG', dialog)
    },

    setLoading ({ commit }, loading) {
      commit('SET_LOADING', loading)
    },

    setErrorMessage ({ commit }, message) {
      commit('SET_ERROR_MESSAGE', message)
      setTimeout(() => (commit('SET_ERROR_MESSAGE', null)), 3000)
    },

    setEmail ({ commit }, email) {
      commit('SET_EMAIL', email)
    },

    resetEmail ({ commit }) {
      commit('SET_EMAIL', null)
    }
  }
}
