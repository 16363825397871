import languageCertificate from '@/api/customer/master/language-certificate'

const data = {
  items: sessionStorage.getItem('languageCertificates')
    ? JSON.parse(sessionStorage.getItem('languageCertificates'))
    : [],
  selected: []
}

export default {
  namespaced: true,

  state () {
    return {
      ...data
    }
  },
  mutations: {
    /**
     * Reset the state to default.
     *
     * @param {*} state
     */
    RESET (state) {
      for (const key in state) {
        state[key] = data[key]
      }
    },

    /**
     * Set the items.
     *
     * @param {*} state
     * @param {*} items
     */
    SET_ITEMS (state, items) {
      state.items = items
    },

    /**
     * Select the selected items.
     *
     * @param {*} state
     * @param {*} items
     */
    SET_SELECTED (state, selected) {
      state.selected = selected
    }
  },
  actions: {
    /**
     * Get all LanguageCertificate records.
     *
     * @param {*}
     */
    all ({ commit, dispatch, state }) {
      if (state.items.length) return

      return languageCertificate.index()
        .then(({ data }) => {
          commit('SET_ITEMS', data)

          sessionStorage.setItem('languageCertificates', JSON.stringify(data))
        })
        .catch(({ response }) => dispatch('httpException/handle', response, { root: true }))
    },

    /**
     * Add item in selected state
     *
     * @param {*} param0
     * @param {*} item
     */
    addSelectedItem ({ commit, state }, item) {
      const selected = [...state.selected]

      selected.push(item)

      commit('SET_SELECTED', selected)
    },

    /**
     * Remove item in selected state
     *
     * @param {*} param0
     * @param {*} item
     */
    removeSelectedItem ({ commit, state }, item) {
      const selected = [...state.selected]

      const index = selected.indexOf(item)

      selected.splice(index, 1)

      commit('SET_SELECTED', selected)
    }
  }
}
