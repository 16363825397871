import skill from '@/api/customer/master/skill'

export default {
  namespaced: true,
  state () {
    return {
      loading: false,
      items: sessionStorage.getItem('skills')
        ? JSON.parse(sessionStorage.getItem('skills'))
        : []
    }
  },
  mutations: {
    /**
     * Set the items.
     *
     * @param {*} state
     * @param {*} items
     */
    SET_ITEMS (state, items) {
      state.items = items
    },
    /**
     * Set the status.
     *
     * @param {*} state
     * @param {*} items
     */
    SET_LOADING (state, loading) {
      state.loading = loading
    }
  },
  actions: {
    /**
     * Fetch all Skill records.
     *
     * @param { commit, dispatch, state}
     */
    fetch ({ commit, dispatch, state }) {
      if (state.items.length) return

      commit('SET_LOADING', true)

      return skill.index()
        .then(({ data }) => {
          commit('SET_ITEMS', data)

          sessionStorage.setItem('skills', JSON.stringify(data))
        })
        .catch(({ response }) => {
          dispatch('httpException/handle', response, { root: true })
        })
        .finally(() => {
          commit('SET_LOADING', false)
        })
    }
  }
}
