import Config from '@/config/app'
import company from '@/api/client/company'

export default {
  namespaced: true,
  state () {
    return {
      loading: false,
      information: {},
      industries: [],
      appeals: [],
      documents: []
    }
  },
  getters: {
    /**
     * Check if it is loading
     */
    isLoading: state => {
      return state.loading
    },
    /**
     * Identify if has a company
     */
    hasCompany: state => {
      return Object.keys(state.information).length > 0
    },
    /**
     * Identify if company has a duplicate
     */
    hasDuplicateCompany: state => {
      return (id) => state.information.id === id
    },
    /**
     * Get the logo image
     * @param {*} state
     * @return object
     */
    logoImage: state => {
      return state.documents.find((document) => {
        const type = parseInt(document.payload.type)
        return type === Config.LOGO_IMAGE
      }) || {}
    },
    /**
     * Get the cover image
     * @param {*} state
     * @return object
     */
    coverImage: state => {
      return state.documents.find((document) => {
        const type = parseInt(document.payload.type)
        return type === Config.COVER_IMAGE
      }) || {}
    },
    /**
     * Get the appeal images
     * @param {*} state
     * @return array
     */
    appealTopImages: state => {
      return state.documents.filter((document) => {
        const type = parseInt(document.payload.type)
        return type === Config.APPEAL_TOP_IMAGE
      }) || []
    },
    /**
     * Get the appeal images
     * @param {*} state
     * @return array
     */
    appealMiddleImages: state => {
      return state.documents.filter((document) => {
        const type = parseInt(document.payload.type)
        return type === Config.APPEAL_MIDDLE_IMAGE
      }) || []
    },
    /**
     * Get the appeal images
     * @param {*} state
     * @return array
     */
    appealBottomImages: state => {
      return state.documents.filter((document) => {
        const type = parseInt(document.payload.type)
        return type === Config.APPEAL_BOTTOM_IMAGE
      }) || []
    }
  },
  mutations: {
    /**
     * Set the information.
     *
     * @param {*} state
     * @param {*} information
     */
    setInformation (state, information) {
      state.information = information
    },
    /**
     * Set the industries.
     *
     * @param {*} state
     * @param {*} industries
     */
    setIndustries (state, industries) {
      state.industries = industries || []
    },
    /**
     * Set the appeals.
     *
     * @param {*} state
     * @param {*} appeals
     */
    setAppeals (state, appeals) {
      state.appeals = appeals || []
    },
    /**
     * Set the appeals.
     *
     * @param {*} state
     * @param {*} items
     */
    setDocuments (state, documents) {
      state.documents = documents || []
    },
    /**
     * Set the loading status.
     *
     * @param {*} state
     * @param {*} loading
     */
    setLoading (state, loading) {
      state.loading = loading
    }
  },
  actions: {
    /**
     * Set company details
     *
     * @param {commit, dispatch, getters} object
     * @param object company
     */
    setDetails ({ commit, dispatch, getters }, id) {
      if (getters.hasDuplicateCompany(id)) return

      commit('setLoading', true)

      company.show(id)
        .then(({ data }) => {
          commit('setIndustries', data.industries || [])
          commit('setAppeals', data.appeals || [])
          commit('setDocuments', data.documents || [])
          commit('setInformation', data)
        })
        .catch(({ response }) => dispatch('httpException/handle', response, { root: true }))
        .finally(() => {
          commit('setLoading', false)
        })
    }
  }
}
