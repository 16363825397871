const data = {
  data: {},
  errors: {},
  processing: false,
  dirty: false,
  saved: false,
  saving: false
}

export default {
  namespaced: true,
  strict: process.env.NODE_ENV === 'development',
  state () {
    return {
      ...data
    }
  },
  mutations: {
    /**
     * Set the form data.
     *
     * @param {*} state
     * @param {*} data
     */
    SET_DATA (state, data) {
      state.data = data
    },

    /**
     * Set the form errors.
     *
     * @param {*} state
     * @param {*} errors
     */
    SET_ERRORS (state, errors) {
      state.errors = errors
    },

    /**
     * Set the dirty state of the form.
     * @param {*} state
     * @param {*} dirty
     */
    SET_DIRTY (state, dirty) {
      state.dirty = dirty
    },

    /**
     * Set the processing state of the form.
     *
     * @param {*} state
     * @param {*} processing
     */
    SET_PROCESSING (state, processing) {
      state.processing = processing
    },

    /**
     * Set the saved state of the form.
     *
     * @param {*} state
     * @param {*} saved
     */
    SET_SAVED (state, saved) {
      state.saved = saved
    },

    /**
     * Set the saving state of the form.
     *
     * @param {*} state
     * @param {*} saving
     */
    SET_SAVING (state, saving) {
      state.saving = saving
    },

    /**
     * Reset all state.
     * @param {*} state
     */
    RESET (state) {
      for (const key in state) {
        state[key] = data[key]
      }
    }
  },
  actions: {
    /**
     * Set the form data.
     *
     * @param {*} state
     * @param {*} data
     */
    setData ({ commit }, data) {
      commit('SET_DATA', data)
    },

    /**
     * Set the form errors.
     *
     * @param {*} state
     * @param {*} errors
     */
    setErrors ({ commit }, errors) {
      commit('SET_ERRORS', errors)
    },

    /**
     * Set the dirty state of the form.
     * @param {*} state
     * @param {*} dirty
     */
    setDirty ({ commit }, dirty) {
      commit('SET_DIRTY', dirty)
    },

    /**
     * Set the processing state of the form.
     *
     * @param {*} state
     * @param {*} processing
     */
    setProcessing ({ commit }, processing) {
      commit('SET_PROCESSING', processing)
    },

    /**
     * Set the saved state of the form.
     *
     * @param {*} state
     * @param {*} saved
     */
    setSaved ({ commit }, saved) {
      commit('SET_SAVED', saved)
    },

    /**
     * Set the saving state of the form.
     *
     * @param {*} state
     * @param {*} saving
     */
    setSaving ({ commit }, saving) {
      commit('SET_SAVING', saving)
    },

    /**
     * Reset all state.
     * @param {*} state
     */
    reset ({ commit }) {
      commit('RESET')
    }
  }
}
