import api from '../../index'

const {
  http,
  index
} = api

/**
 * ===================
 * JapaneseLanguageExperience API
 * ===================
 */
export default {
  baseUrl: `${process.env.VUE_APP_CUSTOMER_URL}/api/v1`,
  url: 'japanese-language-experiences',
  http,
  index
}
