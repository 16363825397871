import deactivateReasonApi from '@/api/customer/master/deactivate-reason'

const data = {
  items: sessionStorage.getItem('deactivateReasons')
    ? JSON.parse(sessionStorage.getItem('deactivateReasons'))
    : [],
  selected: {}
}

export default {
  namespaced: true,
  state () {
    return {
      ...data
    }
  },
  mutations: {

    /**
     * Set the items.
     *
     * @param {*} state
     * @param {*} items
     */
    SET_ITEMS (state, items) {
      state.items = items
    },

    /**
     * Set the items.
     *
     * @param {*} state
     * @param {*} selected
     */
    SET_SELECTED (state, selected) {
      state.selected = selected
    },

    /**
     * Reset the state to default.
     *
     * @param {*} state
     */
    RESET (state) {
      for (const key in state) {
        state[key] = data[key]
      }
    }
  },
  actions: {

    /**
     * Get all deactivate reason records
     */
    fetch ({ commit, dispatch, state }) {
      if (state.items.length) return

      return deactivateReasonApi.index()
        .then(({ data }) => {
          commit('SET_ITEMS', data)

          sessionStorage.setItem('deactivateReasons', JSON.stringify(data))
        })
        .catch(({ response }) => dispatch('httpException/handle', response, { root: true }))
    },

    /**
     * Set item in selected state
     *
     * @param {*} param
     * @param {*} item
     */
    setSelectedItem ({ commit }, item) {
      commit('SET_SELECTED', item)
    }
  }
}
