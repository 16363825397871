export default {
  profile_panel_title: 'Profile Completion Status',
  profile: 'Profile',
  skill_and_experience: 'Skill/Experience',
  preference: 'Preference',
  complete: '100%',
  profile_update: 'Profile Update',
  recommended_jobs: 'Recommended Jobs',
  recommended_job_description: 'These jobs are more likely to be hired because they match your skills.',
  saved_search: 'Saved Search',
  new_search: 'Search more',
  my_searches: 'My Searches',
  scout_panel_title: 'Scouts from companies',
  unread_message: 'No Unread Messages',
  view_all: 'View all',
  pinned_panel_title: '"Pinned" by Companies',
  information_panel_title: 'Information from GitTap',
  recommened_jobs_more: 'more',
  required_skill: 'Required Skill',
  scouted_label: 'Congratulations! You are scouted!',
  pinned_label: 'Pinned from the company',
  interested_label: 'Interested from the company',
  left_panel: {
    saved_search: 'Saved Search',
    skill_search: 'Your Skill Search',
    position_search: 'Your Position Search'
  }
}
