import emailNotification from '@/api/customer/master/email-notification'

const data = {
  items: sessionStorage.getItem('emailNotifications')
    ? JSON.parse(sessionStorage.getItem('emailNotifications'))
    : []
}

export default {
  namespaced: true,

  state () {
    return {
      ...data
    }
  },
  mutations: {
    /**
     * Set the items.
     *
     * @param {*} state
     * @param {*} items
     */
    SET_ITEMS (state, items) {
      state.items = items
    },

    /**
     * Reset the state to default.
     *
     * @param {*} state
     */
    RESET (state) {
      for (const key in state) {
        state[key] = data[key]
      }
    }
  },
  actions: {

    /**
     * Get all income range records
     */
    all ({ commit, dispatch, state }) {
      if (state.items.length) return

      return emailNotification.index()
        .then(({ data }) => {
          commit('SET_ITEMS', data)

          sessionStorage.setItem('emailNotifications', JSON.stringify(data))
        })
        .catch(({ response }) => dispatch('httpException/handle', response, { root: true }))
    },

    /**
     * Commit the SET_ITEMS mutation
     *
     */
    setItems ({ commit }, items) {
      commit('SET_ITEMS', items)

      sessionStorage.setItem('emailNotifications', JSON.stringify(items))
    }
  }
}
