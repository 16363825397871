import api from '../index'

/**
 * ===================
 * Applicant API
 * ===================
 */
export default {
  baseUrl: `${process.env.VUE_APP_CUSTOMER_URL}/api/personal/v1`,
  url: 'applicants',
  ...api,

  /**
   * Get the applicant info of the authenticated user.
   *
   */
  auth (params) {
    return this.http(this.baseUrl)
      .get(`${this.url}/auth`, { params })
  },

  /**
   * Upload file to parse.
   *
   * @param int id
   * @return {*} http
   */
  parseFile (payload) {
    return this.http(this.baseUrl).post(`${this.url}/parseFile`, payload, {
      headers: { 'Content-Type': 'multipart/form-data' },
      withCredentials: true
    })
  },

  /**
   * Post url to parse.
   *
   * @param int id
   * @return {*} http
   */
  parseUrl (url) {
    return this.http(this.baseUrl).post(`${this.url}/parseUrl`, { url })
  },

  /**
   * Upload profile photo.
   *
   * @param file
   * @return {*} http
   */
  photoUpload (payload) {
    return this.http(this.baseUrl)
      .post(`${this.url}/profile-photo`, payload, {
        headers: { 'Content-Type': 'multipart/form-data' },
        withCredentials: true
      })
  },
  /**
   * Delete profile photo.
   *
   * @param id
   * @return {*} http
   */
  photoDelete () {
    return this.http(this.baseUrl)
      .delete(`${this.url}/profile-photo`)
  },

  /**
   * Update the applicant's preference for working remotely.
   *
   * @param {*} payload
   */
  workRemotely (payload) {
    return this.http(this.baseUrl)
      .put(`${this.url}/work-remotely`, payload)
  },

  /**
   * Update language locale
   *
   * @param {*} payload
   */
  setLocale (payload) {
    return this.http(this.baseUrl)
      .put(`${this.url}/set-locale`, payload)
  },

  /**
   * Update registration status to complete
   *
   * @return {*} http
   */
  completeStep () {
    return this.http(this.baseUrl)
      .put(`${this.url}/complete-step`)
  },

  /**
   * Upload profile video
   *
   * @return {*} http
   */
  videoUpload (payload) {
    return this.http(this.baseUrl)
      .post(`${this.url}/profile-video`, payload, {
        headers: { 'Content-Type': 'multipart/form-data' },
        withCredentials: true
      })
  },

  /**
   * Delete profile video
   *
   * @return {*} http
   */
  videoDelete () {
    return this.http(this.baseUrl)
      .delete(`${this.url}/profile-video`)
  }
}
