import api from '../index'

const {
  http,
  show,
  store,
  simpleSearch
} = api

/**
 * =================================
 * Applicant Position Experience API
 * =================================
 */
export default {
  baseUrl: `${process.env.VUE_APP_CUSTOMER_URL}/api/personal/v1`,
  url: 'applicant-position-experiences',
  http,
  simpleSearch,
  show,
  store,

  /**
   * Sync the ApplicantPositionExperience of an applicant.
   *
   */
  sync (payload) {
    return this.http(this.baseUrl)
      .post(`${this.url}/sync`, payload)
  }
}
