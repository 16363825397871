export default {
  header: {
    text1: '面接・カジュアル面談に進んだ方、全員にプレゼント',
    text1En: {
      description1: '面接・カジュアル面談に進んだ方',
      description2: '全員にプレゼント'
    },
    text2: {
      amazonGiftCard: 'Amazon Gift Card',
      number: '2,000',
      yen: '円分'
    },
    text3: {
      toYear: 'To 2022',
      nextStepCampaign: 'Next Step応援キャンペーン'
    },
    text4: 'キャンペーン期間：12/1(水)～1/31(月)'
  },
  content1: {
    headerText: 'プレゼント条件',
    body1: '掲載求人・企業からのスカウトに対して、「応募」or「話を聞いてみたい」をクリックし、「面接」or「カジュアル面談」に進んだ方全員にAmazon Gift Card 2,000円分をプレゼント。',
    body2: '2021年から2022年へ、次のステップに向けた転職活動を応援しています！ぜひ気軽に、様々な求人に応募して、多くの企業とお話してみてください。',
    jobSearch: '求人検索ページへ'
  },
  content2: {
    headerText: '詳細・注意事項',
    list1: 'プレゼントはおひとり様1回まで（キャンペーン期間中の最初の面接・カジュアル面談について、プレゼントをお送りします）',
    list2: 'キャンペーン期間：2021年12月1日(水)～2022年1月31日(月)、期間中の応募 ',
    list3: 'Amazon Gift Cardは、Eメールにてギフトコードを送らせていただきます。Eメールの送信は2022年1月～2月になります、ご了承ください。',
    list4: {
      text1: '「面接」or「カジュアル面談」に進んだ方は ',
      textLinkHere: 'こちら',
      text2: 'からご報告ください。ご報告をいただけない場合、プレゼントをお渡しできない場合がありますのでご注意ください。'
    }
  }
}
