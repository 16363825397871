import api from '../index'

const {
  http,
  store,
  destroy
} = api

/**
 * ===================
 * Interests API
 * ===================
 */
export default {
  baseUrl: `${process.env.VUE_APP_COMMON_URL}/api/v1`,
  url: 'interests',
  http,
  store,
  destroy,
  /**
   * Get all job interests.
   *
   */
  search (params) {
    return this.http(this.baseUrl)
      .post(`${this.url}/search`, params)
  }
}
