<template>
  <v-container>
    <v-row class="py-4">
      <v-col
        class="px-0"
        cols="12"
        md="7"
      >
        <span
          v-for="(item, index) in links"
          :key="index"
        >
          <v-btn
            class="text-none caption"
            text
            link
            x-small
            @click="goToLink(item.path)"
          >
            <span
              class="footer-font"
              style="font-size: 14px;"
            >
              {{ item.title }}
            </span>
          </v-btn>
          <span
            v-if="index != links.length-1"
            class="footer-divider"
          >|</span>
        </span>
      </v-col>
      <v-col
        cols="6"
        md="5"
        class="text-right px-0"
      >
        <span
          class="footer-font"
          style="font-size: 12px;"
        >
          GitTap&copy; Bizmates Inc. All Rights Reserved {{ year }}
        </span>
        <span
          class="ml-7"
        >
          <v-btn
            v-for="(item, index) in socialPageDetails"
            :key="index"
            icon
            class="mr-1"
            @click="goToLink(item.link)"
          >
            <v-img
              :src="require(`@/assets/${item.iconFileName}`)"
              height="24px"
              width="24px"
              contain
            />
          </v-btn>
        </span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    year: {
      type: String,
      required: true,
      default: String
    },
    links: {
      type: Array,
      required: true,
      default: Array
    }
  },
  data () {
    return {
      socialPageDetails: [
        {
          iconFileName: 'facebook-square.svg',
          link: process.env.VUE_APP_GITTAP_FACEBOOK_URL
        },
        {
          iconFileName: 'linkedin.svg',
          link: process.env.VUE_APP_GITTAP_LINKEDIN_URL
        }
      ]
    }
  },
  methods: {
    goToLink (link) {
      window.open(link, '_blank')
    }
  }
}
</script>

<style>
.footer-divider{
  color: #B7BCC9;
  font-weight: 900 !important;
}
.footer-font{
  letter-spacing: 0px !important;
  color: #1A1A1A;
  opacity: 1;
}
</style>
