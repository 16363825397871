export default {
  policy: {
    text: 'Privacy Policy'
  },
  mainHeader: {
    text: 'Bizmates, Inc. (hereinafter referred to as the “Company”) shall provide the Privacy Policy (hereinafter referred to as the “Policy”) as follows and handle with the utmost care in accordance therewith the personal information that is collected by the Company from customers (hereinafter referred to as the “Customer”) who use the services the Company provides (whether paid or free, and such as site visits or e-mail inquiries shall be included. hereinafter referred to as the “Services”)'
  },
  numeric: [
    {
      title: 'Personal information',
      content: 'Personal information means a name, gender, date of birth, email address, or the like that the Customer provides in use of the Services of the Company, whereby a specific individual can be identified. Personal information that the Company collects shall be limited to the information that the Customer registers on the website of the Company (the “Site”) and other information that the Customer provides in relation to the Services, and the information that the Company collects when the Customer uses the Services.'
    },
    {
      title: 'On proper acquisition, use and provision of personal information',
      numbered: true,
      bullets: [
        {
          content: 'The Company shall acquire personal information by proper methods, to the extent necessary for execution of the business, taking into account the details and scale of the business. Personal information shall be used within the scope of the specified purpose of use, and if the Company changes the purpose of use, the Company shall notify the subject of information in advance and obtain the consent thereof.'
        },
        {
          content: 'The Company shall not provide personal information to a third party without the consent of the subject of information, except for in the case of entrusting work for execution of the purpose of use; provided, however, that the Company may provide personal information without the consent of the subject in the following cases:',
          romans: [
            {
              numeral: '(i)',
              content: 'Where the provision is under the laws and regulations;'
            },
            {
              numeral: '(ii)',
              content: 'Where the provision is necessary for the protection of human life, body or assets, and it is difficult to obtain the consent of the subject of information;'
            },
            {
              numeral: '(iii)',
              content: 'Where the provision is especially necessary for improvement of public health or promotion of the sound development of children, and it is difficult to obtain the consent of the subject of information;'
            },
            {
              numeral: '(iv)',
              content: 'Where it is necessary to cooperate with a government agency, or local municipality, or parties entrusted thereby, in execution of the affairs provided under the laws and regulations, and there is a possibility of creating a disruption in the execution of the relevant affairs by obtaining the consent of the subject of information;'
            },
            {
              numeral: '(v)',
              content: 'Where the personal information is provided as a result of business succession due to merger or other reasons, and the information will be handled within the scope of the purpose of use before the succession;'
            }
          ]
        },
        {
          content: 'Even if the Company entrusts the handling of personal information to a third party, the Company shall exercise necessary and proper supervision of the relevant contractor of the entrustment so that personal information of the Customer will be managed safely.'
        },
        {
          content: 'The Company shall use and provide personal information after the Personal Information Protection Manager confirms the appropriateness thereof, in order to prevent out-of-purpose use or provision of the personal information. In addition, the Company has reinforced controlling activities through voluntary checks or internal audits, etc.'
        }
      ]
    },
    {
      title: 'Security management measures for protection of personal information',
      numBullets: [
        {
          label: 'Preventive measures for leaks, destruction/loss or damage of personal information',
          content: 'Company has implemented proper security measures to prevent leaks, destruction/loss or damage of personal information. In addition, the Company educates and supervises employees for thorough implementation thereof. When the Company outsources the work of handling personal information, the Company has ensured the selection and supervision of the outsources strictly.'
        },
        {
          label: 'Corrective action for leaks, destruction/loss or damage of personal information',
          content: 'In the case of the occurrence of leaks, destruction/loss or damage of personal information, the Company shall place the utmost priority on the prevention of the spread of damage, and take corrective actions promptly, as well as pursue the causes and strive to prevent recurrence. In addition, the Company shall report information relating to incidents to related parties and organizations to the extent necessary.'
        }
      ]
    },
    {
      title: 'Compliance with the laws and regulations, guidelines provided by the government and other norms',
      content: 'The Company shall comply with the Act on the Protection of Personal Information and related laws and regulations, guidelines provided by the government and norms, etc. of affiliated organizations and the like'
    },
    {
      title: 'Continuous improvement of the personal information protection management system',
      content: 'The Company shall execute, maintain and regularly review the management system related to protection of personal information, and strive for continuous improvement thereof.'
    },
    {
      title: 'Use of statistical data',
      content: 'The Company may create statistical data processed so that no individual can be identified, based on the provided personal information. The Company may use without any restriction the statistical data by which an individual cannot be identified.'
    },
    {
      title: 'Cautions',
      numBullets: [
        {
          content: 'On-line English conversation business (on-line English conversation business, overseas on-site training business, etc.) and on-line Japanese conversation business',
          romans: [
            {
              numeral: '(i)',
              content: 'The Skype ID is open information that can be searched on in Skype software, etc., and disclosed to trainers for the purpose of provision of lessons. Therefore, the Skype ID does not fall under personal information that is subject to protection and management by the Company taking necessary and proper measures. The Company requests that Customers take reasonable and necessary preventive measures for protection and management of their own personal information, such as registering a Skype ID different from the one used privately, or the like.'
            },
            {
              numeral: '(ii)',
              content: 'Out of the information the trainer of the Company collects during lessons, information whereby a specific individual can be identified obtained for the purpose of use in lessons shall be included in personal information.'
            },
            {
              numeral: '(iii)',
              content: 'The Company prohibits Customers from having contact with trainers other than in the Services of the Company, whether it be on-line or off-line. The Company shall assume absolutely no liability for leaks of personal information caused by personal contact with trainers.'
            }
          ]
        },
        {
          content: 'Personnel referral services',
          romans: [
            {
              numeral: '(i)',
              content: 'Out of information the adviser of the Company collects during the counseling, information whereby a specific individual can be identified shall be included in personal information.'
            },
            {
              numeral: '(ii)',
              content: 'The Company prohibits Customers from having contact with advisers other than in the Services of the Company, whether on-line or off-line. The Company shall assume absolutely no liability for leaks of personal information caused by personal contact with advisers.'
            }
          ]
        }
      ]
    },
    {
      title: 'Handling of complaints and consultations relating to personal information',
      content: 'If there is any complaint or consultation relating to handling of personal information to the Company, please contact the Personal Information Inquiry Counter as below. Also, inquiries about protection of personal information of the Company shall be handled as well at the Counter below: Bizmates, Inc. support@bizmates.jp'
    },
    {
      title: 'Revision of this Policy',
      content: 'The contents of this Policy may be revised without advance approval. The Policy after revision shall come into effect when published on the Site, except for in cases where otherwise provided by the Company.'
    }
  ],
  childHeader: {
    numeric: true,
    title: 'Matters on concerning publication of the purpose of use of personal information',
    text: 'Matters on disclosure of the purpose of use of personal information',
    content: 'The purpose of use of personal information handled by Bizmates, Inc. (hereinafter referred to as the “Company”) shall be as in the following table. For personal information with the (*) mark, it is possible to request of the Company notification or disclosure of the purpose of use, correction, addition or deletion of contents, suspension of use, deletion or suspension of provision to a third party as being personal information subject to disclosure.'
  },
  tables: {
    headers: ['Type of personal information', 'Purpose of use'],
    numeric: true,
    content: [
      {
        label: 'All businesses in common',
        rows: [
          {
            content: [
              {
                desc: [
                  'Subscriber information *',
                  '(such as a name, gender, date of birth, e-mail address or information of device, log, cookie, location, or activity history on the internet)'
                ]
              },
              {
                desc: [
                  'For provision of products and services applied for For guidance and messages from the Company relating to products and services',
                  'For analysis of customer trends, surveys and analysis of product development, etc.',
                  'For handling when trouble occurs, or emergencies such as disasters For settlement of service use fees, collection of delayed payments, etc.',
                  'For identification'
                ]
              }
            ]
          },
          {
            content: [
              {
                desc: [
                  'Business partner information*'
                ]
              },
              {
                desc: [
                  'For customer management and marketing use'
                ]
              }
            ]
          },
          {
            content: [
              {
                desc: [
                  'Seminar participant information*'
                ]
              },
              {
                desc: [
                  'For guidance and messages from the Company relating to products and services'
                ]
              }
            ]
          },
          {
            content: [
              {
                desc: [
                  'Various inquiries information*'
                ]
              },
              {
                desc: [
                  'For handling various inquiries'
                ]
              }
            ]
          },
          {
            content: [
              {
                desc: [
                  'Recruitment applicant information*'
                ]
              },
              {
                desc: [
                  'For judgment on hiring personnel that the Company needs'
                ]
              }
            ]
          },
          {
            content: [
              {
                desc: [
                  'Existing worker information*'
                ]
              },
              {
                desc: [
                  'For HR management work and work-related contact'
                ]
              }
            ]
          },
          {
            content: [
              {
                desc: [
                  'Information relating to executives and employees of the Company, and their family, and retirees of the Company*'
                ]
              },
              {
                desc: [
                  'For acquisition and management of employee information under the laws and regulations',
                  'For health management of employees',
                  'For performance of HR salary work operations',
                  'For benefits and welfare',
                  'For emergency contact in disasters, etc.',
                  'For any other processing necessary for personnel affairs and labor affairs management'
                ]
              }
            ]
          }
        ]
      },
      {
        label: 'On-line English conversation business and on-line Japanese conversation business',
        rows: [
          {
            content: [
              {
                desc: [
                  'Corporate lecture-taker information'
                ]
              },
              {
                desc: [
                  'Same as the purpose of use for “subscriber information” above'
                ]
              }
            ]
          }
        ]
      },
      {
        label: 'Personnel placement business',
        rows: [
          {
            content: [
              {
                desc: [
                  'Information on subscription applicant and users*'
                ]
              },
              {
                desc: [
                  'For provision of the Services by the Company, such as referrals of work, communications relating to work, etc.',
                  'For guidance and messages from the Company relating to products and services',
                  'For analysis of customer trends, surveys and analysis of product development, etc.',
                  'For handling when trouble occurs, or emergencies such as disasters, etc.',
                  'For collection of compensation for damage, etc.',
                  'For identification'
                ]
              }
            ]
          },
          {
            content: [
              {
                desc: [
                  'Job-offering companies’ information*'
                ]
              },
              {
                desc: [
                  'For referrals of personnel from the Company, and provision of applied products and services',
                  'For guidance and messages from the Company relating to products and services',
                  'For analysis of customer trends, surveys and analysis of product development, etc.',
                  'For handling when trouble occurs, or emergencies such as disasters, etc.',
                  'For settlement of service use fees, collection of delayed payments, etc.'
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  info: [
    {
      label: 'Disclosed matters on personal information subject to disclosure',
      numeric: true,
      content: [
        'The Company publicly announces the following matters upon handling personal information subject to disclosure'
      ],
      numBullet: [
        {
          label: 'Name of the Company',
          content: [
            'Bizmates, Inc. (Chiyoda-ku, Tokyo)'
          ]
        },
        {
          label: 'Personal information protection manager',
          content: [
            'Person in charge of marketing (contact:privacy@bizmates.jp)'
          ]
        },
        {
          label: 'Purpose of use of all personal information subject to disclosure',
          content: [
            'See “1. Matters on disclosure of purpose of use of personal information”'
          ]
        },
        {
          label: 'Window for inquiries and complaints on handling of personal information subject to disclosure',
          content: [
            'Personal information inquiry contact: Personal Information Protection Manager',
            'Address: 10F VORT Akihabara maxim,1-7-9, Kanda-Sudacho, Chiyoda-ku, Tokyo',
            'Contact: privacy@bizmates.jp'
          ]
        },
        {
          label: 'Name of certified personal information protection organization with which the Company is affiliated, and the place for requests for resolution of complaints',
          content: [
            'Name of certified personal information protection organization: Japan Information Processing and Development Center (JIPDEC)',
            'Party to petition for resolution of complaints: Personal Information Protection Complaints Consultation Office',
            'Address: C/o Roppongi First Building, 1-9-9, Roppongi, Minato-ku, Tokyo 106-0032',
            'Tel: 03-5860-7565 , 0120-700-779'
          ]
        },
        {
          label: 'Method of request for disclosure, etc.',
          content: [
            'See “3. Procedures concerning requests for disclosure, etc. of personal information”'
          ]
        }
      ]
    },
    {
      label: 'Procedures concerning requests for disclosure, etc. of personal information',
      numeric: true,
      content: [
        'The Company shall accept requests for notification or disclosure of the purpose of use of personal information, correction, addition, deletion of the contents thereof, suspension of use, deletion or suspension of provision to a third party, and complaints or consultations relating to personal information at the Personal Information Inquiry Counter (privacy@bizmates.jp); provided, however, that for personal information requested, if the personal information cannot be disclosed such as pursuant to the provisions of the laws and regulations, etc. or the storage period of the Company is elapsed, please understand that the Company may not respond to requests for notification or disclosure of the purpose of use of personal information of the customer, corrections, additions to, deletion of the contents thereof, suspension of use, deletion or suspension of provision to a third party.',
        'Please inquire of the Personal Information Inquiry Counter for requests for disclosure, etc. of personal information. The Company will send the prescribed form (Personal Information Inquiry Request Form), and the Customer shall enter the required matters and send the Form to the following address.'
      ],
      note: [
        '【Items that must be sent】',
        '【Address for sending】'
      ],
      numBullet: [
        {
          label: 'Personal Information Inquiry Request Form',
          content: [
            'Please enter the following matters in the Form',
            '(1) Address, name, telephone number and seal of the subject person',
            '(2) Date of request',
            '(3) Information to specify the personal information of which disclosure is requested'
          ]
        },
        {
          label: 'Certificate to identify the subject, or the proxy thereof',
          content: [
            '<In the case of the subject of information>',
            '• Any one of driving license, passport, health insurance card, or resident card',
            '<In the case of proxy>',
            '• Letter of proxy to indicate the person is the proxy of the subject (essential)',
            '• Either of the seal impression certificate of the subject person affixed to the letter of proxy, or identification documents of the proxy'
          ]
        },
        {
          label: 'Handling charge: 1,000 yen (only in the case of notification or disclosure of the purpose of use)'
        }
      ]
    }
  ],
  companyInfo: [
    'Personal Information Protection Manager',
    'Bizmates, Inc.',
    '10F VORT Akihabara maxim, 1-7-9, Kanda-Sudacho, Chiyoda-ku, Tokyo'
  ],
  closing: {
    req: '* In the case of a request by proxy, please make an inquiry. We limit the persons who can make a request by proxy to the following:',
    list: [
      '• Legal representative for a minor or adult ward',
      '• Proxy delegated by the subject person for requests for disclosure, etc.'
    ],
    msg: 'When the Company receives the above request, upon carrying out the necessary investigation, etc., the Company shall reply in writing within two (2) weeks. Please understand that when we reply, in order to avoid prevention of leaks of personal information by impersonation, we will use registered post to be signed and received by addressee only. The governing language of this Privacy Policy shall be Japanese.'
  },
  remark: {
    name: 'Nobuaki Suzuki',
    position: 'President',
    company: 'Bizmates, Inc.'
  }
}
