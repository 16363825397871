export default {
  title: '保存した検索結果',
  saveDuplicatedMessage: 'その条件はすでに保存されています',
  putSearchConditionMessage: '検索条件を入力してください',
  alert: 'お知らせ',
  subText:
    '「お知らせ」をONにすると、保存した検索条件にマッチする求人をメールでレコメンドします',
  confirmDelete: {
    header: '検索条件を削除',
    description: 'このアクションは元に戻すことができません。この検索条件を削除してもよろしいですか？',
    delete: '削除',
    cancel: 'キャンセル'
  }
}
