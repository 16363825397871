import random from 'lodash/random'
/**
 * =======================================
 * Send token to identify users to Google Analytics
 * =======================================
 */
export default {
  methods: {
    customActionPush (params) {
      try {
        const user = JSON.parse(sessionStorage.getItem('user'))
        let uid = (user && user.id) || localStorage.getItem('tmp_uid')
        if (!uid) {
          uid = `G-${parseInt(random(5, true) * 10000)}`
          localStorage.setItem('tmp_uid', uid)
        }
        const variables = {
          event: 'userflow',
          processaction: JSON.stringify({
            uid,
            l_id: 0,
            act: 1,
            cv: 6,
            ...params
          })
        }
        console.log('GTM Action push start')
        console.log(variables)
        console.log('GTM Action push end')
        window.dataLayer.push(variables)
        return true
      } catch (e) {
        console.log(e)
        return true
      }
    }
  }
}
