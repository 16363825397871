export default {
  email: {
    subscribeEmail: {
      title: 'Subscribe Email',
      subtitle1: 'We will send you job postings and information that are useful for your career. Please note that you still receive some alert emails and important information even if you turn off all the subscriptions.',
      subtitle2: ''
    },
    notifications: 'Notifications',
    reminderOfMessage: 'Reminder of Message',
    reminderOfScouted: 'Reminder of Scouted',
    notificationOfPinned: 'Notification of Pinned',
    notifFromGitTap: 'Notification from GitTap'
  }
}
