import Vue from 'vue'
import Router from 'vue-router'

import App from './app'
import PageNotFound from '@/pages/PageNotFound'

Vue.use(Router)

export default new Router({
  mode: 'history',
  scrollBehavior (to, from) {
    // always scroll to top on different path
    if (to.path !== from.path) {
      window.scrollTo(0, 0)
    }
  },
  routes: [
    ...App.routes,
    {
      path: '*',
      name: 'PageNotFound',
      component: PageNotFound
    }
  ]
})
