export default {
  title: 'お問い合わせフォーム',
  agreement: {
    line_1: 'Bizmates、Inc.の',
    link: 'プライバシーポリシー',
    line_2: 'に同意します'
  },
  form: {
    name: {
      label: '名前',
      placeholder: 'あなたの名前'
    },
    email: {
      label: 'Eメール',
      placeholder: 'あなたのメール'
    },
    country: {
      label: '国',
      placeholder: 'あなたの国を選択してください'
    },
    question: {
      label: '質問',
      placeholder: 'ここに質問を書いてください'
    }
  },
  confirmation: {
    title: 'お問い合わせ・ご連絡フォーム 内容確認',
    body: '以下の内容で送信されます',
    table: {
      contact_information: 'ご担当者様情報',
      contents: 'お問い合わせ内容'
    },
    success: {
      title: 'お問い合わせ・ご連絡フォーム 送信完了',
      body: 'お問い合わせを送信しました。 GitTap事務局にて内容を確認し、5営業日以内に返信させていただきます。'
    }
  }
}
