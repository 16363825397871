/**
 * =====================================================
 * Help store
 * =====================================================
 *
 * Handles help states and behavior of the application.
 *
 * =====================================================
 */
const data = {
  show: false,
  title: '',
  body: '',
  header: '',
  tip: ''
}

export default {
  namespaced: true,
  state () {
    return {
      ...data
    }
  },
  mutations: {
    /**
     * Reset the state to default.
     *
     * @param {*} state
     */
    RESET (state) {
      for (const key in state) {
        state[key] = data[key]
      }
    },

    /**
     * Set the show.
     *
     * @param state
     * @param show
     */
    SET_SHOW (state, show) {
      state.show = show
    },

    /**
     * Set the title.
     *
     * @param state
     * @param title
     */
    SET_TITLE (state, title) {
      state.title = title
    },

    /**
     * Set the body.
     *
     * @param state
     * @param body
     */
    SET_BODY (state, body) {
      state.body = body
    },

    /**
     * Set the header.
     *
     * @param state
     * @param header
     */
    SET_HEADER (state, header) {
      state.header = header
    },

    /**
     * Set the tip.
     *
     * @param state
     * @param tip
     */
    SET_TIP (state, tip) {
      state.tip = tip
    }
  },
  actions: {
    /**
     * Commit the SET_SHOW mutation.
     *
     * @param {*} param0
     * @param {*} show
     */
    setShow ({ commit }, show) {
      commit('SET_SHOW', show)
    },

    /**
     * Commit the SET_TITLE mutation.
     *
     * @param {*} param0
     * @param {*} title
     */
    setTitle ({ commit }, title) {
      commit('SET_TITLE', title)
    },

    /**
     * Commit the SET_BODY mutation.
     *
     * @param {*} param0
     * @param {*} body
     */
    setBody ({ commit }, body) {
      commit('SET_BODY', body)
    },

    /**
     * Commit the SET_HEADER mutation.
     *
     * @param {*} param0
     * @param {*} header
     */
    setHeader ({ commit }, header) {
      commit('SET_HEADER', header)
    },

    /**
     * Commit the SET_TIP mutation.
     *
     * @param {*} param0
     * @param {*} tip
     */
    setTip ({ commit }, tip) {
      commit('SET_TIP', tip)
    },

    /**
     * Reset the state to its default value.
     *
     * @param {commit}
     */
    reset ({ commit }) {
      commit('RESET')
    }
  }
}
