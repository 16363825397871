<template>
  <div class="content">
    <span class="notif-header">
      {{ $t('notification.notifications') }}
    </span>
    <v-sheet v-if="loading">
      <v-skeleton-loader
        v-for="n in 3"
        :key="n"
        class="mx-auto"
        type="list-item-three-line"
      />
    </v-sheet>
    <div
      v-else-if="!loading && list.length < 1"
      class="no-unread"
    >
      <span>{{ $t('notification.no_unread_notification') }}</span>
    </div>
    <div v-else>
      <v-row
        v-for="(notification,index) in list"
        :key="index"
        class="notifications"
        @click="redirectTo('/notifications/'+notification.id)"
      >
        <v-col
          cols="2"
        >
          <div
            v-if="notification.type === 1"
            class="icon"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="13.686"
              height="16.679"
              viewBox="0 0 13.686 16.679"
            >
              <path
                id="Icon_material-notifications-none"
                data-name="Icon material-notifications-none"
                d="M12.843,20.429a1.716,1.716,0,0,0,1.711-1.711H11.132A1.716,1.716,0,0,0,12.843,20.429ZM17.975,15.3V11.02c0-2.626-1.394-4.824-3.849-5.406V5.033a1.283,1.283,0,0,0-2.566,0v.582C9.113,6.2,7.711,8.386,7.711,11.02V15.3L6,17.008v.855H19.686v-.855Zm-1.711.855H9.421V11.02c0-2.121,1.292-3.849,3.421-3.849S16.264,8.9,16.264,11.02Z"
                transform="translate(-6 -3.75)"
                fill="#626262"
              />
            </svg>
          </div>
          <div
            v-else
            class="icon"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="13.598"
              height="12.918"
              viewBox="0 0 13.598 12.918"
            >
              <path
                id="Icon_material-card-giftcard"
                data-name="Icon material-card-giftcard"
                d="M15.238,5.72H13.756a2.035,2.035,0,0,0-3.617-1.8l-.34.456-.34-.462A2.035,2.035,0,0,0,5.842,5.72H4.36a1.349,1.349,0,0,0-1.353,1.36L3,14.558a1.355,1.355,0,0,0,1.36,1.36H15.238a1.355,1.355,0,0,0,1.36-1.36V7.079A1.355,1.355,0,0,0,15.238,5.72Zm-3.4-1.36a.68.68,0,1,1-.68.68A.682.682,0,0,1,11.838,4.36Zm-4.079,0a.68.68,0,1,1-.68.68A.682.682,0,0,1,7.759,4.36Zm7.479,10.2H4.36V13.2H15.238Zm0-3.4H4.36V7.079H7.814L6.4,9l1.1.8L9.119,7.6l.68-.925.68.925L12.1,9.8,13.2,9,11.784,7.079h3.454Z"
                transform="translate(-3 -3)"
                fill="#ffa700"
              />
            </svg>
          </div>
        </v-col>
        <v-col
          class="pb-6"
          cols="10"
        >
          <div class="notif-title">
            <!-- eslint-disable vue/no-v-html -->
            <span
              class="body"
              v-html="notification.body"
            />
            <span class="timestamp">
              {{ notification.timestamp }}
            </span>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>

import luxon from '@/mixins/luxon'

export default {
  mixins: [luxon],
  props: {
    value: {
      type: [Number, null],
      default: null
    },
    items: {
      type: Array,
      default: Array
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      selected: null, // selected index of the exapansion panel
      notifications: this.items
    }
  },
  computed: {
    list () {
      return this.notifications.map((notification) => {
        notification.timestamp = this.asiaTokyoToLocal(notification.updated_at).toRelative()
        return notification
      })
    }
  },
  methods: {
    redirectTo (route) {
      this.$router.push({ path: route })
    }
  }
}
</script>

<style scoped>
.unread {
  border-left: 8px solid var(--v-blank3-base)
}
.notif-header {
  font: normal normal normal 14px/18px Roboto;
  letter-spacing: 0px;
}
.box {
  display: flex;
  text-overflow: ellipsis;
  max-height: 3.6em;
}
.icon {
  display: flex;
  border: 1px solid #B7BCC9;
  height: 30px;
  width: 30px;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
}
.notif-title .body {
  display: block;
  display: -webkit-box;
  max-width: 100%;
  height: 53px;
  margin: 0 auto;
  font-size: 14px;
  line-height: 1;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font: normal normal normal 13px/18px Roboto;
}
.notif-title .timestamp {
  font: normal normal normal 10px/18px Roboto;
  color: #626262;
  position: absolute;
  right: 15px;
}
.notifications {
  cursor: pointer;
  margin-right: -16px;
  margin-left: -16px;
}
.notifications:hover {
  background: #F4F4F4 0% 0% no-repeat padding-box;
}
.content {
  padding: 9px 16px 0px;
}
.no-unread {
  text-align: center;
  padding: 10px;
  color: #626262 !important;
  font: normal normal normal 13px/18px Roboto;
}
</style>
