import writingLevel from '@/api/customer/master/writing-level'

const data = {
  items: sessionStorage.getItem('writingLevels')
    ? JSON.parse(sessionStorage.getItem('writingLevels'))
    : []
}

export default {
  namespaced: true,

  state () {
    return {
      ...data
    }
  },
  mutations: {
    /**
     * Reset the state to default.
     *
     * @param {*} state
     */
    RESET (state) {
      for (const key in state) {
        state[key] = data[key]
      }
    },

    /**
     * Set the items.
     *
     * @param {*} state
     * @param {*} items
     */
    SET_ITEMS (state, items) {
      state.items = items
    }
  },
  actions: {
    /**
     * Get all writingLevel records.
     *
     * @param {*}
     */
    all ({ commit, dispatch, state }) {
      if (state.items.length) return

      return writingLevel.index()
        .then(({ data }) => {
          commit('SET_ITEMS', data)

          sessionStorage.setItem('writingLevels', JSON.stringify(data))
        })
        .catch(({ response }) => dispatch('httpException/handle', response, { root: true }))
    }
  }
}
