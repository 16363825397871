export default {
  NOTIFICATIONS: {
    MESSAGE: {
      TIMINGS: 60000 * 5 // every 5 minutes
    }
  },
  MESSAGE: {
    LONG_POLLING: {
      INTERVAL: 60000 * 5 // every 5 minutes
    },
    MESSAGE_TYPES: {
      SCOUT: 1,
      PIN: 2,
      MESSAGING: 3,
      LETS_TALK: 4,
      LIKE_TO_KNOW_MORE: 5,
      CL_MESSAGING: 6,
      VIDEO_INTERVIEW: 7,
      VIDEO_INTERVIEW_ANSWER: 8,
      VIDEO_INTERVIEW_COMPLETION: 9,
      SCOUT_TEMPLATE: 10,
      SCOUT_APPLIED: 11,
      SCOUT_DECLINED: 12,
      SCOUT_KNOW_MORE: 13,
      SAVE: 14
    },
    MESSAGE_STATUSES: {
      KNOW_MORE: 1,
      APPLIED: 2,
      DECLINED: 3
    },
    CONTENT_TYPES: {
      MESSAGE: 1,
      FILE: 2
    },
    SENDER_TYPE: {
      CS: 1,
      CL: 2,
      SYSTEM: 4
    },
    FILE_DIR: 'message_attachments',
    MAX_FILE_SIZES: {
      VIDEO: 52428800,
      NON_VIDEO: 5242880
    },
    ALLOWED_FILE_TYPES: {
      VIDEO: ['video/mp4', 'video/quicktime'],
      NON_VIDEO: [
        'application/msword',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/pdf',
        'image/png',
        'image/jpeg'
      ]
    },
    FILE_TYPE_GROUPS: {
      VIDEOS: [
        'video/mp4',
        'video/quicktime'
      ],
      FILES: [
        'application/msword',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/pdf'],
      PHOTOS: [
        'image/png',
        'image/jpeg'
      ]
    },
    PREVIEWABLE_FILE_TYPES: [
      'image/png',
      'image/jpeg',
      'application/pdf',
      'video/mp4',
      'video/quicktime'
    ]
  },
  APPLY_STATUS: {
    DOCUMENT_SELECTION: 1,
    FIRST_INTERVIEW: 2,
    SECOND_OR_FURTHER_INTERVIEW: 3,
    FINAL_INTERVIEW: 4,
    REJECT: 5,
    CANCEL: 6,
    JOB_OFFER: 7,
    PLACEMENT: 8,
    JOIN: 9,
    INVISIBLE: 10
  },
  JOB_POSTING: {
    CODE_PREFIX: 'JP',
    /**
     * Job Posting Statuses
     */
    AWAITING_FOR_APPROVAL_STATUS: 1,
    DRAFT_STATUS: 2,
    PUBLISHED_STATUS: 3,
    INACTIVE_STATUS: 4,
    DECLINED_STATUS: 5,
    SUSPENDED_STATUS: 6,

    /**
     * Language Display
    */
    JAPANESE_AND_ENGLISH: 1,
    JAPANESE_ONLY: 2,
    ENGLISH_ONLY: 3
  },
  SKILL_CATEGORIES: {
    FEATURED_SKILLS: 1
  },
  WORKING_STATUSES: {
    STUDENT: 4,
    OTHER: 6
  },
  QUESTIONNAIRE_TYPES: {
    SELECTION: 1,
    FREE_TEXT: 2,
    WITH_VIDEO: 3
  },
  USER_TYPE: {
    CS: 1,
    CL: 2,
    ADMIN: 3,
    SYSTEM: 4
  },
  LANGUAGE_ID: {
    ENGLISH: 12,
    JAPANESE: 23
  },
  LANGUAGE_SCORE_LEVEL_ID: {
    NATIVE: 1
  },
  JAPAN_PREFECTURES: {
    TOTAL_COUNT: 47
  },
  IT_SKILL_CATEGORIES: {
    SPECIAL_IT_SKILLS: 1
  },
  DIALING_CODES: {
    JAPAN: 104
  },
  YEARS_OF_EXPERIENCE: {
    FIVE_YEARS: 5,
    THREE_TO_FIVE_YEARS: 4,
    ONE_TO_THREE_YEARS: 3,
    LESS_THAN_ONE_YEAR: 2,
  }
}
