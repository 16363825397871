export default {
  title: 'Messages',
  count: '{0} unread messages',
  searchPlaceholder: 'Search by company name or job title',
  textareaPlaceholder: 'Message to {0}',
  sharedFiles: 'Shared files',
  sharedPhotos: 'Shared photos',
  file_too_big_title: 'File size is too big.',
  file_too_big_message: 'Maximum file size is up to 5MB.',
  file_too_big_video_message: 'Maximum file size is up to 50MB.',
  today: 'Today',
  yesterday: 'Yesterday',
  preview: 'Preview is not available. Please download it.',
  file_limit_title: 'File limit exceeded!',
  file_limit_body: 'You can attach maximum of Five files or One video (50MB) at once.',
  file_type_invalid: 'The {attribute} must be a file of type: {value}.',
  job_posting_closed: 'This position has been closed.',
  is_reported: 'This message has been invisible to the recipient. Please check if inappropriate contents are included.',
  is_filtered: 'Your contact information (email address, phone number, and so on…) will be displayed when you go on to the interview process.',
  closed: 'Closed',
  video_interview: 'You have received the details for video selection.',
  video_interview_alert: 'Video will be deleted in 30 days',
  video_interview_completion: 'You answered the question and sent it',
  pressEnter: 'Enter to add a new line',
  unread: 'Unread',
  send: 'Send',
  lessThanOrEqualTo: 'Must be less than or equal to {0}.',
}
