/**
 * =======================================
 * API Request for Authorization
 * =======================================
 */
import axios from 'axios'
import Config from '@/config/app'
import lang from '@/services/lang'

const locale = lang.get() ? lang.get().value : null

export default {
  baseUrl: Config.services.auth.url,

  /**
   * Login Request
   *
   * @param {*} params
   * @return {*} http
   */
  login (params) {
    const headers = {
      Accept: 'application/json',
      'Accept-Language': locale
    }

    return axios.post(`${this.baseUrl}/api/v1/login`, params, { headers, withCredentials: true })
  },

  /**
   * Logout Request
   *
   * @param {*} params
   * @return {*} http
   */
  logout () {
    return axios.post(`${this.baseUrl}/api/v1/logout`, {}, {
      headers: {
        Accept: 'application/json',
        'Accept-Language': locale
      },
      withCredentials: true
    })
  }
}
