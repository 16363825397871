import accountInformation from './account-information'
import authentication from './authentication'
import awards from './awards'
import basicPersonalInformation from './basic-personal-information'
import changeEmail from './change-email'
import changePassword from './change-password'
import common from './common'
import contactInformation from './contact-information'
import document from './document'
import education from './education'
import exception from './exception'
import experience from './experience'
import messages from './messages'
import preference from './preference'
import language from './language'
import login from './login'
import validations from './validations'
import profileRegistration from './profile-registration'
import navigation from './navigation'
import signUp from './sign-up'
import socialNetworkingServices from './social-networking-services'
import jobSearch from './job-search'
import searchCondition from './search-condition'
import jobDetail from './job-detail'
import notification from './notification'
import companyDetail from './company-detail'
import myPage from './my-page'
import myPageHeader from './my-page-header'
import approachList from './approach-list'
import bottomNav from './bottom-nav'
import deleteAccount from './delete-account'
import faq from './faq'
import inquiry from './inquiry'
import article from './article'
import articleHandlingPersonalInformation from './article-handling-personal-information'
import policy from './policy'
import toolbar from './toolbar'
import videoInterview from './video-interview'
import referral from './referral'
import jobApply from './job-apply'
import scoutReply from './scout-reply'
import campaignPage from './campaign-page'
import simpleVueValidation from './simple-vue-validation'
import settings from './settings'
import accountSettings from './account-settings'
import inform from './inform'
import channel from './channel'
import profileSetting from './profile-setting'

export default {
  accountInformation,
  authentication,
  awards,
  basicPersonalInformation,
  changeEmail,
  changePassword,
  common,
  contactInformation,
  document,
  education,
  exception,
  experience,
  language,
  login,
  messages,
  validations,
  profileRegistration,
  navigation,
  preference,
  signUp,
  socialNetworkingServices,
  jobSearch,
  searchCondition,
  jobDetail,
  notification,
  companyDetail,
  myPage,
  myPageHeader,
  approachList,
  bottomNav,
  deleteAccount,
  faq,
  inquiry,
  article,
  articleHandlingPersonalInformation,
  policy,
  toolbar,
  videoInterview,
  referral,
  jobApply,
  scoutReply,
  campaignPage,
  simpleVueValidation,
  settings,
  accountSettings,
  inform,
  channel,
  profileSetting
}
