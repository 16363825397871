import api from '../index'

const {
  http,
  store,
  put,
  index
} = api

/**
 * ===================
 * Applicant contact informations API
 * ===================
 */
export default {
  baseUrl: `${process.env.VUE_APP_CUSTOMER_URL}/api/personal/v1`,
  url: 'profile/contact-information',
  http,
  store,
  put,
  index,

  /**
   * Sync the Applicant Contact Information records from the request.
   *
   * @param payload
   */
  sync (payload) {
    return this.http(this.baseUrl)
      .post(`${this.url}/sync`, payload)
  }
}
