export default {
  namespaced: true,
  strict: process.env.NODE_ENV === 'development',
  state () {
    return {
      pagination: {
        current_page: 1,
        per_page: 10
      }
    }
  },
  mutations: {
    /**
     * Set the pagination.
     *
     * @param {*} state
     * @param {*} pagination
     */
    SET_PAGINATION (state, pagination) {
      state.pagination = pagination
    }
  },
  actions: {
    /**
     * Set the pagination.
     *
     * @param {*} state
     * @param {*} pagination
     */
    setPagination ({ commit }, pagination) {
      commit('SET_PAGINATION', pagination)
    }
  }
}
