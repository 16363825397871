import applicantBasicInfoApi from '@/api/customer/applicant-basic-info'

export default {
  namespaced: true,
  state () {
    return {
      loading: false,
      basicInfo: {}
    }
  },
  getters: {
    emptyDetails: state => {
      return !Object.keys(state.basicInfo).length
    }
  },
  mutations: {
    /**
     * Set the status.
     *
     * @param {*} state
     * @param {*} items
     */
    setLoading (state, loading) {
      state.loading = loading
    },
    /**
     * Set the item.
     *
     * @param {*} state
     * @param {*} auth
     */
    setBasicInfo (state, data) {
      state.basicInfo = {
        ...data
      }
    }
  },
  actions: {
    /**
     * Get Applicant Basic Information
     *
     * @param {commit, dispatch, getters }
     * @param {*} params
     */
    getApplicantBasicInfo ({ commit, dispatch, getters }, params) {
      if (!getters.emptyDetails) return

      commit('setLoading', true)

      params.with = [
        'city',
        'country',
        'nationality',
        'workPermission',
        'japanPrefecture.japanRegion'
      ]

      return applicantBasicInfoApi.simpleSearch(params)
        .then(({ data }) => commit('setBasicInfo', data))
        .catch(({ response }) => dispatch('httpException/handle', response, { root: true }))
        .finally(() => commit('setLoading', false))
    }
  }
}
