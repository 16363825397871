import api from '../../index'

const { store } = api
/**
 * ===================
 * Applicant API
 * ===================
 */
export default {
  baseUrl: `${process.env.VUE_APP_CUSTOMER_URL}/api/v2`,
  url: 'applicant',
  ...api,
  store,

  /**
   * Get the applicant info
   *
   */
  applicantShow() {
    return this.http(this.baseUrl).get(`${this.url}/profile`)
  },

  applicantSave(params) {
    return this.http(this.baseUrl).post(`${this.url}/profile`, params)
  },

  deletePhoto(id) {
    return this.http(this.baseUrl).delete(`${this.url}/profile-photo/${id}`)
  },

  savePhoto(payload) {
    return this.http(this.baseUrl).post(`${this.url}/profile-photo`, payload, {
      headers: { 'Content-Type': 'multipart/form-data' },
      withCredentials: true,
    })
  },

  deleteVideo(id) {
    return this.http(this.baseUrl).delete(`${this.url}/profile-video/${id}`)
  },

  saveVideo(id, payload, progressHandler) {
    return this.http(this.baseUrl).post(`${this.url}/profile-video`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progress) => {
        progressHandler(progress)
      },
    })
  },

  deleteGithub() {
    return this.http(this.baseUrl).delete(`${this.url}/profile/github`)
  },

  getApplicantPreview() {
    return this.http(this.baseUrl).get(`${this.url}/profile/preview`)
  },
}
