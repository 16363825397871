import axios from 'axios'
import autoAuth from '@/api/client/auto-auth'

export default {
  namespaced: true,
  state () {
    return {
      accessToken: '',
      expiration: 0
    }
  },
  mutations: {
    setAccessToken (state, accessToken) {
      state.accessToken = accessToken
    },

    /**
     * Set expiration of the accessToken to store
     * @param {*} state
     * @param {*} expiration
     */
    setExpiration (state, expiration) {
      state.expiration = expiration
    }
  },
  actions: {
    async getAccessToken ({ commit, dispatch, state }) {
      if (state.accessToken) return

      await autoAuth.auth()
        .then((response) => {
          commit('setAccessToken', response.data.access_token)
          // mille second
          // to keep enough buffer until expiration of server side, set short expiration
          const expiration = Date.now() + ((response.data.expires_in / 2) * 1000)
          commit('setExpiration', expiration)
        })
        .catch(({ response }) => {
          const error = {
            status: response.status,
            statusText: response.statusText,
            data: response.data
          }
          dispatch('httpException/handle', error, { root: true })
        })
    }
  }
}
