export default {
  title: 'Change password',
  current: {
    label: 'Current password',
    placeholder: 'Your current password'
  },
  new: {
    label: 'New Password',
    placeholder: 'Your new password'
  },
  confirm: {
    label: 'New password (confirmation)',
    placeholder: 'Confirm your new password'
  },
  submit: 'Save New Password',
  validations: {
    oldPassword: {
      required: 'Current password is required.',
      incorrect: 'Current password is incorrect.'
    },
    newPassword: {
      required: 'New password is required.',
      minLength: 'Must be at least 8 characters.',
      maxLength: 'Can only have up to 20 characters.',
      same: 'New password must not be the same with the current password.',
      alphaAndNumeric: 'Must contain alphabet and numeric characters.'
    },
    passwordConfirmation: {
      required: 'Password confirmation is required.',
      matched: 'Passwords doesn\'t match.'
    }
  },
  success: 'Password changed successfully'
}
