export default {
  maximumSelect: 'Maximum 15 items can be selected',
  maximumFiveSelect: 'Maximum 5 items can be selected',
  simpleRegistration: {
    skill: 'Skill',
    job: 'Job Experience',
    japanese: 'Japanese',
    japaneseLevel: 'Japanese Level',
    preference: 'Preference',
    basicInformation: 'Basic Information'
  },
  steps: {
    step1: 'Step 1',
    step2: 'Step 2',
    step3: 'Step 3',
    step4: 'Step 4'
  },
  next: 'Next',
  back: 'Back',
  complete: 'Complete',
  steppers: {
    '01': 'Language',
    '02': 'Skill/Experience',
    '03': 'Preference',
    '✓': 'Finish'
  },
  finish: {
    completed: 'Registration Completed',
    find: "Let's find a job!",
    search: 'Job Search',
    failed: 'Sorry, registration failed!',
    try: 'Please try again later or contact us.',
    back: 'Back To Home',
    next: 'Go on to the next',
    profile: 'Additional Information',
    info: {
      line1: 'Required fields have been filled.',
      line2:
        'Let us add more details to your profile to make it more attractive.',
      line3:
        'Additional profile information rapidly increase your chances of receiving job offers.'
    }
  },

  experience: {
    skill: 'Skill',
    currentStatus: 'Current Status',
    work: 'Work',
    education: 'Educational background',
    personalInformation: 'Personal Information'
  },
  help: {
    title: 'Help',
    nativeLanguage: {
      title: 'Native Language',
      body: 'if you have more than two, chose one'
    },
    speakingEnglish: {
      title: 'Speaking English/Japanese',
      body: `<strong class="foreground--text">Native:</strong>
      The level of speaking as a native speaker (1st language / mother tongue).

      <strong class="foreground--text">Fluent:</strong>
      Well advanced in any occupation or branch of knowledge.
      
      <strong class="foreground--text">Business:</strong>
      Capable of using a language easily and accurately in business.
      
      <strong class="foreground--text">Daily conversation:</strong>
      The level of speaking with certain phrases and simple word in a casual situation.

      <strong class="foreground--text">Greeting/None:</strong>
      The level of speaking with simple phrases or YES  NO for greeting or self-introduction.`
    },
    jlpt: {
      title: 'JLPT',
      body: `<strong class="foreground--text">N1:</strong>
      The ability to understand Japanese used in a variety of circumstances.
      
      <strong class="foreground--text">N2:</strong>
      The ability to understand Japanese used in everyday situations, and in a variety of circumstances to a certain degree.

      <strong class="foreground--text">N3:</strong>
      The ability to understand Japanese used in everyday situations to a certain degree.

      <strong class="foreground--text">N4:</strong>
      The ability to understand basic Japanese.

      <strong class="foreground--text">N5:</strong>
      The ability to understand some basic Japanese.
      `
    },
    autoFillOut: {
      title: 'Auto fill out',
      body: 'If you have CV file, our system will parse it and help to fill in some fields (one time).'
    },
    gtalent: {
      title: 'What is G  Talent ?',
      body: 'G Talent is recruitment agency specialized in Global IT Talent in Japan. Some recruiters specialized IT industries support you get jobs in Japan.'
    }
  },
  tips: {
    default: {
      header: 'Welcome to GitTap',
      tip: `Fill out your information.
      Get Scouted!!
      
      If you have any question, click question mark. HELP will be showed up here.`
    },
    prefectures: {
      header: 'Select prefectures',
      tip: 'You can choose prefectures'
    },
    industry: {
      header: 'Select Industry',
      tip: 'You can choose industry'
    },
    itSkill: {
      header: 'Select IT skills',
      tip: 'You can select your IT skills.'
    },
    jobRole: {
      header: 'Select job roles',
      tip: 'You can select your experienced job role.'
    },
    school: {
      header: 'Input schools',
      tip: 'You can input your educational background.'
    },
    company: {
      header: 'Add Company',
      tip: 'Added company will be sorted automatically.'
    }
  },
  registerToGTalent:
    'Register to <span class="primary--text">G Talent</span> [Our job hunting services]',
  workRemotelyFromOverseas: 'If possible, work remotely from overseas',
  gTalentHelp:
    '<strong>“G Talent”</strong> is a recruitment agency which specializes in assisting Global IT Talent in their job search in Japan. Recruiters who are specialized in the IT industries will support you to land your dream opportunity in Japan.',
  gTalentDescription:
    'You can also Register to <strong>“G Talent”</strong>, which is our job hunting service.[Please Note : When applying to our services, it is<br>necessary to agree to the <span class="primary--text">terms of use</span> and to provide consent<br> for <span class="primary--text">handling of personal information</span>(i.e member information). Please read and review the terms and conditions of this Agreement carefully before using this Service.]',
  yearsOfExperience: {
    fiveYears: '5+ years',
    threeToFiveYears: '3 - 5 years',
    oneToThreeYears: '1 - 3 years',
    lessThanOneYear: 'Less than a year'
  },
  welcome: {
    header: 'Welcome to GitTap',
    item1: 'Registration is not yet complete. <br> Fulfill your profile first.',
    item2:
      'The screening pass rate and scout reception rate will greatly increase.',
    enterManually: 'Enter Manually'
  }
}
