export default {
  error: 'Error',
  required: 'Required',
  float: 'Must be a number.',
  integer: 'Must be an integer.',
  number: 'Must be a number.',
  lessThan: 'Must be less than {0}.',
  lessThanOrEqualTo: 'Must be less than or equal to {0}.',
  greaterThan: 'Must be greater than {0}.',
  greaterThanOrEqualTo: 'Must greater than or equal to {0}.',
  between: 'Must be between {0} and {1}.',
  size: 'Size must be {0}.',
  length: 'Length must be {0}.',
  minLength: 'Must have at least {0} characters.',
  maxLength: 'Must be {0} characters or less.',
  lengthBetween: 'Length must between {0} and {1}.',
  in: 'Must be {0}.',
  notIn: 'Must not be {0}.',
  match: 'Not matched.',
  regex: 'Format is invalid.',
  digit: 'Must be a digit.',
  email: 'This email address is invalid',
  url: 'Invalid url',
  unique: 'Must be unique.',
  saveDuplicatedMessage: 'That condition is already saved',
  optionCombiner: function (options) {
    if (options.length > 2) {
      options = [
        options.slice(0, options.length - 1).join(', '),
        options[options.length - 1]
      ]
    }
    return options.join(' or ')
  }
}
