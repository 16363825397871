export default {
  email: {
    subscribeEmail: {
      title: 'メール受信設定',
      subtitle1: '注目の求人情報やキャリアアップに役立つ情報をお届けします。',
      subtitle2: '※すべてをOFFにした場合でも、一部アラートメール等は届くようになっています。予めご了承ください。'
    },
    notifications: 'お知らせ',
    reminderOfMessage: 'メッセージ受信のリマインド',
    reminderOfScouted: '「スカウト」受信のリマインド',
    notificationOfPinned: '「気になる」受信のお知らせ',
    notifFromGitTap: 'GitTap事務局からのお知らせ'
  }
}
