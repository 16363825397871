import peoplePercent from '@/api/client/people-percent'

export default {
  namespaced: true,
  state () {
    return {
      loading: false,
      items: sessionStorage.getItem('peoplePercent')
        ? JSON.parse(sessionStorage.getItem('peoplePercent'))
        : []
    }
  },
  getters: {
    hasItems: state => {
      return state.items.length > 0
    }
  },
  mutations: {
    /**
     * Set the items.
     *
     * @param {*} state
     * @param {*} items
     */
    SET_ITEMS (state, items) {
      state.items = items
    },
    /**
     * Set the loading status.
     *
     * @param {*} state
     * @param {*} items
     */
    SET_LOADING (state, loading) {
      state.loading = loading
    }
  },
  actions: {
    /**
     * Fetch items
     * If items is already populated skip the process
     *
     * @param { getters, dispatch}
     */
    softFetch ({ getters, dispatch }) {
      if (getters.hasItems) return

      dispatch('fetch')
    },

    /**
     * Fetch Items
     *
     * @param {commit, dispatch} object
     */
    fetch ({ commit, dispatch }) {
      commit('SET_LOADING', true)

      return peoplePercent.index()
        .then(({ data }) => {
          commit('SET_ITEMS', data)

          sessionStorage.setItem('peoplePercent', JSON.stringify(data))
        })
        .catch(({ response }) => {
          dispatch('httpException/handle', response, { root: true })
        })
        .finally(() => {
          commit('SET_LOADING', false)
        })
    }
  }
}
