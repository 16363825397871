import api from '../index'

const { http } = api

/**
 * =============================
 * Approach List Applicants API
 * =============================
 */
export default {
  /**
   * Approach List v1 endpoint
   */
  baseUrlV1: `${process.env.VUE_APP_COMMON_URL}/api/v1`,
  urlV1: 'approach-lists/applicants',

  baseUrl: `${process.env.VUE_APP_COMMON_URL}/api/v2`,
  url: 'customer/approach',
  http,

  /**
   * Get the applied list.
   *
   * @param params
   */
  applied(params) {
    return this.http(this.baseUrl)
      .get(`${this.url}/applies`, { params })
  },

  /* Get the interested list.
   *
   * @param params
   */
  interests(params) {
    return this.http(this.baseUrl)
      .get(`${this.url}/interests`, { params })
  },

  /* Get the scouts list.
   *
   * @param params
   */
  scouts() {
    return this.http(this.baseUrl)
      .get(`${this.url}/scouts`)
  },

  /* Set Read.
   *
   * @param params
   */
  read(params) {
    return this.http(this.baseUrl)
      .post(`${this.url}/read`, params)
  },

  /* Hide Approach.
   *
   * @param params
   */
  hide(params) {
    return this.http(this.baseUrl)
      .post(`${this.url}/hide`, params)
  },

  /* Apply in approach.
   *
   * @param params
   */
  apply(params) {
    return this.http(this.baseUrl)
      .post(`${this.url}/store-apply`, params)
  },

  /* Store Save Approach.
   *
   * @param params
   */
  save(params) {
    return this.http(this.baseUrl)
      .post(`${this.url}/save`, params)
  },

  /**
   * Get the hidden list.
   *
   * @param params
   */
  hidden(params) {
    return this.http(this.baseUrl)
      .get(`${this.url}/hidden`, { params })
  },

  /**
   * Get the recommendation list.
   *
   * @param params
   */
  recommend(params) {
    return this.http(this.baseUrl).get(`${this.url}/recommend`, { params })
  },

  /**
   * unhide the hidden item.
   *
   * @param params
   */
  visible(params) {
    return this.http(this.baseUrl)
      .post(`${this.url}/visible`, params)
  },

  /* Get the job details.
   *
   * @param params
   */
  jobDetails(id) {
    return this.http(this.baseUrl)
      .get(`${this.url}/job-posting/${id}`)
  },

  /* Get the job details.
   *
   * @param params
   */
  saved(params) {
    return this.http(this.baseUrl)
      .get(`${this.url}/saves`, { params })
  },

  /* Get unread approach count.
   *
   * @param params
   */
  unread () {
    return this.http(this.baseUrl)
      .get(`${this.url}/unread`)
  },

  /**
   * Has Approach
   *
   * @param id
   */
  hasApproach (params) {
    return this.http(this.baseUrl)
      .post(`${this.url}/has-approach`, params)
  },

  /**
   * I would like to Know More.
   *
   * @param {*} payload
   */
  pinResponse (payload) {
    return this.http(this.baseUrlV1)
      .post(`${this.urlV1}/pin-response`, payload)
  },

  /**
   * Mark the match as read.
   *
   * @param {*} id
   */
  markAsReadMatch (id) {
    return this.http(this.baseUrlV1)
      .put(`${this.urlV1}/mark-as-read-match/${id}`)
  },

  /**
   * Mark the pin as hidden.
   *
   * @param id
   */
  markAsReadPin (id) {
    return this.http(this.baseUrlV1)
      .put(`${this.urlV1}/mark-as-read-pin/${id}`)
  },

  /**
   * Mark the pin as visible.
   *
   * @param id
   */
  markAsVisiblePin (id) {
    return this.http(this.baseUrlV1)
      .put(`${this.urlV1}/mark-as-visible-pin/${id}`)
  },

  /**
   * Mark the pin as hidden.
   *
   * @param id
   */
  markAsHiddenPin (id) {
    return this.http(this.baseUrlV1)
      .put(`${this.urlV1}/mark-as-hidden-pin/${id}`)
  },
}
